<template>
  <v-container fluid class="pa-0">
    <div class="addressImage">
      <v-img v-if="backgroundImage" height="100%" :src="backgroundImage"> </v-img>
    </div>
    <v-row justify="center">
      <v-card flat class="addressContainer" color="transparent">
        <div
          :name="debugName('config.addressSearchText')"
          :class="messageFont"
          id="address-search-text"
          class="autocomplete-message font-weight-bold primary--text address-search-label"
          v-html="addressSearchText"
        ></div>
        <v-autocomplete
          :name="debugName('config.addressSearchPlaceholder')"
          class="pb-2"
          :color="autoCompleteColor"
          ref="autocomplete"
          v-model="model"
          :loading="loading"
          :items="addressSuggestions"
          item-text="full"
          :search-input.sync="search"
          outlined
          :placeholder="placeholder"
          solo
          @change="addressChanged"
          clearable
          clear-icon="$clear"
          :hint="addressSearchHint"
          return-object
          @click:clear="clear"
          hide-no-data
          autofocus
          no-filter
          :rules="[addressRule]"
        />
        <v-row
          v-if="serviceabilityFields || debugName('true')"
          :name="debugName('config.serviceabilityFields')"
          dense
          justify="start"
        >
          <v-container class="serviceability-fields">
            <v-col v-for="field in serviceabilityFields" :key="field.storeProp"
              :class="field.colClass ? field.colClass : 'col-12 pt-0'">
              <AccountFieldUI :class="field.fieldClass" :field="field" />
            </v-col>
            <v-col v-if="!serviceabilityFields">--ServicabilityFields go here--</v-col>
          </v-container>
        </v-row>
        <v-row v-if="addressSearchSubtitle || debugName('true')" :name="debugName('config.addressSearchSubtitle')">
          <v-col v-if="addressSearchSubtitle" class="address-search-subtitle" v-html="addressSearchSubtitle"></v-col>
          <v-col v-else class="address-search-subtitle"
            ><span :name="debugName('config.addressSearchSubtitle')">--addressSearchSubtitle goes here--</span></v-col
          >
        </v-row>
        <v-row dense justify="center">
          <v-col align-self="center">
            <StepperButtons />
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api'
import useServiceability from './useServiceability'
import { FETCH_ADDRESS_SUGGESTIONS, GET_ADDRESS_SUGGESTIONS, RESET_ADDRESS_SUGGESTIONS } from '@/store/types'
import useOrder from '@/components/order/useOrder'
import StepperButtons from '@/components/order/StepperButtons.vue'
import useImageUtil from '@/components/shared/useImageUtil' //KWC move to App.vue?
import useUiConfig from '@/components/shared/useUiConfig'
import { getConfigBoolean, getConfigItem, getConfigItemByString, getConfigString } from '../shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import ga4 from '@/gtm/ga4'
import router from '@/router'
import { AccountField } from '@adg/catalog/src/common/UIConfig'
import AccountFieldUI from '@/components/order/account/AccountFieldUI.vue'
import type { Place } from '@adg/catalog/src/common/addressUtil'
import { debugName } from '@/utils/StoreHelpers'

export default defineComponent({
  name: 'Serviceability',
  components: {
    StepperButtons,
    AccountFieldUI
  },
  setup(props, { refs, root }) {
    const { $vuetify, $router } = root as any
    const { imageTagToVideo } = useImageUtil() //KWC I think this should be in App.vue
    const { autoCompleteColor } = useUiConfig()

    const addressSearchText = computed(() => getConfigString(ConfigKeys.addressSearchText) ?? 'Find the best offers in your area')
    const placeholder = computed(() => getConfigString(ConfigKeys.addressSearchPlaceholder) ?? 'Enter your address')
    const student = computed(() => getConfigBoolean(ConfigKeys.student))
    const shouldValidateAddress = computed(() => getConfigBoolean(ConfigKeys.addressRule) ?? false)
    const extraAutoCompleteParams = computed(() => getConfigString(ConfigKeys.extraAutoCompleteParams) ?? '')
    const serviceabilityFields = computed((): AccountField[] => getConfigItem(ConfigKeys.serviceabilityFields) ?? undefined)
    const addressSearchSubtitle = computed(() => getConfigItem(ConfigKeys.addressSearchSubtitle) ?? undefined)

    const isStudent = computed({
      get: () => $store.getters.getStudent,
      set: (val) => {
        $store.commit('setStudent', val)
        usePiniaShopper().shopper.customInfo.isStudent = val
      }
    })

    const studentText = computed(() => getConfigString(ConfigKeys.studentText) ?? "I'm currently a student")

    const addressSearchHint = computed(
      () => getConfigString(ConfigKeys.addressSearchHint) ?? '* Residential customers only. Certain terms and conditions apply'
    )

    const image = computed(() => getConfigString(ConfigKeys.addressSearchBackground))

    const mobileImage = computed(() => getConfigString(ConfigKeys.addressSearchBackgroundMobile) ?? image.value)

    const iPadImage = computed(() => getConfigString(ConfigKeys.addressSearchBackgroundiPad) ?? image.value)

    const backgroundImage = computed(() => {
      switch ($vuetify.breakpoint.name) {
        case 'xs':
          return mobileImage.value
        case 'sm':
          return iPadImage.value
        case 'md':
          return iPadImage.value
        case 'lg':
          return image.value
        case 'xl':
          return image.value
        default:
          return image.value
      }
    })

    onMounted(() => {
      imageTagToVideo()
      let autocompleteInput = (refs as any).autocomplete.$refs.input
      autocompleteInput.addEventListener('focus', onFocus, true)
    })

    const onFocus = () => {
      if (refs && refs.autocomplete) {
        ; (refs as any).autocomplete.isMenuActive = true
      }
    }

    const loading = ref(false)
    const model = ref({} as Place)
    const search = ref('')
    const { convertAddress } = useServiceability()
    const { validation, currentStep } = useOrder($store, $router)

    const addressRule = (value: Place) => {
      if (value?.full && shouldValidateAddress.value) {
        let firstChar = parseInt(value.full[0])
        if (isNaN(firstChar)) {
          return 'Address search requires a street number to find a match.'
        }
      }
      return true
    }

    const addressSuggestions = computed(() => {
      const suggestions = $store.getters[GET_ADDRESS_SUGGESTIONS]
      return suggestions.map(convertAddress)
    })

    watch(search, (search, prevSearch) => {
      if (search === '') $store.commit('updateShopperAddress', undefined)
      if (prevSearch && prevSearch.indexOf('units)') >= 0) return
      if (model !== null && model.value !== null && model.value && search && search.indexOf('units)') >= 0) {
        querySelections(search, model.value.secondarySearch)
        $store.commit('updateShopperAddress', undefined)
      } else {
        search && search !== model.value && querySelections(search)
      }
    })

    const clear = () => {
      $store.commit(RESET_ADDRESS_SUGGESTIONS)

      $store.commit('updateShopperAddress', undefined)
    }

    const addressChanged = (v: any) => {
      $store.commit('updateShopperAddress', model.value)

      // if (model !== null && model.value !== null && model.value?.full?.indexOf('units') == -1) {
      //   ga4.pushAddressSelected()
      // }
    }

    const querySelections = async (searchValue: string, secondarySearchValue?: string) => {
      loading.value = true
      $store.dispatch(FETCH_ADDRESS_SUGGESTIONS, {
        search: searchValue,
        selected: secondarySearchValue ? secondarySearchValue.trim() : null,
        extraAutoCompleteParams: extraAutoCompleteParams.value
      })
      if (secondarySearchValue) {
        search.value = secondarySearchValue.substring(0, secondarySearchValue.indexOf('(')).trim()
        onFocus()
      }
      loading.value = false
    }

    const messageFont = computed(() => {
      switch ($vuetify.breakpoint.name) {
        case 'xs':
          return 'title'
        case 'sm':
          return 'headline'
        case 'md':
          return 'display-1'
        case 'lg':
          return 'display-1'
        case 'xl':
          return 'display-1'
        default:
          return 'title'
      }
    })

    const openIframe = () => router.push('/iframe')

    return {
      addressSearchText,
      addressSearchHint,
      loading,
      model,
      search,
      addressChanged,
      clear,
      addressSuggestions,
      validation,
      image,
      messageFont,
      autoCompleteColor,
      student,
      studentText,
      isStudent,
      placeholder,
      addressRule,
      shouldValidateAddress,
      backgroundImage,
      openIframe,
      serviceabilityFields,
      addressSearchSubtitle,
      debugName
    }
  }
})
</script>

<style>
.smaller-checkbox {
  border-width: thin;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.bigger-checkbox {
  font-weight: 100;
  transform: scale(1.65);
  transform-origin: left;
  border-width: thin;
}

.addressImage {
  height: 71vh;
}
</style>

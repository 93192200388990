import { GET_SHOW_SPINNER } from '@/store/types'
import { UIConfig } from '@adg/catalog/src/common/UIConfig'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { computed } from '@vue/composition-api'
import { getConfigItem } from './shared/getConfigItem'

export default ($store) => {
  const uiConfig = computed(() => $store.getters.getUIConfig as UIConfig)

  const footerColor = computed(() => getConfigItem(ConfigKeys.footerColor) ?? 'black')

  const links = computed(() => uiConfig.value.footerLinks ?? uiConfig.value['footer-links'] ?? [])

  const socialMediaImages = computed(() => uiConfig.value.socialMediaImages ?? [])

  const footerComponent = computed(() => getConfigItem(ConfigKeys.footerComponent) ?? 'AppFooter')

  const footerTopText = computed(() => getConfigItem(ConfigKeys.footerTopText))

  const footerLogo = computed(() => getConfigItem(ConfigKeys.footerIcon) ?? '/api/content/footerLogo.png')

  return {
    uiConfig,
    footerColor,
    links,
    footerComponent,
    footerTopText,
    footerLogo,
    socialMediaImages
  }
}


<div v-if="isDebug" style="display: inline-block">
  <v-icon :size="$vuetify.breakpoint.xsOnly ? 18 : 18" class="black--text text--lighten-2" dark @click="openModal">{{
    mdiBugCheck
  }}</v-icon>
  <v-overlay :opacity="0.1" v-if="showModal" z-index="6" height="100vh" class="customOverlay">
    <v-dialog v-model="showModal" @close="closeModal" max-width="70%">
      <v-card class="pt-2 rounded-lg" rounded>
        <v-card-actions v-if="showAgentIframe">
          <v-btn @click="openIframe"> Agent View </v-btn>
        </v-card-actions>
        <v-card-text class="py-0">
          <ExpandableLine :name="tooltipName" :value="item" :expanded="true" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-overlay>
</div>


<v-container class="mt-12">
  <v-row no-gutters class="text-center">
    <v-col id="active-address-title" class="headline" cols="12" v-if="matchedAddress && isResidential"
      >We found an active account at that address.</v-col
    >
    <v-col id="active-account-subtitle" class="title" cols="12" v-if="matchedAddress && isResidential"
      >Please tell us more about yourself</v-col
    >
    <v-col class="headline pb-4" cols="12" v-if="matchedAddress && !isResidential">
      The address you entered matches to a commercial / business address.</v-col
    >
  </v-row>
  <v-row justify="center" class="text-center">
    <v-col cols="12" class="col-md-4 pa-2" v-for="(q, i) in questions" :key="q.question">
      <ServiceabilityQuestion
        :id="'question' + i"
        :question="q"
        :color="colors[i]"
        @answerToQuestion="answerQuestion"
        @questionValue="questionValue"
        :residential="isResidential"
      />
    </v-col>
  </v-row>
  <v-row dense justify="center">
    <v-col align-self="center">
      <StepperButtons :backAction="returnToStart" />
    </v-col>
  </v-row>
</v-container>

import { groupBy } from 'lodash'
import { COMPONENT_ORDER } from '@/store/types'
import { AnyKeyFunction } from './HelperTypes'
import { Product } from '@adg/catalog/src'

export interface Group {
  name: string
  products: any[]
  type: string
}

const getType = (items: Group[]) => {
  let type = ''
  if (items.some((i: any) => i.Min === 1 && i.Max === 1)) {
    type = 'radio'
  } else if (items.some((i: any) => i.Min === 0 && i.Max === 1)) {
    type = 'checkbox'
  }
  return type
}

//todo: this needs to be typed, unsure of what to type it
export const groupProducts = (products) => {
  const groups: Group[] = []
  const groupByGroupName = groupBy(products, 'Group')
  Object.keys(groupByGroupName).forEach((k: string) => {
    const groupType: string = getType(groupByGroupName[k])
    let products = groupByGroupName[k].sort((a, b) => a[COMPONENT_ORDER] - b[COMPONENT_ORDER])
    if (products[0].Rank !== undefined) {
      products = products.sort((a, b) => a.Rank - b.Rank)
    }
    const x = { name: k, products, type: groupType }
    groups.push(x)
  })
  // groups.push({name: 'test', products: [], type: 'radio'})
  return groups
}

// type KeyFunction<T> = (item: T) => string
// type AnyKeyFunction<T> = (item: T) => any
// type KeyWithStringValue<T> = { [P in keyof T]-?: T[P] extends string ? P : never }[keyof T]
// type KeyWithAnyValue<T> = { [P in keyof T]-?: T[P] extends any ? P : never }[keyof T]
// type ParmReturnType<T, F extends AnyKeyFunction<T>> = { [P in keyof T]-?: T extends KeyWithAnyValue<T> ? T[P] : ReturnType<F> }

// function myGroupBy<T extends object, F extends KeyWithAnyValue<T>>(
//   array: T[],
//   keyFunction: P | AnyKeyFunction<T>
// ): Map<ParmReturnType<T,P>, T[]> {
//   const map = new Map<R, T[]>()

//   array.forEach((item) => {
//     const key = typeof keyFunction === 'function' ? keyFunction(item) : (item[keyFunction] as string)
//     const collection = map.get(key)
//     if (!collection) {
//       map.set(key, [item])
//     } else {
//       collection.push(item)
//     }
//   })

//   return map
// }

function aBetterGroupBy<T extends object, F extends AnyKeyFunction<T>, R extends ReturnType<F>>(
  array: T[],
  keyFunction: F
): Map<R, T[]> {
  const map = new Map<R, T[]>()

  array.forEach((item) => {
    const key = keyFunction(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })

  return map
}

// type Foo = {
//   first_name: string
//   last_name: string
//   age: number | string
//   height: number
// }

// const foo: Foo[] = [
//   { first_name: 'John', last_name: 'Doe', age: 24, height: 180 },
//   { first_name: 'Jane', last_name: 'Doe', age: 22, height: 170 },
//   { first_name: 'John', last_name: 'Smith', age: 58, height: 190 },
//   { first_name: 'Jane', last_name: 'Smith', age: 52, height: 180 }
// ]

// const groupedFoo = myGroupBy(foo, (f) => f.height)
// const groupedFoo2 = myGroupBy(foo, (f) => f.first_name)
// const names = aBetterGroupBy(foo, (f) => f.first_name)
// const ages = aBetterGroupBy(foo, (f) => f.age)

// console.log(ages)


<v-container fluid class="pa-0">
  <v-row>
    <v-col cols="12" class="pl-6">
      <div v-if="verticalThreeButtons && thirdButtonText">
        <v-row
          v-for="button in verticalButtonOrder"
          v-if="button !== 'ach'"
          :class="`stepper-button-row-stacked stepper-button-row-stacked-${button}`"
        >
          <v-col v-if="button === 'back' && showBackButton" cols="12" class="vertical-button-col">
            <v-btn
              v-if="button === 'back' && showBackButton"
              id="back-button"
              :rounded="stepperButtonRoundedCorners"
              class="mr-3 stepper-back payment-back"
              color="default"
              @click="stepBack"
              >Back</v-btn
            >
          </v-col>

          <v-btn
            v-for="b in verticalButtonOrder"
            v-if="button === 'cc' && b !== 'back'"
            :rounded="stepperButtonRoundedCorners"
            :disabled="btnDisabled"
            :id="b === 'cc' ? 'cc-button' : 'ach-button'"
            class="pill mr-3 mt-2 gtm-next-btn stepper-next"
            :class="`${defaultButtonColors()}, ${b}-button`"
            :color="stepperButtonForwardColor"
            @click="threeButtonClick(b)"
          >
            {{ b === 'cc' ? customButtonText : thirdButtonText }}
          </v-btn>
          <!-- <v-btn
            v-if="button === 'cc'"
            :rounded="stepperButtonRoundedCorners"
            :disabled="btnDisabled"
            :id="buttonId"
            class="pill mr-3 mt-2 gtm-next-btn stepper-next cc-button"
            :class="defaultButtonColors()"
            :color="stepperButtonForwardColor"
            @click="thirdButtonAction"
          >
            {{ thirdButtonText }}
          </v-btn>
          <v-btn
            v-if="thirdButtonText && button === 'ach'"
            :rounded="stepperButtonRoundedCorners"
            :disabled="btnDisabled"
            :id="'third-button'"
            class="pill mr-3 mt-2 gtm-next-btn third-button ach-button"
            :class="defaultButtonColors()"
            :color="stepperButtonForwardColor"
            @click="thirdButtonAction"
          >
            {{ thirdButtonText }}
          </v-btn> -->
        </v-row>
      </div>
      <div v-else>
        <v-row class="stepper-button-row">
          <v-btn
            id="back-button"
            :rounded="stepperButtonRoundedCorners"
            class="mr-3 stepper-back"
            color="default"
            @click="stepBack"
            v-if="showBackButton"
            >Back</v-btn
          >
          <div v-if="showCustomButton">
            <v-btn
              :rounded="stepperButtonRoundedCorners"
              :disabled="btnDisabled"
              :id="buttonId"
              class="pill mr-3 gtm-next-btn stepper-next"
              :class="defaultButtonColors()"
              :color="stepperButtonForwardColor"
              @click="stepForward"
            >
              {{ customButtonText }}
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              :rounded="stepperButtonRoundedCorners"
              v-if="!isSubmitOrderStep && currentStep !== 2"
              :disabled="btnDisabled"
              :id="buttonId"
              class="pill mr-3 gtm-next-btn stepper-next"
              :class="defaultButtonColors()"
              :color="stepperButtonForwardColor"
              @click="stepForward"
              :elevation="stepperButtonElevation"
            >
              {{ customButtonText }}
            </v-btn>
            <v-btn
              :rounded="stepperButtonRoundedCorners"
              v-if="isSubmitOrderStep"
              :disabled="btnDisabled"
              :id="buttonId"
              class="pill stepper-submit"
              :class="defaultButtonColors()"
              :color="stepperButtonForwardColor"
              @click="submitOrder"
              :elevation="stepperButtonElevation"
            >
              {{ customButtonText }}
            </v-btn>
          </div>
          <v-btn
            v-if="thirdButtonText"
            :rounded="stepperButtonRoundedCorners"
            :disabled="btnDisabled"
            :id="'third-button'"
            class="pill mr-3 pt-3 gtm-next-btn third-button"
            :class="defaultButtonColors()"
            :color="stepperButtonForwardColor"
            @click="thirdButtonAction"
          >
            {{ thirdButtonText }}
          </v-btn>
        </v-row>
      </div>
      <v-row justify="center" v-if="showTermsOfService">
        <a class="pt-2" :href="termsOfServiceDocument.link" target="_blank">{{ termsOfServiceDocument.text }}</a>
        <Tooltip :payload="{ Name: termsOfServiceDocument.text }" />
      </v-row>
      <div v-for="(footerItem, index) in offerFooter" :key="index">
        <v-row id="offer-footer-row" justify="center" v-if="showOfferFooter(footerItem)">
          <a id="offer-footer-item" class="pt-2" :href="footerItem.link" target="_blank" v-html="footerItem.text"></a>
          <Tooltip :payload="{ Name: footerItem.name }" />
        </v-row>
      </div>
    </v-col>
  </v-row>
</v-container>

import { computed } from '@vue/composition-api'
import store from '@/store'

export default () => {
  const uiConfig = computed(() => store.getters.getUIConfig)

  const defaultButtonColor = computed(() => uiConfig?.value?.defaultButtonColor)
  const defaultButtonTextColor = computed(() => uiConfig.value.defaultButtonTextColor)

  const buttonColor = (revertColor?: string) => defaultButtonColor.value ?? revertColor ?? ''

  const buttonTextColor = (revertColor?: string) => {
    if (defaultButtonTextColor.value) {
      return `${defaultButtonTextColor.value}--text`
    }
    if (revertColor) {
      return `${revertColor}--text`
    }
    return ''
  }

  //depricated
  const defaultButtonColors = (revertColor?: string) => {
    let btnClass = defaultButtonColor.value ?? revertColor ?? ''
    if (!!defaultButtonTextColor.value) {
      btnClass += ` ${defaultButtonTextColor.value}--text`
    }
    return btnClass
  }

  return { defaultButtonColors, buttonColor, buttonTextColor }
}


<v-hover>
  <template v-slot="{ hover }">
    <v-row justify="center">
      <v-card
        :key="question.question"
        hover
        flat
        class="pa-2 my-auto outer-card card-bottom-corners"
        min-width="230"
        max-width="350"
        color="transparent"
        v-bind="cardAttrs"
        @click="onClick(question)"
      >
        <v-card class="questionToolbar rounded-b-0" dark :color="color" :height="160">
          <v-container>
            <v-row class="text-center">
              <v-col class="title font-weight-bold">
                <div v-html="question?.question"></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-container fluid class="pa-0">
          <v-row dense>
            <v-col cols="12" class="py-0">
              <v-card class="rounded-t-0">
                <v-img :src="backgroundImage" class="align-end questionImage" :height="backgroundImage ? '300px' : '50px'">
                  <v-card-text class="pt-0">
                    <div color="black" v-html="question?.questionText"></div>
                    <v-card-actions class="pt-0 pb-12" id="serviceability-actions">
                      <v-spacer></v-spacer>
                      <v-btn
                        v-for="action in question?.actions"
                        :key="action.name"
                        :class="defaultButtonColors('secondary')"
                        min-width="100"
                        :href="action.link"
                        ripple
                        @click="selected(action)"
                        :rounded="serviceabilityQuestionButtonRounded"
                        :elevation="serviceabilityQuestionButtonElevation"
                      >
                        {{ action.name }}
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card-text>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
  </template>
</v-hover>

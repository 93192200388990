<template>
  <v-container fluid class="pa-0" id="tv-equipment">
    <v-row>
      <v-col cols="12" class="pb-0 pt-2">
        <NumberPicker
          v-model="numTV"
          @input="numberOfTvsChanged"
          :min="calcMinTv"
          :max="getMaxTvs"
          :value="numTV"
          description="How many TVs would you like service on?"
        />
      </v-col>
    </v-row>

    <v-row v-for="(n, index) in numTV" :key="n">
      <v-col cols="6" class="py-0 pr-1">
        <v-select
          class="my-0 py-0 black--text"
          dense
          :append-icon="products.length > 1 ? '$dropdown' : ''"
          outlined
          v-model="tvSelections[index]"
          :readonly="products.length === 1"
          :label="buildTvLabel(index)"
          :items="sortedTvItems(index)"
          item-text="Display Name"
          item-value="Name"
          @change="tvSelectionChanged(tvSelections[index], n)"
          return-object
        ></v-select>
      </v-col>
      <v-col cols="1" class="pa-0"><Tooltip :payload="{ ...tvSelections[index], type: 'Equipment' }" /></v-col>
      <v-col cols="5" class="py-0 text-right">
        <span
          v-html="
            uiMacroParser(shopper, tvSelections[index].PricePrefix ?? '') +
            ' ' +
            (tvSelections[index] && coreCurrency(getTvItemPrice(tvSelections[index], index)))
          "
        ></span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { coreCurrency } from '@adg/catalog/src/common/filters'
import { defineComponent, ref, computed, onMounted, watch, watchEffect } from '@vue/composition-api'
import NumberPicker from '../../shared/NumberPicker.vue'
import TvEquipmentMarketing from './TvEquipmentMarketing.vue'
import { bus } from '@/main'
import { mdiInformation } from '@mdi/js'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import useFunctions from '../useFunctions'
import useCatalogConfig from '@/components/shared/useCatalogConfig'
import { curry, countBy, isEqual } from 'lodash'
import useCart from '../cart/useCart'
import { catalogRank, CountedEquipmentGroup, getItemPrice } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'
import useTv from './useTv'
//import { deepEqual } from 'node:assert'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { IShopper } from '@adg/catalog/src/modules/Shopper'

export default defineComponent({
  name: 'TvEquipment',
  props: {
    products: {
      type: Array as () => CountedEquipmentGroup[],
      required: true
    },
    minTvChoices: Number,
    maxTvChoices: Number
  },
  components: {
    TvEquipmentMarketing,
    NumberPicker,
    Tooltip
  },
  setup(props, { emit }) {
    watch(
      () => props.products,
      (a, b) => {
        tvSelections.value.splice(0, 100)
        setIncludes()
        numberOfTvsChanged(props.minTvChoices)
      }
    )
    const { calculatePrice } = useFunctions()
    //const tv1Products = computed(() => props.products?.filter((p: any) => !p.Parent)) ?? []
    const { getMaxTvs, getMinTvs } = useCatalogConfig($store)
    const { mapCountedSelections } = useTv()
    const { cartItems } = useCart($store)

    const shopper = computed(() => $store.getters.getShopper as IShopper)

    const numTV = computed({
      get: () => {
        const val = $store.getters.getNumTv as number
        return val
      },
      set: (val) => {
        $store.commit('setNumTv', val)
      }
    })
    const tvSelections = ref([] as CountedEquipmentGroup[])

    const buildTvLabel = (index: number) => `TV${index + 1}`

    const sortedTvItems = (index: number) => {
      //  props.products?.filter(curry(parentExistsInOtherTvs)(index)).sort((a, b) => catalogRank(a.Rank) - catalogRank(b.Rank)) ?? []
      const items =
        props.products
          ?.map((i) => ({ ...i, 'Display Name': i['Display Name'] ?? i.Name })) // make sure 'Display Name' is set
          .filter(curry(parentExistsInOtherTvs)(index))
          .sort((a, b) => catalogRank(a.Rank) - catalogRank(b.Rank)) ?? []
      return items
    }

    // determine if an item has a parent in other TV selection boxes
    const parentExistsInOtherTvs = (index, product: CountedEquipmentGroup) => {
      // tvSelections already includes the current index, so we need to exclude it
      let othertvs = [...tvSelections.value]
      othertvs.splice(index, 1) // remove the current index
      return !product.Parent || othertvs.map((s) => s.Name).includes(product.Parent)
    }

    const tvSelectionChanged = () => {
      const tvDefault: any = props.products?.find((e: any) => e?.Default === 'yes')
      const newS: any[] = []

      tvSelections.value.forEach((tv, index) => {
        if (tv.Parent) {
          const parentSelected = tvSelections.value.find((x, index) => x.Name === tv.Parent)
          newS.push(parentSelected ? { ...tv } : { ...tvDefault })
        } else {
          newS.push({ ...tv })
        }
      })
      // clear tvSelections
      tvSelections.value.splice(0, 100)
      tvSelections.value = calcPrice(newS)
      numberOfTvsChanged(tvSelections.value.length)
      emit('input', tvSelections.value)
    }

    watchEffect(() => {
      const allSelections = cartItems.value.map((s) => s.Name)
      const allState = [...allSelections]
      tvSelections.value.map((n) => calculatePrice(allState, n))
    })

    const calcPrice = (selections: CountedEquipmentGroup[]) => {
      const allSelections = cartItems.value.map((s) => s.Name)
      const allState = [...allSelections]
      const calculated = selections.map((n) => calculatePrice(allState, n))
      return calculated
    }

    const sorted = computed(
      () =>
        (props.products &&
          [...props.products].sort((a: any, b: any) => {
            if (a.Default === b.Default) {
              if (a.priceIncluded === b.priceIncluded) {
                return a.Rank - b.Rank
              } else {
                return a.priceIncluded ? -1 : 1
              }
            } else {
              return a.Default === 'yes' ? -1 : 1
            }
          })) ??
        []
    )

    const process = (product, count, numTvs) => {
      const included = product?.hasOwnProperty('included') ? Number(product.included) : 0
      const priceIncluded = count < included
      return { ...product, priceIncluded }
    }

    const calcMinTv = computed(() => tvSelections.value[0]?.Min ?? props.minTvChoices)

    const numberOfTvsChanged = async (numTvs) => {
      const newS = [] as CountedEquipmentGroup[]
      const unparented = tvSelections.value[0] ? tvSelections.value : sorted.value.filter((t: any) => !t.Parent)

      let defaultTv1: CountedEquipmentGroup = sorted.value.find((s) => s.Name === unparented[0].Name) ?? unparented[0]
      let defaultTv2 = sorted.value.find((t: any) => !!defaultTv1 && t.Parent === defaultTv1.Name) || defaultTv1

      // console.log(defaultTv1)

      // if (defaultTv1 && defaultTv2) {
      //   if (defaultTv1.itemType && defaultTv1.itemType === 'CountedEquipmentGroup') {
      //     const childFound = defaultTv1.subItems?.find((item) => (item.Min ?? 0) <= 1 && 1 <= (item.Max ?? Infinity)) ?? defaultTv1
      //   }

      // find first child of defaultTv1 fo Tv2

      for (let i = 0; i < numTvs; i++) {
        // if (defaultTv2.itemType && defaultTv2.itemType === 'CountedEquipmentGroup') {
        //   defaultTv2 = defaultTv2.subItems?.find((item) => (item.Min ?? 0) <= i && i <= (item.Max ?? Infinity)) ?? defaultTv2
        // }
        let currentTv
        if (tvSelections.value.length > i) {
          currentTv = tvSelections.value[i]
        } else {
          currentTv = i === 0 ? defaultTv1 : defaultTv2
        }
        const countByName = countBy(newS, (v) => v.Name)
        const count = tvSelections.value.length > 0 ? countByName[currentTv.Name] ?? 0 : 0
        newS.push(process(currentTv, count, numTvs))
      }
      // }
      tvSelections.value = calcPrice(newS)
      numTV.value = numTvs
      emit('input', tvSelections.value)
    }

    bus.$on('cartChanged', (data) => {
      const newSelections = calcPrice(tvSelections.value)
      if (!isEqual(tvSelections.value, newSelections)) {
        tvSelections.value = newSelections
        emit('input', tvSelections.value)
      }
    })

    bus.$on('packageChanged', (data) => {
      //reset state
      tvSelections.value.splice(0, 100)

      numberOfTvsChanged(props.minTvChoices)
    })

    const setIncludes = () => {
      props.products?.forEach((product: any) => {
        product.priceIncluded = !!product.included
      })
    }

    onMounted(() => {
      numberOfTvsChanged(props.minTvChoices)
    })

    const getTvItemPrice = (item: CountedEquipmentGroup, index: number) => {
      if (!item.subItems) return getItemPrice(item, 'Monthly Price')
      const filteredItems = mapCountedSelections(tvSelections.value)

      return getItemPrice(filteredItems[index], 'Monthly Price')
    }

    return {
      numTV,
      numberOfTvsChanged,
      tvSelectionChanged,
      tvSelections,
      buildTvLabel,
      sortedTvItems,
      mdiInformation,
      parentExistsInOtherTvs,
      getMaxTvs,
      getMinTvs,
      calcMinTv,
      getTvItemPrice,
      coreCurrency,
      uiMacroParser,
      shopper
    }
  }
})
</script>

<style>
#tv-equipment .theme--light.v-select .v-select__selection--disabled {
  color: black;
}
</style>

import { AutomationSchedule, Timeslot, TimeslotDef } from './AutomationSchedule'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import dayjsBusinessDays from 'dayjs-business-days2'
import { SchedulingConfig } from './UIConfig'
import { IShopper } from '../modules/Shopper'
dayjs.extend(isBetween)
dayjs.extend(dayjsBusinessDays)

/**
 * Calculate the date that is `daysOut` business days from `startDate`
 * @param startDate | start date converted to time in milliseconds (Ex: new Date().getTime())
 * @param daysOut | number - number of business days to add to `startDate`
 * @param unavailableDays | number[] - days of the week that are unavailable (0 = Sunday, 1 = Monday, etc.)
 * @param includeUnavailableDays | boolean - include unavailable days in the calculation
 * @returns Date | date that is `daysOut` business days from `startDate`
 */
export const calculateDaysOut = (
  startDate: number,
  daysOut: number,
  unavailableDays: number[],
  includeUnavailableDays: boolean = false
): Date => {
  if (!includeUnavailableDays) {
    dayjs.setWorkingWeekdays([0, 1, 2, 3, 4, 5, 6].filter((i) => !unavailableDays.includes(i)))
  } else {
    dayjs.setWorkingWeekdays([0, 1, 2, 3, 4, 5, 6])
  }
  return dayjs(startDate).businessDaysAdd(daysOut).toDate()
}

const createTimeSlot = (day: number, defaults: TimeslotDef[]): Timeslot[] => {
  const ts: Timeslot[] = []
  defaults.forEach((d) => {
    const [startHour, startMinute] = d.startTime.split(':')
    const [endHour, endMinute] = d.endTime.split(':')
    const startTime = dayjs(day).hour(Number.parseInt(startHour)).minute(Number.parseInt(startMinute))
    const endTime = dayjs(day).hour(Number.parseInt(endHour)).minute(Number.parseInt(endMinute))
    const dayOfWeek = dayjs(day).day()

    if (d.weekdays?.length && !d.weekdays.includes(dayOfWeek)) {
      // don't push this time slot
    } else {
      ts.push({
        startTime: startTime.toDate().getTime(),
        endTime: endTime.toDate().getTime(),
        description: d.label,
        label: d.label
      })
    }
  })

  return ts
}

export const getPreferredDaysOut = (config: SchedulingConfig, shopper?: IShopper): { min: number; max: number } => {
  const isPortedPhoneOrder =
    shopper?.customInfo?.currentPhone?.number && shopper?.cart?.products.some((p) => p['Product Type'] === 'Phone')
  if (isPortedPhoneOrder) {
    return {
      min: config.installMinDaysOutPortedPhone ?? config.installMinDaysOut,
      max: config.installMaxDaysOutPortedPhone ?? config.installMaxDaysOut
    }
  }

  return {
    min: config.installMinDaysOut,
    max: config.installMaxDaysOut
  }
}

export const createDefaultTimeslots = (
  startDate: number,
  minDaysOut: number,
  maxDaysOut: number,
  unavailableDays: number[],
  defaults: TimeslotDef[]
): AutomationSchedule => {
  dayjs.setWorkingWeekdays([0, 1, 2, 3, 4, 5, 6].filter((i) => !unavailableDays.includes(i)))
  const defaultTimeSlots: Timeslot[] = []

  const minDateOut = calculateDaysOut(startDate, minDaysOut, unavailableDays)
  const maxDateOut = calculateDaysOut(startDate, maxDaysOut, unavailableDays)

  //if (!minDateOut || !maxDateOut) return []

  const start = dayjs(minDateOut.getTime()).hour(0).minute(0).second(0).millisecond(0)
  const end = dayjs(maxDateOut.getTime()).hour(0).minute(0).second(0).millisecond(0)

  const days: number[] = []

  dayjs.setWorkingWeekdays([0, 1, 2, 3, 4, 5, 6].filter((i) => !unavailableDays.includes(i)))

  let checkDate = dayjs(start)
  while (checkDate.isSame(start) || checkDate.isSame(end) || checkDate.isBetween(start, end)) {
    if (checkDate.isBusinessDay()) {
      days.push(checkDate.toDate().getTime())
    }
    checkDate = checkDate.add(1, 'd')
  }

  days
    .map((d) => {
      return createTimeSlot(d, defaults)
    })
    .flat()
    .forEach((t) => defaultTimeSlots.push(t))

  return { automated: 'no', timeSlots: defaultTimeSlots }
}


<v-container style="max-width: 1200px" class="px-sm-12 px-2">
  <v-row class="pa-0">
    <v-col class="col-md-7 col-sm-12 px-0">
      <v-container fluid class="py-0">
        <div v-for="([productType, products], index) in Object.entries(sortedProducts)" :key="index">
          <component
            :is="products[0]['UI Component']"
            :product="
              products.length > 1 ? products.map((p) => ({ ...p, displayOrder: index })) : { ...products[0], displayOrder: index }
            "
            :sharpCorners="sharpCorners"
          />

          <!--          <div-->
          <!--            style="padding-bottom: 20px"-->
          <!--            v-for="(product, index) in sortedProducts"-->
          <!--            :key="product.Name">-->

          <!--          <component-->
          <!--              :is="product[UI_COMPONENT]"-->
          <!--              :product="{...product, displayOrder: index}">-->
          <!--            </component>-->
        </div>

        <div id="customize-streaming-services">
          <StreamingServices :sharpCorners="sharpCorners" />
        </div>

        <!--          This takes a Package or should it just take items interested in (Upgrades with subcategory Home Automation)-->
        <div>
          <HomeAutomation :sharpCorners="sharpCorners" />
        </div>

        <!--          This takes a Package or just for now Upgrade of type Package Service (Service Protection Plan-->
        <div>
          <AdditionalServices :sharpCorners="sharpCorners" />
        </div>

        <StepperButtons />
      </v-container>
    </v-col>
    <v-col class="hidden-sm-and-down col-md-5 col-sm-12">
      <Cart />
    </v-col>
  </v-row>
</v-container>

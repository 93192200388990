<template>
  <v-container class="mt-12" v-if="comingSoon" :class="comingSoon.class">
    <v-row>
      <v-col id="coming-soon-title" class="headline" cols="12" v-html="comingSoon.title"></v-col>
      <v-col id="coming-soon-subtitle" class="title" cols="12" v-html="comingSoon.subtitle"></v-col>
    </v-row>
    <v-row>
      <v-col v-for="(action, i) in comingSoon.actions" :class="action.class" v-bind:key="`coming-soon-action-${i}`">
        <div v-if="action.type === 'button'">
          <v-btn
            v-bind="action.bind"
            :color="action.color"
            :href="action.link"
            @click="answerQuestion(action.onClick)"
            target="_blank"
          >
            <span>{{ action.text }}</span>
          </v-btn>
        </div>
        <div v-else-if="action.type === 'text'">
          <p v-html="action.text"></p>
        </div>
        <div v-else-if="action.type === 'forcastedConstructionStartDate'">
          <p v-html="action.text + forcastedConstructionStartDate"></p>
        </div>
        <div v-else-if="action.type === 'estimatedConstructionCompletionDate'">
          <p v-html="action.text + estimatedConstructionCompletionDate"></p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from '@vue/composition-api'
import { SET_ADDRESS_VERIFIED } from '@/store/types'
import StepperButtons from '../order/StepperButtons.vue'
import { bus } from '@/main'
import $store from '@/store'
import { getConfigItem } from '../shared/getConfigItem'
import { ActionType, ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { ComingSoon } from '@adg/catalog/src/common/UIConfig'
import { logger } from '@/utils/RemoteLogger'
import useSalesLead from '../useSalesLead'

export default defineComponent({
  name: 'ComingSoon',
  components: {
    StepperButtons
  },
  setup(props, { root }) {
    const { $router } = root as any
    const comingSoon = computed((): ComingSoon => getConfigItem(ConfigKeys.comingSoon))
    const { processSalesLead } = useSalesLead()
    const forcastedConstructionStartDate = ref('')
    const estimatedConstructionCompletionDate = ref('')

    const returnToStart = () => {
      bus.$emit('resetApp')
    }

    onMounted(() => {
      const matchedAddress = $store.getters['GET_MATCHED_ADDRESS']
      forcastedConstructionStartDate.value = matchedAddress?.tags?.forcastedConstructionStartDate
      estimatedConstructionCompletionDate.value = matchedAddress?.tags?.estimatedConstructionCompletionDate
    })

    const answerQuestion = (answer: ActionType | undefined) => {
      switch (answer?.kind) {
        case 'order':
          $store.commit(SET_ADDRESS_VERIFIED, true)
          $router.push('/order/2')
          break
        case 'reset':
          returnToStart()
          break
        case 'lead':
          processSalesLead(answer?.salesLeadInfo)
          break
        default:
          logger.error(`Unknown action type`, answer)
          break
      }
    }

    return {
      answerQuestion,
      comingSoon,
      returnToStart,
      forcastedConstructionStartDate,
      estimatedConstructionCompletionDate
    }
  }
})
</script>

<style scoped></style>

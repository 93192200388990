<template>
  <v-slide-group
    v-if="shouldUseCarousel"
    @click:next="incrementCenteredItem"
    @click:prev="decrementCenteredItem"
    center-active
    v-model="centeredItem"
    show-arrows="always"
    :key="'carousel-' + renderMe"
  >
    <div :style="carouselSubgridContainerStyles" class="subgrid-container-carousel">
      <v-slide-item :key="i" v-for="(p, i) in filteredPackages">
        <div :class="packageItemClass(i)">
          <PackageNew
            v-if="!broadbandLabelPlacement || broadbandLabelPlacement !== 'standard'"
            :class="isFlipped[i] ? 'flipped-package' : ''"
            :packageData="p"
            :colorIndex="i"
            :displayNameTextColor="displayNameTextColor(i)"
            :hasUnderPriceText="hasUnderPriceText"
            :broadbandLabelPlacement="broadbandLabelPlacement"
            @packageSelected="packageSelected"
            @labelFooterClicked="toggleBroadbandLabel(i, p)"
            @thumbnailClicked="toggleBroadbandLabel(i, p)"
            :labelHtml="getLabelHtmlForPackageName(p.Name)"
          ></PackageNew>
          <BroadbandLabelBase
            :class="isFlipped[i] ? 'flipped-label' : ''"
            v-if="shouldShowLabel(i, p.Name)"
            :shopper="shopper"
            :packageData="p"
            :colorIndex="i"
            :selectButtonInLabel="true"
            :closeButtonInLabel="broadbandLabelPlacement !== 'standard' && broadbandLabelPlacement !== 'below'"
            :labelHtml="getLabelHtmlForPackageName(p.Name)"
            @labelClosed="toggleBroadbandLabel(i, p.Name)"
            @packageSelected="packageSelected"
          ></BroadbandLabelBase>
        </div>
      </v-slide-item>
    </div>
  </v-slide-group>
  <div v-else :style="subgridContainerColumnStyles" class="subgrid-container">
    <template v-for="(p, i) in filteredPackages">
      <div :key="index" :class="packageItemClass(i)">
        <PackageNew
          v-if="!broadbandLabelPlacement || broadbandLabelPlacement !== 'standard'"
          :class="isFlipped[i] ? 'flipped-package' : ''"
          :packageData="p"
          :colorIndex="i"
          :displayNameTextColor="displayNameTextColor(i)"
          :hasUnderPriceText="hasUnderPriceText"
          :broadbandLabelPlacement="broadbandLabelPlacement"
          @packageSelected="packageSelected"
          @labelFooterClicked="toggleBroadbandLabel(i, p)"
          @thumbnailClicked="toggleBroadbandLabel(i, p)"
          :labelHtml="getLabelHtmlForPackageName(p.Name)"
        ></PackageNew>
        <BroadbandLabelBase
          :class="isFlipped[i] ? 'flipped-label' : ''"
          :id="`broadband-label-${i + 1}`"
          v-if="shouldShowLabel(i, p.Name)"
          :shopper="shopper"
          :packageData="p"
          :colorIndex="i"
          :selectButtonInLabel="true"
          :closeButtonInLabel="broadbandLabelPlacement !== 'standard' && broadbandLabelPlacement !== 'below'"
          :labelHtml="getLabelHtmlForPackageName(p.Name)"
          @labelClosed="toggleBroadbandLabel(i)"
          @packageSelected="packageSelected"
        ></BroadbandLabelBase>
      </div>
    </template>
    <div v-if="offerCardFooterItems" class="offer-footer">
      <template v-for="offerCardFooterItem in offerCardFooterItems">
        <div v-html="offerCardFooterItem"></div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import PackageNew from './PackageNew.vue'
import BroadbandLabelBase from './BroadbandLabelBase.vue'
import { ConfigKeys, Package } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'
import usePackage from './usePackage'
import { getConfigItem } from '@/components/shared/getConfigItem'

interface ViewedOffer {
  name: string
  tag: string
}

export default defineComponent({
  name: 'PackageContainer',
  components: {
    PackageNew,
    BroadbandLabelBase
  },
  props: {
    filterPackages: {
      type: Array<Package>,
      required: true
    },
    packageSelected: Function
  },
  setup(props, { emit, root: { $vuetify } }) {
    const renderMe = ref(0)
    const shopper = computed(() => $store.getters.getShopper)
    const filteredPackages = computed(() => props.filterPackages)
    const isExpanded = ref(new Array(filteredPackages.value.length).fill(false))
    const isFlipped = ref(new Array(filteredPackages.value.length).fill(false))
    const labelsGenerated = computed(() => $store.getters.getLabelsGenerated)
    const offerCardFooterItems = computed(() => getConfigItem(ConfigKeys.offerCardFooterItems) ?? undefined)
    const enableCarousel = computed(() => getConfigItem(ConfigKeys.enableCarousel) ?? false)
    const centeredItem = ref(0)
    const clientPackageCardMaxWidth = computed(() => getConfigItem(ConfigKeys.clientPackageCardMaxWidth) ?? '340px')
    const singlePackageRowCutoffWidth = computed(() => getConfigItem(ConfigKeys.singlePackageRowCutoffWidth) ?? 1919)
    const maximumPackagesPerRow = computed(() => getConfigItem(ConfigKeys.maximumPackagesPerRow) ?? 4)
    const carouselMaxWidthCutOff = computed(() => getConfigItem(ConfigKeys.carouselMaxWidthCutOff) ?? 1400)
    const offerCardObserverDelay = computed(() => getConfigItem(ConfigKeys.offerCardObserverDelay) ?? 100) // 100ms delay
    const offerCardObserverThreshold = computed(() => getConfigItem(ConfigKeys.offerCardObserverThreshold) ?? 0.8) // 80% of card in view
    const labelObserverThreshold = computed(() => getConfigItem(ConfigKeys.labelObserverThreshold) ?? 0.1) // 10% of label in view
    const offersViewed = ref([] as ViewedOffer[])
    const labelsViewed = ref([] as String[])

    const shouldUseCarousel = computed(() => {
      if (!enableCarousel.value) {
        return false
      }
      if ($vuetify.breakpoint.width > carouselMaxWidthCutOff.value) {
        return false
      }
      if ($vuetify.breakpoint.width < 600) {
        return true
      }
      if ($vuetify.breakpoint.width > 599 && $vuetify.breakpoint.width < 1024 && filteredPackages.value.length > 2) {
        return true
      }
      if (
        $vuetify.breakpoint.width > 1023 &&
        $vuetify.breakpoint.width <= carouselMaxWidthCutOff.value &&
        filteredPackages.value.length > 4
      ) {
        return true
      }
      return false
    })

    const {
      selectPackage,
      displayNameTextColor,
      broadbandConfigString,
      broadbandLabelPlacement,
      approvedBroadbandLabelClasses,
      broadbandLabelToggleOption,
      packageMaxWidth
    } = usePackage($store, $vuetify, emit)

    const packageTrackingObservers = (filteredPackages: Package[]) => {
      // default 100 ms delay to allow cards for render to in DOM before selecting & setting up observers
      setTimeout(() => {
        filteredPackages.forEach((p: Package, i: number) => {
          const offerCardElement = document.querySelector('.package-' + (i + 1))
          if (offerCardElement) {
            var observer = new IntersectionObserver(
              async function (entries) {
                if (
                  entries[0].isIntersecting === true &&
                  // check if offer with same name and tag (if tag exists) already been viewed
                  !offersViewed.value.find((offer) => offer.name === p.Name && (offer.tag === p.Tag || !offer.tag))
                ) {
                  offersViewed.value.push({
                    name: p.Name,
                    tag: p.Tag ?? ''
                  })
                  $store.commit('processPackageViewEvent', p)
                }
              },
              { threshold: [offerCardObserverThreshold.value] }
            )
            observer.observe(offerCardElement)
          }
        })
      }, offerCardObserverDelay.value)
    }

    const labelsTrackingObservers = () => {
      setTimeout(() => {
        labelsGenerated.value.forEach((label, i) => {
          const labelElement = document.getElementById(`broadband-label-${i + 1}`)
          if (labelElement) {
            var observer = new IntersectionObserver(
              async function (entries) {
                // check if label is in view and not already viewed
                if (entries[0].isIntersecting === true && !labelsViewed.value.includes(label.uniquePlanId)) {
                  labelsViewed.value.push(label.uniquePlanId)
                  const pkg = filteredPackages.value.find((p) => p.uniquePlanId === label.uniquePlanId)
                  if (pkg) {
                    $store.commit(
                      'processBroadbandLabelViewedEvent',
                      filteredPackages.value.find((p) => p.uniquePlanId === label.uniquePlanId)
                    )
                  }
                }
              },
              { threshold: [labelObserverThreshold.value] }
            )
            observer.observe(labelElement)
          }
        })
      }, offerCardObserverDelay.value)
    }

    onMounted(() => {
      packageTrackingObservers(filteredPackages.value)
      // when labels are statically displayed below offer cards, set up observers for views
      if (broadbandLabelPlacement.value === 'below') {
        labelsTrackingObservers()
      }
    })

    //watch when props change, parse broadband label and add to labelsHtml
    //if broadband label is not already in labelsHtml (to prevent re-parsing, and dupliating DB records)
    watch(
      () => props.filterPackages,
      async (newVal, oldVal) => {
        isFlipped.value = new Array(newVal.length).fill(false)
        isExpanded.value = new Array(newVal.length).fill(false)
        packageTrackingObservers(newVal)
        if (broadbandLabelPlacement.value === 'below') {
          labelsTrackingObservers()
        }
        newVal?.forEach((p: Package) => {
          if (getLabelHtmlForPackageName(p.Name) || !p.BroadbandLabel?.[0]) {
            return
          }
          if (newVal.length >= 1 && shouldUseCarousel.value) {
            // even, divide by 2 (e.g: 4 / 2 = 2, which is the 3rd of 4)
            if (newVal.length % 2 === 0) {
              centeredItem.value = newVal.length / 2
            }
            // odd round down to nearest whole number e.g: floor(3 / 2) = 1, which is the 2nd of 3
            else {
              centeredItem.value = Math.floor(newVal.length / 2)
            }
          }
        })
      }
    )

    const carouselSubgridContainerStyles = computed(() => {
      return {
        gridTemplateColumns: `repeat(${filteredPackages.value.length}, minmax(225px, max-content))`
      }
    })

    const decrementCenteredItem = () => {
      centeredItem.value = centeredItem.value - 1
    }
    const incrementCenteredItem = () => {
      centeredItem.value = centeredItem.value + 1
    }

    const subgridContainerColumnStyles = computed(() => {
      // If 5 or 6 Packages, and width is > variable (with default 1919px), and max packages per row is greater than or equal to the number of packages
      // Then create same number of columns as packages to make all packages in one row
      if (
        filteredPackages.value.length > 4 &&
        maximumPackagesPerRow.value >= filteredPackages.value.length &&
        $vuetify.breakpoint.width > singlePackageRowCutoffWidth.value
      ) {
        return {
          gridTemplateColumns: `repeat(${filteredPackages.value.length}, minmax(225px, ${clientPackageCardMaxWidth.value}))`
        }
      }
      // > 1023, max of 4 per row
      // If fewer than 4 packages, make as many columns as packages
      if ($vuetify.breakpoint.width > 1023) {
        return {
          gridTemplateColumns: `repeat(${filteredPackages.value.length >= 4 ? 4 : filteredPackages.value.length}, minmax(225px, ${
            clientPackageCardMaxWidth.value
          }))`
        }
      }
      // > 599 < 1024, 2 per row
      if ($vuetify.breakpoint.width > 599 && $vuetify.breakpoint.width < 1024) {
        return {
          gridTemplateColumns: `repeat(2, minmax(225px, ${clientPackageCardMaxWidth.value}))`
        }
      }
      // Under 600, 1 per row
      return {
        gridTemplateColumns: `repeat(1, minmax(225px, ${clientPackageCardMaxWidth.value}))`
      }
    })

    const packageItemClass = (i: number) => {
      const numPackages = filteredPackages.value.length
      // If 5 or 6 Packages, and width is > variable (with default 1919px), and max packages per row is greater than or equal to the number of packages
      // then give single-row class to make all packages in one row
      if (
        numPackages > 4 &&
        maximumPackagesPerRow.value >= numPackages &&
        $vuetify.breakpoint.width > singlePackageRowCutoffWidth.value
      ) {
        return `item item-${i + 1}-of-${numPackages} single-row`
      }
      // For >1024, 4 cards fit in one row
      // Need to position the cards in grid based on the number of packages
      if ($vuetify.breakpoint.width > 1023) {
        return `item item-${i + 1}-of-${numPackages}`
      }
      // For >600, <1024 (2 per row), check if odd number of packges and if last item, give orphan-item class
      // since it will be on its own row and needs to be centered
      if ($vuetify.breakpoint.width > 599 && $vuetify.breakpoint.width < 1024) {
        if (filteredPackages.value.length % 2 === 1 && i === numPackages - 1) {
          return 'item orphan-item'
        }
      }
      // else just give normal item class
      return 'item'
    }

    const getLabelHtmlForPackageName = (name: string) => {
      return labelsGenerated.value.find((label) => label.packageName === name)?.html ?? undefined
    }

    const shouldShowLabel = (i: number, packageName: string) => {
      return (
        (isExpanded.value[i] ||
          isFlipped.value[i] ||
          broadbandLabelPlacement.value === 'below' ||
          broadbandLabelPlacement.value === 'standard') &&
        getLabelHtmlForPackageName(packageName)
      )
    }

    const toggleBroadbandLabel = (index: number, pkg?: Package) => {
      if (broadbandLabelToggleOption.value === 'expand') {
        isExpanded.value.splice(index, 1, !isExpanded.value[index])
      }
      if (broadbandLabelToggleOption.value === 'flip') {
        isFlipped.value.splice(index, 1, !isFlipped.value[index])
      }
      if (pkg && pkg.Name && pkg.uniquePlanId && (isExpanded.value[index] || isFlipped.value[index])) {
        const label = labelsGenerated.value.find((label) => label.uniquePlanId === pkg.uniquePlanId)
        // check if label hasnt been viewed yet, if it hasnt, commit event
        if (label && !labelsViewed.value.includes(pkg.uniquePlanId)) {
          labelsViewed.value.push(pkg.uniquePlanId)
          $store.commit('processBroadbandLabelViewedEvent', pkg)
        }
      }
    }

    const hasUnderPriceText = computed(() => {
      return filteredPackages.value?.some((filterPackage: any) => {
        return filterPackage['Under Price Text']
      })
    })

    const lobString = computed(() => {
      let filterPackage
      if (filteredPackages.value) {
        filterPackage = filteredPackages.value[0]
      }

      let lobString = ''

      const lobs = filterPackage?.lobs

      if (lobs?.includes('Internet')) {
        lobString += 'Internet'
      }

      if (lobs?.includes('TV')) {
        lobString += 'TV'
      }

      if (lobs?.includes('Latino TV')) {
        lobString += 'LatinoTV'
      }

      if (lobs?.includes('Phone')) {
        lobString += 'Phone'
      }

      return lobString.replaceAll(/,/g, '')
    })

    return {
      lobString,
      displayNameTextColor,
      hasUnderPriceText,
      filteredPackages,
      shopper,
      isExpanded,
      isFlipped,
      packageItemClass,
      broadbandLabelToggleOption,
      labelsGenerated,
      getLabelHtmlForPackageName,
      broadbandLabelPlacement,
      approvedBroadbandLabelClasses,
      broadbandConfigString,
      toggleBroadbandLabel,
      selectPackage,
      shouldShowLabel,
      subgridContainerColumnStyles,
      carouselSubgridContainerStyles,
      decrementCenteredItem,
      incrementCenteredItem,
      shouldUseCarousel,
      centeredItem,
      packageMaxWidth,
      enableCarousel,
      offerCardFooterItems,
      clientPackageCardMaxWidth,
      maximumPackagesPerRow,
      singlePackageRowCutoffWidth,
      carouselMaxWidthCutOff,
      renderMe,
      offersViewed,
      packageTrackingObservers,
      offerCardObserverDelay,
      offerCardObserverThreshold,
      labelObserverThreshold
    }
  }
})
</script>

<style>
.v-slide-group .v-icon__svg {
  height: 36px;
  width: 36px;
}
.v-slide-group__prev,
.v-slide-group__next {
  align-self: flex-start;
  margin-top: 20vh;
  min-width: unset;
}
.subgrid-container {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-row-gap: 12px;
  grid-column-gap: 24px;
}
.subgrid-container-carousel {
  display: grid;
  justify-content: center;
  grid-row-gap: 12px;
  grid-column-gap: 24px;
}
.v-item-group svg {
  fill: var(--v-primary-base);
}
.subgrid-container .item {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: auto / span 2;
  grid-template-columns: auto;
  justify-items: center;
  justify-self: center;
  width: 100%;
}
.subgrid-container-carousel .item {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: auto / span 2;
  justify-content: center;
  max-width: 60vw;
  width: max-content !important;
  justify-self: center;
}
.subgrid-container-carousel .broadband-card {
  margin-bottom: 12px;
}
.package {
  min-width: 225px;
  max-width: 340px;
  grid-row: 1 / 2;
  justify-self: center;
  width: 100%;
}
.broadband-card {
  min-width: 225px;
  max-width: 340px;
  justify-self: center;
  margin-top: 12px;
  grid-row: 2 / 3;
  display: grid;
  padding: 12px;
  grid-template-rows: min-content auto min-content;
  word-break: break-word;
}
.flipped-package {
  display: none;
}
.flipped-label {
  margin-top: 0px;
  grid-row: auto / span 2;
}
.subgrid-container .item-5-of-6 {
  grid-column-start: 2;
  grid-column-end: 3;
}
.subgrid-container .item-6-of-6 {
  grid-column-start: 3;
  grid-column-end: 4;
}
.subgrid-container .item-5-of-5 {
  grid-column: 1 / -1;
}
.subgrid-container .orphan-item {
  grid-column: 1 / -1;
}
.v-item-group {
  margin-right: 12px;
}
.broadband-card .text-right {
  text-wrap: nowrap;
}
.broadband-card .bbl-link {
  text-wrap: wrap;
}
.broadband-card .text-left {
  min-width: 90px;
}
.subgrid-container .single-row {
  grid-column: auto !important;
}
</style>


<v-container fluid class="pa-0" id="tv-equipment">
  <v-row>
    <v-col cols="12" class="pb-0 pt-2">
      <NumberPicker
        v-model="numTV"
        @input="numberOfTvsChanged"
        :min="calcMinTv"
        :max="getMaxTvs"
        :value="numTV"
        description="How many TVs would you like service on?"
      />
    </v-col>
  </v-row>

  <v-row v-for="(n, index) in numTV" :key="n">
    <v-col cols="6" class="py-0 pr-1">
      <v-select
        class="my-0 py-0 black--text"
        dense
        :append-icon="products.length > 1 ? '$dropdown' : ''"
        outlined
        v-model="tvSelections[index]"
        :readonly="products.length === 1"
        :label="buildTvLabel(index)"
        :items="sortedTvItems(index)"
        item-text="Display Name"
        item-value="Name"
        @change="tvSelectionChanged(tvSelections[index], n)"
        return-object
      ></v-select>
    </v-col>
    <v-col cols="1" class="pa-0"><Tooltip :payload="{ ...tvSelections[index], type: 'Equipment' }" /></v-col>
    <v-col cols="5" class="py-0 text-right">
      <span
        v-html="
          uiMacroParser(shopper, tvSelections[index].PricePrefix ?? '') +
          ' ' +
          (tvSelections[index] && coreCurrency(getTvItemPrice(tvSelections[index], index)))
        "
      ></span>
    </v-col>
  </v-row>
</v-container>

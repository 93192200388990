import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { computed } from '@vue/composition-api'
import { getConfigItem } from '../shared/getConfigItem'

export default (emit: any) => {
  // const headerColor = computed(() => props.color ?? lobHeaderColors.value[props.componentOrder ?? 0])

  const headerColor = (lobHeaderColors: string[], componentOrder?: number, color?: string): string => {
    return color ?? lobHeaderColors[componentOrder ?? 0]
  }

  const headerClicked = () => {
    emit('headerClicked')
  }

  const headerTextClass = (image: string | undefined): string => {
    return image === 'wifi-black.png' ? 'header-text black customize-text' : 'header-text customize-text'
  }

  const cardHeaderComponent = computed(() => getConfigItem(ConfigKeys.cardHeaderComponent) ?? 'default')

  const cartCloseIcon = computed(() => getConfigItem(ConfigKeys.cartCloseIcon) ?? 'mdi-close')
  const cartCloseIconColor = computed(() => getConfigItem(ConfigKeys.cartCloseIconColor) ?? 'accent')

  return {
    headerColor,
    headerClicked,
    headerTextClass,
    cardHeaderComponent,
    cartCloseIcon,
    cartCloseIconColor
  }
}

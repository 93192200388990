
<div id="appHeader">
  <v-system-bar app class="white--text" color="primary" height="40" v-if="determineIfBannerDisplayed">
    <v-spacer></v-spacer>
    <div class="pr-10" v-html="bannerText"></div>

    <v-btn
      color="secondary"
      outlined
      class="promo-button font-weight-bold hidden-md-and-up"
      @click="applyPromo"
      :small="$vuetify.breakpoint.smAndUp"
      :x-small="$vuetify.breakpoint.xsOnly"
    >
      <span class="white--text">CLICK TO APPLY TO CART</span>
    </v-btn>
    <v-spacer></v-spacer>
  </v-system-bar>

  <v-app-bar class="appBar" height="92" flat :color="bannerColor" :extension-height="mobileCart ? 40 : 0" app>
    <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
    <a :href="logoLink">
      <img contain :src="headerLogo" id="logo" class="logo" alt="logo"></img>
    </a>
    <v-spacer v-if="allowDebug"></v-spacer>
    <div v-if="allowDebug" class="pt-4 pb-0 pl-3 pr-3" style="background-color: black">
      <v-switch v-model="debugMode" label="Debug Mode" color="red" dark />
    </div>
    <TooltipDebug :item="appState" :name="'State'" />

    <v-spacer></v-spacer>
    <div v-if="showTagsDebug">
      <TagsDebug :address="punctuatedAddress" />
    </div>
    <div v-else>
      <span
        class="header-address justify-end text-center hidden-xs-only"
        v-if="punctuatedAddress != ''"
        v-bind:style="{ color: addressTextColor }"
        v-html="punctuatedAddress"
      ></span>
    </div>

    <template v-slot:extension>
      <v-container fluid class="pa-0" v-if="mobileCart">
        <v-row justify="center" class="secondary black--text text-center">
          <v-toolbar @click="dialog = true" :color="monthlyTotalHeaderColor">
            <v-spacer></v-spacer>
            <span class="white--text headline font-weight-bold monthly-total">Monthly Total ${{ monthlyTotal.toFixed(2) }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = true" class="text-none">
              <v-icon color="white">mdi-cart-check</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-row>
      </v-container>
    </template>
  </v-app-bar>

  <!--    DIALOG CART-->
  <v-row justify="center">
    <v-dialog overlay-color="black" overlay-opacity=".8" v-model="dialog" scrollable max-width="450px">
      <v-card-text style="height: 800px" class="pa-0">
        <Cart @cartClicked="dialog = !dialog" />
      </v-card-text>
      <v-divider></v-divider>
    </v-dialog>
  </v-row>
</div>

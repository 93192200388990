
<v-tooltip right color="secondaryDark">
  <template v-slot:activator="{ on }">
    <span>
      <span>Select the type of box you want for each TV</span>
      <v-btn v-on="on" x-small icon
        ><v-icon>{{ mdiInformation }}</v-icon></v-btn
      >
    </span>
  </template>
  <div>Which TV boxes are right for me?</div>
  <v-card max-width="350" color="secondaryDark">
    <v-list subheader three-line color="secondaryDark" dark>
      <v-list-item v-for="e in equipment" :key="e.Name">
        <v-list-item-content>
          <v-list-item-title>{{ e.Name }}</v-list-item-title>
          <v-list-item-subtitle v-if="e['Marketing Text 1']">{{ e['Marketing Text 1'] }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="e['Marketing Text 2']">{{ e['Marketing Text 2'] }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="e['Marketing Text 3']">{{ e['Marketing Text 3'] }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</v-tooltip>

import { FeedItem, getItemPrice } from '@adg/catalog/src/modules/Catalog'
import * as catalogFunctions from './catalogFunctions'

/**
 * Dynamically execute user defined functions from the Catalog
 */
export default () => {
  const calculatePrice = (currentState, input: FeedItem): FeedItem => {
    const item = { ...input }
    item['Monthly Price'] = getItemPrice(input, 'Monthly Price')
    item['OTC'] = getItemPrice(input, 'OTC')
    if (item['Price Calculation'] && item['Monthly Price'] !== 'Included') {
      item['Monthly Price'] = call(currentState, item['Price Calculation'])
    }
    return item
  }

  // First argument is function name, rest is args.
  const call = (state, input) => {
    const arr = input.split(',')
    const functionName = arr.slice(0, 1)[0]
    const args = arr
      .slice(1)
      .map((x) => (x.indexOf('|') ? x.split('|') : x))
      .map((x) => (x.length === 1 ? x[0] : x))
    return catalogFunctions[functionName](state, ...args)
  }

  const dateDiff = (startingDate, endingDate?) => {
    let startDate = new Date(new Date(startingDate).toISOString().substr(0, 10))
    if (!endingDate) {
      endingDate = new Date().toISOString().substr(0, 10) // need date in YYYY-MM-DD format
    }
    let endDate = new Date(endingDate)
    if (startDate > endDate) {
      let swap = startDate
      startDate = endDate
      endDate = swap
    }
    let startYear = startDate.getFullYear()
    let february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28
    let daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    let yearDiff = endDate.getFullYear() - startYear
    let monthDiff = endDate.getMonth() - startDate.getMonth()
    if (monthDiff < 0) {
      yearDiff--
      monthDiff += 12
    }
    let dayDiff = endDate.getDate() - startDate.getDate()
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--
      } else {
        yearDiff--
        monthDiff = 11
      }
      dayDiff += daysInMonth[startDate.getMonth()]
    }

    return { yearDiff, monthDiff, dayDiff }
  }

  return {
    calculatePrice,
    dateDiff
  }
}

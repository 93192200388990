<template>
  <v-tooltip right color="secondaryDark" id="pt" v-if="message">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :size="$vuetify.breakpoint.xsOnly ? 18 : 18"
        class="secondaryDark--text text--lighten-2"
        dark
        v-bind="attrs"
        v-on="on"
        >{{ mdiInformation }}</v-icon
      >
    </template>

    <v-card dark color="secondaryDark" class="pt-2 rounded-lg" rounded>
      <v-card-text class="py-0">
        {{ message }}
      </v-card-text>
    </v-card>
  </v-tooltip>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { mdiInformation } from '@mdi/js'

export default defineComponent({
  name: 'TooltipBasic',
  props: {
    message: String
  },

  setup(props) {
    return {
      mdiInformation
    }
  }
})
</script>

<style></style>

<template>
  <v-container id="monthly-total-row" class="py-0 grey lighten-3">
    <v-row v-if="!hasPromos" align="start" id="monthly-total-row" class="grey lighten-3 pt-2 px-0 ma-0">
      <v-col :class="textClasses" id="monthly-total-row" class="pa-0" cols="8"
        ><span v-html="uiMacroParser(shopper, itemName) + parent.qty"></span><TooltipDebug :item="item" /><Tooltip
          :payload="item"
        />
      </v-col>
      <v-col :class="priceClasses" id="monthly-total-row" class="pa-0 text-right" cols="4">
        <span v-html="uiMacroParser(shopper, parent.PricePrefix ?? '') + ' ' + coreCurrency(parent.price)"></span>
      </v-col>
    </v-row>

    <v-row v-else class="pa-0 ma-0" align="start" justify="space-between">
      <v-expansion-panels flat v-model="panel" id="mpdPanel">
        <v-expansion-panel class="grey lighten-3 pt-2 px-0 ma-0">
          <v-expansion-panel-header hide-actions class="pa-0 ma-0" style="font-size: 1rem" align="start">
            <v-row class="pa-0 ma-0" align="start">
              <v-col :class="textClasses" class="pa-0" cols="8"
                ><span v-html="uiMacroParser(shopper, itemName) + parent.qty"></span><TooltipDebug :item="item" /><Tooltip
                  :payload="item"
                />
              </v-col>
              <v-col :class="priceClasses" class="pa-0 text-right" cols="4">
                <v-icon :color="componentColor" class="pb-1" small>{{ circleIcon }}</v-icon>
                <span v-html="uiMacroParser(shopper, parent.PricePrefix ?? '') + ' ' + coreCurrency(parent.price)"></span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="hasPromos">
              <v-row v-for="(promo, i) in promoProgressions" :key="`${parent.name}-promo-${i}`">
                <v-col class="py-0 promo-text" cols="11">{{ promo }} </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, ref } from '@vue/composition-api'
import {
  itemDisplayName,
  getItemPrice,
  ChargeTypeKey,
  FeedItem,
  getItemPriceAsString,
  getRecurringItemPeriods
} from '@adg/catalog/src/modules/Catalog'
import { mdiPlusCircle, mdiMinusCircle } from '@mdi/js'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import TooltipBasic from '@/components/shared/tooltip/TooltipBasic.vue'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import $store from '@/store'
import { coreCurrency } from '@adg/catalog/src/common/filters'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { IShopper } from '@adg/catalog/src/modules/Shopper'

interface LineItem {
  name?: string
  itemType: string
  description?: string
  qty?: string | number
  price?: string | number
  PricePrefix?: string
}

export default defineComponent({
  name: 'MonthlyPriceDisplay',
  components: {
    Tooltip,
    TooltipBasic,
    TooltipDebug
  },
  props: {
    item: {
      type: Object as () => FeedItem,
      required: true
    },
    priceType: {
      type: String as () => ChargeTypeKey,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    myComponentColor: {
      type: String,
      required: false
    },
    additionalTextClasses: {
      type: String,
      required: false
    },
    additionalPriceClasses: {
      type: String,
      required: false
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { attrs }) {
    const parent: ComputedRef<LineItem> = computed(() => ({
      name: itemDisplayName(props.item),
      itemType: props.item.itemType,
      description: props.item.Description,
      qty: props.item.qty ?? '',
      price: getItemPrice(props.item, props.priceType, 1),
      PricePrefix: props.item.PricePrefix
    }))

    const shopper = computed(() => $store.getters.getShopper as IShopper)

    const isDebug = computed(() => $store.getters.getDebugMode)

    const panel = props.expanded ? ref(0) : ref()

    const itemPeriods = computed(() => getRecurringItemPeriods(props.item))

    const defaultColor = 'primary'

    const itemName = computed(() => props.name ?? props.item.Name)

    const circleIcon = computed(() => (panel.value === 0 ? mdiMinusCircle : mdiPlusCircle))

    //const textClasses = computed(() => `${props.myComponentColor ?? defaultColor}--text`)
    const textClasses = computed(() => `${props.myComponentColor ?? defaultColor}--text ${props.additionalTextClasses ?? ''}`)

    const priceClasses = computed(() => `${props.myComponentColor ?? defaultColor}--text ${props.additionalPriceClasses ?? ''}`)

    const textDefaultAttrs = computed(() => ({
      color: attrs.color || 'cyan'
    }))

    const componentColor = computed(() => props.myComponentColor ?? defaultColor)

    const promoProgressions = computed(() =>
      itemPeriods.value.map((month) => {
        const price = getItemPriceAsString(props.item, props.priceType, month.StartMonth)
        if (month.EndMonth === Infinity) {
          // return `$${price} for months ${month.StartMonth} ...`
          // return `$${price} for months ${month.StartMonth} and therafter`
          return `${price} thereafter`
        }
        return `${price} for months ${month.StartMonth} - ${month.EndMonth}`
      })
    )

    // const children = computed(() => getItemSubItems(props.item, 1) ?? [])

    // const parentPrice = computed(() => (panel.value === 0 && hasChildren.value ? 'grey--text' : ''))

    // const hasChildren = computed(() => {
    //   const childrenLength = isDebug.value ? 0 : 1
    //   return children.value.length > childrenLength && (props.item.expandable || isDebug.value)
    // })

    // if (parent.value.name === 'HD Set Top Box') {
    // console.log(parent.value.name)
    // if (parent.value.name == 'HD Set Top Box') {
    //   console.log(props.item)
    // }
    // }

    const hasPromos = computed(() => {
      const promoLength = isDebug.value ? 0 : 1
      return promoProgressions.value.length > promoLength
    })

    return {
      parent,
      // children,
      // hasChildren,
      hasPromos,
      panel,
      mdiPlusCircle,
      mdiMinusCircle,
      // parentPrice,
      componentColor,
      textClasses,
      priceClasses,
      circleIcon,
      itemName,
      promoProgressions,
      coreCurrency,
      uiMacroParser,
      shopper
    }
  }
})
</script>

<style>
#mpdPanel .v-expansion-panel-content__wrap {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  /* padding-left: 18px !important; */
  border: none;
}
#mpdPanel .v-expansion-panel-header {
  min-height: 24px !important;
  font-size: 1rem;
}
.promo-text {
  font-size: 14px;
  font-style: italic;
  color: gray;
}
</style>


<v-card :tile="sharpCorners" class="text-center" width="100%" height="100%">
  <v-container class="streaming-service-card-container">
    <v-row :class="feature.RowClass" v-for="(feature, i) in streamingServiceFeatures" :key="'service' + i">
      <v-col :id="feature.Id" :class="feature.Class">
        <div v-if="feature.FeatureType === 'Text'" v-html="feature.Description"></div>
        <v-img contain v-if="feature.FeatureType === 'Image'" :src="feature.Image"></v-img>
        <v-btn
          :class="'buttonClass' + ' ' + getCatalogItemClassName(feature.Name, 'Feature')"
          v-if="feature.FeatureType === 'Button'"
          @click="toggleIsSelected"
          >{{ buttonText(feature) }}</v-btn
        >
        <v-checkbox
          v-if="feature.FeatureType === 'Checkbox'"
          :label="feature.Description"
          v-model="checkModel"
          :true-value="streamingService"
          :false-value="null"
          :class="getCatalogItemClassName(feature.Name, 'Feature')"
        ></v-checkbox>
        <Tooltip :payload="feature" />
        <TooltipDebug :item="feature" />
      </v-col>
    </v-row>
  </v-container>
</v-card>

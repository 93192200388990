<template>
  <div>
    <StepperButtons
      :forwardAction="openModal"
      :buttonText="paymentModalButtonText"
      key="PaymentStepper"
      :thirdButtonText="thirdButtonText"
      :thirdButtonAction="thirdButtonAction"
      :disabled="disabled"
    />
    <div v-if="showModal && iFrameSrcDoc">
      <v-overlay :opacity="0.5" v-if="showModal" z-index="6" class="customOverlay">
        <v-dialog v-model="showModal" @close="showModal = false" width="400px">
          <v-card class="dialog-card">
            <PaymentSuccess v-if="buttonState === 'success'" />
            <div v-else>
              <div class="close-button">
                <v-btn icon @click="showModal = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <div class="pay-header">
                <v-img contain :src="headerLogo" height="80" max-width="200px"></v-img>
                <!-- <div class="mb-3">Vexus Secure Payment</div> -->
              </div>
              <v-form v-model="validated" class="pay-content">
                <p
                  v-if="paymentConfig.aboveIframeHtml"
                  class="mb-3 font-weight-normal"
                  v-html="insertPrices(paymentConfig.aboveIframeHtml)"
                />

                <p v-else class="mb-3 font-weight-normal">
                  One last step! Payment is required at this time to complete your Vexus Fiber order and to confirm your
                  installation appointment. Please enter payment information below:
                </p>
                <div class="iframe-wrapper">
                  <iframe id="gocareIframe" class="gocare-iframe" :srcdoc="iFrameSrcDoc" scrolling="yes" />
                </div>
                <div v-if="paymentConfig.payIconHigh" class="pay-methods">
                  <span>Available Payment Methods:</span>
                  <v-img src="/api/content/payment-methods.jpg" width="250" height="30" />
                </div>
                <v-list>
                  <v-list-item>
                    <v-list-item-action style="align-self: start">
                      <v-checkbox v-model="showBillingFields" class="billing-checkbox" />
                    </v-list-item-action>
                    <v-list-item-content>
                      <span class="billing-different-text"
                        >My billing address is different from my service address<span v-if="serviceAddress">:</span></span
                      >
                      <span class="service-address">
                        {{ serviceAddress }}
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <div v-if="showBillingFields" class="pay-account-fields">
                  <v-row v-if="paymentConfig.accountFields?.length">
                    <v-col
                      v-for="field of paymentConfig.accountFields"
                      :key="field.storeProp"
                      :class="field.class ? field.class : 'col-12 pt-0'"
                    >
                      <AccountFieldUI :field="field" @change="accountFieldChanged($event, field.changeEvent)" />
                    </v-col>
                  </v-row>
                </div>

                <v-radio-group v-if="allowAutoPay" class="pay-radio-group" v-model="autoPay" row>
                  <v-radio :value="false">
                    <template v-slot:label>
                      <span v-if="paymentConfig.payRadioText" v-html="insertPrices(paymentConfig.payRadioText)" />
                      <span v-else>Make a one-time payment of ${{ grandTotal.toFixed(2) }} today</span>
                    </template>
                  </v-radio>
                  <v-radio class="radio-top" :value="true">
                    <template v-slot:label>
                      <div v-if="paymentConfig.autoPayRadioText">
                        <span v-html="insertPrices(paymentConfig.autoPayRadioText)" />
                        <Tooltip :payload="{ Name: 'AutoPay' }" />
                      </div>
                      <div v-else>
                        <span> Make a one-time payment of ${{ grandTotal.toFixed(2) }} today AND enroll me in Vexus Easy Pay </span>
                        <Tooltip :payload="{ Name: 'AutoPay' }" />
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>

                <div class="disclaimer">
                  <span v-if="paymentConfig.disclaimer" v-html="paymentConfig.disclaimer" />
                  <span v-else
                    >By clicking below, you agree to the <a>privacy policy</a>, <a>refund policy</a>, and
                    <a>terms of service</a>
                  </span>
                </div>

                <div v-if="buttonState === 'failure'" class="error-container">
                  <span v-if="paymentConfig.paymentFailedText" v-html="paymentConfig.paymentFailedText" />
                  <span v-else class="pay-error">
                    There was a problem processing your payment. Please try again, or continue without paying.
                  </span>
                </div>

                <div class="pay-container">
                  <v-btn
                    class="pay-button"
                    :disabled="buttonState === 'loading' || !validated"
                    :color="validated ? submitColor : 'grey'"
                    @click="submitPayment"
                    >{{ payBtnTxt }}</v-btn
                  >
                  <!-- <span v-if="autoPay" class="monthly-charge">+ ${{ monthlyTotal.toFixed(2) }}/mo</span> -->
                </div>

                <div v-if="buttonState === 'failure'" class="skip-container">
                  <v-btn class="skip-button" @click="submitOrderWithoutPay">Submit Order (without paying)</v-btn>
                </div>
                <div v-if="!paymentConfig.payIconHigh" class="pay-methods">
                  <span>Available Payment Methods:</span>
                  <v-img src="/api/content/payment-methods.jpg" width="250" height="30" />
                </div>

                <div>
                  <p v-if="paymentConfig.contactDetails" v-html="paymentConfig.contactDetails" class="location" />
                  <p v-else class="location">
                    Vexus Fiber
                    <br />
                    4006 W Loop 289
                    <br />
                    Lubbock, TX 79407
                    <br />
                    <a :href="`tel:18006582150`">1-800-658-2150</a>
                  </p>
                </div>
              </v-form>
            </div>
          </v-card>
        </v-dialog>
      </v-overlay>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, ComputedRef, defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import $store from '@/store'
import { headScript } from '@/utils/SourceLoader'
import StepperButtons from '../StepperButtons.vue'
import { bus } from '@/main'
import useCart from '@/components/order/cart/useCart'
import { PaymentInfo } from '@adg/catalog/src/modules/Converge/converge'
import useAutomationState from '@/store/useAutomationState'
// import { payHtml } from './payHtml'
import { getConfigItem, getConfigString } from '@/components/shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import { PaymentConfig } from '@adg/catalog/src/common/UIConfig'
import AccountFieldUI from '../account/AccountFieldUI.vue'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { PaymentDisposition, ShopperDisposition } from '@adg/catalog/src/common/Payments'
import { insertPrices, iFrameSrcDoc, addDispositionListener, serviceAddress, paymentModalButtonText } from './PaymentFunctions'
import ga4 from '@/gtm/ga4'
import PaymentSuccess from './PaymentSuccess.vue'

export default defineComponent({
  name: 'CustomPayment',
  components: {
    StepperButtons,
    Tooltip,
    AccountFieldUI,
    PaymentSuccess
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { cancelAutomation } = useAutomationState($store)
    const { grandTotal, monthlyTotal } = useCart($store)
    const headerLogo = computed(() => getConfigItem(ConfigKeys.headerLogo) ?? '/api/content/logo.png')

    const paymentConfig = computed((): PaymentConfig => getConfigItem(ConfigKeys.paymentConfig))
    const shopper = computed((): IShopper => $store.getters.getShopper)
    const validated = ref(false)

    const thirdButtonText = computed(() =>
      shopper.value.tags?.salesChannel === 'd2d' ? 'Submit (AutoPay Sign-up Required)' : undefined
    )
    const thirdButtonAction = () => {
      if (shopper.value.tags?.salesChannel === 'd2d') {
        $store.commit('setReoccuringPayments', true)
        bus.$emit('submitOrder')
      }
    }
    const showModal = ref(false)
    const openModal = () => {
      $store.dispatch('initializePayment')
      showModal.value = true
      ga4.pushPageView('/prepayment')
    }

    const allowAutoPay = computed(() => paymentConfig.value.allowAutoPay ?? true)
    const autoPay = ref(false)

    const showBillingFields = ref(false)

    type ButtonState = 'default' | 'loading' | 'success' | 'error' | 'failure'
    const buttonState: Ref<ButtonState> = ref('default')

    const submitColor = computed(() => {
      switch (buttonState.value) {
        case 'default':
        case 'failure':
          return 'primary'
        case 'loading':
          return 'grey'
        case 'success':
          return 'green'
        case 'error':
          return 'error'
        default:
          return 'primary'
      }
    })

    const payBtnTxt = computed(() => {
      switch (buttonState.value) {
        case 'default':
        case 'failure':
          return `Pay ${grandTotal.value.toFixed(2)} USD`
        case 'loading':
          return 'Submitting'
        case 'success':
          return 'Success'
        case 'error':
          return 'Error'
        default:
          return `Pay $${grandTotal.value.toFixed(2)} USD`
      }
    })

    watch(allowAutoPay, (newVal, oldVal) => {
      if (newVal) {
        autoPay.value = newVal
      }
    })

    const submitPayment = () => {
      if (['loading', 'success'].includes(buttonState.value)) {
        return
      }
      buttonState.value = 'loading'
      const iframe = document.getElementById('gocareIframe')
      if (iframe !== null) {
        ;(iframe as any).contentWindow.postMessage({ type: 'submit' }, '*')
      }
    }

    const submitOrderWithoutPay = () => {
      cancelAutomation("User didn't pay")
      showModal.value = false
      bus.$emit('submitOrder')
    }

    const closeAndSubmitOrder = () => {
      //console.log('closeAndSubmitOrder')
      showModal.value = false
      bus.$emit('submitOrder')
    }

    const accountFieldChanged = (event: unknown, field?: string) => {
      if (field) {
        const iframe = document.getElementById('gocareIframe')
        if (iframe !== null) {
          ;(iframe as any).contentWindow.postMessage({ type: field, event }, '*')
        }
      }
    }

    const onSuccess = (disposition: ShopperDisposition) => {
      $store.commit('setPaymentDisposition', disposition)
      buttonState.value = 'success'
    }

    const onError = (disposition: ShopperDisposition) => {
      $store.commit('setPaymentDisposition', disposition)
      buttonState.value = 'failure'
    }
    const onFinished = (finalDisposition: PaymentDisposition) => {
      setTimeout(function () {
        closeAndSubmitOrder()
      }, 1000)
    }

    addDispositionListener(onFinished, onSuccess, onError, closeAndSubmitOrder)

    return {
      autoPay,
      monthlyTotal,
      grandTotal,
      showModal,
      submitOrderWithoutPay,
      submitPayment,
      openModal,
      buttonState,
      payBtnTxt,
      submitColor,
      headerLogo,
      iFrameSrcDoc,
      paymentConfig,
      insertPrices,
      allowAutoPay,
      accountFieldChanged,
      showBillingFields,
      serviceAddress,
      paymentModalButtonText,
      thirdButtonText,
      thirdButtonAction,
      validated
    }
  }
})
</script>
<style scoped>
.dialog-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: ; */
  /* position: relative; */
  border-radius: 4px;
  background-color: #fff;
  /* height: 400px; */
  /* width: 400px; */
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.pay-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.iframe-wrapper {
  border-radius: 10px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: -8px;
  margin-right: -8px;
  border: 2px solid var(--v-secondary-base);
}

.gocare-iframe {
  border-width: 0;
  height: 6cm;
  width: 100%;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

#submit {
  margin-top: 16px;
}

.pay-button {
  height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  margin-top: 16px;
  color: white;
  /* font-family: 'Roboto', sans-serif; */
  /* background-color: #f15d22 !important; */
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.skip-button {
  height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  margin-top: 16px;
  color: white;
  /* font-family: 'Roboto', sans-serif; */
  background-color: #f15d22 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.pay-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-container {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.skip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pay-error {
  color: red;
  font-size: 12px;
}

.pay-methods {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75rem !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  /* font-family: 'Roboto', sans-serif !important; */
}

.location {
  text-align: center;
  margin-top: 15px;
  font-size: 0.75rem !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  /* font-family: 'Roboto', sans-serif !important; */
}

.disclaimer {
  font-size: 0.75rem !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  /* font-family: 'Roboto', sans-serif !important; */
}

.pay-header {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  /* font-family: Roboto, sans-serif; */
  font-size: 30px !important;
  /* background-color: var(--v-secondary-base); */
  /* color: white; */
  border-bottom-color: var(--v-secondary-base) !important;
  border-bottom-style: solid !important;
  border-bottom-width: 5px !important;
}

.pay-amount {
  font-weight: bold;
}

.monthly-charge {
  font-weight: bold;
}

.radio-top {
  padding-top: 10px;
  align-items: flex-start;
  display: flex;
}

.pay-radio-group {
  margin-top: 0px;
}

.label {
  color: #9b9c9e;
  display: inline-block;
  width: 150px;
  font-family: arial;
  font-size: 14px;
}

.pay-account-fields {
  padding-left: 10px;
  padding-right: 10px;
}
</style>

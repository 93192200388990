
<v-card :tile="sharpCorners" class="lg tr-0 tl-0 mb-5">
  <!-- <component :is="headerComponent" :title="headerText" :image="headerIconOrImage" :componentOrder="product.displayOrder" /> -->
  <CardHeader
    :title="phoneHeaderText"
    :image="phoneHeaderImage"
    :componentOrder="product?.displayOrder"
    :color="phoneHeaderBackgroundColor"
  ></CardHeader>
  <v-container id="phone">
    <PackageUpgrades :product="product" />
    <v-form v-model="allPhoneInfoValid" v-on:submit.prevent ref="form">
      <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''" class="pb-0">
        <v-col class="py-0 title primary--text" cols="12" id="customize-group-header">
          Phone Number Option <Tooltip :payload="{ Name: 'Phone Number Option' }" />
        </v-col>
      </v-row>
      <v-radio-group class="pt-0 ma-0" v-model="phoneNumberChoice" @change="phoneChoiceChanged">
        <div class="radioRow" v-for="p in product?.Upgrades" :key="p.Name">
          <v-radio
            :class="getCatalogItemClassName(p.Name, p.itemType)"
            v-if="p.Subcategory !== 'Package Upgrade'"
            :value="p.Name"
          />
          {{ p.Description }}
          <Tooltip :payload="p" />
          <TooltipDebug :item="p" />
        </div>
      </v-radio-group>
      <div v-if="phoneNumberChoice === 'Keep'">
        <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''" class="pb-0">
          <v-col class="pt-0 title primary--text" cols="12"> Current Phone Provider Information </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              v-model="currentPhone"
              label="Current Phone Number"
              :rules="[requiredRule, phoneRule]"
              v-mask="phoneMask"
              single-line
              solo
            />
          </v-col>
        </v-row>
        <v-row v-if="phonePortingLoaText" class="pl-3 pt-2 pr-2"><span v-html="phonePortingLoaText"></span></v-row>
        <div v-if="showPhonePortConsent" class="pl-2">
          <v-list class="pt-4">
            <v-list-item :ripple="false">
              <template v-slot:default="{ active, toggle }">
                <v-list-item-action>
                  <v-checkbox :rules="[requiredRule]" class="blackCheckbox" v-model="phonePortConsentModel" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-html="phonePortConsentTitle" />
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list>
          <v-row>
            <v-col class="pt-0">
              <p v-html="phonePortConsentText" />
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row v-if="showCurrentPhoneBillingName" dense>
            <v-col>
              <v-text-field
                v-model="shopper.customInfo.currentPhone.billingName"
                label="Name as it appears on bill"
                :rules="[requiredRule]"
                maxlength="100"
                single-line
                solo
              />
            </v-col>
          </v-row>
          <v-row v-if="showCurrentPhoneCarrier" dense>
            <v-col v-if="phoneCarrierDropdown">
              <PhoneCarrierDropdown :field="phoneCarrierDropdown" />
            </v-col>
            <v-col v-else>
              <v-text-field
                v-model="shopper.customInfo.currentPhone.carrier"
                label="Current Provider"
                :rules="[requiredRule]"
                maxlength="100"
                single-line
                solo
              />
            </v-col>
          </v-row>

          <v-row v-if="showCurrentPhoneAccountNumber" dense>
            <v-col>
              <v-text-field
                v-model="shopper.customInfo.currentPhone.accountNumber"
                label="Account Number"
                :rules="[requiredRule]"
                maxlength="100"
                single-line
                solo
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <div v-for="group in productGroups" :key="group.group">
        <div v-if="notAllExcluded(group.products)">
          <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
            <v-col class="py-0 title primary--text"
              >{{ getGroupTitle(group.group) }}
              <Tooltip :payload="{ Name: group.group }" />
              <TooltipDebug :item="group" :name="getGroupTitle(group.group)" />
            </v-col>
          </v-row>
          <v-row v-if="getGroupSubtitle(group.group)" :class="$vuetify.breakpoint.xs ? 'text-center' : ''">
            <v-col class="py-0" v-html="getGroupSubtitle(group.group)"></v-col>
          </v-row>

          <v-row v-if="group.products.length > 0 && radioGroup(group.products)">
            <v-container class="pl-1">
              <v-radio-group
                :mandatory="false"
                @change="equipmentChanged"
                v-model="choices[group.group]"
                class="pt-0 ma-0"
                :key="renderMe"
              >
                <div v-for="p in group.products" :key="p.Name">
                  <v-row v-if="shouldShowProduct(p)" dense class="py-0 px-0">
                    <v-col cols="9" class="py-0">
                      <v-row class="pl-5" style="flex-wrap: nowrap">
                        <v-radio class="py-0 black--text" :class="getCatalogItemClassName(p.Name, p.itemType)" :value="p.Name" />
                        <span v-html="uiMacroParser(shopper, itemDisplayName(p))"></span>
                        <Tooltip :payload="p" />
                        <TooltipDebug :item="p" />
                      </v-row>
                    </v-col>
                    <v-col v-if="p.included" cols="3" class="text-right pt-1"> Included </v-col>
                    <v-col v-else-if="getItemPrice(p, 'Monthly Price')" cols="3" class="text-right pt-1">
                      <span
                        v-html="uiMacroParser(shopper, p.PricePrefix) + ' ' + coreCurrency(getItemPrice(p, 'Monthly Price'))"
                      ></span>
                    </v-col>
                    <v-col v-else cols="3" class="text-right pt-1">
                      <span v-html="uiMacroParser(shopper, p?.PricePrefix) + ' ' + coreCurrency(getItemPrice(p, 'OTC'))"></span>
                    </v-col>
                  </v-row>
                  <div v-for="c in findChildren(p, equipment)" :key="c.Name">
                    <v-row v-show="productPicker(c) && c.Parent && isVisible(c)">
                      <v-col cols="9" style="padding-left: 51px" class="py-1">
                        <ProductPicker
                          :product="choices[c.Name] ? choices[c.Name] : c"
                          :min="c.Min"
                          :max="c.Max"
                          @input="equipmentChanged"
                          v-model="choices[c.Name]"
                          :render-me="renderMe"
                        />
                        <TooltipDebug :item="c" />
                      </v-col>
                      <v-col cols="3" class="text-right pt-1">
                        <span v-if="choices[c.Name]">{{ coreCurrency(choices[c.Name].calculatedPrice) }}</span>
                        <span v-if="!choices[c.Name]">{{ coreCurrency(0) }}</span>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-radio-group>
            </v-container>
          </v-row>

          <v-list v-else>
            <div v-for="p in group.products" :key="p.Name">
              <ProductPickerLineItem
                v-if="isProductPicker(p) && shouldShowProduct(p)"
                v-model="choices[p.Name]"
                :item="choices[p.Name] ? choices[p.Name] : p"
                @input="equipmentChanged"
                :render-me="renderMe"
                :max="p.Max"
                :min="p.Min"
              />
              <v-list-item v-else-if="shouldShowProduct(p)" :ripple="false">
                <template v-slot:default="{ active, toggle }">
                  <v-list-item-action>
                    <v-checkbox
                      :disabled="p.included === '1' || p.required === '1'"
                      hide-details
                      class="my-1"
                      :class="getCatalogItemClassName(p.Name, p.itemType)"
                      v-model="choices[p.Name]"
                      :true-value="p.Name"
                      :false-value="null"
                      @change="equipmentChanged"
                      :key="renderMe"
                    >
                    </v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-html="uiMacroParser(shopper, itemDisplayName(p))"></span>
                      <Tooltip :payload="p" />
                      <TooltipDebug :item="p" />
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="p.required">{{ getProductSubtitle(p) }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="p.included">Included</v-list-item-action>
                  <v-list-item-action v-else-if="getItemPrice(p, 'Monthly Price')"
                    ><span
                      v-html="uiMacroParser(shopper, p?.PricePrefix) + ' ' + coreCurrency(getItemPrice(p, 'Monthly Price'))"
                    ></span
                  ></v-list-item-action>
                  <v-list-item-action v-else
                    ><span v-html="uiMacroParser(shopper, p?.PricePrefix) + ' ' + coreCurrency(getItemPrice(p, 'OTC'))"></span
                  ></v-list-item-action>
                </template>
              </v-list-item>
              <div v-for="c in findChildren(p, equipment)" :key="c.Name">
                <v-row v-show="productPicker(c) && c.Parent && isVisible(c)">
                  <v-col cols="9" style="padding-left: 51px" class="py-1">
                    <ProductPicker
                      :product="choices[c.Name] ? choices[c.Name] : c"
                      :min="0"
                      :max="6"
                      @input="equipmentChanged"
                      v-model="choices[c.Name]"
                      :render-me="renderMe"
                    />
                    <TooltipDebug :item="c" />
                  </v-col>
                  <v-col cols="3" class="text-right pt-1">
                    <span v-if="choices[c.Name]">{{ coreCurrency(choices[c.Name].calculatedPrice) }}</span>
                    <span v-if="!choices[c.Name]">{{ coreCurrency(0) }}</span>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </v-form>
  </v-container>
</v-card>

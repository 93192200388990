
<v-toolbar flat :height="cardHeaderDefaultHeight" dark :color="color" @click="headerClicked()">
  <v-container fluid class="pa-0">
    <v-row :class="$vuetify.breakpoint.xs || ($vuetify.breakpoint.sm && isCartHeader) ? 'text-center' : ''">
      <v-col id="cart-close-button" class="close-button" v-if="isCartHeader && $vuetify.breakpoint.smAndDown" cols="2">
        <v-btn icon @click="$emit('cartHeaderCloseButtonClicked')">
          <v-icon :color="cartCloseIconColor">{{ cartCloseIcon }}</v-icon>
        </v-btn>
      </v-col>
      <v-col class="title" :cols="isCartHeader ? 8 : 12" v-html="title"> </v-col>
      <v-spacer v-if="isCartHeader && $vuetify.breakpoint.xs"></v-spacer>
    </v-row>
  </v-container>
  <v-btn
    disabled
    small
    fab
    text
    bottom
    right
    absolute
    :class="$vuetify.breakpoint.xs || ($vuetify.breakpoint.sm && isCartHeader) ? ['pt-2', 'center-button'] : ''"
  >
    <v-img v-if="image" :src="`/api/content/${image}`" height="45" width="45" />
  </v-btn>
</v-toolbar>

export interface ITooltip {
  name: string
  message: string[]
  type?: string
  title?: string
  image?: string[]
  logo?: string
  channelGroup?: []
  component?: any
  composite?: string[]
}

export class Tooltip implements ITooltip {
  constructor(
    public name: string,
    public message: string[],
    public type?: string,
    public title?: string,
    public image?: string[],
    public logo?: string,
    public channelGroup?: [],
    public component?: any,
    public composite?: string[],
    public clickable?: boolean
  ) {}
}

type MPString = string & { __brand: 'MPString' }

function isMPString(s: string): s is MPString {
  return s.includes('\\')
}
function assertIsMPString(s: string): asserts s is MPString {
  if (!isMPString(s)) {
    throw new Error(`Expected string with backslashes, got ${s}`)
  }
}

type mpSanitizedString = string & { __brand: 'mpSanitizedString' }
type mpPureString = string & { __brand: 'mpPureString' } // MP string without embedded HTML
type mpHtmlString = string & { __brand: 'mpHtmlString' } // MP string with embedded HTML

// proposed UDF structure
type udf = {
  signature: '\\function(s1,s2)'
  body: `
    \\red({{s1}}) \\blue({{s2}})
    `
  description: `This function takes two parameters and returns a string`
}

// Preprocessor to excape HTML characters before using macroParser
//&, <, >, ", ', `, , !, @, $, %, (, ), =, +, {, }, [, and ]
export function escapeHTML(input: string): mpPureString {
  return input.replace(/[&<>"'`,!@$%()=+{}[\]]/g, (tag) => {
    // escape all these characters including '&'
    // return input.replace(/(?:[<>"'`,!@$%()=+{}[\]]|(?:[&](?!amp;)))/g, (tag) => {  // dont escape '&' if it is already escaped
    const charsToReplace: Record<string, string> = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
      '`': '&#x60;',
      ',': '&#x2c;',
      '!': '&#x21;',
      '@': '&#x40;',
      $: '&#x24;',
      '%': '&#x25;',
      // '(': '&#x28;',  // can't escape these because they are used in macroParser
      // ')': '&#x29;',  // can't escape these because they are used in macroParser
      '=': '&#x3D;',
      '+': '&#x2B;',
      '{': '&#x7B;',
      '}': '&#x7D;',
      '[': '&#x5B;',
      ']': '&#x5D;'
    }
    return charsToReplace[tag] || tag
  }) as mpPureString
}

export function escapeATL(input: string): string {
  return input.replace(/([|()\\])/g, (tag) => {
    const charsToReplace: Record<string, string> = {
      '\\|': '|',
      '\\(': '(',
      '\\)': ')',
      '\\\\': '\\'
    }
    return charsToReplace[tag] || tag
  })
}

// function to sanitize any string for use in ATL
export function sanitizeString(input: string): mpSanitizedString {
  return escapeATL(escapeHTML(input)) as mpSanitizedString
}

class ParseError extends Error {
  input_text?: string
  constructor(message?: string, input_text?: string) {
    super(message)
    this.name = 'ParseError'
    this.input_text = input_text
    // Set the prototype explicitly.
    // Object.setPrototypeOf(this, ParseError.prototype);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isParseError(e: any): e is ParseError {
  return e instanceof ParseError
  //  || ((typeof e === 'object') && (e as ParseError).name === 'ParseError')
}

export type stringProvider = (...s: string[]) => string

// export type customFunctions = Record<string, stringProvider | string>
export type customFunctions = Record<string, stringProvider>

export function macroParser(input?: string, funcs?: customFunctions, validClasses?: string[]): string {
  input ??= ''
  try {
    input = escapeHTML(input)
    return span_parse(input, funcs, validClasses)
  } catch (e: any) {
    // if (isParseError(e)) {
    //   console.error(`macroParser error: ${e.message}`)
    // } else {
    //   console.error('unknown parser error', e)
    // }
    return input + ' (UI_MACRO_PARSER: ERROR PARSING STRING) ' + e?.message
  }
}

// recursive function to convert a string into html span elements
// that convert strings like \classname(text) into <span class="classname">text</span>
// throws ParseError if there is an error parsing the string
// input: string to parse
// original_input: original input string (used in recursive calls for error messages)
// returns: parsed string
function span_parse(
  input: string,
  funcs?: customFunctions,
  validClasses?: string[],
  original_input?: string,
  calledFunctions?: string[]
): string {
  let out = ''
  if (calledFunctions === undefined) {
    calledFunctions = []
  }
  // special tokes are:
  //  \( - escaped open parenthesis
  //  \) - escaped close parenthesis
  //  \\ - escaped backslash
  //  \| - escaped pipe
  //  \<span classname or function name>( - span tag with class classname and the opening parenthesis

  // regex splits the input into 3 parts:
  //  pre - text before the next token
  //  cmd - the token itself
  //  rest - text after the token
  //                    . \\ . \( . \) . \| . |. ). (. \func_name(      .\\.
  const fieldRegex = /^((?:.|\n)*?)(\\\\|\\\(|\\\)|\\\||\||\)|\(|\\[a-zA-Z0-9_-]+\(|\\)((?:.|\n)*)/m
  const slashRegex = /^(\\\\|\\\(|\\\)|\\\|)$/m
  const fields = fieldRegex.exec(input)
  if (fields === null) {
    out = input // regex failed, so just return the input
  } else {
    const pre = fields[1] // text before the next token
    const cmd = fields[2] // the token itself
    const rest = fields[3] // text after the token
    out += pre // add pre token text
    if (cmd === '(' || cmd === ')' || cmd === '\\' || cmd === '|') {
      throw new ParseError(`Unescaped "${cmd}" in substring "${input}"`, original_input ?? input)
    } else if (slashRegex.exec(cmd) !== null) {
      // unescape special characters
      out += cmd[1] // add escaped character
      out += span_parse(rest, funcs, validClasses, original_input ?? input, calledFunctions) // add rest
    } else {
      // find matching closing parenthesis (ugly but works)
      const srest = rest.split('')
      let depth = 1
      let is_escape_token = false
      let index = -1
      let split_parms = [] as string[]
      let prms_index = 0
      for (let i = 0; i < srest.length; i++) {
        if (srest[i] === '(' && !is_escape_token) depth += 1
        if (srest[i] === ')' && !is_escape_token) depth -= 1
        if (srest[i] === '|' && !is_escape_token && depth === 1) {
          split_parms.push(rest.substring(prms_index, i))
          prms_index = i + 1
        }
        is_escape_token = srest[i] === '\\' && !is_escape_token
        if (depth === 0) {
          split_parms.push(rest.substring(prms_index, i))
          index = i
          break
        }
      }
      if (index === -1) {
        throw new ParseError(`Unmatched "(" in string "${cmd + rest}"`, original_input ?? input)
      }
      const functionName = cmd.slice(1, -1) // the function name \functionname(
      const functionParm = rest.substring(0, index) // the function parameter \functionname(functionParm)
      const remainingtext = rest.substring(index + 1) // the remaining text after the closing parenthesis
      if (funcs !== undefined && functionName in funcs) {
        if (calledFunctions.includes(functionName)) {
          throw new ParseError(`Recursive call to function "${functionName}" in string "${cmd + rest}"`, original_input ?? input)
        }
        const func = funcs[functionName]
        let result
        if (typeof func === 'string') {
          result = func // func is a string, so just add it
        } else {
          result = func(...split_parms)
        }
        out += span_parse(result, funcs, validClasses, original_input ?? input, [...calledFunctions, functionName]) // add rest
      } else {
        if (validClasses === undefined || !validClasses.includes(functionName)) {
          throw new ParseError(
            `Unsupported function or CSS class "${functionName}" referenced in string "${cmd + rest}"`,
            original_input ?? input
          )
        }
        out += `<span class="${functionName}">` // add span tag
        out += span_parse(functionParm, funcs, validClasses, original_input ?? input, calledFunctions) // add inner text
        out += '</span>' // close span
      }
      out += span_parse(remainingtext, funcs, validClasses, original_input ?? input, calledFunctions) // add rest
    }
  }
  return out
}

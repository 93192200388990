<template>
  <v-card class="mx-auto product-picker py-0 my-0" max-width="500" :outlined="outlined" :flat="flat">
    <v-container fluid class="pa-0 ma-0">
      <v-row no-gutters>
        <v-col class="black--text" cols="12">
          <v-tooltip right class="" color="secondaryDark">
            <template v-slot:activator="{ on }">
              <span v-html="uiMacroParser(shopper, itemDisplayName(product))"></span>
              <span v-if="thumbLabel === false" class="pl-3 font-weight-bold secondaryDark--text">({{ model }}) </span>
              <span v-if="product.Tooltip" v-on="on"
                ><v-btn x-small icon
                  ><v-icon class="secondaryDark--text text--lighten-2">{{ mdiInformation }}</v-icon></v-btn
                >
              </span>
            </template>
            {{ product.Tooltip }}
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="subtitle">
        <v-col class="pb-4 caption">
          <span v-html="subtitle"></span>
        </v-col>
      </v-row>
    </v-container>
    <!--      <v-list-item>-->
    <v-slider
      class="ma-0"
      :class="getCatalogItemClassName(product.Name, product.itemType)"
      v-model="model"
      color="secondary"
      track-color="grey"
      always-dirty
      :min="min"
      :max="max"
      @change="changed"
      thumb-size="20"
      :thumb-label="thumbLabel"
      ticks
      style="max-width: 275px"
    >
      <template v-slot:prepend>
        <v-icon color="secondaryDark" @click="decrement">
          {{ mdiMinus }}
        </v-icon>
      </template>

      <template v-slot:append>
        <v-icon color="secondaryDark" @click="increment">
          {{ mdiPlus }}
        </v-icon>
      </template>
    </v-slider>
    <!--      </v-list-item>-->
  </v-card>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from '@vue/composition-api'
import { mdiInformation, mdiPlus, mdiMinus } from '@mdi/js'
import useUiConfig from '@/components/shared/useUiConfig'
import { getItemPrice, getItemPriceAsNumber } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'
import { itemDisplayName } from '@adg/catalog/src/modules/Catalog/v4/CatalogUtils'
import { getCatalogItemClassName } from '@adg/catalog/src/common/utils'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { IShopper } from '@adg/catalog/src/modules/Shopper'

export default defineComponent({
  name: 'ProductPicker',
  props: {
    renderMe: Number,
    product: {},
    min: Number,
    max: Number,
    thumbLabel: {
      //type: Boolean | String,
      validator: (v) => v === 'always' || v === true || v === false,
      default: 'always'
    },
    outlined: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { getProductSubtitle } = useUiConfig($store)
    const subtitle = computed(() => getProductSubtitle(props.product))

    const model = computed({
      get: () => (props.product.qty ? props.product.qty : 0),
      set: (val) => val
    })
    const price = ref(0)

    const shopper = computed(() => $store.getters.getShopper as IShopper)

    const changed = (val) => {
      if (getItemPrice(props.product, 'Monthly Price')) {
        price.value = getItemPriceAsNumber(props.product, 'Monthly Price') * val
      }
      if (props.product.OTC) {
        price.value = val === 0 ? 0 : getItemPriceAsNumber(props.product, 'OTC') // This look WRONG   KWC
      }
      emit('input', {
        ...props.product,
        qty: val,
        calculatedPrice: price.value
      })
    }
    const increment = () => {
      // prevent increment beyond maximum
      if (model.value < props.max) {
        changed(model.value + 1)
      }
    }
    const decrement = () => {
      if (model.value > props.min) {
        changed(model.value - 1)
      }
    }

    return {
      model,
      changed,
      increment,
      decrement,
      mdiInformation,
      mdiPlus,
      mdiMinus,
      subtitle,
      itemDisplayName,
      getCatalogItemClassName,
      uiMacroParser,
      shopper
    }
  }
})
</script>

<style>
.product-picker .v-list-item {
  padding: 0px;
}

.product-picker .v-input-control {
  padding: 0px !important;
}

.product-picker .v-input__slot {
  padding: 0px !important;
}

.product-picker div.v-messages.theme--light {
  min-height: 0px;
}
</style>

<template>
  <v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xsOnly ? 400 : 500">
    <template v-slot:activator="{ on, attrs }">
      <div v-on:mouseenter="dialog = !dialog">
        <v-icon :size="$vuetify.breakpoint.xsOnly ? 18 : 18">
          {{ mdiInformation }}
        </v-icon>
      </div>
    </template>
    <v-card dark color="secondaryDark" class="pt-2 rounded-lg" rounded>
      <v-card-text v-for="item in items" :key="item.name" class="py-0">
        <v-card-title v-if="item.title" class="pb-0">{{ item.title }}</v-card-title>
        <v-img v-if="item.logo" max-height="100" max-width="200" :src="item.logo" />
        <v-card-text v-html="item.message" class="py-4" />
        <!--                          <span v-html="item.message"/>-->
        <v-row no-gutters justify="space-around" @click="handleCarousel(item)">
          <v-col cols="3" class="col-sm-3 pa-2" v-for="img in item.image" :key="img">
            <video style="object-fit: contain" :src="img" autoplay muted width="100%" />
          </v-col>
        </v-row>
      </v-card-text>
      <ImageCarousel :images="carouselImages" :dialog="carouselDialog" @dialogClosed="dialogClosed" />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, watch, ref, computed } from '@vue/composition-api'
import ImageCarousel from '@/components/shared/ImageCarousel.vue'
import { API_CONTENT_ROOT } from '@/store/types'
import { mdiInformation } from '@mdi/js'
import { Tooltip } from './ITooltip'
import useTooltip from './useTooltip'

//TODO: Integrate Me with TooltipDialog component used by Boost!!
//todo: Tooltip $store
export default defineComponent({
  name: 'TooltipLogoDialog',
  props: {
    toolTip: Object
  },
  components: {
    ImageCarousel
  },
  setup(props, { root: { $store } }) {
    const dialog = ref(false)
    const { getTooltip } = useTooltip($store)

    //TODO: not sure what type item is, will fix in the future

    const dialogClosed = () => (carouselDialog.value = false)
    const handleCarousel = (item) => {
      carouselDialog.value = true
      carouselImages.value = item.image
    }
    const carouselImages = ref([])
    const carouselDialog = ref(false)

    watch(dialog, (current, prev) => {
      if (!current && prev !== false) {
        dialogClosed()
      }
    })

    const items = computed(() => {
      let tips: any = []
      const tooltipWithFormattedImage: any = {
        ...props.toolTip,
        image: props.toolTip?.image ? props.toolTip.image.map((i: string) => `${API_CONTENT_ROOT}/${i}`) : []
      }
      tips.push(tooltipWithFormattedImage)
      if (tooltipWithFormattedImage.composite) {
        tooltipWithFormattedImage.composite.forEach((c) => {
          if (c !== props.toolTip?.name) {
            const tt = getTooltip({ Name: c })
            tt &&
              tips.push({
                ...tt,
                image: tt.image ? tt.image.map((i) => `${API_CONTENT_ROOT}/${i}`) : [],
                logo: tt.logo ? `${API_CONTENT_ROOT}/${tt.logo}` : undefined
              })
          }
        })
      }
      return tips
    })

    return {
      items,
      carouselDialog,
      carouselImages,
      handleCarousel,
      API_CONTENT_ROOT,
      dialogClosed,
      mdiInformation,
      dialog
    }
  }
})
</script>

<style scoped>
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 6px;
}
</style>

<template>
  <v-toolbar flat :height="cardHeaderDefaultHeight" dark :color="color" @click="headerClicked()">
    <v-container fluid class="pa-0">
      <v-row :class="$vuetify.breakpoint.xs || ($vuetify.breakpoint.sm && isCartHeader) ? 'text-center' : ''">
        <v-col id="cart-close-button" class="close-button" v-if="isCartHeader && $vuetify.breakpoint.smAndDown" cols="2">
          <v-btn icon @click="$emit('cartHeaderCloseButtonClicked')">
            <v-icon :color="cartCloseIconColor">{{ cartCloseIcon }}</v-icon>
          </v-btn>
        </v-col>
        <v-col class="title" :cols="isCartHeader ? 8 : 12" v-html="title"> </v-col>
        <v-spacer v-if="isCartHeader && $vuetify.breakpoint.xs"></v-spacer>
      </v-row>
    </v-container>
    <v-btn
      disabled
      small
      fab
      text
      bottom
      right
      absolute
      :class="$vuetify.breakpoint.xs || ($vuetify.breakpoint.sm && isCartHeader) ? ['pt-2', 'center-button'] : ''"
    >
      <v-img v-if="image" :src="`/api/content/${image}`" height="45" width="45" />
    </v-btn>
  </v-toolbar>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api'
import useCardHeader from './useCardHeader'

export default defineComponent({
  name: 'CardHeaderDefault',
  props: {
    title: String,
    image: String,
    componentOrder: Number,
    color: String,
    isCartHeader: Boolean
  },
  setup(props, { emit, root }) {
    const { headerClicked, cartCloseIcon, cartCloseIconColor } = useCardHeader(emit)
    const $vuetify = root.$vuetify

    //Below is to get correct height for mobile cart.
    //If its cart header, and smAndDown, height is 60.
    //If its cart header, and smAndUp, height is 40.
    //Otherwise headers are 40 at smAndUp, 60 otherwise per original logic.
    const cardHeaderDefaultHeight = computed(() => {
      if (props.isCartHeader) {
        if ($vuetify.breakpoint.smAndDown) {
          return 60
        } else {
          return 40
        }
      } else if ($vuetify.breakpoint.smAndUp) {
        return 40
      }
      return 60
    })

    return {
      headerClicked,
      cartCloseIcon,
      cartCloseIconColor,
      cardHeaderDefaultHeight
    }
  }
})
</script>

<style scoped>
.center-button {
  left: 50%;
  transform: translateX(-50%);
}
#cart-close-button {
  padding-top: 4px;
}
</style>


<v-card :tile="sharpCorners" class="lg tr-0 tl-0" v-if="pkgUpgrades.length > 0">
  <!-- <component :is="headerComponent" :title="headerText" :image="headerIconOrImage" :componentOrder="componentOrder" /> -->
  <CardHeader
    :title="additionalServicesHeaderText"
    :image="additionalServicesHeaderImage"
    :componentOrder="componentOrder"
    :color="additionalServicesHeaderBackgroundColor"
  ></CardHeader>
  <v-container id="additional-services">
    <div v-for="group in packageServiceUpgradeGroups" :key="group.group">
      <div v-if="notAllExcluded(group.upgrades)">
        <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
          <v-col
            v-if="getGroupTitleDefaultToGroupName(group.group) && getGroupTitleDefaultToGroupName(group.group) !== 'undefined'"
            class="title primary--text"
            id="customize-group-header"
            >{{ getGroupTitleDefaultToGroupName(group.group) }} <Tooltip :payload="{ Name: group.group }" /><TooltipDebug
              :item="group"
              :name="getGroupTitleDefaultToGroupName(group.group)"
            />
          </v-col>
        </v-row>
        <v-row v-if="getGroupSubtitle(group.group)" :class="$vuetify.breakpoint.xs ? 'text-center' : ''">
          <v-col v-html="getGroupSubtitle(group.group)"> </v-col>
        </v-row>
      </div>
      <v-list v-if="notAllExcluded(group.upgrades)">
        <v-radio-group
          v-if="radioGroup(group.upgrades)"
          :mandatory="false"
          @change="changed"
          v-model="additionalServicesUpgradeChoices[group.group]"
          :key="renderMe"
          hide-details="auto"
        >
          <v-list-item
            v-for="packageServiceUpgrade in group.upgrades"
            :key="packageServiceUpgrade.Name"
            v-if="!packageServiceUpgrade.excluded"
          >
            <v-list-item-action>
              <v-radio
                :class="getCatalogItemClassName(packageServiceUpgrade.Name, packageServiceUpgrade.itemType)"
                :value="packageServiceUpgrade.Name"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                ><span v-html="uiMacroParser(shopper, itemDisplayName(packageServiceUpgrade))"></span>
                <Tooltip :payload="packageServiceUpgrade" />
                <TooltipDebug :item="packageServiceUpgrade" />
              </v-list-item-title>
              <v-list-item-subtitle v-if="getSubtitle(itemDisplayName(packageServiceUpgrade), undefined)"
                >{{ getSubtitle(itemDisplayName(packageServiceUpgrade), undefined) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="getItemPrice(packageServiceUpgrade, 'Monthly Price') !== undefined"
              ><span
                v-html="
                  uiMacroParser(shopper, packageServiceUpgrade?.PricePrefix) +
                  ' ' +
                  coreCurrency(getItemPrice(packageServiceUpgrade, 'Monthly Price'))
                "
              ></span
            ></v-list-item-action>
            <v-list-item-action v-else
              ><span
                v-html="
                  uiMacroParser(shopper, packageServiceUpgrade?.PricePrefix) +
                  ' ' +
                  coreCurrency(getItemPrice(packageServiceUpgrade, 'OTC'))
                "
              ></span
            ></v-list-item-action>
          </v-list-item>
        </v-radio-group>

        <div v-else>
          <div v-for="packageServiceUpgrade in group.upgrades" :key="packageServiceUpgrade.Name">
            <ProductPickerLineItem
              :unindentSlider="true"
              v-if="isProductPicker(packageServiceUpgrade) && !packageServiceUpgrade.excluded"
              v-model="additionalServicesUpgradeChoices[packageServiceUpgrade.Name]"
              :item="
                additionalServicesUpgradeChoices[packageServiceUpgrade.Name]
                  ? additionalServicesUpgradeChoices[packageServiceUpgrade.Name]
                  : packageServiceUpgrade
              "
              @input="changed"
              :render-me="renderMe"
              :max="packageServiceUpgrade.Max"
              :min="packageServiceUpgrade.Min"
            />
            <v-list-item v-else-if="!packageServiceUpgrade.excluded" :ripple="false">
              <template v-slot:default="{ active, toggle }">
                <v-list-item-action>
                  <v-checkbox
                    :disabled="packageServiceUpgrade.included === '1' || packageServiceUpgrade.required === '1'"
                    hide-details
                    v-model="additionalServicesUpgradeChoices[packageServiceUpgrade.Name]"
                    :true-value="packageServiceUpgrade.Name"
                    :false-value="null"
                    @change="changed"
                    :class="getCatalogItemClassName(packageServiceUpgrade.Name, packageServiceUpgrade.itemType)"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    ><span v-html="uiMacroParser(shopper, itemDisplayName(packageServiceUpgrade))"></span>
                    <Tooltip :payload="packageServiceUpgrade" />
                    <TooltipDebug :item="packageServiceUpgrade" />
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="getSubtitle(itemDisplayName(packageServiceUpgrade), undefined)"
                    >{{ getSubtitle(itemDisplayName(packageServiceUpgrade), undefined) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="getItemPrice(packageServiceUpgrade, 'Monthly Price') !== undefined"
                  ><span
                    v-html="
                      uiMacroParser(shopper, packageServiceUpgrade?.PricePrefix) +
                      ' ' +
                      coreCurrency(getItemPrice(packageServiceUpgrade, 'Monthly Price'))
                    "
                  ></span
                ></v-list-item-action>
                <v-list-item-action v-else
                  ><span
                    v-html="
                      uiMacroParser(shopper, packageServiceUpgrade?.PricePrefix) +
                      ' ' +
                      coreCurrency(getItemPrice(packageServiceUpgrade, 'OTC'))
                    "
                  ></span
                ></v-list-item-action>
              </template>
            </v-list-item>
          </div>
        </div>
      </v-list>
    </div>
  </v-container>
</v-card>


<div v-if="!hasChildren">
  <v-row v-if="isDebug">
    <v-col class="py-0" cols="4"
      ><span>{{ name }}</span>
      <span v-if="showTypes" style="color: green; font-weight: bold"> {{ getType(value) }}</span>
    </v-col>
    <v-col class="py-0" cols="8">
      <!-- todo: value is obviously a string, but ts won't infer that. also why is the styling done in a v-bind here? -->
      <Shorten v-if="typeof value === 'string'" :shortenValue="value" :amount="50" />

      <span v-else-if="value === undefined" style="color: blue; font-weight: bold">UNDEFINED</span>
      <span v-else-if="value === null" style="color: red; font-weight: bold">NULL</span>

      <span v-else>{{ value }}</span></v-col
    >
  </v-row>
  <v-row v-else>
    <v-col class="py-0" cols="4"
      ><span>{{ name }}</span>
    </v-col>
    <v-col class="py-0" cols="8">
      <span>{{ value }}</span>
    </v-col>
  </v-row>
  <!-- <v-row v-for="key in value" :key="key">
    <v-col class="py-0 text-right" cols="4">
      <span>{{ key }}</span>
    </v-col>
    <v-col class="py-0 text-right" cols="8">
      <span>{{ value[key] }}</span>
    </v-col>
  </v-row> -->
</div>
<v-expansion-panels v-else flat v-model="panel">
  <v-expansion-panel class="ma-0 pa-0" id="expLine">
    <v-expansion-panel-header hide-actions class="pa-0">
      <v-row v-if="objectValue">
        <v-col class="py-0" cols="5">
          <span>
            {{ name }}
            <v-icon v-if="panel == 0" class="pb-1 mr-1" small color="primary">{{ mdiMinusCircle }}</v-icon>
            <v-icon v-else class="pb-1 mr-1" small color="primary">{{ mdiPlusCircle }}</v-icon>
          </span>
        </v-col>
        <v-col class="py-0" cols="7">
          <!-- todo: value is obviously a string, but ts won't infer that. also why is the styling done in a v-bind here? -->
          <span v-if="typeof value === 'string'">"{{ shorten(value, 25) }}"</span>
          <span v-else-if="value === undefined" style="color: blue; font-weight: bold">UNDEFINED</span>
          <span v-else-if="value === null" style="color: red; font-weight: bold">NULL</span>

          <span v-else>"{{ objectValue }}"</span></v-col
        >
      </v-row>
      <v-row v-else class="ma-0">
        <v-col class="pa-0" cols="12">
          <span
            ><v-icon v-if="panel == 0" class="pb-1 mr-1" small color="primary">{{ mdiMinusCircle }}</v-icon>
            <v-icon class="pb-1 mr-1" v-else small color="primary">{{ mdiPlusCircle }}</v-icon
            >{{ name }}</span
          >
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-if="value && typeof value === 'object'" class="ma-0 pa-0">
        <div v-for="key in sortedKeys(value)" :key="key" class="ma-0 pa-0">
          <!-- todo: come back to this issue -->
          <ExpandableLine
            v-if="parseFloat(key) !== NaN && arrayName && arrayValue"
            :name="value[key][arrayName] ?? `UNDEFINED`"
            :objectValue="value[key][arrayValue]"
            :value="value[key]"
            :showTypes="showTypes"
          />
          <ExpandableLine v-else :name="key" :value="value[key]" :showTypes="showTypes" />
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</v-expansion-panels>

export interface PhoneNumberDetails {
  countryCode?: string
  areaCode: string
  exchange: string
  lineNumber: string
  formattedString: string
}

type UnformatedPhoneNumber = Omit<PhoneNumberDetails, 'formattedString'>

export const parsePhoneNumber = (phoneNumberString: string): PhoneNumberDetails | undefined => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    let countryCode = match[1] ? '+1 ' : ''
    const u: UnformatedPhoneNumber = { countryCode, areaCode: match[2], exchange: match[3], lineNumber: match[4] }
    return { ...u, formattedString: formatNumber(u) }
  }
  return undefined
}

const formatNumber = (ph: UnformatedPhoneNumber): string => {
  const countryCode = ph?.countryCode ? '+1 ' : ''
  return `${countryCode}(${ph.areaCode})${ph.exchange}-${ph.lineNumber}`
}

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const ph = parsePhoneNumber(phoneNumberString)
  const countryCode = ph?.countryCode ? '+1 ' : ''
  return ph ? `${countryCode}(${ph.areaCode}) ${ph.exchange}-${ph.lineNumber}` : phoneNumberString
}

export const unFormatPhoneNumber = (phoneNumberString: string): string => {
  const ph = parsePhoneNumber(phoneNumberString)
  const countryCode = ph?.countryCode ? '1 ' : ''
  return ph ? `${countryCode}${ph.areaCode}${ph.exchange}${ph.lineNumber}` : phoneNumberString
}


<v-container v-if="leadCapture">
  <v-row class="align-center justify-center">
    <v-col cols="12">
      <v-card :dark="leadCapture.isBackgroundDark" id="lead-capture-form-header-card" color="primary">
        <div class="d-flex flex-no-wrap justify-space-between">
          <div :class="leadCaptureHeaderFormClass">
            <v-card-title
              :id="leadCapture.header1.id"
              :class="$vuetify.breakpoint.mdAndUp ? 'text-h3' : 'text-h5'"
              v-html="
                leadCapture.header1.marketReadyDateDescription && marketReadyDate
                  ? leadCapture.header1.marketReadyDateDescription + ' ' + marketReadyDate + '!'
                  : leadCapture.header1.description
              "
            ></v-card-title>

            <v-card-title
              :id="leadCapture.header2.id"
              :class="$vuetify.breakpoint.mdAndUp ? 'text-h5 ' : 'text-h7'"
              v-if="leadCapture.header2"
              v-html="leadCapture.header2.description"
            ></v-card-title>
          </div>

          <v-spacer v-if="$vuetify.breakpoint.mdAndUp && leadCapture.image"></v-spacer>

          <div v-if="leadCapture.image" style="display: flex; justify-content: center; align-items: center">
            <v-img
              v-if="$vuetify.breakpoint.lgAndUp"
              class="ma-3"
              id="lead-form-image"
              :src="`/api/content/${leadCapture.image}`"
            ></v-img>
          </div>
          <v-spacer></v-spacer>
        </div>
      </v-card>
    </v-col>
  </v-row>
  <v-row class="align-center justify-center">
    <v-col cols="12">
      <v-card color="formBackground">
        <v-form v-model="validated">
          <v-container id="lead-capture-container">
            <v-row dense>
              <v-col
                style="height: 80px"
                v-for="field in leadCapture.fields"
                :key="field.storeProp"
                :class="field.class ? field.class : 'col-12 pt-0'"
              >
                <AccountFieldUI :class="field.class" :field="field" />
              </v-col>
            </v-row>
            <v-row v-if="leadCapture.additionalFields" dense>
              <v-col
                v-for="field in leadCapture.additionalFields"
                :key="field.storeProp"
                :class="field.class ? field.class : 'col-12 pt-0'"
                :id="field.id"
              >
                <AccountFieldUI :class="field.class" :field="field" />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" class="lead-capture-submit"
                ><v-btn
                  class="button"
                  :rounded="leadCapture.roundedSubmitButton"
                  :disabled="!validated"
                  @click="submit()"
                  color="primary"
                  >{{ leadCapture.buttonText ? leadCapture.buttonText : 'Submit' }}</v-btn
                ></v-col
              >
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</v-container>

import { cloneDeep, isEqual } from 'lodash'
import { IShopper } from './shopper'
import { IShopperUpdate } from './index'

// diff two shopper objects and return a new object with only the changed fields
// except for the id and logMsg fields ... they have to be included
let lastShopper: any = {}
// WARNING: shopperDelta() this is a STATEFUL function
// it will mutate the lastShopper object and return the diff
// reset = true will reset the lastShopper object and return the full object

export function shopperDelta<T extends Partial<IShopper>>(newShopper: T, reset = false): IShopperUpdate {
  if (reset) lastShopper = {}
  const delta: T = { ...newShopper }
  for (const key in delta) {
    if (key !== 'id' && key !== 'logMsg' && isEqual(delta[key], (lastShopper as any)[key])) {
      delete delta[key]
    }
  }
  lastShopper = cloneDeep(newShopper)
  return delta as IShopperUpdate
}

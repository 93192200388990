import { FullAddress } from '../modules/Address/address'
import { MatchType } from './MatchType'
import { AutomationSchedule } from './AutomationSchedule'
import { IShopper } from '../modules/Shopper/v2'
import { CreditCheckResponse } from './CreditChecks'
import { anyConditionTrue, conditionReasons, getTrueConditions } from './utils'
import { Conditional } from './UIConfig'

export interface AutomationInfo<T> {
  automationState?: LegacyAutomationState
  data: T
}

export type AutomationStatus =
  | 'initialized'
  | 'cancelled'
  | 'created'
  | 'failure'
  | 'submitted'
  | 'success'
  | 'unlocked'
  | 'partial'
  | 'skipped'
  | null
  | undefined

export function createInitialAutomationState(): LegacyAutomationState {
  return {
    type: 'legacy',
    status: 'initialized'
  }
}
export function goodStatus(status: AutomationStatus): boolean {
  switch (status) {
    case null:
    case undefined:
    case 'initialized':
    case 'created':
    case 'submitted':
    case 'success':
    case 'unlocked':
    case 'partial': {
      return true
    }
    default: {
      // "NO-GOOD"
      return false
    }
  }
}

export function commonShouldAutomate(
  shopper: IShopper,
  automationEnabled: boolean,
  automationBlockers: string[],
  automateActiveResidentialAccounts: boolean,
  automateCommercialAccounts: boolean,
  automateInitialPreSales: boolean,
  automateReturnPreSales: boolean,
  automatedMatchTypeRegex = 'EXACT',
  logMsgs?: string[],
  customAutomationBlockerConditions?: Array<Conditional[] | Conditional>
): boolean {
  const isResidential = shopper.tags?.classification === 'RESIDENTIAL'
  const isInitialPresale = shopper.customInfo?.initialPreSale ?? false;
  const isReturningPreSale = shopper.customInfo?.returningPreSale ?? false;

  const logReturn = (msg: string) => {
    //if (logMsgs) logMsgs.push(`ShouldAutomate: ${msg}`)
    if (logMsgs) logMsgs.push(`Automation not attempted ${msg}`)
  }

  if (!automationEnabled) {
    // logReturn('FALSE: Automation is disabled by caller of commonShouldAutomate()')
    return false
  }

  if (shopper.cancelAutomation) {
    // logReturn('FALSE: shopper.cancelAutomation is true')
    return false
  }

  if (isInitialPresale && !automateInitialPreSales) {
    logReturn("for initial presales orders")
    return false
  }

  if (isReturningPreSale && !automateReturnPreSales) {
    logReturn("for returning presales orders")
    return false
  }

  if (shopper.tags?.cancelAutomation) {
    let catalogCancelAutomationReason = 'as determined by the catalog'
    if (shopper.tags?.cancelAutomationReason) {
      catalogCancelAutomationReason = catalogCancelAutomationReason.concat(': ', shopper.tags?.cancelAutomationReason)
    }
    logReturn(catalogCancelAutomationReason)
    return false
  }

  if (customAutomationBlockerConditions && customAutomationBlockerConditions.length > 0) {
    if (anyConditionTrue(customAutomationBlockerConditions, shopper)) {
      logMsgs?.push(
        `custom condition caused automation to be blocked due to the following rules: ${conditionReasons(
          getTrueConditions(customAutomationBlockerConditions, shopper)
        )} `
      )
      return false
    }
  }

  // This should be functionally the same as the commented out code below

  const shouldAutomateExact = (): boolean => {
    if (['NEVER', 'FORMER'].includes(shopper.matchedAddress?.status ?? '')) return true
    if (shopper.matchedAddress?.status === 'ACTIVE' && isResidential && automateActiveResidentialAccounts) return true
    if (!isResidential && automateCommercialAccounts) return true
    return false
  }

  const automatedMatchTypes = new RegExp(`^${automatedMatchTypeRegex}$`)

  const isAllowedMatchType = (): boolean => {
    if (shopper.matchType && automatedMatchTypes.test(shopper.matchType)) {
      if (shopper.matchType === MatchType.EXACT) return shouldAutomateExact()
      else return true
    }
    return false
  }

  if (!isAllowedMatchType()) {
    logReturn('based on address match business rules')
    return false
  }

  // if (
  //   !(
  //     (shopper.matchType === MatchType.EXACT &&
  //       (['NEVER', 'FORMER'].includes(shopper.matchedAddress?.status ?? '') ||
  //         (shopper.matchedAddress?.status === 'ACTIVE' && isResidential && automateActiveResidentialAccounts) ||
  //         (!isResidential && automateCommercialAccounts))) ||
  //     shopper.matchType === MatchType.POLYGON
  //   )
  // ) {
  //   // logReturn( `FALSE: MatchType:status "${shopper.matchType}:${ shopper.matchedAddress?.status ?? '' }" is not one of EXACT:NEVER|EXACT:FORMER|EXACT:ACTIVE(automatable)|EXACT:(Commercial automatable)|POLYGON:*`)
  //   logReturn('based on address match business rules')
  //   return false
  // }
  if (!goodStatus(shopper.automationState?.status)) {
    // logReturn(`FALSE: AutomationStatus of "${shopper.automationState?.status}" is considered "NO-GOOD"`)
    logReturn('based on defined business rules')
    return false
  }
  if (shopper.automationState?.catalogLoadSuccessful !== undefined && shopper.automationState?.catalogLoadSuccessful === false) {
    // logReturn('FALSE: automationState.CatalogLoadSuccessful is not true')
    logReturn('based on catalog consistency business rules')
    return false
  }
  let blockingCartItems = shopper.order?.items ?? []
  blockingCartItems = blockingCartItems.concat(shopper.order?.products ?? [])
  const blockers = blockingCartItems.map((p) => p.Name).filter((i) => (automationBlockers ?? []).includes(i))
  const blocked = blockers.length > 0
  if (blocked) {
    // logReturn(`FALSE: Automation is 'blocked' by cart items: "${blockers.join(', ')}"`)
    logReturn('based on defined business rules on cart items')
    return false
  }
  return true
}

export interface AutomationStateBase {
  type: string
}

export interface LegacyAutomationState extends AutomationStateBase {
  type: 'legacy'
  status?: AutomationStatus | null
  message?: null | string
  timestamp?: string | null
  workOrders?: WorkOrder[]
  customer?: Customer | null
  schedule?: AutomationSchedule
  catalogLoadSuccessful?: boolean
  softCreditCheck?: CreditCheckResponse | null
  postCCPayment?: null | string
  availablePhoneNumbers?: AvailablePhoneNumbers
  idImageHash?: string
}

export interface AvailablePhoneNumbers {
  status: boolean
  phoneNumbers: string[]
  reservationId: string
}

export interface Customer {
  locationId: string
  houseNumber?: string
  occupantCode?: string
  firstName: string
  lastName: string
  emailAddress: string
  primaryPhone: string
  alternatePhone?: string
  category: Category
  type?: Category
  accountNumber?: string
  orderId?: string
  marketType?: string
  onlinePin?: string
  address?: FullAddress
}

export enum State {
  In = 'IN',
  Sro = 'SRO'
}

export enum Category {
  R = 'R',
  Residential = 'RESIDENTIAL'
}

export interface WorkOrder {
  status: AutomationStatus
  type: State | null
  message: null | string
  orderKey: OrderKey | null
}

export interface OrderKey {
  orderNumber: null | string
  lockKey: null | string
}

import { v4 as uuidv4 } from 'uuid'
import ga4 from '@/gtm/ga4'
import { IShopper, newShopper } from '@adg/catalog/src/modules/Shopper/v2'
import store from '@/store'

let appLoadId: string | undefined = undefined

export const getOrCreateAppLoadId = (): string => {
  if (appLoadId) return appLoadId

  appLoadId = uuidv4()
  ga4.setDataLayerVariable({ event: 'app_load_id_changed', app_load_id: appLoadId })
  return appLoadId
}

export const getNewBuyflowStartId = (): string => {
  const uuid = uuidv4()
  ga4.setDataLayerVariable({ event: 'buyflow_start_id_changed', buyflow_start_id: uuid })
  return uuid
}
export const appNewShopper = (): IShopper => {
  const shopper = newShopper()
  shopper.appLoadId = getOrCreateAppLoadId()
  shopper.buyflowStartId = getNewBuyflowStartId()
  return shopper
}
export const debugName = (text: string): string | undefined => {
  const isDebug = store.getters.getDebugMode
  return isDebug ? text : undefined
}


<v-app>
  <template v-if="useHeaderNew">
    <div class="banner-header-container">
      <v-row class="no-gutters" height="auto">
        <v-col cols="12" class="promo-banner-col">
          <Banner class="promo-banner" />
        </v-col>
        <v-col cols="12" class="header-new-col">
          <HeaderNew class="header-new-banner" />
        </v-col>
      </v-row>
    </div>
  </template>
  <template v-else>
    <Banner class="hidden-print-only" />
    <Header class="hidden-print-only" />
  </template>
  <v-main :class="`main-app-${currentStep}`" :id="currentStep === 1 ? 'serviceability-steps' : 'buyflow-steps'">
    <router-view></router-view>
  </v-main>
  <component :class="'footer-step-' + currentStep" :is="footerComponent" />
  <Spinner />
</v-app>


<div>
  <CardHeaderCustom
    @cartHeaderCloseButtonClicked="$emit('cartHeaderCloseButtonClicked')"
    v-if="cardHeaderComponent === 'alternate'"
    :title="title"
    :image="image"
    :componentOrder="componentOrder"
    :color="lobColor"
    :isCartHeader="isCartHeader"
  ></CardHeaderCustom>
  <CardHeaderDefault
    @cartHeaderCloseButtonClicked="$emit('cartHeaderCloseButtonClicked')"
    v-else
    :title="title"
    :image="image"
    :componentOrder="componentOrder"
    :color="lobColor"
    :isCartHeader="isCartHeader"
  ></CardHeaderDefault>
</div>

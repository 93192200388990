<template>
  <div v-if="showBanner" :key="currentRoute">
    <v-system-bar app dark height="auto" :class="currentBanner?.class">
      <v-spacer></v-spacer>
      <div class="pr-10 banner-title" v-html="currentBanner?.title"></div>
      <v-spacer></v-spacer>
    </v-system-bar>
  </div>
</template>
<script lang="ts">
import { usePiniaRoute } from '@/store/pinia/piniaRoute'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { computed, defineComponent, watch } from '@vue/composition-api'
import { getConfigItem, getConfigString } from '../shared/getConfigItem'

interface BannerConfig {
  title?: string
  class?: string
  routeDisallowMatch?: string
  routeAllowMatch?: string
  configMatch?: string
}

export default defineComponent({
  name: 'Banner',
  setup() {
    const currentRoute = computed(() => usePiniaRoute().currentPath)

    const regexMatch = (match: string, re: string = '.*'): boolean => {
      const rex = new RegExp(re)
      return rex.test(match)
    }

    const banners = computed((): BannerConfig[] => getConfigItem(ConfigKeys.banners))
    const bannerMatch = computed(() => getConfigString(ConfigKeys.bannerMatch) ?? '') // if bannerMatch is undefined, only match '' or '.*'

    const currentBanner = computed(() => {
      const retVal =
        banners.value &&
        banners.value
          .filter((banner) => regexMatch(bannerMatch.value, banner.configMatch)) //if configMatch is undefined, match all bannerMatch possiblities
          .filter((banner) => (banner.routeDisallowMatch ? !regexMatch(currentRoute.value, banner.routeDisallowMatch) : true)) // routeDisallow undefined, match all
          .find((banner) => regexMatch(currentRoute.value, banner.routeAllowMatch)) // routeAllow undefined, match all

      if (retVal && !retVal.class) {
        retVal.class = 'default-banner primary'
      }
      return retVal
    })

    const showBanner = computed(() => currentBanner.value?.title)

    return {
      currentRoute,
      showBanner,
      currentBanner
    }
  }
})
</script>
<style>
.default-banner {
  text-align: center !important;
}
</style>

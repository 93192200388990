
<v-card flat class="mx-auto number-picker pa-0 ma-0">
  <v-list-item class="pt-3">
    <v-slider
      v-model="model"
      color="secondary"
      track-color="grey"
      always-dirty
      :value="value"
      :min="min"
      :max="max"
      @change="changed"
      thumb-size="20"
      thumb-label="always"
      style="max-width: 275px"
    >
      <template v-slot:prepend>
        <v-icon color="secondary-dark" @click="decrement">
          {{ minus }}
        </v-icon>
      </template>

      <template v-slot:append>
        <v-icon color="secondary-dark" @click="increment">
          {{ plus }}
        </v-icon>
      </template>
    </v-slider>
  </v-list-item>
</v-card>

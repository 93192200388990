
<v-card>
  <v-row>
    <v-dialog v-model="showDialog" width="500" overlay-opacity=".9">
      <v-icon dark @click="closeDialog">{{ mdiClose }}</v-icon>
      <v-carousel height="800">
        <v-carousel-item
          dark
          reverse-transition="fade-transition"
          transition="fade-transition"
          v-for="(test, idx) in images"
          :key="idx"
        >
          <v-container>
            <v-row class="text-center">
              <v-col>
                <video style="object-fit: contain" :src="test" playsinline autoplay muted height="600" width="300"></video>
              </v-col>
            </v-row>
          </v-container>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </v-row>
</v-card>

// Catalog User Defined Functions

// calcPrice
//
// Select one of two prices for an item based on whether all the prerequisite dependencies
// of the item in question have also been selected (in selectedItems)
// todo: is this deprecated?
export const calcPrice = (selectedItems, dependencies, priceIfMet, priceNotMet) => {
  const prereqs = [dependencies].flat()

  const isMet = (d) => {
    const split = d.split(':')
    const name = split.length === 1 ? d : split[0]
    const qty = split.length === 2 ? parseInt(split[1]) : 1
    const filtered = selectedItems.filter((si) => si === name)
    return filtered.length >= qty
  }
  const price = parseFloat(prereqs.every(isMet) ? priceIfMet : priceNotMet)
  return price
}

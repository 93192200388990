<template>
  <!-- <v-container dense>
    <v-row no-gutters dense v-for="(q, i) in field.questions" v-bind:key="`referral-${i}`">
      <v-col dense justify="start" align-self="start" class="ref-col col-sm-6 col-12" cols="6">
        <v-checkbox dense :label="q.name" v-model="questions[i]" />
      </v-col>
      <v-col no-gutters justify="start" dense v-if="questions[i]" class="ref-col col-sm-6 col-12" cols="6">
        <v-text-field solo dense outlined :label="q.label" v-model="answers[i]" />
      </v-col>
    </v-row>
  </v-container> -->

  <!-- revisit to try and fix typing issues in the HTML, unsure how to handle it now -->

  <div>
    <v-row>
      <v-col>
        <div class="title field-label" v-html="field.label" />
      </v-col>
      <v-col cols="2" class="add-button">
        <v-btn icon :disabled="maxAnswersSubmitted" color="primary" @click="addReferral()"
          >Add<v-icon>{{ mdiPlusCircle }}</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-row class="pb-2" dense v-for="(q, i) in questions" v-bind:key="`referral-${i}`">
      <v-col class="col-11">
        <v-row>
          <v-col class="ref-col col-12" :class="questions[i] && questions[i].type ? 'col-sm-6' : 'col-sm-12'">
            <v-select
              dense
              outlined
              return-object
              :items="field.questions"
              label="Select One"
              item-text="name"
              v-model="questions[i]"
              :rules="required(i) ? rules : []"
            />
          </v-col>
          <v-col v-if="questions[i] && questions[i].type && questions[i].type === 'text'" class="ref-col col-sm-6 col-12">
            <v-text-field dense outlined :rules="getLocalRules(q.rules)" :label="q.label" v-model="answers[i]"> </v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="ref-col-2 pa-0" cols="1">
        <v-btn color="red" icon @click="removeReferral(i)" v-if="!required(i)"
          ><v-icon>{{ mdiCloseCircle }}</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import $store from '@/store'
import { AccountField, Question } from '@adg/catalog/src/common/UIConfig'
import { mdiPlusCircle, mdiCloseCircle } from '@mdi/js'
import { getRules } from './accountFunctions'
import { Referral } from '@adg/catalog/src/modules/Shopper'
import { getNum } from '@adg/catalog/src/common/utils'

export default defineComponent({
  name: 'Referrals',
  props: {
    field: {
      type: Object as PropType<AccountField>,
      required: true
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    //todo: why is questions this type? causing template issues above unsure how to remedy
    const questions = ref<(Question | string)[]>(
      new Array<Question | string>(props.field.maxLength ? getNum(props.field.maxLength) : 2)
    )
    const answers = ref(new Array<string>())

    const required = (i: number) => {
      return props?.field.maxLength && i < getNum(props.field.maxLength)
    }

    const addReferral = () => {
      questions.value.push('')
    }
    const removeReferral = (i: number) => {
      questions.value.splice(i, 1)
    }

    const getLocalRules = (ruleNames: string[]) => getRules($store, ruleNames)
    // watch(questions, (newval, oldval) => {
    //   console.log(newval)
    // })

    const updateSecurityQuestions = () => {
      const qAndAs: Referral[] = questions.value.map((q, i) => {
        if (typeof q !== 'string') {
          return {
            method: q.name ?? '',
            comment: q.label ?? undefined,
            referredBy: answers.value[i]
          }
        } else {
          return {}
        }
      })
      $store.commit('setReferralQuestions', qAndAs)
    }

    const maxAnswersSubmitted = computed(() => {
      if (!props.field.maxNumberOfAnswers) return false
      if (questions.value.length < props.field.maxNumberOfAnswers) return false
      return true
    })

    watch(questions, (pre, post) => {
      updateSecurityQuestions()
    })
    watch(answers, (pre, post) => {
      updateSecurityQuestions()
    })

    return {
      questions,
      answers,
      required,
      addReferral,
      removeReferral,
      mdiCloseCircle,
      mdiPlusCircle,
      getLocalRules,
      maxAnswersSubmitted
    }
  }
})
</script>
<style>
.ref-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -10px;
  margin-bottom: -10px;
}
.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  margin-bottom: -10px;
}
.ref-col-2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>

<template>
  <v-card :tile="sharpCorners" class="lg tr-0 tl-0" v-if="pkgUpgrades.length > 0">
    <!-- <component :is="headerComponent" :title="headerText" :image="headerIconOrImage" :componentOrder="componentOrder" /> -->
    <CardHeader
      :title="additionalServicesHeaderText"
      :image="additionalServicesHeaderImage"
      :componentOrder="componentOrder"
      :color="additionalServicesHeaderBackgroundColor"
    ></CardHeader>
    <v-container id="additional-services">
      <div v-for="group in packageServiceUpgradeGroups" :key="group.group">
        <div v-if="notAllExcluded(group.upgrades)">
          <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
            <v-col
              v-if="getGroupTitleDefaultToGroupName(group.group) && getGroupTitleDefaultToGroupName(group.group) !== 'undefined'"
              class="title primary--text"
              id="customize-group-header"
              >{{ getGroupTitleDefaultToGroupName(group.group) }} <Tooltip :payload="{ Name: group.group }" /><TooltipDebug
                :item="group"
                :name="getGroupTitleDefaultToGroupName(group.group)"
              />
            </v-col>
          </v-row>
          <v-row v-if="getGroupSubtitle(group.group)" :class="$vuetify.breakpoint.xs ? 'text-center' : ''">
            <v-col v-html="getGroupSubtitle(group.group)"> </v-col>
          </v-row>
        </div>
        <v-list v-if="notAllExcluded(group.upgrades)">
          <v-radio-group
            v-if="radioGroup(group.upgrades)"
            :mandatory="false"
            @change="changed"
            v-model="additionalServicesUpgradeChoices[group.group]"
            :key="renderMe"
            hide-details="auto"
          >
            <v-list-item
              v-for="packageServiceUpgrade in group.upgrades"
              :key="packageServiceUpgrade.Name"
              v-if="!packageServiceUpgrade.excluded"
            >
              <v-list-item-action>
                <v-radio
                  :class="getCatalogItemClassName(packageServiceUpgrade.Name, packageServiceUpgrade.itemType)"
                  :value="packageServiceUpgrade.Name"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  ><span v-html="uiMacroParser(shopper, itemDisplayName(packageServiceUpgrade))"></span>
                  <Tooltip :payload="packageServiceUpgrade" />
                  <TooltipDebug :item="packageServiceUpgrade" />
                </v-list-item-title>
                <v-list-item-subtitle v-if="getSubtitle(itemDisplayName(packageServiceUpgrade), undefined)"
                  >{{ getSubtitle(itemDisplayName(packageServiceUpgrade), undefined) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="getItemPrice(packageServiceUpgrade, 'Monthly Price') !== undefined"
                ><span
                  v-html="
                    uiMacroParser(shopper, packageServiceUpgrade?.PricePrefix) +
                    ' ' +
                    coreCurrency(getItemPrice(packageServiceUpgrade, 'Monthly Price'))
                  "
                ></span
              ></v-list-item-action>
              <v-list-item-action v-else
                ><span
                  v-html="
                    uiMacroParser(shopper, packageServiceUpgrade?.PricePrefix) +
                    ' ' +
                    coreCurrency(getItemPrice(packageServiceUpgrade, 'OTC'))
                  "
                ></span
              ></v-list-item-action>
            </v-list-item>
          </v-radio-group>

          <div v-else>
            <div v-for="packageServiceUpgrade in group.upgrades" :key="packageServiceUpgrade.Name">
              <ProductPickerLineItem
                :unindentSlider="true"
                v-if="isProductPicker(packageServiceUpgrade) && !packageServiceUpgrade.excluded"
                v-model="additionalServicesUpgradeChoices[packageServiceUpgrade.Name]"
                :item="
                  additionalServicesUpgradeChoices[packageServiceUpgrade.Name]
                    ? additionalServicesUpgradeChoices[packageServiceUpgrade.Name]
                    : packageServiceUpgrade
                "
                @input="changed"
                :render-me="renderMe"
                :max="packageServiceUpgrade.Max"
                :min="packageServiceUpgrade.Min"
              />
              <v-list-item v-else-if="!packageServiceUpgrade.excluded" :ripple="false">
                <template v-slot:default="{ active, toggle }">
                  <v-list-item-action>
                    <v-checkbox
                      :disabled="packageServiceUpgrade.included === '1' || packageServiceUpgrade.required === '1'"
                      hide-details
                      v-model="additionalServicesUpgradeChoices[packageServiceUpgrade.Name]"
                      :true-value="packageServiceUpgrade.Name"
                      :false-value="null"
                      @change="changed"
                      :class="getCatalogItemClassName(packageServiceUpgrade.Name, packageServiceUpgrade.itemType)"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      ><span v-html="uiMacroParser(shopper, itemDisplayName(packageServiceUpgrade))"></span>
                      <Tooltip :payload="packageServiceUpgrade" />
                      <TooltipDebug :item="packageServiceUpgrade" />
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="getSubtitle(itemDisplayName(packageServiceUpgrade), undefined)"
                      >{{ getSubtitle(itemDisplayName(packageServiceUpgrade), undefined) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="getItemPrice(packageServiceUpgrade, 'Monthly Price') !== undefined"
                    ><span
                      v-html="
                        uiMacroParser(shopper, packageServiceUpgrade?.PricePrefix) +
                        ' ' +
                        coreCurrency(getItemPrice(packageServiceUpgrade, 'Monthly Price'))
                      "
                    ></span
                  ></v-list-item-action>
                  <v-list-item-action v-else
                    ><span
                      v-html="
                        uiMacroParser(shopper, packageServiceUpgrade?.PricePrefix) +
                        ' ' +
                        coreCurrency(getItemPrice(packageServiceUpgrade, 'OTC'))
                      "
                    ></span
                  ></v-list-item-action>
                </template>
              </v-list-item>
            </div>
          </div>
        </v-list>
      </div>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, ref, reactive } from '@vue/composition-api'
import CardHeader from '@/components/layout/CardHeader.vue'
import { getConfigItem } from '@/components/shared/getConfigItem'
import {
  catalogRank,
  ConfigKeys,
  nullCheck,
  qtyCheck,
  itemDisplayName,
  getItemPrice,
  addCalculatedPrice,
  isProductPicker,
  Upgrade
} from '@adg/catalog/src/modules/Catalog'
import { SUBCATEGORY } from '@/store/types'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import $store from '@/store'
import useUiConfig from '../shared/useUiConfig'
import ProductPicker from '@/components/shared/ProductPicker.vue'
import ProductPickerLineItem from '@/components/shared/ProductPickerLineItem.vue'
import { flatten, groupBy, max, mean, min, toArray } from 'lodash'
import { bus } from '@/main'
import useComponentUtil from '../shared/useComponentUtil'
import { getCatalogItemClassName } from '@adg/catalog/src/common/utils'
import { coreCurrency } from '@adg/catalog/src/common/filters'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { IShopper } from '@adg/catalog/src/modules/Shopper'

interface AdditionalServiceUpgrade extends Upgrade {
  qty?: number
  calculatedPrice?: number
}

export default defineComponent({
  name: 'AdditionalServices',
  props: {
    packageUpgrades: Object,
    sharpCorners: Boolean
  },
  components: {
    CardHeader,
    Tooltip,
    TooltipDebug,
    ProductPicker,
    ProductPickerLineItem
  },
  setup(props, { emit }) {
    const renderMe = ref(0)

    const { getSubtitle } = useUiConfig()
    const selectedPackage = computed(() => $store.getters.getPackage)
    const { getGroupTitleDefaultToGroupName, getMarketingInfo, getProductSubtitle, getGroupSubtitle } = useUiConfig()
    const { checkbox, productPicker, radioGroup, findChildren } = useComponentUtil(props)

    const shopper = computed(() => $store.getters.getShopper as IShopper)

    //If streaming service upgrades are present, then increment componentOrder
    //in the computed below
    const streamingServicesPresent = computed(() => {
      return $store.getters.getAvailablePackageUpgrades
        .filter((u: Upgrade) => u[SUBCATEGORY] === 'Streaming Service')
        .some((streamingService: Upgrade) => !streamingService.excluded)
    })

    //This is only used to determine card header background color
    const componentOrder = computed(() => {
      const numProducts = selectedPackage.value.Products ? selectedPackage.value.Products.length : 0
      if (streamingServicesPresent.value) {
        return numProducts + 1
      } else return numProducts
    })

    const additionalServicesHeaderImage = computed(() => getConfigItem(ConfigKeys.additionalServicesHeaderImage) ?? '')
    const additionalServicesHeaderText = computed(
      () => getConfigItem(ConfigKeys.additionalServicesHeaderText) ?? 'ADDITIONAL SERVICES'
    )
    const additionalServicesHeaderBackgroundColor = computed(
      () => getConfigItem(ConfigKeys.additionalServicesHeaderBackgroundColor) ?? undefined
    )

    let additionalServicesUpgradeChoices: Record<string, AdditionalServiceUpgrade | string> = reactive({})

    const pkgUpgrades = computed(() => {
      const pkgs = $store.getters.getAvailablePackageUpgrades
        .filter((u: Upgrade) => u[SUBCATEGORY] === 'Package Service')
        .sort((a: Upgrade, b: Upgrade) => catalogRank(a.Rank) - catalogRank(b.Rank))
      return pkgs
    })

    const packageServiceUpgradeGroups = computed(() => {
      const packageUpgrades = pkgUpgrades.value.map((upgrade: Upgrade) => {
        upgrade.Rank ??= Number.MAX_SAFE_INTEGER // lowest rank
        return upgrade
      })
      const dontsort =
        min(packageUpgrades.map((upgrade: Upgrade) => upgrade.Rank)) ===
        max(packageUpgrades.map((upgrade: Upgrade) => upgrade.Rank))

      const grouped: any = pkgUpgrades.value ? groupBy(packageUpgrades, 'Group') : []

      const test = Object.keys(grouped).map((key) => ({
        group: key,
        upgrades: dontsort ? grouped[key] : grouped[key].sort((a, b) => a.Rank - b.Rank),
        //if they are not a part of a group, give them a very low meanrank so that group will show up first
        meanrank: key !== 'undefined' ? mean(grouped[key].map((x: Upgrade) => x.Rank)) : Number.MIN_SAFE_INTEGER // KWC for now just use mean value for rank of the group
      }))
      return dontsort ? test : test.sort((a, b) => a.meanrank - b.meanrank)
    })

    const changed = () => {
      const allItems = flatten(toArray(additionalServicesUpgradeChoices).filter(nullCheck))
      const strings = allItems.filter((a) => typeof a === 'string')
      const objs = allItems.filter((a) => typeof a !== 'string')
      const stringToObjects = pkgUpgrades.value?.filter((p: Upgrade) => strings.includes(p.Name) ?? [])

      const potentialCartItems = [...objs, ...stringToObjects]

      const readyForCart = potentialCartItems.filter(nullCheck).filter(qtyCheck)

      //remove those items not ready for cart
      potentialCartItems
        .filter(nullCheck)
        .filter((f) => !readyForCart.find((ready) => ready.Name === f.Name))
        .forEach((r) => {
          if (r !== null && additionalServicesUpgradeChoices[r.Name].qty) {
            // Why are we doing this? - DMC
            additionalServicesUpgradeChoices[r.Name].qty = 0
            additionalServicesUpgradeChoices[r.Name].calculatedPrice = 0
          }
        })
      $store.commit('setPackageUpgrades', {
        upgrades: [...objs, ...stringToObjects],
        subCategory: 'Package Service'
      })
      renderMe.value = renderMe.value + 1
    }

    // if the pkg upgrade is included, and ISN'T in the cart,
    //select the value on the control so that they are checked which updates the cart
    const initializeModel = () => {
      Object.keys(additionalServicesUpgradeChoices).forEach((k) => {
        delete additionalServicesUpgradeChoices[k]
      })
      const packageServiceUpgrades = $store.getters.getPackageUpgrades.filter(
        (packageUpgrade: Upgrade) => packageUpgrade[SUBCATEGORY] === 'Package Service'
      )

      packageServiceUpgrades.forEach((serviceUpgrade: Upgrade) => {
        //radio buttons use groups THIS COMPONENT DOESN'T SUPPORT GROUPS YET
        if (serviceUpgrade.Min === 1 && serviceUpgrade.Max === 1) {
          if (serviceUpgrade.Group && serviceUpgrade.Default === 'yes') {
            additionalServicesUpgradeChoices[serviceUpgrade.Group] = serviceUpgrade.Name
          }
        }
        //checkbox dont use groups
        if (serviceUpgrade.Min === 0 && serviceUpgrade.Max === 1) {
          additionalServicesUpgradeChoices[serviceUpgrade.Name] = serviceUpgrade.Name
        }
        //product picker uses objects
        if (serviceUpgrade.Max && serviceUpgrade.Max > 1) {
          additionalServicesUpgradeChoices[serviceUpgrade.Name] = {
            ...serviceUpgrade,
            calculatedPrice: addCalculatedPrice(serviceUpgrade)
          }
        }
      })
      renderMe.value = renderMe.value + 1
    }

    const notAllExcluded = (upgrades: Upgrade[]) => upgrades.some((upgrade) => !upgrade.excluded)

    bus.$on('packageChanged', () => {
      initializeModel()
    })

    return {
      selectedPackage,
      pkgUpgrades,
      componentOrder,
      getItemPrice,
      itemDisplayName,
      additionalServicesHeaderImage,
      additionalServicesHeaderText,
      additionalServicesHeaderBackgroundColor,
      getSubtitle,
      isProductPicker,
      additionalServicesUpgradeChoices,
      renderMe,
      changed,
      streamingServicesPresent,
      packageServiceUpgradeGroups,
      notAllExcluded,
      getGroupTitleDefaultToGroupName,
      getMarketingInfo,
      getProductSubtitle,
      getGroupSubtitle,
      checkbox,
      productPicker,
      radioGroup,
      findChildren,
      getCatalogItemClassName,
      coreCurrency,
      uiMacroParser,
      shopper
    }
  }
})
</script>

<style scoped>
#additional-services .v-list-item {
  padding-left: 0px;
  padding-right: 0px;
}

#additional-services #customize-group-header {
  padding-top: 0px;
  padding-bottom: 8px;
}
#additional-services .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
#additional-services .v-list-item {
  margin: 0;
  padding: 0;
  min-height: 6px;
}
#additional-services .v-list {
  padding: 0;
}
</style>

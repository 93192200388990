<template>
  <div v-on:mouseenter="dialog = !dialog">
    <v-icon :size="$vuetify.breakpoint.xsOnly ? 25 : 18">
      {{ mdiInformation }}
    </v-icon>

    <v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xsOnly ? 400 : 800">
      <div v-on:mouseleave="dialog = !dialog">
        <v-card color="secondaryDark">
          <v-card-text class="pt-6">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Includes</th>
                    <th class="text-left">GOOD</th>
                    <th class="text-left">BETTER</th>
                    <th class="text-left">BEST</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data" :key="item.name">
                    <td>
                      {{ item.name }}
                      <v-tooltip v-if="item.tooltip" right max-width="200" color="secondaryDark">
                        <template v-slot:activator="{ on }">
                          <span>
                            <v-btn v-on="on" x-small icon
                              ><v-icon>{{ mdiInformation }}</v-icon></v-btn
                            >
                          </span>
                        </template>
                        <span v-html="item.tooltip.join('')" />
                      </v-tooltip>
                    </td>
                    <td>
                      <v-icon color="primary" v-if="item.good">{{ mdiCheckCircle }}</v-icon>
                    </td>
                    <td>
                      <v-icon color="primary" v-if="item.better">{{ mdiCheckCircle }}</v-icon>
                    </td>
                    <td>
                      <v-icon color="primary" v-if="item.best">{{ mdiCheckCircle }}</v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from '@vue/composition-api'
import { mdiInformation, mdiCheckCircle } from '@mdi/js'

export default defineComponent({
  name: 'VastHomeAutomationMarketing',
  setup() {
    const dialog = ref(false)

    const data = [
      { name: 'Control Panel', good: true, better: true, best: true },
      { name: '3 Door Sensors', good: true, better: true, best: true },
      { name: '1 Motion-Standard', good: true, better: true, best: true },
      { name: 'Mobile App Control', good: true, better: true, best: true },
      {
        name: 'Central Monitoring ',
        good: false,
        better: true,
        best: true,
        tooltip: [
          'Central-timely, professional burglary and fire dispatch notifications via PHONE, text or email.',
          '<br>',
          '<br>',
          'Self-customer monitored burglary and fire notifications via text or email only.'
        ]
      },
      { name: '1 Indoor Camera', good: false, better: false, best: true }
    ]

    return {
      data,
      mdiInformation,
      mdiCheckCircle,
      dialog
    }
  }
})
</script>

<style scoped></style>

import { getConfigItem } from '@/components/shared/getConfigItem'
import { computed } from '@vue/composition-api'
import useCatalogConfig from '@/components/shared/useCatalogConfig'
import { AccountPanel, UIConfig } from '@adg/catalog/src/common/UIConfig'
import { ConfigKeys, MarketingText } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'

//import { GET_CATALOG } from '@/store/types'

export default () => {
  const uiConfig = computed((): UIConfig => $store.getters.getUIConfig)
  const marketingText = computed((): MarketingText[] => $store.getters.getMarketingText)
  // const getChannelGroup = name => {
  //   const channelGroup = $store.getters[GET_CATALOG]["ChannelGroups"][name];
  //const catalog = computed(() => $store.getters[GET_CATALOG])
  const { getSalesLeadLink } = useCatalogConfig($store)
  //   return channelGroup ? channelGroup : [];
  // };
  const offerHeaderColors = computed(() => (uiConfig.value.offers ? uiConfig.value.offers.map((offer) => offer.headerColor) : []))
  const offerHeaderSecondaryColors = computed(() =>
    uiConfig.value.offers ? uiConfig.value.offers.map((offer) => offer.headerColor2) : []
  )
  const offerImages = computed(() => (uiConfig.value.offers ? uiConfig.value.offers.map((offer) => offer.image) : []))
  const offerButtonColors = computed(() => (uiConfig.value.offers ? uiConfig.value.offers.map((offer) => offer.buttonColor) : []))
  const addressSearchText = computed(() => uiConfig.value.addressSearchText ?? 'Find the best offers in your area')

  const configSteps = computed(() => uiConfig.value.steps ?? [])

  const allowScheduling = computed(() => uiConfig.value.allowScheduling ?? true)

  const installationOptionsHtml = computed(() => uiConfig.value.installationOptionsHtml)

  const installationDateHtml = computed(() => uiConfig.value.installationDateHtml)

  const lobHeaderColors = computed(() => (uiConfig.value.lobs ? uiConfig.value.lobs.map((lob) => lob.headerColor) : []))
  const autoCompleteColor = computed(() =>
    uiConfig.value.autoComplete && uiConfig.value.autoComplete.color ? uiConfig.value.autoComplete.color : 'secondary'
  )

  const accountInfo = computed(() =>
    uiConfig.value.buyFlow ? uiConfig.value.buyFlow.find((page) => page.name === 'accountInfo') : undefined
  )

  const accountPanels = computed({
    get: (): AccountPanel[] => $store.getters.getAccountPanels ?? [],
    set: (val: AccountPanel[]) => $store.commit('setAccountPanels', val)
  })

  const accountInfoHeaderColor = computed(() => (accountInfo && accountInfo.value ? accountInfo.value.headerColor : 'secondary'))

  const scheduleInfo = computed(() =>
    uiConfig.value.buyFlow ? uiConfig.value.buyFlow.find((page) => page.name === 'schedule') : undefined
  )
  const scheduleInfoHeaderColor = computed(() =>
    scheduleInfo && scheduleInfo.value ? scheduleInfo.value.headerColor : 'secondary'
  )

  // const salesLeadInfo = computed((): SalesLeadInfo | undefined => {
  //   const catalogLeadLink = getSalesLeadLink()
  //   //KWC salesLead
  //   if (catalogLeadLink) {
  //     const cfg = catalogLeadLink.toString().trim()
  //     if (cfg.startsWith('http'))
  //       return {
  //         type: SalesLeadType.CATALOG_REDIRECT,
  //         salesLeadLink: catalogLeadLink
  //       }
  //     if (cfg.startsWith('{')) return JSON.parse(cfg) || undefined
  //     return undefined
  //   } else {
  //     return uiConfig.value?.salesLead || undefined
  //   }
  // })

  const getMarketingInfo = (productType, groupName) => {
    let info
    if (uiConfig.value.productTypes) {
      const pt = uiConfig.value.productTypes.find((x) => x.productType === productType)
      if (pt) {
        const group = pt.groups.find((grp) => grp.name === groupName)
        if (group) info = group.marketingInformation
      }
    }
    return info
  }

  const getGroupTitle = (groupName) => {
    let title = `Missing Group Title for ${groupName}`
    const item = marketingText.value ? marketingText.value.find((mt) => mt.Name === groupName) : []
    if (item) {
      title = item['Title']
    }
    return title
  }

  const getGroupTitleDefaultToGroupName = (groupName: string | undefined) => {
    let title = groupName
    const item = marketingText.value ? marketingText.value.find((mt: MarketingText) => mt.Name === groupName) : []
    if (item && item['Title']) {
      title = item['Title']
    }
    return title ?? ''
  }

  const getGroupSubtitle = (groupName) => {
    let subtitle
    const item = marketingText.value ? marketingText.value.find((mt) => mt.Name === groupName) : []
    if (item) {
      subtitle = item['Group Subtitle']
    }
    return subtitle ?? ''
  }

  const getGroupMarketingText = (productType, groupName) => {
    let marketingText = false
    if (uiConfig.value.productTypes) {
      const pt = uiConfig.value.productTypes.find((x) => x.productType === productType)
      if (pt) {
        const group = pt.groups.find((grp) => grp.name === groupName)
        if (group) marketingText = group.marketingText
      }
    }
    return marketingText
  }

  const getProductTypeLabel = (productType) => {
    let label = productType
    if (uiConfig.value.productTypes) {
      const pt = uiConfig.value.productTypes.find((x) => x.productType === productType)
      if (pt) label = pt.label
    }
    return label
  }
  const getProductTypeOfferLobs = (productType) => {
    let lobs = [productType]
    if (uiConfig.value.productTypes) {
      const pt = uiConfig.value.productTypes.find((x) => x.productType === productType)
      if (pt) lobs = pt.packageLobs
    }
    return lobs
  }

  const getMaxTvs = () => {
    let maxTvs = 5
    if (uiConfig.value.productTypes) {
      const pt = uiConfig.value.productTypes.find((x) => x.productType === 'TV')
      if (pt && pt.maxTvs) maxTvs = pt.maxTvs
    }
    return maxTvs
  }

  const getMinTvs = () => {
    let minTvs = 1
    if (uiConfig.value.productTypes) {
      const pt = uiConfig.value.productTypes.find((x) => x.productType === 'TV')
      if (pt && pt.hasOwnProperty('minTvs')) minTvs = pt.minTvs
    }
    return minTvs
  }

  const getSubtitle = (name: string, type?: string): string | undefined => {
    let subtitle
    const item: MarketingText | undefined = marketingText?.value.find((mt) => mt.Name === name)
    if (item) {
      const key = type ? `Subtitle[${type}]` : 'Subtitle[default]'
      subtitle = item[key as keyof MarketingText]?.toString()
    }
    return subtitle ?? undefined
  }

  const getProductSubtitle = (product) => {
    return getSubtitle(
      product.Name,
      product.included ? 'included' : product.required && product.required === '1' ? 'required' : 'default'
    )
  }

  return {
    addressSearchText,
    offerHeaderColors: offerHeaderColors,
    offerHeaderSecondaryColors: offerHeaderSecondaryColors,
    offerImages: offerImages,
    offerButtonColors: offerButtonColors,
    lobHeaderColors: lobHeaderColors,
    autoCompleteColor: autoCompleteColor,
    accountInfoHeaderColor: accountInfoHeaderColor,
    scheduleInfoHeaderColor: scheduleInfoHeaderColor,
    //salesLeadInfo,
    configSteps,
    installationOptionsHtml,
    installationDateHtml,
    allowScheduling,
    getGroupTitle,
    getGroupMarketingText,
    getMarketingInfo,
    getProductTypeLabel,
    getProductTypeOfferLobs,
    getMaxTvs,
    getMinTvs,
    getProductSubtitle,
    getSubtitle,
    marketingText,
    accountPanels,
    getGroupSubtitle,
    getGroupTitleDefaultToGroupName
  }
}

import { logger } from '@/utils/RemoteLogger'
import { Package } from '@adg/catalog'
import { BroadbandLabel, ConfigKeys, getItemFeatures, getItemFeesRecursive } from '@adg/catalog/src/modules/Catalog'
import { isParseError, macroParser } from '@adg/catalog/src/util/parser/macroParser'
import { ShopperParser } from '@adg/catalog/src/util/parser/ShopperParser'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import packageFunctions from './packageFunctions'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { mdiRowing } from '@mdi/js'
import axios from 'axios'
import { coreCurrency } from '@adg/catalog/src/common/filters'
import { computed } from '@vue/composition-api'
import $store from '@/store'
import { v4 as uuidv4 } from 'uuid'

// import packageFunctions from '//packageFunctions'

//@ts-ignore
const html = (strings, ...values) => String.raw({ raw: strings }, ...values)
//not sure if html will work here
type macroPackage = Package & {
  [key: string]: string
}

export interface CreateBroadbandLabelPayload {
  orgId: string
  clientId: string
  shopperId: string
  labelHtmlString: string
  packageName: string
  labelPlacement: string
  uniquePlanId: string
}

export interface UpdateBroadbandLabelPayload {
  id: number
  shopperId: string
  uniquePlanId: string
  labelHtmlString: string
}

export interface BroadbandLabelHtml {
  html: string
  uniquePlanId: string
}

export function broadbandLabelParser(
  shopper: IShopper,
  pkg: Package,
  input: string,
  approvedBroadbandLabelClasses: string[],
  broadbandLabelPlacement: string,
  broadbandLabel?: BroadbandLabel
): BroadbandLabelHtml {
  if (!input) return { html: 'NO DATA AVAILABLE', uniquePlanId: '' }
  try {
    const shopperParser = new ShopperParser(shopper, pkg, approvedBroadbandLabelClasses)
    const { packageDisplayName, packageTotalPrice } = packageFunctions()
    const features = getItemFeatures(pkg)
    const participatesInACP = computed(() => getConfigItem(ConfigKeys.participatesInACP) ?? 'NO')
    const broadbandLabelPrefixes = computed(() => getConfigItem(ConfigKeys.broadbandLabelPrefixes) ?? {})
    const labelContractLengthString = computed(() => getConfigItem(ConfigKeys.broadbandLabelContractLengthString))
    const labelFeeItemPrefixString = computed(() => getConfigItem(ConfigKeys.broadbandLabelFeeItemPrefixString))
    const feeItemPrefix = computed(() => {
      if (labelFeeItemPrefixString.value || labelFeeItemPrefixString.value === '') {
        return labelFeeItemPrefixString.value
      } else {
        return '- '
      }
    })
    const monthlyIntroPriceStrings = computed(
      () =>
        getConfigItem(ConfigKeys.monthlyIntroPriceStrings) ?? {
          monthlyIntroPriceText1: 'This Monthly Price is an introductory rate. After the introductory period of ',
          monthlyIntroPriceText2: ' the rate will increase to ',
          monthlyIntroPriceText3: ' per month.',
          noIntroRate: 'This Monthly Price is not an introductory rate.'
        }
    )
    const monthlyContractStrings = computed(
      () =>
        getConfigItem(ConfigKeys.monthlyContractStrings) ?? {
          monthlyContractText1: 'This Monthly Price does require a ',
          monthlyContractText2: ' contract. View terms: '
        }
    )

    //will need to be reworked to include all included equipment/fees
    const allFees = [getItemFeesRecursive(pkg, 1, true)]

    pkg.Products.forEach((p) => {
      const fees = getItemFeesRecursive(p, 1, true)
      allFees.push(fees)
    })

    function padZeroesFifteenDigits(num: number, length?: number): string {
      const retVal = num.toString().padStart(15 - (length ?? broadbandLabelPrefixes.value?.third?.length), '0')
      return retVal
    }

    const monthlyProviderCharges = allFees
      .flat()
      .filter((f) => f['Monthly Price'] !== undefined)
      ?.map((f) => {
        if (typeof f['Monthly Price'] !== 'number') {
          return {
            name: f['Display Name'] ?? f.Name,
            price: f['Monthly Price']
          }
        }
        return {
          name: f['Display Name'] ?? f.Name,
          price: '$' + f['Monthly Price']?.toString()
        }
      })

    const oneTimeProviderCharges = allFees
      .flat()
      .filter((f) => f['OTC'] !== undefined)
      ?.map((f) => {
        if (typeof f['OTC'] !== 'number') {
          return {
            name: f['Display Name'] ?? f.Name,
            price: f['OTC']
          }
        }
        return {
          name: f['Display Name'] ?? f.Name,
          price: '$' + f['OTC']?.toString()
        }
      })

    let uniquePlanId = ''

    if (broadbandLabel?.UniquePlanId) {
      uniquePlanId = broadbandLabel.UniquePlanId
    }
    const frontEndLabelId = Number.parseInt(uuidv4())

    // const labelPayload: CreateBroadbandLabelPayload = {
    //   uniquePlanId: uniquePlanId,
    //   orgId: shopper.orgId,
    //   clientId: shopper.clientId,
    //   shopperId: shopper.id,
    //   labelHtmlString: '',
    //   packageName: packageDisplayName(pkg) ?? 'NO PACKAGE NAME',
    //   labelPlacement: broadbandLabelPlacement
    // }

    // $store.dispatch('addBroadbandLabel', labelPayload)

    //call api to insert html string
    if (!broadbandLabel?.UniquePlanId && frontEndLabelId) {
      if (broadbandLabelPrefixes.value.first && broadbandLabelPrefixes.value.second && broadbandLabelPrefixes.value.third) {
        uniquePlanId =
          broadbandLabelPrefixes.value.first +
          broadbandLabelPrefixes.value.second +
          broadbandLabelPrefixes.value.third +
          padZeroesFifteenDigits(frontEndLabelId)
      } else {
        uniquePlanId = shopper.clientId.toUpperCase() + padZeroesFifteenDigits(frontEndLabelId, shopper.clientId.length)
      }
    }

    const row = (s: string) => html`<div style="display: flex; flex-direction: row; justify-content: space-between">${s}</div>`
    const column = (s: string) => `<div style="display: flex; flex-direction: column">${s}</div>`
    const textRight = (s: string) => `<div class="text-right" style="text-align: right">${s}</div>`
    const textLeft = (s: string) => `<div><div class="text-left" style="text-align: left">${s}</div></div>`
    const link = (s: string, href: string) => `<a href="${href}" target="_blank">${s}</a>`

    shopperParser.addfunctions({
      row: (s: string) => row(s),
      column: (s: string) => column(s),
      textRight: (s: string) => textRight(s),
      textLeft: (s: string) => textLeft(s),
      dividerSmall: () => `<div style="border-bottom: 2px solid black"></div>`,
      dividerMedium: () => `<div style="border-bottom: 6px solid black"></div>`,
      dividerLarge: () => `<div style="border-bottom: 12px solid black"></div>`,
      link: (s: string, href: string) => link(s, href),
      getContactUsData: () => {
        const link = features.find((f) => f.FeatureType === 'LabelContactURL')
        const phone = features.find((f) => f.FeatureType === 'LabelContactPhone')
        if (link && phone) {
          return `<a href="${link.ExternalLink}" target="_blank">${link.Description}</a> / <a href="tel:${phone.Description}">${phone.Description}</a>`
        } else if (link && !phone) {
          return `<a href="${link.ExternalLink}" target="_blank">${link.Description}</a>`
        } else if (!link && phone) {
          return `<a href="tel:${phone.Description}">${phone.Description}</a>`
        } else {
          return 'NO DATA AVAILABLE'
        }
      },
      packageDisplayName: () => packageDisplayName(pkg) ?? 'NO PACKAGE NAME',
      packageTotalPrice: () => packageTotalPrice(pkg) ?? 'NO PACKAGE PRICE',
      participatesInACP: () => participatesInACP.value,
      getLabelInternetCategory: () => {
        const internetCategory = features.find((f) => f.FeatureType === 'LabelInternetCategory')?.Description
        return internetCategory ?? 'NO DATA AVAILABLE'
      },
      getLabelContractLength: () => {
        const contractLength = features.find((f) => f.FeatureType === 'LabelContractLength')?.Description
        return contractLength ?? 'NO DATA AVAILABLE'
      },
      getDiscountBundle: () => {
        const discountBundle = features.find((f) => f.FeatureType === 'LabelDiscountBundle')?.Description
        return discountBundle ?? 'NO DATA AVAILABLE'
      },
      getLabelUploadSpeed: () => {
        const uploadSpeed = features.find((f) => f.FeatureType === 'LabelUploadSpeed')?.Description
        return uploadSpeed ?? 'NO DATA AVAILABLE'
      },
      getLabelDownloadSpeed: () => {
        const downloadSpeed = features.find((f) => f.FeatureType === 'LabelDownloadSpeed')?.Description
        return downloadSpeed ?? 'NO DATA AVAILABLE'
      },
      getLabelLatency: () => {
        const latency = features.find((f) => f.FeatureType === 'LabelLatency')?.Description
        return latency ?? 'NO DATA AVAILABLE'
      },
      getLabelMonthlyData: () => {
        const monthlyData = features.find((f) => f.FeatureType === 'LabelMonthlyData')?.Description
        return monthlyData ?? 'NO DATA AVAILABLE'
      },
      getLabelAdditionalDataCharges: () => {
        const additionalDataCharges = features.find((f) => f.FeatureType === 'LabelAdditionalMonthlyDataCharges')?.Description
        return additionalDataCharges ?? 'NO DATA AVAILABLE'
      },
      getLabelGovernmentTaxes: () => {
        const governmentTaxes = features.find((f) => f.FeatureType === 'LabelGovernmentTaxes')?.Description
        return governmentTaxes ?? 'NO DATA AVAILABLE'
      },
      getLabelEarlyTerminationFee: () => {
        const earlyTerminationFee = features.find((f) => f.FeatureType === 'LabelEarlyTerminationFee')?.Description
        return earlyTerminationFee ?? 'NO DATA AVAILABLE'
      },
      getUniquePlanID: () => uniquePlanId,
      getMonthlyProviderCharges: () => {
        let monthlyChargeString = ''
        monthlyProviderCharges?.forEach((monthlyCharge) => {
          monthlyChargeString += row(column(textLeft('- ' + monthlyCharge.name)) + column(textRight(monthlyCharge.price)))
        })
        return monthlyChargeString
      },
      getOneTimeProviderCharges: () => {
        let oneTimeChargeString = ''
        oneTimeProviderCharges?.forEach((oneTimeCharge) => {
          oneTimeChargeString += row(column(textLeft('- ' + oneTimeCharge.name)) + column(textRight(oneTimeCharge.price)))
        })
        return oneTimeChargeString
      },
      getBroadbandLabelName: () => {
        return broadbandLabel?.Name ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelUniquePlanId: () => {
        return uniquePlanId
      },
      getBroadbandLabelProviderName: () => {
        return broadbandLabel?.ProviderName ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelServicePlanNameOrSpeedTier: () => {
        return broadbandLabel?.ServicePlanNameOrSpeedTier ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelFixedOrMobile: () => {
        return broadbandLabel?.FixedOrMobile ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelIntroPriceText: () => {
        if (broadbandLabel?.HasIntroPrice) {
          return (
            // label specific intro price text, if not defined, use default way to display intro price
            broadbandLabel?.HasIntroPriceDisclosureText ??
            monthlyIntroPriceStrings.value.monthlyIntroPriceText1 +
              broadbandLabel?.IntroPeriod +
              monthlyIntroPriceStrings.value.monthlyIntroPriceText2 +
              coreCurrency(broadbandLabel?.MonthlyPrice) +
              monthlyIntroPriceStrings.value.monthlyIntroPriceText3
          )
        }
        // label specific no intro price text, if not defined, use default way to display no intro price
        return broadbandLabel?.NoIntroPriceDisclosureText ?? monthlyIntroPriceStrings.value.noIntroRate
      },
      getBroadbandLabelMonthlyPrice: () => {
        return coreCurrency(broadbandLabel?.IntroPrice ?? broadbandLabel?.MonthlyPrice) ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelContractText: () => {
        if (broadbandLabel?.ContractRequired && broadbandLabel.ContractLength && broadbandLabel.ContractTermsUrl) {
          return (
            monthlyContractStrings.value.monthlyContractText1 +
            broadbandLabel.ContractLength +
            monthlyContractStrings.value.monthlyContractText2 +
            link(labelContractLengthString.value ?? broadbandLabel.ContractTermsUrl, broadbandLabel.ContractTermsUrl)
          )
        }
        return ''
      },
      getBroadbandLabelEarlyTerminationFee: () => {
        return coreCurrency(broadbandLabel?.EarlyTerminationFee) ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelGovernmentTaxes: () => {
        return coreCurrency(broadbandLabel?.GovernmentTaxes) ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelDiscountsText: () => {
        if (broadbandLabel?.DiscountsUrl && broadbandLabel?.DiscountsText) {
          return link('Click Here', broadbandLabel.DiscountsUrl) + ' ' + broadbandLabel.DiscountsText
        } else if (broadbandLabel?.DiscountsText) {
          return broadbandLabel.DiscountsText
        }
        return ''
      },
      getBroadbandLabelParticipatesInACP: () => {
        return broadbandLabel?.ParticipatesInACP ? 'Yes' : 'No'
      },
      getBroadbandLabelDownloadSpeed: () => {
        return broadbandLabel?.DownloadSpeed ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelUploadSpeed: () => {
        return broadbandLabel?.UploadSpeed ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelLatencySpeed: () => {
        return broadbandLabel?.LatencySpeed ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelIncludedData: () => {
        return broadbandLabel?.IncludedData ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelAdditionalData: () => {
        return broadbandLabel?.AdditionalData ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelAdditionalDataPrice: () => {
        return broadbandLabel?.AdditionalDataPrice ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelNetworkManagementPolicyUrl: () => {
        return broadbandLabel?.NetworkManagementPolicyUrl ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelPrivacyPolicyUrl: () => {
        return broadbandLabel?.PrivacyPolicyUrl ?? 'NO DATA AVAILABLE'
      },
      getBroadbandLabelContactUsData: () => {
        const link = broadbandLabel?.CustomerSupportUrl
        const phone = broadbandLabel?.CustomerSupportPhoneNumber
        if (link && phone) {
          return `<div class="bbl-link"><a href="${link}" target="_blank">${link}</a> / <a href="tel:${phone}">${phone}</a></div>`
        } else if (link && !phone) {
          return `<div class="bbl-link"><a href="${link}" target="_blank">${link}</a><div>`
        } else if (!link && phone) {
          return `<div class="bbl-link"><a href="tel:${phone}">${phone}</a></div>`
        } else {
          return 'NO DATA AVAILABLE'
        }
      },
      getBroadbandLabelMonthlyFees: () => {
        let monthlyFeeString = ''
        for (let i = 1; i <= 10; i++) {
          const index = i.toString()
          const feeName = broadbandLabel?.[('MonthlyFeeName' + index) as keyof typeof broadbandLabel]?.toString()
          const feePrice = broadbandLabel?.[('MonthlyFee' + index) as keyof typeof broadbandLabel]?.toString()
          if (feeName && feePrice) {
            monthlyFeeString += row(
              column(textLeft(feeItemPrefix.value ? feeItemPrefix.value + feeName : feeName)) +
                column(textRight(coreCurrency(feePrice)))
            )
          }
        }
        return monthlyFeeString
      },
      getBroadbandLabelOneTimeFees: () => {
        let oneTimeFeeString = ''
        for (let i = 1; i <= 10; i++) {
          const index = i.toString()
          const feeName = broadbandLabel?.[('OneTimeFeeName' + index) as keyof typeof broadbandLabel]?.toString()
          const feePrice = broadbandLabel?.[('OneTimeFee' + index) as keyof typeof broadbandLabel]?.toString()
          if (feeName && feePrice) {
            oneTimeFeeString += row(
              column(textLeft(feeItemPrefix.value ? feeItemPrefix.value + feeName : feeName)) +
                column(textRight(coreCurrency(feePrice)))
            )
          }
        }
        return oneTimeFeeString
      },
      getBroadbandLabelMonthlyPriceDisclosureTermsAndValues: () => {
        let monthlyPriceDisclosureTermsAndValues = ''
        for (let i = 1; i <= 10; i++) {
          const index = i.toString()
          const termText = broadbandLabel?.[('MonthlyPriceDisclosureTerm' + index) as keyof typeof broadbandLabel]?.toString()
          const valueText = broadbandLabel?.[('MonthlyPriceDisclosureValue' + index) as keyof typeof broadbandLabel]?.toString()
          if (termText && valueText) {
            monthlyPriceDisclosureTermsAndValues += row(column(textLeft(termText)) + column(textRight(coreCurrency(valueText))))
          } else {
            break
          }
        }
        return monthlyPriceDisclosureTermsAndValues
      },
      getBroadbandLabelPricingDisclosureText: () => {
        return broadbandLabel?.PricingDisclosureText ?? ''
      }
    })

    const parsedInput = shopperParser.parse(input)

    const labelPayload: CreateBroadbandLabelPayload = {
      uniquePlanId: uniquePlanId,
      orgId: shopper.orgId,
      clientId: shopper.clientId,
      shopperId: shopper.id,
      labelHtmlString: parsedInput,
      packageName: packageDisplayName(pkg) ?? 'NO PACKAGE NAME',
      labelPlacement: broadbandLabelPlacement
    }

    // const updateLabelPayload: UpdateBroadbandLabelPayload = {
    //   id: 123,
    //   shopperId: shopper.id,
    //   uniquePlanId: uniquePlanId,
    //   labelHtmlString: parsedInput
    // }

    $store.dispatch('addBroadbandLabel', labelPayload)

    return {
      html: parsedInput,
      uniquePlanId: uniquePlanId
    }
  } catch (e) {
    if (isParseError(e)) {
      logger.error(`macroParser error: ${e.message}`)
    } else {
      // console.log(e)
      logger.error('unknown parser error', e)
    }
    return { html: 'NO DATA AVAILABLE', uniquePlanId: '' }
  }
}

export enum ConfigKeys {
  gtmId,
  env,
  debugPassword,
  allowScheduling,
  installationOptionsHtml,
  installationDateHtml,
  installationDateOverride,
  emailScheduleInfo,
  addressSearchText,
  addressSearchHint,
  email,
  emailTemplate,
  emailSubject,
  processCommercial,
  confirmationImage,
  confirmationText,
  offerDisplayText,
  offerDisclaimerText,
  offerTile,
  logoLink,
  cartImage,
  addressSearchBackground,
  student,
  studentText,
  bannerColor,
  noBullets,
  addressSeniority,
  textAlerts,
  showDob,
  creditCheckConsentText,
  creditCheckTooltip,
  creditCheck,
  offerButtonText,
  customerService,
  customerServiceEmail,
  monthlyTotalText,
  selfInstallText,
  selfInstallTextSubtitle,
  pickupTimes,
  pickupLocation,
  googleMapsLocation,
  pickupTitle,
  pickupDisclaimer,
  canSelfInstall,
  driversLicenseNum,
  useConverge,
  schedulingHeaderColor,
  salesLead,
  schedulingPage,
  costDuration,
  scheduling,
  isPreSale,
  enablePreSale,
  serviceabilityQuestions,
  disclaimer,
  residentialLink,
  showEstimatedFirstPayment,
  abovePriceText,
  channelText,
  catchAllProduct,
  termsOfServiceDoc,
  termsOfServiceLink,
  confirmationSubtitle,
  transunionDisclaimer,
  pickupDaysOut,
  creditApprovedSchedulingText,
  headerLogo,
  differentBillingAddress,
  defaultLobs,
  footerColor,
  showBusinessName,
  showCurrentPhoneBillingName,
  showCurrentPhoneCarrier,
  showCurrentPhoneAccountNumber,
  showShopperNameInConfirmation,
  addressSearchPlaceholder,
  showPinField,
  addressRule,
  showSelfInstallScheduling,
  callMeToScheduleText,
  selfInstallationHeaderText,
  selfInstallationRadioText,
  selfInstallationSelectedText,
  professionalInstallationHeaderText,
  professionalInstallationRadioText,
  cartHeaderColor,
  disableEmail,
  phonePortConsentTitle,
  phonePortConsentText,
  prorationFunction,
  prorationCondition,
  prorationName,
  unlockAccountModalText,
  showUnlockAccountButton,
  MinTvs,
  addressSearchBackgroundMobile,
  addressSearchBackgroundiPad,
  noOffersAvailableText,
  disabledSchedulingDates,
  holidaysToDisable,
  printImageTop,
  hidePackagePriceZeroes,
  hidePrintConfirmationTitle,
  internetNoOptionsMessage,
  ageLimit,
  showTermsCheckbox,
  termsCheckboxLabel,
  singlePackageText,
  bundlePackageText,
  textAreaLabel,
  convergeLightBoxUrl,
  enableAutomation,
  automationBlockers,
  editPaymentAmount,
  reverseUpgradeOrder,
  skipAutomation,
  streamingOptionsText,
  headerComponent,
  upgradeTextClass,
  accountInformationText,
  showAccountInfoIcon,
  accountInfoClass,
  textFieldStyle,
  installationOptionsHeaderText,
  showInstallationOptionsWrenchIcon,
  installationOptionsClass,
  confirmationHeaderClass,
  showConfirmationIcon,
  confirmationCardHeaderText,
  confirmationCartClass,
  showConfirmationCartHeaderTitle,
  monthlyTotalHeaderColor,
  addressSearchButtonLabel,
  offerButtonColor,
  lobTextColor,
  confirmationBackgroundImage,
  hideMobileBackgroundImage,
  upgradeBtnType,
  upgradeButtonColor,
  cardHeaderComponent,
  internetHeaderText,
  internetHeaderBackgroundColor,
  internetHeaderImage,
  tvHeaderText,
  tvHeaderBackgroundColor,
  tvHeaderImage,
  phoneHeaderText,
  phoneHeaderBackgroundColor,
  phoneHeaderImage,
  additionalServicesHeaderImage,
  additionalServicesHeaderText,
  additionalServicesHeaderBackgroundColor,
  cartHeaderImage,
  cartHeaderText,
  cartHeaderBackgroundColor,
  cardSharpCorners,
  monthlyTotalColor,
  confirmationCartHeaderImage,
  confirmationCartHeaderText,
  confirmationCartHeaderBackgroundColor,
  packageCardSquareCorners,
  footerLinks,
  confirmationImageHref,
  showSsnField,
  usePackageDescriptionInCart,
  termsOfService,
  pinFieldLabel,
  ssnFieldLabel,
  footerComponent,
  footerTopText,
  footerIcon,
  showStudentId,
  compatiblePreSaleShopperRoute,
  incompatiblePreSaleShopperRoute,
  useDisplayNameInPackageHeader,
  accountInfoTooltipColor,
  unsupportedPreSaleShopperText,
  brand,
  accountPanels,
  banners,
  bannerMatch,
  singlePackageHeader,
  showSpeedUnits,
  packageSquareUsePackageDescription,
  showUpperOfferButton,
  customSchedulingOptions,
  customConfirmationOptions,
  customSchedulingOptionsHeaderText,
  customConfirmationOptionsHeaderText,
  chooseMyDatesText,
  nextAvailableInstallationText,
  shouldCreditCheck,
  creditCheckConfig,
  offerFooter,
  creditCheckTypeFailureDisablesAutomation,
  creditCheckTypeNotPassedDisablesAutomation,
  accountFields,
  isSchedulingAutomated,
  accountPanelContinueButtonColor,
  showRoundedContinueButton,
  showLobCheckboxes,
  wrenchIconColor,
  useAlternativeButton,
  stepperButtonElevation,
  serviceabilityQuestionButtonRounded,
  serviceabilityQuestionButtonElevation,
  tooltipColor,
  firstDisplayNameColor,
  secondDisplayNameColor,
  thirdDisplayNameColor,
  fourthDisplayNameColor,
  stepperButtonRoundedCorners,
  stepperButtonForwardColor,
  hideTvOptionsOnActiveAccountPage,
  showUnderPriceText,
  showDescription,
  showDisplayName,
  marketingTextIconColor,
  returnButtonRounded,
  printButtonRounded,
  infoIconColor,
  displayOtcTotalInCart,
  preSaleDisableAutomation,
  preSaleConfirmationText,
  automatedEmailTemplate,
  delayAutomationConfirmationEmail,
  disableNonAutomatedConfirmationEmail,
  disableSuccessfulAutomationConfirmationEmail,
  disableFailedAutomationConfirmationEmail,
  phonePortingLoaText,
  showRoundedOfferButton,
  tvSetUpText,
  showTvSetUpSubtext,
  showChoosePackageText,
  hideUpgradePriceZeroes,
  axiosTimeout,
  creditModalText,
  creditModalTitle,
  creditModalClose,
  creditModalContinue,
  setTopBoxSubtitleText,
  showPaymentDueText,
  showTextAbovePrice,
  expandCartLineItem,
  comingSoon,
  leadCapture,
  automateActiveAccounts,
  automateCommercialAccounts,
  automateInitialPreSales,
  automateReturnPreSales,
  schedulingFields,
  agentsToBCC,
  isOfferTileClickable,
  packagePieces,
  forceAllowAutomatedSchedulingDates,
  customRules,
  estimatedMonthlyPaymentText,
  customAutomationBlockerConditions,
  internetUpgradeText,
  tvUpgradeText,
  phoneUpgradeText,
  findingOffersText,
  promoStepsAllowed,
  extraAutoCompleteParams,
  streamingServicesCustomizeConditions,
  streamingServicesHeaderBackgroundColor,
  streamingServicesHeaderText,
  streamingServicesHeaderImage,
  streamingServicesConfirmationConditions,
  additionalConfirmationMessages,
  cartCloseIcon,
  cartCloseIconColor,
  paymentConfig,
  paymentOptionsConfirmationHeaderText,
  paymentOptionsConfirmationMessage,
  showExpandedPackageInEmail,
  enablePayment,
  serviceabilityFields,
  defaultScheduleValue,
  customMasks,
  addressSearchInputColor,
  addressSearchButtonColor,
  errorPageHeader,
  errorPageText,
  packageUpgradePrefix,
  packageUpgradePostfix,
  packageUpgradeLessPrefix,
  packageUpgradeLessPostfix,
  addressSearchSubtitle,
  underLobText,
  upgradeButtonIcon,
  upgradeButtonText,
  dividersInCards,
  concatenateConfirmationCardHeaderText,
  showSeperateOrderId,
  printButtonText,
  useButtonsForLobs,
  confirmationAdditionalCustomerDetails,
  confirmationGreeting,
  presaleSubmitButtonText,
  hideSecondCalendar,
  displayMonthlyPriceTopOfCart,
  contactNumberInCart,
  firstCalendarText,
  secondCalendarText,
  confirmationTextConfig,
  showConfirmationHeaderText,
  debugTagConfig,
  broadbandConfigString,
  approvedBroadbandLabelClasses,
  participatesInACP,
  broadbandLabelPlacement,
  broadbandLabelPrefixes,
  headerPieces,
  logoInSteppers,
  useHeaderNew,
  displayCustomerPinInConfirmation,
  previousShopperApiConfig,
  catalogApiConfig,
  timeslotsApiConfig,
  creditCheckApiConfig,
  initializePaymentApiConfig,
  finalizePaymentApiConfig,
  submitOrderApiConfig,
  captchaApiConfig,
  monthlyIntroPriceStrings,
  monthlyContractStrings,
  useMacroParser,
  automatedMatchTypeRegex,
  uiValidClasses,
  displayPresaleCustomerServiceButton,
  externalAccountNumberConfirmationCartMacro,
  displayExternalAccountNumberInConfirmationCart,
  externalAccountNumberConfirmationCartLabel,
  automatedSchedulingMessage,
  displayNewPhoneChoiceInConfirmationCart,
  displayKeepPhoneChoiceInConfirmationCart,
  newPhoneChoiceConfirmationCartMacro,
  keepPhoneChoiceConfirmationCartMacro,
  monthlyChargesCartLabelMacro,
  schedulingCardHeaderTextMacros,
  preSaleDownPayment,
  preSaleRecurringPayment,
  delayCatalogForCaptcha,
  verticalThreeButtons,
  verticalButtonOrder,
  broadbandLabelCTAText,
  closeLabelIcon,
  closeLabelIconColor,
  closeLabelIconText,
  broadbandLabelToggleOption,
  phoneCarrierDropdown,
  payIncludePackageFees,
  enableCarousel,
  offerCardFooterItems,
  clientPackageCardMaxWidth,
  maximumPackagesPerRow,
  singlePackageRowCutoffWidth,
  carouselMaxWidthCutOff,
  confirmationCartInstallationDateHeaderText,
  broadbandLabelContractLengthString,
  broadbandLabelFeeItemPrefixString,
  offerCardObserverDelay,
  offerCardObserverThreshold,
  labelObserverThreshold
}

export type ConfigItems = Partial<Record<keyof typeof ConfigKeys, any>>

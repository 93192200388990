import { setShopperElement } from '@/utils/ShopperHelpers'
import { IShopper, newShopper } from '@adg/catalog/src/modules/Shopper'
import { defineStore } from 'pinia'

export interface ShopperState {
  currentStep: number
  shopper: IShopper
}

export const usePiniaShopper = defineStore('ShopperStore', {
  state: (): ShopperState => ({
    currentStep: 1,
    shopper: newShopper()
  }),
  getters: {},
  actions: {
    setShopper(newShopper: Partial<IShopper>) {
      this.shopper = { ...this.shopper, ...newShopper }
    },
    setShopperField(field: string, value: any) {
      setShopperElement(this.shopper, field, value)
    },
    setDateString(field: string, value: string, dateString: string) {
      setShopperElement(this.shopper, field, dateString)
      setShopperElement(this.shopper, `${field}String`, value)
      // eval(`this.shopper.${field} = '${dateString}'`)
      // eval(`this.shopper.${field}String = '${value}'`)
    }
  }
})

<template>
  <v-overlay v-if="!!currentSpinner?.messages.length" style="z-index: 901">
    <v-container>
      <v-row class="text-center">
        <v-col>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col>
          {{ currentSpinner?.messages[spinnerIndex] }}
        </v-col>
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script lang="ts">
import { GET_SHOW_SPINNER } from '@/store/types'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import $store from '@/store'
import { Spinner } from '@/store/State'

export default defineComponent({
  name: 'Spinner',
  setup() {
    const showSpinner = computed(() => $store.getters[GET_SHOW_SPINNER])

    const spinners = computed((): Spinner[] => $store.getters.getSpinners)
    const spinnerUpdateTime = computed(() => $store.getters.getSpinnerUpdateTime)
    const spinnerIndex = ref(0)
    const currentSpinner = computed((): Spinner | undefined => [...spinners.value].sort((s1, s2) => s2.rank - s1.rank)[0])
    const runSpinners = () => {
      setTimeout(() => {
        if (currentSpinner.value && spinnerIndex.value < currentSpinner.value.messages.length - 1) {
          spinnerIndex.value++
          runSpinners()
        }
      }, currentSpinner.value?.spinnerUpdateTime ?? spinnerUpdateTime.value)
    }

    watch(currentSpinner, (newVal, oldVal) => {
      if (newVal && newVal.messages.length > 0) {
        spinnerIndex.value = 0
        runSpinners()
      }
    })

    return {
      showSpinner,
      currentSpinner,
      spinnerIndex
    }
  }
})
</script>

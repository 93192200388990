
<v-form>
  <v-checkbox v-show="!disableCheckbox" :label="checkboxLabel" dense class="noMargin" v-model="addressSeniorityChecked" />
  <v-autocomplete
    v-if="showAddress"
    ref="autocomplete"
    name="gogo"
    v-model="previousAddress"
    :loading="iLoading"
    :items="addressSuggestions"
    item-text="full"
    :search-input.sync="search"
    :label="addressLabel"
    @change="addressChanged"
    solo
    no-filter
    hide-no-data
    return-object
  />
</v-form>

import {
  Checkout,
  CheckoutStep,
  CheckoutStepTypes,
  EventAction,
  VirtualPageTypes,
  VirtualPageURL
} from '@adg/catalog/src/modules/Ecom/gtmEvents'
import { Catalog, Equipment, FeedItem, Promo, ShoppingOrder, Upgrade } from '@adg/catalog/src/modules/Catalog'
import { EcomProduct, GtmEvent } from '@adg/catalog/src/modules/Ecom'
import store from '../store'
import { GET_CATALOG, GET_MATCHED_ADDRESS } from '@/store/types'
import { bus } from '@/main'
import useCart from '@/components/order/cart/useCart'
import {
  Package,
  Item,
  ItemPrice,
  Product,
  getItemPriceAsNumber,
  isPackage,
  getItemPrice,
  isEquipment,
  isUpgrade,
  isFee,
  isPromo
} from '@adg/catalog/src/modules/Catalog'
import { logger } from '@/utils/RemoteLogger'
import { Route } from 'vue-router'
import { GtmConfigEvent } from '@adg/catalog/src/common/UIConfig'
import { SalesType } from '@adg/catalog/src/modules/Address'
import ga4 from '@/gtm/ga4'

const EVENT_CATEGORY = 'Ecommerce'
const EVENT_NAME = 'ecomPush'
let list: string | undefined = undefined
let preCreditCheck = false
let creditCheckModalDisplayed = false
let creditCheckSent = false
let paymentModalDisplayed = false
let paymentDataSent = false

const initCheckoutListeners = async () => {
  bus.$on('startCheckout', () => {
    const { shoppingCart } = useCart(store)
    pushCheckout(1, shoppingCart.value)
  })

  bus.$on('preCreditCheck', () => {
    preCreditCheck = true
  })

  bus.$on('creditCheckModalDisplayed', () => {
    creditCheckModalDisplayed = true
    if (!creditCheckSent) {
      pushCheckout(2)
    }
    pushPageView('/creditCheck')
  })

  bus.$on('scheduleInstall', () => {
    if (!creditCheckSent) {
      pushCheckout(2)
    }
    pushCheckout(3)
  })

  bus.$on('paymentModalDisplayed', () => {
    paymentModalDisplayed = true
    if (!paymentDataSent) {
      pushCheckout(4)
    }
    pushPageView('/prepayment')
  })

  bus.$on('pushShopperType', () => {
    pushShopperType()
  })

  bus.$on('pushSalesType', () => {
    pushSalesType()
  })
}

const pushLeadCaptureSubmitted = async () => {
  pushPageView('/leadCaptureSubmitted')
}

const pushPromoClick = async (promo: Promo) => {
  if (promo['Billing Codes'] && promo['Billing Codes'].length > 0) {
    pushData({
      event: EVENT_NAME,
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Promo Add',
      // eventLabel: ,
      // eventValue: ,
      ecommerce: {
        promoClick: {
          promotions: [
            {
              name: promo.Name,
              id: promo['Billing Codes'][0].Name
            }
          ]
        }
      }
    })
  }
}

const pushPackageImpressions = async (pkgs: Package[]) => {
  if (!pkgs || pkgs.length == 0) {
    return
  }

  const shopper = store.getters.getShopper
  const offerSet = shopper?.tags?.offerSet ?? ''

  const imps: EcomProduct[] = []

  for (let i = 0; i < pkgs.length; i++) {
    let p = pkgs[i]

    if (p) {
      const product = buildProduct(p)
      // "list" is an object level variable, not locally scoped
      list = product.category ? product.category.replace('Package/', '').slice(0, -1) + '/' + offerSet : undefined
      product.list = list
      product.position = i + 1 // don't want position to be zero based

      imps.push(product)
    }
  }

  pushData({
    event: 'packageImpression',
    ecommerce: {
      impressions: imps
    }
  })

  // We're also going to push all of the impressions as views so that the
  // funnels in ga carry through
  const event: GtmEvent = {
    event: 'ecomPush',
    eventCategory: EVENT_CATEGORY,
    eventAction: 'Detail',
    // eventLabel: ,
    // eventValue: ,
    ecommerce: {
      detail: {
        actionField: { list: list },
        products: imps
      }
    }
  }
  pushData(event)
}

function price(p: ItemPrice | undefined): number {
  return typeof p === 'string' ? 0 : p ?? 0 // if string or undefined use zero
}

const pushPackageChange = async (origPkg: Package | null | undefined, newPkg: Package | null | undefined) => {
  let eventAction: EventAction = 'Package Add'

  // need to push cart remove if there is an existing
  // we no longer allow undefined/null values so now check if the origPkg is empty
  if (origPkg && origPkg.Name != '' && origPkg.Products.length > 0) {
    // determine if this is an upgrade/downgrade
    if (newPkg) {
      if (getItemPriceAsNumber(origPkg, 'Monthly Price') > getItemPriceAsNumber(newPkg, 'Monthly Price')) {
        eventAction = 'Package Downgrade'
      } else {
        eventAction = 'Package Upgrade'
      }
    }

    await pushCartUpdate('Package Remove', 'remove', [origPkg])
  }

  // push cart add
  if (newPkg) {
    await pushCartUpdate(eventAction, 'add', [newPkg])
  }
}

const pushCartUpdate = (eventAction: EventAction, eventType: 'add' | 'remove', items: Item[]) => {
  if (!items || items.length == 0) {
    return
  }

  let products: EcomProduct[] = []
  for (let item of items) {
    if (item) products.push(buildProduct(item))
  }

  const event: GtmEvent = {
    event: 'ecomPush',
    eventCategory: EVENT_CATEGORY,
    eventAction: eventAction,
    // eventLabel: ,
    // eventValue: ,
    ecommerce: {}
  }

  event.ecommerce![eventType] = {
    actionField: { list: list },
    products: products
  }

  pushData(event)
}

const buildProduct = (item: FeedItem): EcomProduct => {
  // if (!item) {
  //   return
  // }

  const companyName = store.getters.getUIConfig['companyName']
  const pkg = store.getters.getPackage as Package
  const shopper = store.getters.getShopper
  const offerSet = shopper?.tags?.offerSet ?? ''
  const gaMarket = shopper?.tags?.gaMarket ?? ''

  return {
    id: item.Name,
    name: item.Name,
    category: getCategory(item),
    brand: companyName,
    price: getPrice(item),
    variant: ga4.getClassification(),
    quantity: item.qty ? item.qty : 1,
    dimension2: getInternetSpeed(item),
    dimension3: getInternetTier(item),
    dimension4: getTvTier(item),
    sku: `${pkg && pkg.Name && pkg.Name !== '' ? pkg.Name : item.Name}|${
      pkg && pkg.Name && pkg.Name !== '' ? getPrice(pkg) ?? '' : getPrice(item)
    }|${gaMarket}|${offerSet}`
  }
}

const getPrice = (item: FeedItem) => {
  const rc = getItemPrice(item, 'Monthly Price')
  if (rc) return rc
  const otc = getItemPrice(item, 'OTC')
  if (otc) return otc
  if (isPackage(item)) {
    if (item.Price) return item.Price
  }
  return undefined
}

const getCategory = (item: FeedItem): string | undefined => {
  if (isPackage(item)) {
    return getCategoryForPackage(item)
  } else if (isEquipment(item)) {
    return getCategoryForEquipment(item)
  } else if (isUpgrade(item)) {
    return getCategoryForUpgrade(item)
  } else if (isFee(item)) {
    return 'Fee/' + (getItemPrice(item, 'OTC') ? 'OTC/' : 'Monthly/') // TODO: item can have both mrc and nrc
  } else if (isPromo(item)) {
    return 'Promo/'
  }

  return undefined
}

const getCategoryForPackage = (item: Package): string | undefined => {
  let cat = 'Package/'

  const types = item.Products.map((p) => p['Product Type'])
  types.sort()

  cat += getProductTypeCount([...types]) + 'P/'

  cat += types.toString().replace(/,/g, ' ') + '/'

  return cat
}

const getProductTypeCount = (types: (string | undefined)[]): number => {
  const productsToIgnore = ['Special Offers']

  if (!types || types.length == 0) {
    return 0
  }
  for (let ignore of productsToIgnore) {
    const index = types.indexOf(ignore)
    if (index != -1) {
      types.splice(index, 1)
    }
  }

  return types.length
}

const getCategoryForEquipment = (item: Equipment): string | undefined => {
  return 'Equipment/' + item['Product Type'] + '/'
}

const getCategoryForUpgrade = (item: Upgrade): string | undefined => {
  if (item.Subcategory === 'Channel') {
    return 'Add-Ons/Channel/'
  } else if (item.Subcategory === 'Equipment') {
    return 'Equipment/' + item['Product Type'] + '/'
  } else if (item.Subcategory === 'Service') {
    return 'Add-Ons/Service/' + item['Product Type'] + '/'
  } else if (item.Subcategory === 'Package Service') {
    return 'Add-Ons/Service/'
  }
}

const getInternetSpeed = (item: FeedItem): string | number | undefined => {
  let speed: string | number | undefined = undefined

  try {
    if (item && isPackage(item) && item.Products) {
      for (let p of item.Products) {
        if (p.Speed) {
          speed = p.Speed
          break
        }
      }
    }
  } catch {
    logger.warn(`ecom: unable to get internet speed for ${item.Name}`)
  }

  return speed
}

const getInternetTier = (item): string | undefined => {
  try {
    if (!item || !isPackage(item)) {
      return undefined
    }

    const speed = getInternetSpeed(item)
    if (!speed) {
      return undefined
    }

    const cat = store.getters[GET_CATALOG] as Catalog
    const catalogSpeeds: any[] = []

    for (let pkg of cat.Packages) {
      for (let prod of pkg.Products) {
        if (prod.Speed && catalogSpeeds.indexOf(prod.Speed) === -1) {
          catalogSpeeds.push(prod.Speed)
        }
      }
    }
    catalogSpeeds.sort(function (a, b) {
      return parseInt(a) - parseInt(b)
    })
    const internetTier = (catalogSpeeds.indexOf(speed) + 1).toString() // don't want zero based

    return internetTier
  } catch {
    logger.warn(`ecom: unable to get internet tier for ${item.Name}`)
    return undefined
  }
}

const getNumChannels = (item): string | undefined => {
  let channels = undefined

  if (item && item.Products) {
    for (let p of item.Products) {
      if (p['Num Channels']) {
        channels = p['Num Channels']
        break
      }
    }
  }

  return channels
}

const getTvTier = (item): string | undefined => {
  try {
    if (item.itemType !== 'Package') {
      return undefined
    }

    const num = getNumChannels(item)
    if (!num) {
      return undefined
    }

    const cat = store.getters[GET_CATALOG]
    const numChannels: any[] = []

    for (let pkg of cat.Packages) {
      for (let prod of pkg.Products) {
        if (prod['Num Channels'] && numChannels.indexOf(prod['Num Channels']) === -1) {
          numChannels.push(prod['Num Channels'])
        }
      }
    }
    numChannels.sort(function (a, b) {
      return parseInt(a) - parseInt(b)
    })
    const tvTier = (numChannels.indexOf(num) + 1).toString() // don't want zero based

    return tvTier
  } catch {
    logger.warn(`ecom: unable to get tv tier for ${item.Name}`)
    return undefined
  }
}

const pushMultiItemChange = async (orig: FeedItem[], newItems: FeedItem[]) => {
  let a = new Set(orig)
  let b = new Set(newItems)

  let removed = [...a].filter((x) => !b.has(x)) // a - b
  let added = [...b].filter((x) => !a.has(x)) // b - a

  if (removed.length > 0) {
    pushCartUpdate('Cart Remove', 'remove', removed)
  }
  if (added.length > 0) {
    pushCartUpdate('Cart Add', 'add', added)
  }
}

const pushPurchase = async (orderId: number, order: ShoppingOrder) => {
  if (!order) {
    return
  }

  if (!paymentDataSent) {
    // this is the case when user didn't need to prepay but we still neet to push step 4
    pushCheckout(4)
  }

  const { grandTotal } = useCart(store)

  const event: GtmEvent = {
    event: EVENT_NAME,
    eventCategory: EVENT_CATEGORY,
    eventAction: 'Purchase',
    // eventLabel: ,
    // eventValue: ,
    ecommerce: {
      currencyCode: 'USD',
      purchase: {
        actionField: {
          id: orderId,
          list: list,
          // affiliation: '',
          revenue: grandTotal.value.toFixed(2)
          // tax: '',
          // shipping: '',
          // coupon: ''
        },
        products: []
      }
    }
  }

  if (event.ecommerce?.purchase) event.ecommerce.purchase.products = buildAllProducts(order, true)

  pushData(event)
}

const pushCheckout = (step: CheckoutStep, order?: ShoppingOrder) => {
  const checkout: Checkout = {
    actionField: {
      step: step
    }
  }
  // products should only be pushed on step 1
  if (step === 1 && order) {
    checkout.products = buildAllProducts(order)
  } else if (step === 2) {
    creditCheckSent = true
    if (creditCheckModalDisplayed) {
      checkout.actionField.option = 'Pre Credit Check Failed'
    } else if (preCreditCheck) {
      checkout.actionField.option = 'Pre Credit Check Passed'
    } else {
      checkout.actionField.option = 'Credit Check Skipped'
    }
  } else if (step === 4) {
    paymentDataSent = true
    if (paymentModalDisplayed) {
      checkout.actionField.option = 'Prepayment Required'
    } else {
      checkout.actionField.option = 'No Prepayment Required'
    }
  }

  const event: GtmEvent = {
    event: EVENT_NAME,
    eventCategory: EVENT_CATEGORY,
    eventAction: 'Checkout',
    // eventLabel: ,
    // eventValue: ,
    ecommerce: {
      checkout: checkout
    }
  }

  pushData(event)
}

const pushCheckoutWithOption = (step: CheckoutStep, option?: string) => {
  const event: GtmEvent = {
    event: EVENT_NAME,
    eventCategory: EVENT_CATEGORY,
    eventAction: 'Checkout',
    // eventLabel: ,
    // eventValue: ,
    ecommerce: {
      checkout: {
        actionField: {
          step: step,
          option
        }
      }
    }
  }

  pushData(event)
}

const buildAllProducts = (order: ShoppingOrder, isPurchase: boolean = false): EcomProduct[] => {
  if (!order) {
    return []
  }
  const companyName = store.getters.getUIConfig['companyName']

  const products: EcomProduct[] = []
  const pkg = store.getters.getPackage
  const { monthlyTotal } = useCart(store)
  const shopper = store.getters.getShopper
  const offerSet = shopper?.tags?.offerSet ?? ''
  const gaMarket = shopper?.tags?.gaMarket ?? ''

  const temp: EcomProduct = {
    id: order.package.Name,
    name: order.package.Name,
    price: order.package.Price,
    quantity: 1,
    category: getCategory(pkg),
    variant: ga4.getClassification(),
    brand: companyName,
    dimension2: getInternetSpeed(pkg),
    dimension3: getInternetTier(pkg),
    dimension4: getTvTier(pkg),
    sku: `${pkg.Name}|${getPrice(pkg)}|${gaMarket}|${offerSet}`
  }

  if (isPurchase) {
    temp.metric2 = monthlyTotal.value ? monthlyTotal.value.toFixed(2) : 0
  }

  products.push(temp)

  if (order.promo) {
    const p = order.promo

    products.push({
      name: p.Name,
      brand: companyName,
      price: p.Price,
      quantity: 1,
      category: getCategory(order.promo),
      variant: p['Promo Type']
    })
  }

  if (order.monthlyCharges) {
    for (let m of order.monthlyCharges) {
      products.push(buildProduct(m))
    }
  }

  if (order.oneTimeCharges) {
    for (let o of order.oneTimeCharges) {
      products.push(buildProduct(o))
    }
  }
  return products
}

const pushRoute = (vueTo: Route, isInitialPreSale: boolean = false, isCommercial: boolean = false) => {
  const translatedPath = ga4.translatePath(vueTo, isInitialPreSale, isCommercial)
  if (translatedPath) return pushPageView(translatedPath)
}

const pushPageView = (path: VirtualPageURL) => {
  if (path != undefined) {
    //console.log(`page view=${translatedPath}`);
    const event: GtmEvent = {
      event: 'virtualPageView',
      virtualPageUrl: path,
      virtualPageTitle: window.document.title
    }
    if (VirtualPageTypes[path] === undefined) {
      logger.warn(`pushPageView(): virtualPageType not found for ${path}`)
    }
    pushData(event)
  }
}

const pushShopperType = () => {
  const event: GtmEvent = {
    event: 'shopperType',
    shopperType: ga4.getClassification()
  }

  pushData(event)
}

const getSalesType = (): SalesType | undefined => {
  const shopper = store.getters.getShopper
  return shopper?.matchedAddress?.salesType ?? 'none'
}

const pushSalesType = () => {
  const event: GtmEvent = {
    event: 'salesType',
    salesType: getSalesType()
  }

  //console.log(`salesType=${event.salesType}`)
  pushData(event)
}

const firePanelEvents = (gtmEvent: GtmConfigEvent) => {
  if (gtmEvent.virtualPage) {
    if (VirtualPageTypes[gtmEvent.virtualPage] === undefined) {
      logger.warn(`firePanelEvents: virtualPageType not found for ${gtmEvent.virtualPage}`)
    }
    pushPageView(gtmEvent.virtualPage as VirtualPageURL)
  }
  if (gtmEvent.step) {
    if (CheckoutStepTypes[gtmEvent.step] === undefined) {
      logger.warn(`firePanelEvents: step not found for Event: ${JSON.stringify(gtmEvent)}`)
    }
    pushCheckoutWithOption(gtmEvent.step, gtmEvent.stepOption)
  }
}

const insertGlobalValues = (data: GtmEvent) => {
  data.dimension5 = getSalesType()
}

const setDataLayerVariable = (data: { [key: string]: string }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

// This function allows us to intercept calls to the datalayer and use it separately
const pushData = (data: GtmEvent) => {
  // console.log('GTM event pushed: ', data)
  window.dataLayer = window.dataLayer || []

  // clear ecom data out of datalayer so it doesn't get pushed on next event
  window.dataLayer.push({ ecommerce: null, eventAction: null, eventCategory: null })

  // insert global values
  insertGlobalValues(data)

  // push our new event
  window.dataLayer.push(data)

  // store the page url we're on for our copy of the event
  data.page = window.location.href
  data.ts = Date.now()
  store.commit('addGaEvent', data)
}

export default {
  pushPromoClick,
  pushPackageImpressions,
  pushPackageChange,
  pushPurchase,
  pushMultiItemChange,
  initCheckoutListeners,
  pushPageView,
  pushRoute,
  firePanelEvents,
  setDataLayerVariable,
  pushLeadCaptureSubmitted
}

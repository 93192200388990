<template>
  <!-- <v-container style="max-width: 1200px" class="px-sm-12">
    <v-row>
      <v-col md="7" cols="12" class="py-0 px-5"> -->
  <v-card tile class="rounded-lg rounded-tr-0 rounded-tl-0" width="700">
    <v-toolbar id="account-header" flat height="40" dark :color="scheduleInfoHeaderColor">
      <v-toolbar-title id="installation-options-header-text">
        <v-icon v-if="showInstallationOptionsWrenchIcon" style="padding-right: 6px" :color="wrenchIconColor">
          {{ mdiWrenchOutline }}
        </v-icon>
        <span id="installation-header-text" v-html="uiMacroParser(shopper, installationOptionsHeaderText)"></span>
      </v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col class="headline primary--text" id="installation-options-address" v-html="address"></v-col>
      </v-row>
      <v-divider></v-divider>
      <CustomSchedulingText />
      <div>
        <PayContainer />
      </div>
    </v-container>
  </v-card>
  <!-- </v-col>
      <v-col md="5" cols="12" class="py-0 hidden-sm-and-down">
        <Cart />
      </v-col>
    </v-row>
  </v-container> -->
</template>

<script lang="ts">
import Cart from '@/components/order/cart/Cart.vue'
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { Days } from '@/constants/Days'
import useOrder from '@/components/order/useOrder'
import StepperButtons from '@/components/order/StepperButtons.vue'
import useUiConfig from '@/components/shared/useUiConfig'
// { KWC Not complete
// import { GET_SCHEDULE_OPTIONS } from "@/store/types";
// } KWC I just commented out the GET_SCHEDULE_OPTIONS line above the "useSchedule" line was already commented out
// import useSchedule from "@/components/schedule/useSchedule";
import { getConfigBoolean, getConfigItem } from '@/components/shared/getConfigItem'
import PayContainer from '@/components/order/payment/PayContainer.vue'
import CustomSchedulingText from '@/components/order/scheduling/CustomSchedulingText.vue'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import $store from '@/store'
import { mdiWrenchOutline } from '@mdi/js'
import { IShopper } from '@adg/catalog/src/modules/Shopper'

export default defineComponent({
  name: 'CustomScheduling',
  components: {
    Cart,
    StepperButtons,
    PayContainer,
    CustomSchedulingText
  },
  setup() {
    const { scheduleInfoHeaderColor } = useUiConfig()
    const address = computed(() => $store.getters.getFormattedAddress)
    const shopper = computed(() => $store.getters.getShopper as IShopper)
    const useConverge = computed(() => getConfigBoolean(ConfigKeys.useConverge))
    const installationOptionsHeaderText = computed(
      () => getConfigItem(ConfigKeys.installationOptionsHeaderText) ?? 'INSTALLATION OPTIONS'
    )
    const showInstallationOptionsWrenchIcon = computed(() => getConfigItem(ConfigKeys.showInstallationOptionsWrenchIcon) ?? false)
    const wrenchIconColor = computed(() => getConfigItem(ConfigKeys.wrenchIconColor))

    onMounted(() => {
      $store.commit('setCartSchedule', { installOption: 'selfInstall' })
    })

    return {
      address,
      scheduleInfoHeaderColor,
      useConverge,
      installationOptionsHeaderText,
      showInstallationOptionsWrenchIcon,
      wrenchIconColor,
      mdiWrenchOutline,
      uiMacroParser,
      shopper
    }
  }
})
</script>

<style>
.scheduleSubTitle {
  font-size: 18px;
  margin-left: 20px;
}

.timeRadio {
  margin-left: 0px;
}

.installationOptionsText {
  font-size: 20px;
}
</style>

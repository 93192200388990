export const coreCurrency = function (value: string | number | undefined): string {
  if (value === undefined) return ''
  if (typeof value === 'string') {
    const n = Number(value)
    if (isNaN(n)) {
      return value
    } else {
      value = n
    }
  }
  return isNaN(value) ? '$0.00' : `$${value.toFixed(2)}`
  // if (value === undefined || value === 'Included') return value
  // if (typeof value === 'string') value = Number(value)
  // return isNaN(value) ? '$0.00' : `$${value.toFixed(2)}`
}

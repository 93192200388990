
<div>
  <v-container style="max-width: 1200px" class="px-sm-12" v-if="currentStep > 4">
    <v-row>
      <v-col md="7" cols="12" class="py-0 px-5 installation-options">
        <div v-if="canSelfInstall">
          <CustomScheduling />
        </div>
        <div class="fields" v-else>
          <v-card :tile="sharpCorners" class="schedule" width="700">
            <v-toolbar flat id="account-header" height="40" dark :color="scheduleInfoHeaderColor">
              <v-toolbar-title id="installation-options-header-text">
                <v-icon v-if="showInstallationOptionsWrenchIcon" style="padding-right: 6px" :color="wrenchIconColor">
                  {{ mdiWrenchOutline }}
                </v-icon>
                <span id="installation-header-text" v-html="installationOptionsHeaderText"></span>
              </v-toolbar-title>
            </v-toolbar>
            <v-form v-model="validated">
              <v-container fluid>
                <v-row>
                  <v-col class="headline primary--text" id="installation-options-address">{{ punctuatedAddress }}</v-col>
                </v-row>
                <div v-for="(item, i) in schedulingFields" :key="`schedulingField-${i}`">
                  <ScheduleOptions v-if="item.type === 'schedule'" />
                  <AccountFieldUI :field="item" v-else />
                </div>
                <PayContainer :disabled="!validated" />
              </v-container>
            </v-form>
          </v-card>
        </div>
      </v-col>
      <v-col md="5" cols="12" class="py-0 hidden-sm-and-down">
        <Cart />
      </v-col>
    </v-row>
  </v-container>
</div>

import { computed, ref } from '@vue/composition-api'
import { CONFIG_DEFAULT_LOBS, CONFIG_MAX_TV, CONFIG_MIN_TV, CONFIG_OFFER_CHECKBOX_LABEL } from '@/store/types'
import { getConfigItem } from './getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'

function isConfigItem(x: any, key: string): boolean {
  return x.Key === key // || x.Name === key
}

export default ($store) => {
  const config = computed(() => $store.getters.getCatalogConfig)

  const findConfigItem = (key: string, type?: string): string => {
    return config.value.find((i) => isConfigItem(i, key) && (i.Type && type ? i.Type === type : true))
  }

  const getCatalogItem = (key: string): any | undefined => {
    return config.value?.find((i) => i?.Name === key)
  }

  const getCatalogItemValue = (key: string): any => {
    //this logic differs from the duplicate in CatalogHelpers.ts, should be deprecated
    const item: string = getCatalogItem(key)?.['Config Value']
    const re = new RegExp(/^\s*[{\[]/)
    if (item && typeof item === 'string' && item.match(re)) {
      return JSON.parse(item) as object
    }
    return item
  }

  const getProductTypeLabel = (productType) => {
    let label = productType
    if (config.value) {
      const c = findConfigItem(CONFIG_OFFER_CHECKBOX_LABEL, productType)
      //config.value.find( (x) => isConfigItem(x, CONFIG_OFFER_CHECKBOX_LABEL) && x.Type === productType)
      if (c) label = c['Config Value']
    }
    return label
  }

  const isProductTypeHidden = (pt) => {
    let hidden = false
    if (config.value) {
      const c = findConfigItem('Hidden', pt)
      if (c) hidden = c['Config Value'] == '1'
    }
    return hidden
  }

  const isProductTypeDisabled = (pt) => {
    let disabled = false
    if (config.value) {
      const c = findConfigItem('disabled', pt)
      if (c) disabled = c['Config Value'] == '1'
    }
    return disabled
  }

  const getProductTypeOfferLobs = (productType): string[] => {
    let lobs = [productType]
    if (config.value) {
      const c = findConfigItem(CONFIG_DEFAULT_LOBS, productType)
      //const c = config.value.find( (x) => isConfigItem(x, CONFIG_DEFAULT_LOBS) && x.Type === productType)
      if (c) lobs = c['Config Value']?.split(',') //change to regex .split
    }
    return lobs
  }

  const getMaxTvs = computed(() => Number.parseInt(getCatalogItemValue(CONFIG_MAX_TV) ?? '5'))

  const getMinTvs = computed(() => Number.parseInt(getConfigItem(ConfigKeys.MinTvs) ?? '0'))

  const getGroupMinTvs = (group) => Number.parseInt(getCatalogItemValue(`${group}${CONFIG_MIN_TV}`) ?? '0')

  const getSalesLeadLink = () => getCatalogItemValue('salesLead') ?? null

  return {
    getCatalogItem,
    getCatalogItemValue,
    getProductTypeLabel,
    getProductTypeOfferLobs,
    getMinTvs,
    getMaxTvs,
    getSalesLeadLink,
    getGroupMinTvs,
    isProductTypeHidden,
    isProductTypeDisabled
  }
}

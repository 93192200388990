
<div>
  <StepperButtons :forwardAction="performAgnosticCreditCheck" />
  <v-overlay :opacity="0.5" v-if="showModal" z-index="6" height="100vh" class="customOverlay">
    <v-dialog v-model="showModal" @close="showModal = false" max-width="550">
      <v-card tile class="grey lighten-4 rounded-lg rounded-tr-0 rounded-tl-0 pt-0">
        <CreditCheckModal v-if="showModal === 'modal'" @closeModal="closeModal" @closeAndStepForward="closeAndStepForward" />
        <v-container v-else>
          <v-row justify="end" class="closeButton">
            <v-col cols="1">
              <v-icon @click="closeModal">{{ mdiClose }}</v-icon>
            </v-col>
          </v-row>
          <v-row class="pl-3">
            <span class="headline primary--text"> Consent to Soft Credit Check </span>
          </v-row>
          <v-radio-group v-model="acceptCreditCheck">
            <div style="display: flex">
              <v-radio :label="creditText.agree" value="agree"> </v-radio
              ><Tooltip
                :payload="{
                  Name: `Credit Check`
                }"
              />
            </div>
            <div v-if="acceptCreditCheck === 'agree'">
              <v-row>
                <v-col sm="6">
                  <v-text-field solo label="Last 4 of your SSN" v-model="ssn" class="pl-8" v-mask="ssnMask"></v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-radio :label="creditText.disagree" value="disagree"> </v-radio>
          </v-radio-group>
          <v-row class="pa-3">
            <v-btn @click="closeModalAndAgnosticCheck" block color="primary" :disabled="continueDisabled">Continue</v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-overlay>
</div>

import { defineStore } from 'pinia'

interface RouteState {
  currentStep: number
  currentPath: string
}

export const usePiniaRoute = defineStore('RouteStore', {
  state: (): RouteState => ({
    currentStep: 1,
    currentPath: '/order/1'
  }),
  getters: {},
  actions: {
    setPath(path: string) {
      this.currentPath = path
    },
    setStep(step: number) {
      this.currentStep = step
    }
  }
})

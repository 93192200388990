<template>
  <div class="pa-5">
    <div class="pa-5 flex-center">
      <v-icon color="green" class="big-icon">{{ mdiCheckCircle }}</v-icon>
    </div>
    <div class="pb-5 px-5 flex-center">
      <span class="headline" v-html="paymentApprovedText"></span>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api'
import { mdiCheckCircle } from '@mdi/js'
import { PaymentConfig } from '@adg/catalog/src/common/UIConfig'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog'
import { paymentSuccessText } from './PaymentFunctions'

export default defineComponent({
  name: 'PaymentSuccess',
  setup(props, { emit }) {
    const paymentApprovedText = paymentSuccessText.value ?? 'Payment successful'
    return {
      mdiCheckCircle,
      paymentApprovedText
    }
  }
})
</script>
<style scoped>
.big-icon >>> .v-icon__svg {
  height: 64px !important;
  width: 64px !important;
  fill: #4caf50 !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>


// NOTE: be careful changing order, because some javascript code converts the enums into ordered numbers
export enum MatchType {
  EXACT = 'EXACT',
  BUILDING = 'BUILDING',
  ZIP_ONLY = 'ZIP_ONLY',
  ZIP_PLUS_STREET = 'ZIP_PLUS_STREET',
  GEOCODE = 'GEOCODE',
  SIMILAR = 'SIMILAR',
  REFERRER = 'REFERRER',
  NONE = 'NONE',
  POLYGON = 'POLYGON',
  VETRO = 'VETRO',
  VETRO_V2 = 'VETRO_V2',
  EXACT_DUP = 'EXACT_DUP'
}

export enum MatchTypeNum {
  EXACT,
  BUILDING,
  ZIP_ONLY,
  ZIP_PLUS_STREET,
  GEOCODE,
  SIMILAR,
  REFERRER,
  NONE,
  POLYGON = 8,
  VETRO = 9,
  VETRO_V2 = 10,
  EXACT_DUP = 11
}

function FailMatchType(message: string): never {
  throw new Error(message)
}

export function MatchTypeNumToString(m: MatchTypeNum): MatchType {
  switch (m) {
    case MatchTypeNum.EXACT:
      return MatchType.EXACT
    case MatchTypeNum.BUILDING:
      return MatchType.BUILDING
    case MatchTypeNum.ZIP_ONLY:
      return MatchType.ZIP_ONLY
    case MatchTypeNum.ZIP_PLUS_STREET:
      return MatchType.ZIP_PLUS_STREET
    case MatchTypeNum.GEOCODE:
      return MatchType.GEOCODE
    case MatchTypeNum.SIMILAR:
      return MatchType.SIMILAR
    case MatchTypeNum.REFERRER:
      return MatchType.REFERRER
    case MatchTypeNum.POLYGON:
      return MatchType.POLYGON
    case MatchTypeNum.VETRO:
      return MatchType.VETRO
    case MatchTypeNum.VETRO_V2:
      return MatchType.VETRO_V2
    case MatchTypeNum.EXACT_DUP:
      return MatchType.EXACT_DUP
    default:
      FailMatchType(`Invalid number (${m}) for MatchTypeNum`)
  }
}

export function MatchTypeFromString(m: string): MatchType {
  switch (m) {
    case MatchType.EXACT:
      return MatchType.EXACT
    case MatchType.BUILDING:
      return MatchType.BUILDING
    case MatchType.ZIP_ONLY:
      return MatchType.ZIP_ONLY
    case MatchType.ZIP_PLUS_STREET:
      return MatchType.ZIP_PLUS_STREET
    case MatchType.GEOCODE:
      return MatchType.GEOCODE
    case MatchType.SIMILAR:
      return MatchType.SIMILAR
    case MatchType.REFERRER:
      return MatchType.REFERRER
    case MatchType.POLYGON:
      return MatchType.POLYGON
    case MatchType.VETRO:
      return MatchType.VETRO
    case MatchType.VETRO_V2:
      return MatchType.VETRO_V2
    case MatchType.EXACT_DUP:
      return MatchType.EXACT_DUP
    case MatchType.NONE:
      return MatchType.NONE
    default:
      FailMatchType(`Invalid value "${m}" for MatchTypeNum`)
  }
}

function MatchTypePrecidence(a: MatchType): number {
  return a === MatchType.EXACT
    ? MatchTypeNum.EXACT
    : a === MatchType.BUILDING
      ? MatchTypeNum.BUILDING
      : a === MatchType.ZIP_ONLY
        ? MatchTypeNum.ZIP_ONLY
        : a === MatchType.ZIP_PLUS_STREET
          ? MatchTypeNum.ZIP_PLUS_STREET
          : a === MatchType.GEOCODE
            ? MatchTypeNum.GEOCODE
            : a === MatchType.SIMILAR
              ? MatchTypeNum.SIMILAR
              : a === MatchType.REFERRER
                ? MatchTypeNum.REFERRER
                : a === MatchType.EXACT_DUP
                  ? MatchTypeNum.EXACT_DUP
                  : a === MatchType.NONE
                    ? MatchTypeNum.NONE
                    : Number.MAX_VALUE
}

export function MatchTypeCompare(a: MatchType, b: MatchType): number {
  return MatchTypePrecidence(a) - MatchTypePrecidence(b)
}


<v-container class="pa-10">
  <v-spacer />
  <v-row justify="center">
    <v-card max-width="500">
      <v-card-title> </v-card-title>
      <v-card-text v-html="unsupportedPreSaleShopperText"></v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-btn class="ma-3" @click="startNew" color="primary"> Start New Order </v-btn>

          <v-btn v-if="displayPresaleCustomerServiceButton" :href="customerService" class="ma-3" color="primary">
            Call Customer Service
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-row>
</v-container>

import { TSON } from 'typescript-json'
import { assertNotNull } from '@adg/catalog/src/util/tsHelpers'
import { usePiniaRoute } from './../../store/pinia/piniaRoute'
import { AutomationSchedule, Timeslot } from '@adg/catalog/src/common/AutomationSchedule'
import { getConfigBoolean, getConfigItem } from '@/components/shared/getConfigItem'
import { ConfigKeys, OrderSchedule } from '@adg/catalog/src/modules/Catalog'
import useUiConfig from '@/components/shared/useUiConfig'
import { SalesLeadType } from '@adg/catalog/src/modules/Catalog'
import { computed } from '@vue/composition-api'
import { FETCH_CATALOG, GET_ADDRESS_VERIFIED, GET_CURRENT_STEP, SET_CURRENT_STEP } from '@/store/types'
import useSalesLead from '@/components/useSalesLead'
import Serviceability from '@/components/serviceability/Serviceability.vue'
import Offers from '@/components/order/Offers.vue'
import Customize from '@/components/order/Customize.vue'
import Account from '@/components/order/account/Account.vue'
import ecom from '@/gtm/ecom'
import ga4 from '@/gtm/ga4'
//import dayjs from 'dayjs'
import dayjs from 'dayjs'
import { curry, Dictionary, groupBy, orderBy } from 'lodash'
import useShopper from './useShopper'
import useAutomationState from '@/store/useAutomationState'
import { logger } from '@/utils/RemoteLogger'
import { SchedulingConfig, ApiConfig } from '@adg/catalog/src/common/UIConfig'
import ScheduleComponent from '@/components/order/scheduling/Schedule.vue'
import { createDefaultTimeslots, getPreferredDaysOut } from '@adg/catalog/src/common/schedulingUtil'
import { Days } from '@/constants/Days'
import { Spinner } from '@/store/State'

export default ($store, $router) => {
  const { salesLead, processSalesLead } = useSalesLead()
  const { configSteps } = useUiConfig()
  const scheduling = computed(() => getConfigItem(ConfigKeys.scheduling) as SchedulingConfig)
  const { cancelAutomation, getshouldAutomateReasons } = useAutomationState($store)
  const findingOffersText = computed(() => getConfigItem(ConfigKeys.findingOffersText ?? 'Finding your offers'))
  const isPreSale = computed(() => $store.getters.getShopper.customInfo.preSale)
  const isReturningPreSale = computed(() => $store.getters.getShopper.customInfo.returningPreSale)
  const catalogApiConfig = computed((): ApiConfig | undefined => getConfigItem(ConfigKeys.catalogApiConfig) ?? {})
  const timeslotApiConfig = computed((): ApiConfig | undefined => getConfigItem(ConfigKeys.timeslotsApiConfig) ?? {})

  const currentStep = computed({
    get: () => {
      let cs = $store.getters[GET_CURRENT_STEP]
      return cs
    },
    set: (val) => {
      $store.commit(SET_CURRENT_STEP, val)
      usePiniaRoute().setStep(val)
    }
  })

  const goToStep = (step, direction?) => {
    $router.push({ path: `/order/${step}` })
  }

  const addressVerified = computed(() => $store.getters[GET_ADDRESS_VERIFIED])

  const addressSearchButtonLabel = computed(() => getConfigItem(ConfigKeys.addressSearchButtonLabel) ?? 'Next')

  const validation = computed(() => {
    const step = currentStep.value
    const address = addressVerified.value

    if (step > 1) {
      if (!address) return false
    }

    if (step === 1) {
      return $store.getters.getFormattedAddress != ''
    } else if (step === 2) {
      const pkg = $store.getters.getPackage
      return pkg && pkg.Name
    } else if (step === 3) {
      const pkg = $store.getters.getPackage
      if (pkg && pkg.Products && pkg.Products.find((p) => p['Product Type'] === 'Phone')) {
        return $store.getters.getPhoneInfoValid
      } else {
        return true
      }
    } else if (step === 4) {
      return $store.getters.getAccountInfoValid
    } else if (step === 5) {
      const pkg = $store.getters.getPackage
      return pkg && pkg.Name
    } else {
      return true
    }
  })

  const catalogSpinner: Spinner = {
    id: 'fetchcatalog',
    messages: catalogApiConfig.value?.messages ?? [findingOffersText.value],
    spinnerUpdateTime: catalogApiConfig.value?.messageTimeout ?? 10,
    rank: 1
  }

  const fetchOffers = (address) => {
    $store.commit('addSpinner', catalogSpinner)
    const delayCatalogForCaptcha = getConfigBoolean(ConfigKeys.delayCatalogForCaptcha)
    if (delayCatalogForCaptcha) delayFetchOffers(address)
    else _fetchOffers(address)
  }

  const delayFetchOffers = (address, attempt: number = 0) => {
    //wait to call fetchOffers until shopper.captchaScore is defined
    const captchaApiConfig: ApiConfig = getConfigItem(ConfigKeys.captchaApiConfig)
    const maxAttempts = (captchaApiConfig.pendingTimeout ?? 10000) / 100
    if ($store.getters.getShopper.qualityScoreStatus === 'unknown' && attempt < maxAttempts) {
      setTimeout(() => {
        delayFetchOffers(address, attempt + 1)
      }, 100)
    } else {
      _fetchOffers(address)
    }
  }
  const _fetchOffers = (address) => {
    // $store.commit('setSpinnerUpdateTime', catalogApiConfig.value.messageTimeout ?? 10)

    let salesLeadRedirectHandled: SalesLeadType
    let errorFound = false

    $store
      .dispatch(FETCH_CATALOG, address)
      .then(() => {
        try {
          salesLeadRedirectHandled = processSalesLead()
        } catch (error) {
          throw new Error(`Processing sales lead failed: ${error}`)
        }
      })
      .catch((error: Error) => {
        logger.error(`Fetching catalog failed: ${error}`, error)
        errorFound = true
      })
      .finally(() => {
        //$store.commit('removeSpinner', 'fetchcatalog')
        if (errorFound) {
          $router.push('/error')
        } else {
          switch (salesLeadRedirectHandled) {
            case SalesLeadType.NONE:
              if (salesLead.value) {
                $router.push('/noservice')
              } else {
                if (addressVerified.value) {
                  if (getConfigItem(ConfigKeys.comingSoon)) {
                    $router.push('/comingSoon')
                    break
                  } else {
                    goToStep(currentStep.value, '')
                  }
                } else {
                  $router.push('/activeAccount')
                }
              }
              //$store.commit('removeSpinner', 'fetchcatalog')
              break
            case SalesLeadType.CROWD_FIBER:
            case SalesLeadType.SIMPLE_REDIRECT:
              ecom.pushPageView('/noservice')
              ga4.pushPageView('/noservice')
              break
            case SalesLeadType.CATALOG_REDIRECT:
              ecom.pushPageView('/presales')
              ga4.pushPageView('/presales')
              break
            default:
          }
        }
        $store.commit('removeSpinner', 'fetchcatalog')
      })
  }

  const submitOrder = async () => {
    try {
      // const { orderToSubmit } = useCart($store)
      // await $store.dispatch('submitOrder', orderToSubmit().order)
      await $store.dispatch('submitOrder')
      $router.push({ name: 'confirmation' })
    } catch (error) {
      logger.error('Submitting order failed', error)
      $router.push('/error')
    }
  }

  const { getShopper } = useShopper($store)

  const updateShopper = (logMsg: string) => {
    return $store.dispatch('updateShopper', logMsg)
  }

  const findSchedulingTimeSlots = async (showSpinner: boolean) => {
    const wasAutomated = $store.getters.getFullyAutomated as boolean

    // const shopperId = getShopper().id

    // const currentShopper = getShopper()

    // const payload = {
    //   shopperId: shopperId,
    //   shopperData: currentShopper,
    //   scheduling: getConfigItem(ConfigKeys.scheduling) as Scheduling
    // }

    if (showSpinner) {
      // $store.commit('setSpinnerUpdateTime', timeslotApiConfig.value?.messageTimeout ?? 10)
      // $store.commit('setSpinners', timeslotApiConfig.value?.messages ?? ['Checking available installation times...'])
      const timeslotSpinner: Spinner = {
        id: 'timeslots',
        messages: timeslotApiConfig.value?.messages ?? ['Checking available installation times...'],
        spinnerUpdateTime: timeslotApiConfig.value?.messageTimeout ?? 10,
        rank: 2
      }
      $store.commit('addSpinner', timeslotSpinner)
    }

    // get curried with wasAutomated pre-populated for use in the Promise resolve callback
    const handleTimeslotResponseWithWasAutomated = curry(handleTimeslotResponse)(wasAutomated)

    $store
      .dispatch('timeslots', getConfigItem(ConfigKeys.scheduling) as SchedulingConfig)
      .then(handleTimeslotResponseWithWasAutomated)
      .catch((e) => {
        // $store.commit(SET_SHOW_SPINNER, { show: false })
        logger.info('remote timeslot fetch failed, try to get default timeslots')
        handleTimeslotResponse(wasAutomated, getDefaultTimeslots())
      })
      .finally(() => {
        if (showSpinner) {
          $store.commit('removeSpinner', 'timeslots')
        }
        if (isPreSale.value && !isReturningPreSale.value) {
          // logic to determine if the order is a first time presale
          submitOrder()
        }
      })
  }

  // Our reimplemented version of the the lodash groupBy function
  type GroupedItems<T> = Record<string, T[]>
  type Grouper<T> = (slot: T) => string

  function groupBy<T>(slots: T[], groupFn: Grouper<T>): GroupedItems<T> {
    return slots.reduce((acc, slot) => {
      const groupStr = groupFn(slot)
      acc[groupStr] ??= []
      acc[groupStr].push(slot)
      return acc
    }, {} as GroupedItems<T>)
  }

  const handleTimeslotResponse = (wasAutomated: boolean, response: AutomationSchedule) => {
    try {
      let schedule: OrderSchedule = response
      const isSchedulingAutomated = getConfigBoolean(ConfigKeys.isSchedulingAutomated)

      if (schedule.timeSlots === undefined || schedule.timeSlots.length === 0) {
        logger.info('no timeslots returned, try to get default timeslots')
        schedule = getDefaultTimeslots()
      }

      //const grt = groupBy(schedule.timeSlots, (o): string => dayjs(o.startTime).format('YYYY-MM-DD'))
      // Group timeslots by day
      //let grt: Dictionary<Timeslot[]> = {}
      let grt: GroupedItems<Timeslot> = {}
      try {
        //grt = groupBy(schedule.timeSlots, (timeslot): string => dayjs(timeslot.startTime).format('YYYY-MM-DD'))
        grt = groupBy(schedule.timeSlots, (timeslot): string => new Date(timeslot.startTime).toISOString().split('T')[0])
      } catch (error) {
        logger.error('ERROR Grouping timeslots by day', error)
        throw error
      }

      // schedule.timeSlotsByDay = grt //old logic
      // start replacement logic

      const forceAllowAutomatedSchedulingDates = computed(
        () => getConfigBoolean(ConfigKeys.forceAllowAutomatedSchedulingDates) ?? false
      )
      const disabledSchedulingDates = getConfigItem(ConfigKeys.disabledSchedulingDates) ?? []
      const disabledHolidays = getConfigItem(ConfigKeys.holidaysToDisable) ?? []

      const translateDatePickerDay = (es6Day: number) => {
        //ES6 sun=0,mon=1, tues=2... sat=6
        //Datepicker sun=6,mon=0, tues=1... sat=5
        switch (es6Day) {
          case Days.SUNDAY:
            return 6
          case Days.MONDAY:
            return 0
          case Days.TUESDAY:
            return 1
          case Days.WEDNESDAY:
            return 2
          case Days.THURSDAY:
            return 3
          case Days.FRIDAY:
            return 4
          case Days.SATURDAY:
            return 5
          default:
            throw new Error(`Invalid day ${es6Day}`)
        }
      }

      const isAvailableDay = (d: string): boolean => {
        if (wasAutomated && forceAllowAutomatedSchedulingDates.value) {
          return true
        }
        if (disabledSchedulingDates.includes(d) || disabledHolidays.includes(d)) {
          // testLog(`disabled scheduling dates or disabled holidays' ${d}`)
          return false
        }
        const unavailableDays = scheduling.value?.unavailableDays ?? []
        const translated = unavailableDays.map((dn: number) => translateDatePickerDay(dn))
        let day = new Date(d).getDay()
        return !translated.includes(day)
      }

      Object.keys(grt).forEach((day) => {
        if (isAvailableDay(day)) {
          if (schedule.timeSlotsByDay === undefined) {
            schedule.timeSlotsByDay = {}
          }
          schedule.timeSlotsByDay[day] = grt[day]
        }
      })

      $store.commit('setAvailableSchedule', schedule)

      //set schedule options to the first day
      const days = Object.keys(schedule.timeSlotsByDay ?? []).sort()

      if (days.length > 0 && schedule.timeSlotsByDay) {
        const first = days[0]
        const second = days.length > 1 ? days[1] : first

        //logger.info('First day', first, 'Second day', second)
        //array of arrays
        $store.commit('setScheduleOptions', [schedule.timeSlotsByDay[first], schedule.timeSlotsByDay[second]])
        //Need to set initial cart state
        const cartSchedule: OrderSchedule = $store.getters.getCartSchedule
        const emailInfo = getConfigItem(ConfigKeys.emailScheduleInfo) ?? undefined

        const automatedSlots = schedule.timeSlotsByDay[first]

        const defaultScheduleValue = getConfigItem(ConfigKeys.defaultScheduleValue)

        if (defaultScheduleValue) {
          cartSchedule.installOption = defaultScheduleValue
        }

        const hideSecondCalendar = getConfigBoolean(ConfigKeys.hideSecondCalendar) || schedule.automated === 'yes'

        const newSchedule: OrderSchedule = {
          ...cartSchedule,
          automated: schedule.automated,
          installOption: schedule.automated === 'yes' ? 'automated' : cartSchedule.installOption,
          scheduleDate: first,
          scheduleDate2: hideSecondCalendar ? undefined : second,
          timeSlots: hideSecondCalendar
            ? [schedule.timeSlotsByDay[first][0]]
            : [schedule.timeSlotsByDay[first][0], schedule.timeSlotsByDay[second][1]],
          emailScheduleInfo: emailInfo
        }
        if (isSchedulingAutomated && schedule.automated !== 'yes' && !getConfigBoolean(ConfigKeys.canSelfInstall)) {
          const failureReasons: string[] = []
          getshouldAutomateReasons(failureReasons)
          if (failureReasons.length === 0) {
            failureReasons[0] = 'Automation not attempted based on defined business rules'
          }
          if (wasAutomated) cancelAutomation(failureReasons[0])
          else logger.info('Could not fetch automated timeslots, using default timeslots')
        }
        $store.commit('setCartSchedule', newSchedule)
      } else {
        throw new Error('No available timeslots')
      }
    } catch (error: any) {
      if (wasAutomated) cancelAutomation('Automation not attempted because of unidentified error')
      logger.error('Error handling timeslot response', error.message || 'Unknown error', response)
      throw error
    }
  }

  const getDefaultTimeslots = (): AutomationSchedule => {
    let daysOut = {
      min: scheduling.value?.installMinDaysOut ?? 1,
      max: scheduling.value?.installMaxDaysOut ?? 15
    }
    const shopper = getShopper()
    if (shopper && scheduling.value) {
      daysOut = getPreferredDaysOut(scheduling.value, shopper)
    }
    const defaults = createDefaultTimeslots(
      new Date().getTime(),
      daysOut.min,
      daysOut.max,
      scheduling.value?.unavailableDays ?? [0] /* sunday */,
      scheduling.value?.defaultTimeSlots ?? [
        { startTime: '08:00', endTime: '12:00', label: 'Morning 8-12 AM' },
        { startTime: '13:00', endTime: '15:00', label: 'Afternoon 1-5 PM' }
      ]
    )
    defaults.automated = 'no'
    return defaults
  }

  const steps = computed(() => {
    const sr = [
      {
        label: configSteps.value?.[0]?.title ?? 'Check Address',
        forwardButtonLabel: addressSearchButtonLabel.value,
        component: 'Serviceability'
      },
      {
        label: configSteps.value?.[1]?.title ?? 'Choose Services',
        forwardButtonLabel: '',
        component: 'Offers'
      },
      {
        label: configSteps.value?.[2]?.title ?? 'Customize Options',
        forwardButtonLabel: 'Next',
        component: 'Customize'
      },
      {
        label: configSteps.value?.[3]?.title ?? 'Set Up Account',
        forwardButtonLabel: 'Next',
        component: 'Account'
      }
    ]
    if (!isPreSale.value || isReturningPreSale.value) {
      sr.push({
        label: configSteps.value?.[4]?.title ?? 'Schedule Installation',
        forwardButtonLabel: 'Submit Order',
        component: 'Schedule'
      })
    }
    return sr
  })

  return {
    currentStep,
    validation,
    addressVerified,
    fetchOffers,
    submitOrder,
    steps,
    goToStep,
    updateShopper,
    findSchedulingTimeSlots,
    addressSearchButtonLabel,
    findingOffersText,
    catalogSpinner
  }
}

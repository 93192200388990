
<v-container
  fluid
  style="max-width: 1800px"
  v-resize="onResize"
  :class="{
    'px-4': $vuetify.breakpoint.xsOnly,
    'px-1': $vuetify.breakpoint.smAndUp
  }"
>
  <div v-if="offerDisplayText">
    <v-row class="text-center">
      <v-col class="display-1" v-html="uiMacroParser(shopper, offerDisplayText)"></v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row class="text-center">
      <v-col class="display-1">Which services do you need?</v-col>
    </v-row>
  </div>
  <v-row v-if="showChoosePackageText" class="text-center">
    <v-col id="choose-package-text" class="headline pt-1" v-html="uiMacroParser(shopper, choosePackageText)"></v-col>
  </v-row>

  <v-container v-if="useButtonsForLobs" id="lob-buttons" class="d-flex justify-center">
    <v-row class="justify-center">
      <v-col
        :cols="$vuetify.breakpoint.xsOnly ? 12 : 'auto'"
        v-for="productType in viewableProductTypes"
        class="d-flex justify-center"
        :key="productType.name + '-button'"
      >
        <v-btn
          rounded
          :class="isProductTypeSelected(productType.name) ? 'active' : 'inactive'"
          :disabled="isProductTypeDisabled(productType.name)"
          @click="productTypeChanged(productType.name)"
          >{{ productType.name }}
          <v-spacer></v-spacer>
          <v-icon v-if="isProductTypeSelected(productType.name)">mdi-check</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-if="underLobText">
    <v-row class="text-center under-lob-text">
      <v-col v-html="uiMacroParser(shopper, underLobText)"></v-col>
    </v-row>
  </v-container>

  <v-container v-if="showLobCheckboxes && !useButtonsForLobs" class="hidden-sm-and-up">
    <v-row dense v-for="productType in viewableProductTypes" :key="productType.name" class="py-0" justify="center">
      <v-card id="lob-checkboxes-row" flat min-width="75%">
        <v-col cols="12" class="py-0" id="lob-checkboxes">
          <v-checkbox
            dense
            :disabled="isProductTypeDisabled(productType.name)"
            v-model="selectedProductTypes"
            :label="productType.label"
            :value="productType.name"
            :color="lobTextColor"
            class="my-0"
            :class="
              $vuetify.breakpoint.xsOnly
                ? `smaller-checkbox ${productType.name}-checkbox`
                : `bigger-checkbox ${productType.name}-checkbox`
            "
          />
        </v-col>
      </v-card>
    </v-row>
  </v-container>

  <v-row v-if="showLobCheckboxes && !useButtonsForLobs" class="hidden-xs-only" justify="center" id="lob-checkboxes">
    <v-checkbox
      v-for="productType in viewableProductTypes"
      :disabled="isProductTypeDisabled(productType.name)"
      :key="productType.name"
      v-model="selectedProductTypes"
      :label="productType.label"
      :value="productType.name"
      color="primary"
      class="bigger-checkbox col-xs-12"
      :class="`${productType.name}-checkbox`"
      style="padding-right: 120px"
    />
  </v-row>

  <v-row v-if="filterPackages.length === 0 || filterPackages.length === undefined" class="text-center">
    <v-col id="no-offers-text" v-html="uiMacroParser(shopper, noOffersText)"></v-col>
  </v-row>

  <PackageContainer v-else :filterPackages="filterPackages" :packageSelected="packageSelected" />

  <!-- <div class="offers-container">
    <template v-for="(p, i) in filterPackages">
      <div :key="i" class="package pa-2">
        <component
          :is="packageComponent"
          :packageData="p"
          :colorIndex="i"
          @packageSelected="packageSelected"
          :minWidth="numPkgs === 4 ? 225 : 275"
        ></component>
      </div>
    </template>
  </div> -->
  <v-row v-if="offerDisclaimerText" class="text-center pt-3 pb-3" justify="center">
    <span class="pt-1" v-html="uiMacroParser(shopper, offerDisclaimerText)"></span>
  </v-row>
  <StepperButtons />
</v-container>


<div>
  <Converge v-if="paymentControl === 'converge' && mustPrePay" :disabled="disabled" />

  <CustomPayment v-else-if="paymentControl === 'gocare' && mustPrePayGoCare" :disabled="disabled" />

  <PayIntegration v-else-if="paymentControl === 'payintegration' && mustPrePayGoCare" :disabled="disabled" />

  <PayTrace v-else-if="paymentControl === 'paytrace' && mustPrePayGoCare" :disabled="disabled" />

  <CardPointe v-else-if="paymentControl === 'cardpointe' && mustTakePayment" />

  <Stripe v-else-if="paymentControl === 'stripe' && mustTakePayment" :disabled="disabled" />

  <TranSafe v-else-if="paymentControl === 'transafe' && mustTakePayment" :disabled="disabled" />

  <StepperButtons v-else key="nonConvergeStepper" :disabled="disabled" />
</div>

import { IShopper } from '@adg/catalog/src/modules/Shopper'
import Vue from 'vue'
import VueRouter, { RouteConfig, RouterOptions } from 'vue-router'
import CoreDefault from '@/components/CoreDefault.vue'
import Order from '@/components/order/Order.vue'
import Confirmation from '@/components/order/Confirmation.vue'
import NoService from '@/components/order/NoService.vue'
import store from '../store'
import { bus } from '@/main'
import ErrorPage from '@/components/ErrorPage.vue'
import ecom from '@/gtm/ecom'
import ServiceabilityQuestions from '@/components/serviceability/ServiceabilityQuestions.vue'
import ComingSoon from '@/components/serviceability/ComingSoon.vue'
import LeadCapture from '@/components/serviceability/LeadCapture/LeadCapture.vue'
import useOrder from '@/components/order/useOrder'
import PreSale from '@/components/serviceability/PreSale.vue'
// import Iframe from '@/components/serviceability/Iframe.vue'
import { usePiniaRoute } from '@/store/pinia/piniaRoute'
import ga4 from '@/gtm/ga4'
import { switchRouteSpan } from '@adg/catalog/src/web/tracer'
import { logger } from '@/utils/RemoteLogger'

Vue.use(VueRouter)

const navRoutes = <Array<RouteConfig>>[
  {
    path: '/',
    name: 'home',
    component: CoreDefault,
    children: [
      { name: 'confirmation', path: 'confirm', component: Confirmation },
      { name: 'noservice', path: 'noservice', component: NoService },
      { name: 'activeAccount', path: 'activeAccount', component: ServiceabilityQuestions },
      { name: 'comingSoon', path: 'comingSoon', component: ComingSoon },
      { name: 'leadCapture', path: 'leadCapture', component: LeadCapture },
      { name: 'order', path: 'order/:step', component: Order },
      { name: 'unsupportedShopper', path: 'preSale', component: PreSale },
      // { name: 'iframe', path: 'iframe', component: Iframe }
      {
        path: '/error',
        component: ErrorPage
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

export const routerOptions = <RouterOptions>{
  routes: navRoutes
}

const router = new VueRouter(routerOptions)

router.afterEach((to, from) => {
  if (from.name && ['confirmation', 'noservice'].includes(from.name)) {
    if (to.path !== '/order/1') router.push('/order/1')
  }

  if (to.path === '/order/2') {
    // this will only get sent once, function is idempotent per app load
    store.commit('sendShopperMetric')
  }
})

router.beforeEach((to, from, next) => {
  switchRouteSpan(to.fullPath)

  if (to.path === '/order/1') {
    store.commit('resetOrder')
    try {
      ga4.pushCoreBuyflowStart()
    } catch (e) {
      const buyflowStartId = store.getters.getBuyflowStartId
      logger.warn(`Failed to push core_buyflow_start event for ${buyflowStartId}`)
    }
  }

  if (to.path === '/order/2') {
    store.commit('resetCart')
  }

  if (from.path === '/' && to.path !== '/order/1') {
    router.push('/order/1')
  }
  if (from.path !== '/') {
    usePiniaRoute().setPath(to.path)
  }
  const { findSchedulingTimeSlots } = useOrder(store, router)
  const shopper = store.getters.getShopper as IShopper
  const isInitialPreSale = shopper.customInfo.preSale && !shopper.customInfo.returningPreSale
  const isCommercial = shopper.tags?.classification === 'COMMERCIAL'

  if (from.path === '/activeAccount' && to.path === '/order/1') {
    next(false)
  }

  if (from.name === 'error') {
    next(false)
  }

  ecom.pushRoute(to, isInitialPreSale, isCommercial)
  ga4.pushRoute(to, isInitialPreSale, isCommercial)

  const showMobileCart = to.name === 'order' && Number.parseInt(to.params.step, 10) > 2
  store.commit('setMobileCart', showMobileCart)

  if (to.path === '/order/2') {
    bus.$emit('pushShopperType')
    const shopper = store.getters.getShopper as IShopper
    //bus.$emit('pushSalesType')
  } else if (to.path === '/order/4') {
    bus.$emit('startCheckout')
    ga4.pushCheckout('begin_checkout')
  } else if (to.path === '/order/5') {
    ecom.setDataLayerVariable({ email_address: shopper.email ?? '' })
    if (isInitialPreSale) {
      bus.$emit('submitOrder')
    } else if (!['/confirm', '/'].includes(from.path ?? '')) {
      bus.$emit('scheduleInstall')
      findSchedulingTimeSlots(true)
    }
  } else if (to.path === '/' && from.path === '/order/1' && window.document.referrer) {
    const uiConfig = store.getters.getUIConfig
    window.location = uiConfig.residentialLink
    //KWC I think we need to use next(false)
    //   // @ts-ignore
    // window.location = window.document.referrer
  }

  next()
})

export default router


<div>
  <div v-if="selfInstallTextSubtitle" class="pt-3 pb-3">
    <span v-html="selfInstallTextSubtitle"></span>
  </div>
  <span> Your equipment will be ready for pickup on {{ readyDate.format('MM/DD/YYYY') }}.</span>
  <div v-if="selfInstallText" class="pt-3 pb-3">
    <span v-html="selfInstallText"></span>
  </div>
  <div v-if="pickupTitle" class="pt-3 pb-3">
    <span v-html="pickupTitle"></span>
  </div>
  <div v-if="pickupLocation" class="pt-3 pb-3">
    <span v-html="pickupLocation"></span>
  </div>
  <div v-if="pickupTimes" class="pt-3 pb-3">
    <span v-html="pickupTimes"></span>
  </div>
  <div v-if="pickupDisclaimer" class="pt-3 pb-3">
    <span v-html="pickupDisclaimer"></span>
  </div>
  <div>
    <iframe
      :src="googleMapsLocation"
      width="100%"
      height="350px"
      style="border: 0"
      allowfullscreen="false"
      loading="lazy"
    ></iframe>
  </div>
</div>

<template>
  <v-footer padless dark class="hidden-print-only app-footer">
    <v-container id="footer-container">
      <v-row id="footer-row-1" justify="center" no-gutters class="py-0 my-0">
        <v-col id="footer-top-text" class="footer-link2 pt-4 text-center">
          <div id="top-footer-text" v-html="uiMacroParser(shopper, footerTopText)"></div>
        </v-col>
      </v-row>
      <div v-if="$vuetify.breakpoint.smAndUp">
        <v-row id="footer-row-2" class="footer-row2">
          <v-col cols="5" class="text-right">
            <v-btn
              color="white"
              v-for="link in links"
              :key="link.name"
              :href="link.link"
              target="_blank"
              text
              id="footer-button"
              class="py-0 my-0 footer-link text-none font-weight-thin"
            >
              {{ link.name }}
            </v-btn>
          </v-col>
          <v-col id="footer-logo" cols="2">
            <v-img class="ml-auto mr-auto" max-width="72px" max-height="36px" :src="footerLogo"> </v-img>
          </v-col>
          <v-col id="footer-copyright" cols="5" class="footer-link2 py-4 text-left">
            &copy;{{ new Date().getFullYear() }} — {{ uiConfig.companyName }}. All rights reserved.
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row id="footer-row-2" class="footer-row2">
          <v-col cols="12" class="text-center">
            <v-btn
              color="white"
              v-for="link in links"
              :key="link.name"
              :href="link.link"
              target="_blank"
              text
              id="footer-button"
              class="py-0 my-0 footer-link text-none font-weight-thin"
            >
              {{ link.name }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col id="footer-copyright" cols="12" class="footer-link2 py-4 text-center">
            &copy;{{ new Date().getFullYear() }} — {{ uiConfig.companyName }}. All rights reserved.
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-footer>
</template>

<script lang="ts">
import { computed, defineComponent, watchEffect } from '@vue/composition-api'
import useFooter from './useFooter'
import $store from '@/store'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { IShopper } from '@adg/catalog/src/modules/Shopper'

export default defineComponent({
  setup(props, context) {
    const {
      root: { $router, $route }
    } = context

    const { links, uiConfig, footerColor, footerTopText, footerLogo } = useFooter($store)

    const year = new Date().getFullYear()

    const shopper = computed(() => $store.getters.getShopper as IShopper)

    return { links, year, uiConfig, footerColor, footerTopText, footerLogo, shopper, uiMacroParser }
  }
})
</script>

<style>
.card-bottom-corners {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.withSystemBar {
  padding: 40px;
}
.noSystemBar {
  padding-top: 10px;
}

#footer-container {
  padding: 0rem;
}
</style>

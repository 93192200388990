import { IShopper } from '@/modules/Shopper'
import { GeneralParser } from './GeneralParser'
import { customFunctions } from './Interface/Parser'
import { macroParser } from './macroParser'
/**
 * BaseParser
 * @index 1
 * @description BsaeParser is the base class for all parsers
 */
export class BaseParser {
  // public validClasses: string[] = []
  public functions: customFunctions = {}

  constructor(public validClasses: string[] = []) {
    this.validClasses = validClasses
  }

  addfunctions(functions: customFunctions) {
    this.functions = {
      ...this.functions,
      ...functions
    }
  }

  parse(input: string): string {
    return macroParser(input, this.functions, [...Object.keys(this.functions), ...this.validClasses])
  }
}

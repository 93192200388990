
<v-container fluid :class="currentStep === 1 ? 'fill-height' : ''" class="pa-0" id="order-container">
  <v-img height="100%" :src="backgroundImage" :id="backgroundImageId">
    <v-row justify="center" no-gutters style="height: 100%">
      <!-- <ServiceabilityQuestions v-if="prompt && !showSpinner.show" /> -->
      <v-stepper
        v-show="!showSpinner"
        class="elevation-0"
        id="v-stepper-container"
        v-model="currentStep"
        :alt-labels="true"
        :style="currentStep === 1 ? 'width: 100%' : ''"
      >
        <v-container id="stepper-container" class="text-center pt-1 pb-0 full-width">
          <v-stepper-header
            id="breadcrumb-padding"
            class="elevation-0 pt-2 pb-0"
            v-show="$vuetify.breakpoint.mdAndUp && showStepper"
          >
            <div v-if="uiConfig.logoInSteppers && currentStep === 1" class="stepper-logo">
              <a :href="logoLink" target="_blank">
                <img :src="headerLogo" />
              </a>
            </div>

            <div v-for="(step, index) in steps" :key="index">
              <v-stepper-step
                :id="breadCrumbId(index)"
                :class="{
                  'current-step': currentStep - 1 === index,
                  'default-step': currentStep - 1 !== index
                }"
                class="px-0 py-2 subtitle-1"
                :step="index + 1"
                :complete="currentStep > index + 1"
              >
                <span
                  :name="debugName(`config.step.[${index}].label`)"
                  :id="'step-label-' + currentStep"
                  class="subtitle-1"
                  :class="{
                    'current-step-label': currentStep - 1 === index,
                    'default-step-label': currentStep - 1 !== index
                  }"
                >
                  {{ step.label }}</span
                >
              </v-stepper-step>
            </div>
          </v-stepper-header>
        </v-container>
        <v-stepper-items :id="'stepperItems-' + currentStep" class="full-width" style="overflow: visible">
          <v-stepper-content v-for="(step, index) in steps" :key="index" class="pt-0 pb-1 px-1" :step="index + 1">
            <Serviceability
              v-if="step.component === 'Serviceability'"
              v-on:goForward="forward"
              v-on:goBack="back"
              v-on:submitOrder="submitOrder"
            />
            <Offers
              v-if="step.component === 'Offers'"
              v-on:goForward="forward"
              v-on:goBack="back"
              v-on:submitOrder="submitOrder"
            />
            <Customize
              v-if="step.component === 'Customize'"
              v-on:goForward="forward"
              v-on:goBack="back"
              v-on:submitOrder="submitOrder"
            />
            <Account
              v-if="step.component === 'Account'"
              v-on:goForward="forward"
              v-on:goBack="back"
              v-on:submitOrder="submitOrder"
            />
            <Schedule
              v-if="step.component === 'Schedule'"
              v-on:goForward="forward"
              v-on:goBack="back"
              v-on:submitOrder="submitOrder"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-row>
  </v-img>
</v-container>

<template>
  <div>
    <div v-if="selfInstallTextSubtitle" class="pt-3 pb-3">
      <span v-html="selfInstallTextSubtitle"></span>
    </div>
    <span> Your equipment will be ready for pickup on {{ readyDate.format('MM/DD/YYYY') }}.</span>
    <div v-if="selfInstallText" class="pt-3 pb-3">
      <span v-html="selfInstallText"></span>
    </div>
    <div v-if="pickupTitle" class="pt-3 pb-3">
      <span v-html="pickupTitle"></span>
    </div>
    <div v-if="pickupLocation" class="pt-3 pb-3">
      <span v-html="pickupLocation"></span>
    </div>
    <div v-if="pickupTimes" class="pt-3 pb-3">
      <span v-html="pickupTimes"></span>
    </div>
    <div v-if="pickupDisclaimer" class="pt-3 pb-3">
      <span v-html="pickupDisclaimer"></span>
    </div>
    <div>
      <iframe
        :src="googleMapsLocation"
        width="100%"
        height="350px"
        style="border: 0"
        allowfullscreen="false"
        loading="lazy"
      ></iframe>
    </div>
  </div>
</template>

<script lang="ts">
import Cart from '@/components/order/cart/Cart.vue'
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import StepperButtons from '@/components/order/StepperButtons.vue'
import { getConfigItem } from '@/components/shared/getConfigItem'
import dayjs from 'dayjs'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import { usePiniaRoute } from '@/store/pinia/piniaRoute'

export default defineComponent({
  name: 'CustomScheduling',
  components: {
    Cart,
    StepperButtons
  },
  setup() {
    const selfInstallTextSubtitle = computed(() => $store.getters.getConfigItems.selfInstallTextSubtitle)
    const selfInstallText = computed(() => $store.getters.getConfigItems.selfInstallText)
    const pickupTimes = computed(() => $store.getters.getConfigItems.pickupTimes)
    const pickupLocation = computed(() => $store.getters.getConfigItems.pickupLocation)
    const pickupTitle = computed(() => $store.getters.getConfigItems.pickupTitle)
    const pickupDisclaimer = computed(() => $store.getters.getConfigItems.pickupDisclaimer)
    const googleMapsLocation = computed(() => $store.getters.getConfigItems.googleMapsLocation)
    const pickupDaysOut = computed(() => parseInt($store.getters.getConfigItems.pickupDaysOut) ?? '1')

    const readyDate = computed(() => dayjs().add(pickupDaysOut.value, 'day'))
    onMounted(() => {
      $store.commit('setReadyDate', readyDate.value.format('YYYY-MM-DD'))
      usePiniaShopper().shopper.customInfo.readyDate = readyDate.value.format('YYYY-MM-DD')
    })

    return {
      selfInstallText,
      selfInstallTextSubtitle,
      readyDate,
      pickupLocation,
      googleMapsLocation,
      pickupTimes,
      pickupDisclaimer,
      pickupTitle
    }
  }
})
</script>

<style>
.scheduleSubTitle {
  font-size: 18px;
  margin-left: 20px;
}

.timeRadio {
  margin-left: 0px;
}

.installationOptionsText {
  font-size: 20px;
}
</style>

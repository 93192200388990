/*
USAGE:
// /libs/utilities/external.ts
// PROJECT/src/views/xxxxxxxxx.vue
......
<script>
    import * as external from '@/libs/utilities/external.ts'
    export default {
        name: "xxxxxxxxx",
        mounted(){
            external.headScript('/assets/script1.js');
            external.bodyScript('/assets/script2.js');
            external.headLink('/assets/style1.css');
            external.bodyLink('/assets/style2.css');
        },
        destroyed(){
            external.deleteScript('/assets/script1.js');
            external.deleteScript('/assets/script2.js');
            external.deleteLink('/assets/style1.css');
            external.deleteLink('/assets/style2.css');
        },
    }
</script>
*/

function getScript(src: string): HTMLScriptElement | null {
  return document.querySelector("script[src='" + src + "']") || null
}

type Callback = () => void

function headScript(src: string, onload?: Callback): void {
  if (!getScript(src)) {
    const script = document.createElement('script')
    script.setAttribute('src', src)
    script.setAttribute('type', 'text/javascript')
    script.onload = () => {
      if (onload) onload()
    }
    document.head.appendChild(script)
  } else {
    if (onload) onload()
  }
}

function bodyScript(src: string, onload?: Callback): void {
  if (!getScript(src)) {
    const script = document.createElement('script')
    script.setAttribute('src', src)
    script.setAttribute('type', 'text/javascript')
    script.onload = () => {
      if (onload) onload()
    }
    document.body.appendChild(script)
  } else {
    if (onload) onload()
  }
}

function deleteScript(src: string): void {
  getScript(src)?.remove()
}

function getLink(src: string): HTMLLinkElement | null {
  return document.querySelector("script[src='" + src + "']") || null
}

function headLink(href: string, onload?: Callback): void {
  if (!getLink(href)) {
    const link = document.createElement('link')
    link.setAttribute('href', href)
    link.setAttribute('rel', 'stylesheet')
    link.setAttribute('type', 'text/css')
    link.onload = () => {
      if (onload) onload()
    }
    document.head.appendChild(link)
  } else {
    if (onload) onload()
  }
}

function bodyLink(href: string, onload?: Callback): void {
  if (!getLink(href)) {
    const link = document.createElement('link')
    link.setAttribute('href', href)
    link.setAttribute('rel', 'stylesheet')
    link.setAttribute('type', 'text/css')
    link.onload = () => {
      if (onload) onload()
    }
    document.body.appendChild(link)
  } else {
    if (onload) onload()
  }
}

function deleteLink(href: string): void {
  getLink(href)?.remove()
}

export { headScript, bodyScript, getScript, deleteScript, headLink, bodyLink, getLink, deleteLink }

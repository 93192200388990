
<div>
  <div v-if="paymentInfo && paymentInfo.status === 'declined'">
    <v-row class="grey lighten-3 pb-0">
      <v-col cols="12" class="caption text--secondary">
        <span>{{ paymentDeclinedText }} </span>
      </v-col>
    </v-row>
  </div>
  <div v-if="paymentInfo && paymentInfo.status === 'error'">
    <v-row class="grey lighten-3 pb-0">
      <v-col cols="12" class="caption text--secondary">
        <span
          >An error occured while processing your payment. A customer service representative will reach out to you to finalize
          your order.
        </span>
      </v-col>
    </v-row>
  </div>
  <div v-if="showSuccess">
    <div v-if="paymentConfig && paymentConfig.paymentApprovedText" v-html="paymentConfig.paymentApprovedText" />
    <v-row v-if="amountPaid" class="grey lighten-3 pb-0">
      <v-col cols="8" class="grey--text subtitle pb-0 pt-0">Amount Paid</v-col>
      <v-col cols="4" class="grey--text text-right pb-0 pt-0">{{ amountPaid }}</v-col>
    </v-row>
    <v-row class="grey lighten-3 pb-0">
      <v-col cols="8" class="grey--text subtitle pb-0">Payment Date</v-col>
      <v-col cols="4" class="grey--text text-right pb-0">{{ payDate }}</v-col>
    </v-row>
    <v-row v-if="payType" class="grey lighten-3 pb-0">
      <v-col cols="8" class="grey--text subtitle pb-0">Payment Type</v-col>
      <v-col cols="4" class="grey--text text-right pb-0">{{ payType }}</v-col>
    </v-row>
    <v-row v-if="cardNumber" class="grey lighten-3 pb-0">
      <v-col cols="7" class="grey--text subtitle pb-0">Card Number</v-col>
      <v-col cols="5" class="grey--text text-right pb-0">{{ cardNumber }}</v-col>
    </v-row>
    <v-row v-if="referenceNumber" class="grey lighten-3 pb-0">
      <v-col cols="7" class="grey--text subtitle pb-0">Reference Number</v-col>
      <v-col cols="5" class="grey--text text-right pb-0">{{ referenceNumber }}</v-col>
    </v-row>
  </div>
</div>


<div :id="`header-new-${currentStep}`">
  <v-system-bar app class="white--text" color="primary" height="40" v-if="determineIfBannerDisplayed">
    <v-spacer></v-spacer>
    <div class="pr-10" v-html="bannerText"></div>

    <v-btn
      color="secondary"
      outlined
      class="promo-button font-weight-bold hidden-md-and-up"
      @click="applyPromo"
      :small="$vuetify.breakpoint.smAndUp"
      :x-small="$vuetify.breakpoint.xsOnly"
    >
      <span class="white--text">CLICK TO APPLY TO CART</span>
    </v-btn>
    <v-spacer></v-spacer>
  </v-system-bar>
  <v-app-bar class="brand-header">
    <v-spacer v-if="allowDebug"></v-spacer>
    <div v-if="allowDebug" class="pt-4 pb-0 pl-3 pr-3" style="background-color: black">
      <v-switch v-model="debugMode" label="Debug Mode" color="red" dark />
    </div>
    <TooltipDebug :item="appState" :name="'State'" />
    <template v-for="headerItem in headerPieces">
      <v-row v-if="shouldHeaderItemDisplay(headerItem.steps)" :class="headerItem.rowClass">
        <v-col cols="auto" :class="headerItem.class">
          <div v-if="headerItem.type === 'clickableImage'">
            <a :href="headerItem.link" target="_blank">
              <img :src="headerItem.img" />
            </a>
          </div>
          <div v-if="headerItem.type === 'image'">
            <img :src="headerItem.img" />
          </div>
          <div v-if="headerItem.type === 'clickableText'">
            <a :href="headerItem.link" target="_blank">
              <div v-html="headerItem.text"></div>
            </a>
          </div>
          <div v-if="headerItem.type === 'text'">
            <div v-html="headerItem.text"></div>
          </div>
          <div v-if="headerItem.type === 'address' && punctuatedAddress != ''">
            <div v-html="punctuatedAddress"></div>
          </div>
          <div v-if="headerItem.type === 'icon'">
              <v-icon>{{ headerItem.icon }}</v-icon>
          </div>
          <div v-if="headerItem.type === 'spacer'">
            <v-spacer></v-spacer>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-app-bar>
  <v-app-bar id="mobile-cart-header" @click="dialog = true" color="black" class="mobile-cart-header" v-if="mobileCart">
    <v-row class="header-row" align="center">
        <v-col class="icon-col" cols="auto">
          <a @click="dialog = true">
            <v-icon color="white">mdi-cart-check</v-icon>
          </a>
      </v-col>
        <v-col class="total-col" cols="auto">
        <v-toolbar-title
          ><v-spacer></v-spacer>Monthly Total ${{ monthlyTotal.toFixed(2) }}</v-toolbar-title
        >
      </v-col>
      <v-row class="img-row">
        <v-col class="img-col" cols="auto" >
          <img :src="headerLogo"></img>
        </v-col>
    </v-row>
    </v-row>
  </v-app-bar>

  <!--    DIALOG CART-->
  <v-row justify="center">
    <v-dialog overlay-color="black" overlay-opacity=".8" v-model="dialog" scrollable max-width="450px">
      <v-card-text style="height: 800px" class="pa-0">
        <Cart @cartClicked="dialog = !dialog" />
      </v-card-text>
      <v-divider></v-divider>
    </v-dialog>
  </v-row>
</div>

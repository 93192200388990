<template>
  <div>
    <v-select
      :items="field.dropdownOptions"
      v-model="accountFieldModel"
      :label="field.label"
      :background-color="field.backgroundColor ? field.backgroundColor : 'white'"
      :rules="rules"
      v-bind="textFieldProps"
    >
      <template v-if="field.labelMacro" v-slot:label>
        <span @click.stop class="dropdown-label" v-html="uiMacroParser(shopper, field.labelMacro, true)"></span>
      </template>
    </v-select>
    <v-text-field
      v-if="secondaryFieldOpen"
      v-model="secondaryFieldModel"
      :label="field.secondaryLabel ?? field.label"
      :background-color="field.backgroundColor ? field.backgroundColor : 'white'"
      :rules="rules"
      v-bind="textFieldProps"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, PropType, ref } from '@vue/composition-api'
import $store from '@/store'
import { getConfigItem } from '../shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { AccountField } from '@adg/catalog/src/common/UiConfig'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { getRules } from './account/accountFunctions'

export default defineComponent({
  name: 'CreditCheck',
  props: {
    field: {
      type: Object as PropType<AccountField>,
      required: true
    },
    maskMap: Map
  },
  setup(props, { emit }) {
    const shopper = computed(() => $store.getters.shopper)
    const rules = getRules($store, props.field.rules ?? [])

    const accountFieldModel = computed({
      get: () => {
        let a = $store.getters.getAccountField(props.field.storeProp) ?? props.field.defaultValue
        return a
      },
      set: (value: any) => {
        $store.commit('setAccountField', { field: props.field.storeProp, value })
        // TODO: see if this is correct for pinia
        let showSecondaryField = false
        if (props.field.secondaryFieldValue) {
          let secondarySearchVal = props.field.secondaryFieldValue
          if (!Array.isArray(props.field.secondaryFieldValue)) {
            secondarySearchVal = [props.field.secondaryFieldValue]
          }
          showSecondaryField = secondarySearchVal.includes(value)
        }

        if (showSecondaryField) {
          secondaryFieldOpen.value = true
        } else {
          secondaryFieldOpen.value = false
          secondaryFieldModel.value = ''
        }

        if (props.field.storeProp) {
          usePiniaShopper().setShopperField(props.field.storeProp, value)
        }
        emit('change', value)
      }
    })

    const secondaryFieldModel = computed({
      get: () => {
        return $store.getters.getAccountField(props.field.secondaryStoreProp)
      },
      set: (value: any) => {
        $store.commit('setAccountField', { field: props.field.secondaryStoreProp, value })
        if (props.field.secondaryStoreProp) {
          usePiniaShopper().setShopperField(props.field.secondaryStoreProp, value)
        }
        emit('change', value)
      }
    })

    const secondaryFieldOpen = ref(false)

    const textFieldProps = computed(() => {
      const textProps: Record<string, string | boolean> = {}
      if (props.field.fieldProps) {
        for (let prop in props.field.fieldProps) {
          textProps[prop] = props.field.fieldProps[prop]
        }
      } else {
        textProps[getConfigItem(ConfigKeys.textFieldStyle) ?? 'solo'] = true
        textProps.singleLine = true
      }
      return textProps
    })

    return { uiMacroParser, accountFieldModel, secondaryFieldModel, secondaryFieldOpen, shopper, rules, textFieldProps }
  }
})
</script>
<style></style>

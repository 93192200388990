
<v-card class="broadband-card">
  <section class="close-row">
    <div v-if="closeButtonInLabel && closeLabelIconText" class="close-label" cols="auto" v-html="closeLabelIconText"></div>
    <v-icon v-if="closeButtonInLabel" class="close-icon" large :color="closeLabelIconColor" @click="$emit('labelClosed')">{{
      closeLabelIcon
    }}</v-icon>
  </section>
  <div class="label-content" v-html="labelHtml"></div>
  <v-btn
    v-if="selectButtonInLabel"
    :class="`button ${getCatalogItemClassName(packageData?.Name, 'Package')}`"
    rounded
    v-html="offerButtonText"
    @click="selectPackage(packageData)"
    dark
    :color="getOfferButtonColor({ type: '' }, colorIndex)"
  >
  </v-btn>
</v-card>

import { AxiosResponse } from 'axios'
import { UIConfig, newUIConfig } from '@adg/catalog/src/common/UIConfig'
import { logger } from '@/utils/RemoteLogger'
import { defineStore } from 'pinia'
import { httpClient } from '../http-client'
//import TSON from 'typia'

declare var uiConfigPresetJSON: any
declare var reCaptchaToken: string
declare function getReCaptchaToken(): string
declare var grecaptcha: any

export const usePiniaUIConfig = defineStore('UIConfig', {
  state: newUIConfig,
  actions: {
    async fetchUIConfig() {
      try {
        if (uiConfigPresetJSON !== undefined) {
          const uiconfig = JSON.parse(uiConfigPresetJSON ?? '{}') as UIConfig
          if (uiconfig.env) {
            return uiconfig
          }
        }
      } catch (error) {
        // console.log('no uiConfigPresetJSON')
      }
      try {
        const response = await httpClient.get<undefined, AxiosResponse<UIConfig>>(`/api/getUiConfig`)
        if (response.status === 200) {
          this.$state = response.data

          // console.log(TSON.assert<UIConfig>(this.$state))

          return response.data
        } else {
          throw new Error(`HTTP status ${response.status}: ${response.statusText}`)
        }
      } catch (error) {
        logger.error(`fetchUIConfig pinia failed with error: ${error}`)
        return undefined
      }
    }
  }
})

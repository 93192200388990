import { getConfigItem } from '@/components/shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { PaymentConfig } from '@adg/catalog/src/common/UIConfig'
import { computed } from '@vue/composition-api'
import { PaymentDisposition, ADGPaymentInitializerResponse } from '@adg/catalog/src/common/Payments'
import $store from '@/store'
import useCart from '../cart/useCart'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { logger } from '@/utils/RemoteLogger'

const paymentConfig = computed((): PaymentConfig => getConfigItem(ConfigKeys.paymentConfig))
const { grandTotal, monthlyTotal, downPaymentTotal, recurringTotal } = useCart($store)
const shopper = computed((): IShopper => $store.getters.getShopper)

/*
This addDispositionListener is responsible for listening to the postMessage from the iframe, calling the appropriate callback,
and then calling the finializePayment action. This may need to happen in a different order, such as finalizing payment THEN calling onSuccess, in 
the event of other payment integrations. A new addDispositionListener function will need to be created for those integrations.
*/
interface ReturnEvent {
  type: string
  data: PaymentDisposition
}

export const addDispositionInfo = (disposition: PaymentDisposition): PaymentDisposition => {
  const contactInfo = shopper.value.customInfo.billingContactInfo
  if (!disposition.paymentContact) disposition.paymentContact = {}
  if (contactInfo?.firstName?.length) disposition.paymentContact.firstName = contactInfo.firstName
  if (contactInfo?.lastName?.length) disposition.paymentContact.lastName = contactInfo.lastName
  if (contactInfo?.email?.length) disposition.paymentContact.email = contactInfo.email
  if (contactInfo?.phone?.length) disposition.paymentContact.phone = contactInfo.phone
  if (!disposition.paymentContact.address) disposition.paymentContact.address = {}
  if (contactInfo?.address?.addressLine?.length) disposition.paymentContact.address.addressLine = contactInfo.address.addressLine
  if (contactInfo?.address?.city?.length) disposition.paymentContact.address.city = contactInfo.address.city
  if (contactInfo?.address?.state?.length) disposition.paymentContact.address.state = contactInfo.address.state
  if (contactInfo?.address?.zip?.length) disposition.paymentContact.address.zip = contactInfo.address.zip
  return disposition
}

let addDispositionListenerCount = 0
export const addDispositionListener = (
  onFinished: Function,
  onSuccess?: Function,
  onError?: Function,
  onClose?: Function,
  onPending?: Function
) => {
  async function handleDisposition(e: { data: ReturnEvent }) {
    const event: ReturnEvent = e.data
    if (event.type === 'close-iframe' && typeof onClose === 'function') {
      onClose()
    }

    if (event.type !== 'disposition') {
      return
    }
    logger.info(`Received disposition from iframe: `, event.data)
    let disposition = event.data
    if (disposition.status === 'success' && typeof onSuccess === 'function') {
      await onSuccess(disposition)
    }
    if (disposition.status === 'pending' && typeof onPending === 'function') {
      await onPending(disposition)
    }
    if (disposition.status === 'error' && typeof onError === 'function') {
      await onError(disposition)
    }

    if (disposition.done) {
      try {
        //TODO collect disposition data from outside iframe (billingAddress, ccFirstName, ccLastName)
        //disposition.
        disposition = await $store.dispatch('finalizePayment', addDispositionInfo(disposition))
      } catch (err) {
        logger.error(`Error finalizing payment: `, err)
        disposition.status = 'error'
        disposition.errors ??= []
        disposition.errors.push({ code: 'FINALIZE_ERROR', message: `Error finalizing payment: ${err}}` })
      } finally {
        if (disposition.status === 'error' && typeof onError === 'function') {
          if (disposition.cancelAutomation) {
            await onFinished(disposition)
          } else {
            await onError(disposition)
          }
        } else if (disposition.status === 'success' && typeof onFinished === 'function') {
          onFinished(disposition)
        }
      }
    }
  }
  if (!addDispositionListenerCount) {
    window.addEventListener('message', handleDisposition, false)
    addDispositionListenerCount += 1
  }
}

export const iFrameSrcDoc = computed(() => {
  const defaultIframe = paymentConfig.value?.payIframeHtml
  let iframe: string | undefined
  const iframeParams: ADGPaymentInitializerResponse | null = $store.getters.getPaymentInitializer

  //if a iframe override is set in uiConfig, use that instead of the integration one
  if (defaultIframe) {
    iframe = defaultIframe
  }

  if (iframeParams?.srcdoc) {
    iframe = iframeParams.srcdoc
  }
  let retval =
    iframe?.replace(`<<<AMOUNT>>>`, grandTotal.value.toFixed(2)).replace(`<<<MONTHLY_TOTAL>>>`, monthlyTotal.value.toFixed(2)) ?? ''
  if (paymentConfig.value.customFields?.length) {
    paymentConfig.value.customFields.forEach((field) => {
      const val = $store.getters.getAccountField(field.shopperVal) ?? field.default ?? ''
      retval = retval.replace(`${field.replaceVariable}`, val)
    })
  }
  return retval
})

export const iFrameSrc = computed((): string | undefined => {
  return $store.getters.getPaymentInitializer?.src ?? undefined
})

export const insertPrices = (str: string): string =>
  str
    .replace(`<<<MONTHLY_TOTAL>>>`, monthlyTotal.value.toFixed(2))
    .replace(`<<<AMOUNT>>>`, grandTotal.value.toFixed(2))
    .replace(`<<<DOWNPAYMENT>>>`, downPaymentTotal.value.toFixed(2))
    .replace(`<<<RECURRING_TOTAL>>>`, recurringTotal.value.toFixed(2))

export const serviceAddress = computed(
  (): string | undefined => shopper.value.punctuatedAddress ?? shopper.value.formattedAddress ?? shopper.value.place?.full
)

export const paymentModalButtonText = computed(() => {
  const both = paymentConfig.value.bothPaymentsModalText
  const down = paymentConfig.value.downPaymentModalText
  const recurring = paymentConfig.value.recurringPaymentModalText
  if (downPaymentTotal.value > 0 && recurringTotal.value > 0 && both) return both
  if (downPaymentTotal.value > 0 && down) return down
  if (recurringTotal.value > 0 && recurring) return recurring
  else {
    return paymentConfig.value.paymentModalButtonText ?? paymentConfig.value.paymentButtonText ?? 'Pay Now'
  }
})

export const secondPaymentButtonText = computed(() => {
  return paymentConfig.value.secondPaymentButtonText
})

export const paymentText = computed(() => {
  if (downPaymentTotal.value > 0 && recurringTotal.value > 0) {
    return paymentConfig.value.bothPaymentsText ?? paymentConfig.value.aboveIframeHtml ?? ''
  }
  if (downPaymentTotal.value > 0) {
    return paymentConfig.value.downPaymentText ?? paymentConfig.value.aboveIframeHtml ?? ''
  }
  if (recurringTotal.value > 0) {
    return paymentConfig.value.recurringPaymentText ?? paymentConfig.value.aboveIframeHtml ?? ''
  } else {
    return paymentConfig.value.aboveIframeHtml ?? ''
  }
})
export const payButtonText = computed(() => {
  if (downPaymentTotal.value > 0 && recurringTotal.value > 0) {
    return paymentConfig.value.bothPaymentsButtonText
  }
  if (downPaymentTotal.value > 0) {
    return paymentConfig.value.downPaymentButtonText
  }
  if (recurringTotal.value > 0) {
    return paymentConfig.value.recurringPaymentButtonText
  } else {
    return 'Error, no payment amount'
  }
})
export const paymentSuccessText = computed(() => {
  if (paymentConfig.value.paymentApprovedText) {
    return paymentConfig.value.paymentApprovedText
  }
  if (downPaymentTotal.value > 0 && recurringTotal.value > 0) {
    return paymentConfig.value.bothPaymentsSuccessText
  }
  if (downPaymentTotal.value > 0) {
    return paymentConfig.value.downPaymentSuccessText
  }
  if (recurringTotal.value > 0) {
    return paymentConfig.value.recurringPaymentSuccessText
  } else {
    return 'Error, no payment amount'
  }
})

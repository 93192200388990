
<div v-if="notAllExcluded">
  <v-card class="rounded" tile>
    <CardHeader
      :render-me="renderMe"
      :title="streamingServicesHeaderText"
      :image="streamingServicesHeaderImage"
      :componentOrder="componentOrder"
    ></CardHeader>
    <v-container>
      <v-row>
        <v-col
          v-if="!streamingService.excluded"
          id="streaming-services-card"
          :cols="streamingServiceCols"
          v-for="(streamingService, i) in streamingServices"
          :key="'service' + i"
        >
          <StreamingServicesCard
            :sharpCorners="sharpCorners"
            @change="upgradeChanged"
            v-model="streamingServiceSelections[streamingService.Name]"
            :streamingService="streamingService"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</div>

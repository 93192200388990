<template>
  <div>
    <StepperButtons :forwardAction="performAgnosticCreditCheck" />
    <v-overlay :opacity="0.5" v-if="showModal" z-index="6" height="100vh" class="customOverlay">
      <v-dialog v-model="showModal" @close="showModal = false" max-width="550">
        <v-card tile class="grey lighten-4 rounded-lg rounded-tr-0 rounded-tl-0 pt-0">
          <CreditCheckModal v-if="showModal === 'modal'" @closeModal="closeModal" @closeAndStepForward="closeAndStepForward" />
          <v-container v-else>
            <v-row justify="end" class="closeButton">
              <v-col cols="1">
                <v-icon @click="closeModal">{{ mdiClose }}</v-icon>
              </v-col>
            </v-row>
            <v-row class="pl-3">
              <span class="headline primary--text"> Consent to Soft Credit Check </span>
            </v-row>
            <v-radio-group v-model="acceptCreditCheck">
              <div style="display: flex">
                <v-radio :label="creditText.agree" value="agree"> </v-radio
                ><Tooltip
                  :payload="{
                    Name: `Credit Check`
                  }"
                />
              </div>
              <div v-if="acceptCreditCheck === 'agree'">
                <v-row>
                  <v-col sm="6">
                    <v-text-field solo label="Last 4 of your SSN" v-model="ssn" class="pl-8" v-mask="ssnMask"></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-radio :label="creditText.disagree" value="disagree"> </v-radio>
            </v-radio-group>
            <v-row class="pa-3">
              <v-btn @click="closeModalAndAgnosticCheck" block color="primary" :disabled="continueDisabled">Continue</v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>
<script lang="ts">
import { computed, ComputedRef, defineComponent, Ref, ref } from '@vue/composition-api'
import { getConfigBoolean, getConfigItem } from '../getConfigItem'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import StepperButtons from '@/components/order/StepperButtons.vue'
import { bus } from '@/main'
import { mdiClose } from '@mdi/js'
import creditCheck from './useCreditCheck'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import { CreditCheckResponse, CreditCheckType } from '@adg/catalog/src/common/CreditChecks'
import useAutomationState from '@/store/useAutomationState'
import { GET_MATCHED_ADDRESS } from '@/store/types'
import { MatchType } from '@adg/catalog/src/common/MatchType'
import CreditCheckModal from './CreditCheckModal.vue'
import ga4 from '@/gtm/ga4'

export default defineComponent({
  name: 'CreditCheck',
  components: {
    Tooltip,
    StepperButtons,
    CreditCheckModal
  },
  setup(props, { emit, refs }) {
    const ssn = computed({
      get: () => $store.getters.getShopper.customInfo.ssn,
      set: (val) => {
        $store.commit('setSsn', val)
        usePiniaShopper().shopper.customInfo.ssn = val
      }
    })

    const ssnMask = {
      mask: '####',
      tokens: {
        '#': { pattern: /\d/ }
      }
    }
    const { agnosticCreditCheck, shouldCreditCheck } = creditCheck()
    const latestCreditCheck = computed((): CreditCheckResponse | undefined => $store.getters.getLatestCreditCheck)
    const firstCreditCheck = computed((): CreditCheckResponse | undefined => $store.getters.getFirstCreditCheck)
    const acceptCreditCheck = ref('')
    const showModal: Ref<boolean | string> = ref(false)
    const openModal = (val?: boolean | string) => {
      bus.$emit('creditCheckModalDisplayed')
      ga4.pushPageView('/creditCheck')
      showModal.value = val ?? true
    }

    const isStudent = computed(() => $store.getters.getStudent)
    // const latestCreditCheck = computed(() => $store.getters.getLatestCreditCheck)

    const closeModal = (): void => {
      showModal.value = false
    }

    const closeAndStepForward = (): void => {
      closeModal()
      bus.$emit('stepForward')
    }

    // const handleCreditCheckResults = (result: boolean) => {
    //   if (result || isStudent.value) {
    //     closeAndStepForward()
    //   } else {
    //     openModal()
    //   }
    // }
    // const performPreCreditCheck = () => {
    //   bus.$emit('preCreditCheck')
    //   agnosticCreditCheck(closeAndStepForward, openModal, 'pre')
    //   // softCreditCheck(handleCreditCheckResults) //only show modal for non-students
    // }

    const closeModalAndAgnosticCheck = (): void => {
      if (acceptCreditCheck.value === 'agree' && latestCreditCheck.value?.next && shouldCreditCheck(latestCreditCheck.value)) {
        closeModal()
        agnosticCreditCheck(closeAndStepForward, closeAndStepForward, latestCreditCheck.value)
      } else {
        closeAndStepForward()
      }
    }

    const performAgnosticCreditCheck = (): void => {
      if (firstCreditCheck.value?.next && shouldCreditCheck(firstCreditCheck.value)) {
        agnosticCreditCheck(closeAndStepForward, openModal, firstCreditCheck.value)
      } else {
        closeAndStepForward()
      }
    }

    const creditText = computed(
      () =>
        getConfigItem(ConfigKeys.creditCheckConsentText) ?? {
          agree: 'Yes, I will provide the last 4 of my SSN to perform a soft credit check',
          disagree: 'No, I prefer to prepay for services before completing my order'
        }
    )

    const tooltipText = computed(() => {
      return {
        Name: getConfigItem(ConfigKeys.creditCheckTooltip) ?? 'This is a tooltip'
      }
    })

    const continueDisabled = computed(
      () => acceptCreditCheck.value === '' || (acceptCreditCheck.value === 'agree' && ssn.value === '')
    )

    return {
      ssn,
      acceptCreditCheck,
      creditText,
      tooltipText,
      openModal,
      closeModal,
      showModal,
      closeAndStepForward,
      mdiClose,
      continueDisabled,
      ssnMask,
      closeModalAndAgnosticCheck,
      performAgnosticCreditCheck
    }
  }
})
</script>
<style>
.closeButton {
  width: 100%;
  margin: 0;
}
</style>

<template>
  <v-footer padless dark class="hidden-print-only app-footer">
    <v-container id="footer-container">
      <v-row justify="center" no-gutters class="py-0 my-0">
        <v-btn
          v-for="link in links"
          :key="link.name"
          :href="link.link"
          target="_blank"
          text
          id="footer-button"
          class="py-0 my-0 footer-link text-none font-weight-thin"
        >
          {{ link.name }}
        </v-btn>
      </v-row>
      <v-row v-if="socialMediaImages" justify="center" no-gutters class="py-0 my-0">
        <v-btn
          v-for="image in socialMediaImages"
          :key="image.name"
          :href="image.link"
          target="_blank"
          text
          :id="image.name + '-button'"
          class="py-0 my-0 footer-link text-none font-weight-thin"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path :d="image.svgPath" :fill="image.color" /></svg>
        </v-btn>
      </v-row>
      <v-row class="footer-row2">
        <v-col class="footer-link2 py-4 text-center">
          &copy;{{ new Date().getFullYear() }} — {{ uiConfig.companyName }}. All rights reserved.
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script lang="ts">
import { computed, defineComponent, watchEffect } from '@vue/composition-api'
import useFooter from './useFooter'
import $store from '@/store'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import useOrder from './order/useOrder'

export default defineComponent({
  setup(props, context) {
    const {
      root: { $router, $route }
    } = context

    const { currentStep } = useOrder($store, $router)

    const { links, uiConfig, footerColor, footerTopText, socialMediaImages } = useFooter($store)

    const year = new Date().getFullYear()

    return { links, year, uiConfig, footerColor, footerTopText, socialMediaImages, currentStep }
  }
})
</script>

<style>
.card-bottom-corners {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.withSystemBar {
  padding: 40px;
}
.noSystemBar {
  padding-top: 10px;
}

#footer-container {
  padding: 0rem;
  /*background: var(--v-secondary-base);*/
}
</style>


<v-container>
  <v-row justify="end" class="closeButton">
    <v-col v-if="!creditModalClose" cols="1">
      <v-icon @click="closeModal">{{ mdiClose }}</v-icon>
    </v-col>
  </v-row>
  <v-row class="pl-3 credit-title-container">
    <span class="headline primary--text" v-html="creditModalTitle" />
  </v-row>
  <v-row class="pa-3 credit-text-container"> <div v-html="creditModalText" /> </v-row>

  <v-row class="pa-3">
    <v-col>
      <v-btn class="credit-continue-btn" @click="closeAndStepForward" block color="primary" v-html="creditModalContinue"></v-btn>
    </v-col>
    <v-col v-if="creditModalClose">
      <v-btn class="credit-close-btn" @click="closeModal" block color="primary" v-html="creditModalClose"></v-btn>
    </v-col>
  </v-row>
</v-container>


<!-- <v-container style="max-width: 1200px" class="px-sm-12">
  <v-row>
    <v-col md="7" cols="12" class="py-0 px-5"> -->
<v-card tile class="rounded-lg rounded-tr-0 rounded-tl-0" width="700">
  <v-toolbar id="account-header" flat height="40" dark :color="scheduleInfoHeaderColor">
    <v-toolbar-title id="installation-options-header-text">
      <v-icon v-if="showInstallationOptionsWrenchIcon" style="padding-right: 6px" :color="wrenchIconColor">
        {{ mdiWrenchOutline }}
      </v-icon>
      <span id="installation-header-text" v-html="uiMacroParser(shopper, installationOptionsHeaderText)"></span>
    </v-toolbar-title>
  </v-toolbar>
  <v-container fluid>
    <v-row>
      <v-col class="headline primary--text" id="installation-options-address" v-html="address"></v-col>
    </v-row>
    <v-divider></v-divider>
    <CustomSchedulingText />
    <div>
      <PayContainer />
    </div>
  </v-container>
</v-card>
<!-- </v-col>
    <v-col md="5" cols="12" class="py-0 hidden-sm-and-down">
      <Cart />
    </v-col>
  </v-row>
</v-container> -->

export default () => {
  // This function will set an event listener and replace IMG tags when the browser does not support VIDEO in IMG tags
  // Safari is optimized to use video files (MP4) with images, but other browser do not support
  const imageTagToVideo = (height?: string, width?: string) => {
    let videoAttr = {
      autoplay: true,
      loop: false,
      mute: true,
      disableRemotePlayback: true,
      playsinline: true
    }
    let imgMP4s = Array.prototype.map.call(document.querySelectorAll('img[src*=".mp4"]'), function (img) {
      let src = img.src
      img.src = null

      img.addEventListener('error', function (e) {
        let video = document.createElement('video')

        for (let key in videoAttr) {
          video.setAttribute(key, videoAttr[key])
        }
        for (let imgAttr = img.attributes, len = imgAttr.length, i = 0; i < len; i++) {
          video.setAttribute(imgAttr[i].name, imgAttr[i].value)
        }
        if (height) video.setAttribute('height', height)
        if (width) video.setAttribute('width', width)

        if (img.parentNode !== null) {
          img.parentNode.insertBefore(video, img)
          img.parentNode.removeChild(img)
        }
      })

      img.src = src
    })
  }

  return {
    imageTagToVideo
  }
}

<template>
  <div>
    <v-row v-show="editPaymentAmount" justify="center">
      <v-col class="col-md-6 col-xs-12">
        <v-text-field v-model="payAmount" label="Payment Amount" id="payment_amount"></v-text-field>
      </v-col>
    </v-row>
    <StepperButtons :forwardAction="openModal" buttonText="Pay Now" key="convergeStepper" :disabled="disabled" />
  </div>
</template>

<script lang="ts">
import { bodyScript } from '@/utils/SourceLoader'
import { ref, defineComponent, computed, watch } from '@vue/composition-api'
import StepperButtons from '@/components/order/StepperButtons.vue'
import axios from 'axios'
import { bus } from '@/main'
import useCart from '@/components/order/cart/useCart'
import { ConvergeResponse, PaymentInfo, TokenRequest } from '@adg/catalog/src/modules/Converge/converge'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import creditCheck from '@/components/shared/creditCheck/useCreditCheck'
import { getConfigBoolean, getConfigString } from '@/components/shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import useAutomationState from '@/store/useAutomationState'
import $store from '@/store'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import { logger } from '@/utils/RemoteLogger'
declare var PayWithConverge: undefined

export default defineComponent({
  name: 'ConvergeLightbox',
  components: {
    StepperButtons
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { mustPrePay } = creditCheck()

    const showModal = ref(false)
    const openModal = async () => {
      bus.$emit('paymentModalDisplayed')
      await getToken()
      await openLightbox()
    }

    const { grandTotal } = useCart($store)

    const closeModal = () => (showModal.value = false)

    const unused = ref('')
    const token = ref('')
    const title = 'Payment'
    const shopper = computed((): IShopper => $store.getters.getShopper)
    const payAmount = ref('')
    const jqueryLoaded = ref(false)
    const payWithConvergeLoaded = ref(false)
    const loadLightBoxExecuted = ref(false)

    watch(grandTotal, (grand, prev) => {
      payAmount.value = grand.toFixed(2)
    })

    const editPaymentAmount = computed(() => getConfigBoolean(ConfigKeys.editPaymentAmount))

    const tspace = (s: string | undefined | null) => (s ? `${s} ` : '')

    const lightboxUrl = computed(() => getConfigString(ConfigKeys.convergeLightBoxUrl))

    watch(lightboxUrl, (newLBU, prevLBU) => {
      if (newLBU && !loadLightBoxExecuted.value) {
        loadLightBox(newLBU)
      }
    })

    const tokenRequest = computed((): TokenRequest => {
      const addr = shopper.value.matchedAddress
      const streetAddr = addr ? tspace(addr.streetNum) + tspace(addr.prefix) + tspace(addr.street) + addr.streetType || '' : ''
      const zip = addr?.zip || ''

      let ssl_avs_address = ''
      let ssl_avs_zip = ''
      if (shopper.value.place?.street_line && shopper.value.place?.zipcode) {
        ssl_avs_address = shopper.value.place?.street_line ?? ''
        ssl_avs_zip = shopper.value.place?.zipcode ?? ''
      } else if (streetAddr && zip) {
        ssl_avs_address = streetAddr
        ssl_avs_zip = zip
      }

      return {
        ssl_avs_address,
        ssl_avs_zip,
        ssl_description:
          'First month payment' +
          (shopper.value.externalAccountNumber ? ` - Account # ${shopper.value.externalAccountNumber}` : ''),
        ssl_first_name: shopper.value.firstName ?? '',
        ssl_last_name: shopper.value.lastName ?? '',
        sub_id: shopper.value.externalAccountNumber ?? '',
        ssl_amount: !editPaymentAmount.value
          ? grandTotal.value.toFixed(2)
          : payAmount.value === ''
          ? grandTotal.value.toFixed(2)
          : payAmount.value,
        ssl_transaction_type: 'CCSALE',
        ssl_merchant_txn_id: shopper.value.id,
        ssl_get_token: 'Y',
        ssl_add_token: 'Y'
      }
    })
    const getToken = async () => {
      token.value = await (await axios.post('/api/converge/getToken', tokenRequest.value)).data
    }

    const openLightbox = async () => {
      // if (payWithConvergeLoaded.value === false || jqueryLoaded.value === false) {
      //   $store.commit('setPaymentInfo', {
      //     tokenRequest: tokenRequest.value,
      //     token: token.value,
      //     status: 'error',
      //     error: 'Unable to load Converge Dialog dependencies'
      //   } as PaymentInfo)
      //   bus.$emit('submitOrder')
      //   return false
      // }
      var paymentFields = {
        ssl_txn_auth_token: token.value
      }
      const { cancelAutomation } = useAutomationState($store)
      // const piniaShopper = usePiniaShopper().shopper

      var callback = {
        onError: function (error: unknown) {
          const res: PaymentInfo = {
            tokenRequest: tokenRequest.value,
            token: token.value,
            status: 'error',
            error
          }
          //console.log('error', error)
          logger.error('Converge Error', error)
          cancelAutomation(`Payment Error: ${error}`)
          $store.commit('setPaymentInfo', res)
          // piniaShopper.customInfo.paymentInfo = res
          bus.$emit('submitOrder')
        },
        onCancelled: function () {
          const res: PaymentInfo = {
            tokenRequest: tokenRequest.value,
            token: token.value,
            status: 'cancelled'
          }
          $store.commit('setPaymentInfo', res)
          // piniaShopper.customInfo.paymentInfo = res

          // Do not submit Order if cancelled (by user)
        },
        onDeclined: function (response: ConvergeResponse) {
          const res: PaymentInfo = {
            tokenRequest: tokenRequest.value,
            token: token.value,
            convergeResponse: response,
            status: 'declined'
          }
          //console.log('declined', JSON.stringify(response, null, '\t'))
          cancelAutomation(`Payment Declined`)

          $store.commit('setPaymentInfo', res)
          // piniaShopper.customInfo.paymentInfo = res

          bus.$emit('submitOrder')
        },
        onApproval: function (response: ConvergeResponse) {
          const res: PaymentInfo = {
            tokenRequest: tokenRequest.value,
            token: token.value,
            convergeResponse: response,
            status: 'approved'
          }
          //console.log('approval', JSON.stringify(response, null, '\t'))
          $store.commit('setPaymentInfo', res)
          // piniaShopper.customInfo.paymentInfo = res

          bus.$emit('submitOrder')
        }
      }

      // the weird way of calling and using PayWithConverge is because it is a dynamically created global created by the
      // PayWithConverge.js loaded dynamically below.  I don't know a better Typescipty way to declare an use it KWC
      try {
        try {
          ;(PayWithConverge as any).open(paymentFields, callback)
        } catch (e) {
          // try to load/reload and wait for 3 seconds ... and pray, otherwise the outer try/catch will catch it
          if ((typeof PayWithConverge !== 'function' && lightboxUrl.value) || (!loadLightBoxExecuted.value && lightboxUrl.value)) {
            logger.warn('Converge Error', 'Unable to load Converge Dialog dependencies - trying to reload')
            loadLightBox(lightboxUrl.value)
          }
          await new Promise((resolve) => setTimeout(resolve, 4000))
          ;(PayWithConverge as any).open(paymentFields, callback)
        }
      } catch (e) {
        const errstr = `Payment Failure: Unable to load Converge Dialog dependencies jqueryLoaded=${jqueryLoaded.value},payWithConvergeLoaded=${payWithConvergeLoaded.value}: ${e}`
        const res: PaymentInfo = {
          tokenRequest: tokenRequest.value,
          token: token.value,
          status: 'error',
          error: errstr
        }
        logger.error(errstr)
        cancelAutomation(errstr)
        $store.commit('setPaymentInfo', res)
        // piniaShopper.customInfo.paymentInfo = res

        bus.$emit('submitOrder')
      }
      return false
    }

    const loadLightBox = (lbu: string) => {
      //console.log(`ConvergeLightbox: loading lightbox ${lbu}`)
      bodyScript('https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js', () => (jqueryLoaded.value = true))
      bodyScript(lbu, () => (payWithConvergeLoaded.value = true))
      loadLightBoxExecuted.value = true
    }

    return {
      title,
      tokenRequest,
      token,
      getToken,
      openLightbox,
      unused,
      showModal,
      openModal,
      closeModal,
      payAmount,
      editPaymentAmount,
      grandTotal,
      mustPrePay
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

import { API_CONTENT_ROOT } from '@/store/types'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { computed } from '@vue/composition-api'

export default () => {
  const getTermsOfServiceDocument = (termsOfService: TermOfService[], packages): { text: string; link: string } => {
    let text: string = termsOfService[0]?.text ?? 'Terms of Service'
    const doc = getConfigItem(ConfigKeys.termsOfServiceDoc)
    let link = getConfigItem(ConfigKeys.termsOfServiceLink) ?? termsOfService[0]?.link //use ui config if not defined in catalog
    if (doc) {
      link = `${API_CONTENT_ROOT}/${doc}`
    }
    if (link === '') text = ''
    return { text, link }
  }
  const termsOfServiceDocument = computed((): { text: string; link: string } => {
    let termsOfService = getConfigItem(ConfigKeys.termsOfService)
    let text = termsOfService?.[0]?.text ?? 'Terms of Service'
    const doc = getConfigItem(ConfigKeys.termsOfServiceDoc)
    let link = getConfigItem(ConfigKeys.termsOfServiceLink) ?? termsOfService?.[0]?.link //use ui config if not defined in catalog
    if (doc) {
      link = `${API_CONTENT_ROOT}/${doc}`
    }
    if (link === '') text = ''
    return { text, link }
  })

  return {
    termsOfServiceDocument,
    getTermsOfServiceDocument
  }
}


<div @click="handleShortenClick()">
  <span v-if="expanded === 'short'">{{ shorten(shortenValue) }}</span>
  <div v-if="expanded === 'split'">
    <div v-for="(piece, i) in shortenValue.split('|')">
      <span v-if="i" style="color: red">| </span><span> {{ piece }} </span>
    </div>
  </div>
  <p v-if="expanded === 'full'">{{ shortenValue }}</p>
</div>

<template>
  <v-container>
    <v-row justify="end" class="closeButton">
      <v-col v-if="!creditModalClose" cols="1">
        <v-icon @click="closeModal">{{ mdiClose }}</v-icon>
      </v-col>
    </v-row>
    <v-row class="pl-3 credit-title-container">
      <span class="headline primary--text" v-html="creditModalTitle" />
    </v-row>
    <v-row class="pa-3 credit-text-container"> <div v-html="creditModalText" /> </v-row>

    <v-row class="pa-3">
      <v-col>
        <v-btn class="credit-continue-btn" @click="closeAndStepForward" block color="primary" v-html="creditModalContinue"></v-btn>
      </v-col>
      <v-col v-if="creditModalClose">
        <v-btn class="credit-close-btn" @click="closeModal" block color="primary" v-html="creditModalClose"></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, ref } from '@vue/composition-api'
import { getConfigBoolean, getConfigItem } from '../getConfigItem'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import { mdiClose } from '@mdi/js'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'
import { CreditCheckResponse, CreditCheckType } from '@adg/catalog/src/common/CreditChecks'
export default defineComponent({
  name: 'CreditCheckModal',
  components: {
    Tooltip
  },
  props: {},
  setup(props, { emit, refs }) {
    const ssnMask = {
      mask: '####',
      tokens: {
        '#': { pattern: /\d/ }
      }
    }
    const latestCreditCheck = computed((): CreditCheckResponse | undefined => $store.getters.getLatestCreditCheck)
    const firstCreditCheck = computed((): CreditCheckResponse | undefined => $store.getters.getFirstCreditCheck)
    const creditModalTitle = computed(() => getConfigItem(ConfigKeys.creditModalTitle) ?? 'Accept to Continue')
    const creditModalClose = computed(() => getConfigItem(ConfigKeys.creditModalClose) ?? 'No')
    const creditModalText = computed(
      () =>
        getConfigItem(ConfigKeys.creditModalText) ??
        '<p>SubscriberWise lookup failed, payment required. Would you like to continue?</p>'
    )
    const creditModalContinue = computed(() => getConfigItem(ConfigKeys.creditModalContinue) ?? 'Yes')
    const acceptCreditCheck = ref('')

    const isStudent = computed(() => $store.getters.getStudent)
    // const latestCreditCheck = computed(() => $store.getters.getLatestCreditCheck)

    const closeModal = () => emit('closeModal')

    const closeAndStepForward = () => emit('closeAndStepForward')

    return {
      acceptCreditCheck,
      closeModal,
      closeAndStepForward,
      mdiClose,
      creditModalContinue,
      creditModalClose,
      creditModalText,
      creditModalTitle
    }
  }
})
</script>
<style>
.closeButton {
  width: 100%;
  margin: 0;
}
.credit-title-container {
  justify-content: center;
}
.credit-text-container {
  text-align: center;
}
</style>

<template>
  <v-card>
    <v-row>
      <v-dialog v-model="showDialog" width="500" overlay-opacity=".9">
        <v-icon dark @click="closeDialog">{{ mdiClose }}</v-icon>
        <v-carousel height="800">
          <v-carousel-item
            dark
            reverse-transition="fade-transition"
            transition="fade-transition"
            v-for="(test, idx) in images"
            :key="idx"
          >
            <v-container>
              <v-row class="text-center">
                <v-col>
                  <video style="object-fit: contain" :src="test" playsinline autoplay muted height="600" width="300"></video>
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'

//TODO: is this file still being used and does it need to be fixed up?

export default defineComponent({
  name: 'ImageCarousel',
  props: {
    images: Array,
    dialog: Boolean
  },
  setup(props, { emit }) {
    const show = ref(false)
    const closeDialog = () => emit('dialogClosed')

    const showDialog = computed({
      get: () => props.dialog,
      set: (val) => {
        if (val) show.value = val
      }
    })

    return {
      showDialog,
      closeDialog,
      mdiClose
    }
  }
})
</script>

<style scoped></style>

<template>
  <v-container fluid class="pv-2">
    <v-row v-if="message" class="pt-4 text-center grey--text">
      <v-col class="headline"><span v-html="message" /> </v-col>
    </v-row>
    <v-row v-else class="text-center grey--text">
      <v-col class="headline">Sorry ... we currently do not offer service that address. Please try again!</v-col>
    </v-row>
    <v-row justify="center">
      <v-img :src="image" max-width="300px"> </v-img>
    </v-row>
    <v-row justify="center">
      <v-btn class="mr-3" :class="defaultButtonColors()" color="primary" @click="stepOne">Back</v-btn>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, computed } from '@vue/composition-api'
import useButtonColors from '@/components/shared/useButtonColors'
import $store from '@/store'

export default defineComponent({
  name: 'NoService',
  components: {},
  setup(props, { root }) {
    const { $router } = root as any

    const noserviceConfig = computed(() => $store.getters.getUIConfig.buyFlow.find((c) => c.name === 'noservice'))
    const message = computed(() => noserviceConfig.value?.message1?.join(''))
    const image = computed(() => (noserviceConfig.value ? `/api/content/${noserviceConfig.value.image}` : ''))

    const stepOne = () => $router.push('/order/1')
    //const stepOne = () => bus.$emit('stepOne')

    const print = () => window.print()

    const { defaultButtonColors } = useButtonColors()

    return {
      defaultButtonColors,
      stepOne,
      message,
      image
    }
  }
})
</script>

<style></style>

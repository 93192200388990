
<v-card class="mx-auto product-picker py-0 my-0" max-width="500" :outlined="outlined" :flat="flat">
  <v-container fluid class="pa-0 ma-0">
    <v-row no-gutters>
      <v-col class="black--text" cols="12">
        <v-tooltip right class="" color="secondaryDark">
          <template v-slot:activator="{ on }">
            <span v-html="uiMacroParser(shopper, itemDisplayName(product))"></span>
            <span v-if="thumbLabel === false" class="pl-3 font-weight-bold secondaryDark--text">({{ model }}) </span>
            <span v-if="product.Tooltip" v-on="on"
              ><v-btn x-small icon
                ><v-icon class="secondaryDark--text text--lighten-2">{{ mdiInformation }}</v-icon></v-btn
              >
            </span>
          </template>
          {{ product.Tooltip }}
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row v-if="subtitle">
      <v-col class="pb-4 caption">
        <span v-html="subtitle"></span>
      </v-col>
    </v-row>
  </v-container>
  <!--      <v-list-item>-->
  <v-slider
    class="ma-0"
    :class="getCatalogItemClassName(product.Name, product.itemType)"
    v-model="model"
    color="secondary"
    track-color="grey"
    always-dirty
    :min="min"
    :max="max"
    @change="changed"
    thumb-size="20"
    :thumb-label="thumbLabel"
    ticks
    style="max-width: 275px"
  >
    <template v-slot:prepend>
      <v-icon color="secondaryDark" @click="decrement">
        {{ mdiMinus }}
      </v-icon>
    </template>

    <template v-slot:append>
      <v-icon color="secondaryDark" @click="increment">
        {{ mdiPlus }}
      </v-icon>
    </template>
  </v-slider>
  <!--      </v-list-item>-->
</v-card>

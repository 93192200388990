
<div>
  <v-menu min-width="450px" class="tagmenu" :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <div v-on="on" icon>
        <span class="header-address justify-end text-center hidden-xs-only" v-if="address != ''" v-html="address"></span>
      </div>
    </template>

    <div v-if="isDebugMode" class="tag-toggle">
      <v-switch v-model="showAllTags" class="mt-0" label="Show All Tag Maps" color="red" />
    </div>
    <v-list style="max-height: 90vh" class="overflow-y-auto pl-2">
      <NonExpandableLine
        v-for="([key, value], i) in Object.entries(tags).sort()"
        :name="key"
        :value="value"
        :key="`${key}-${i}`"
        :showAllTags="showAllTags"
      />
    </v-list>
  </v-menu>
</div>

import { IShopper, shopperVersion } from '@adg/catalog/src/modules/Shopper'
import { catalogVersionNumber } from '@adg/catalog/src/modules/Catalog'
import { logger } from '@/utils/RemoteLogger'
import { isParseError, macroParser } from '@adg/catalog/src/util/parser/macroParser'
import { getElementAsString } from '@adg/catalog/src/common/utils'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { computed } from '@vue/composition-api'
import { formatPhoneNumber } from '@adg/catalog/src/common/PhoneNumber'

export function isCompatibleShopper(shopper: Partial<IShopper>): boolean {
  // console.log(shopper)
  if (shopper.catalog?.version !== catalogVersionNumber) return false
  if (shopper.version && shopper.version !== shopperVersion) return false
  return true
}

interface Dest {
  [key: string]: any
}

export const setShopperElement = (dest: Dest, field: string, value: any): void => {
  const fields = field.split('.')
  if (fields.length === 1) {
    dest[fields[0]] = value
  } else {
    if (typeof dest[fields[0]] !== 'object') dest[fields[0]] = {}
    setShopperElement(dest[fields[0]], fields.slice(1).join('.'), value)
  }
}

export const uiMacroParser = (shopper: IShopper, input: string, overrideGlobalParsing: boolean = false): string => {
  const useMacroParser = computed(() => getConfigItem(ConfigKeys.useMacroParser) ?? false)
  const validClasses = computed(() => getConfigItem(ConfigKeys.uiValidClasses) ?? [])
  if (!input) return ''
  else if (useMacroParser.value === false && !overrideGlobalParsing) return input
  try {
    const retString = macroParser(
      input,
      {
        shopper: (s: string) => {
          let shopperVal = getElementAsString(shopper, s)
          if (shopperVal === null || shopperVal === undefined) {
            shopperVal = ''
            logger.warn(`macroparser error: couldn't find shopper value for element: ${s}`)
          }
          return shopperVal
        },
        strikethrough: (s: string) => `<span style="text-decoration: line-through">${s}</span>`,
        unnamedFunction: (s: string) => s,
        link: (s: string, href: string) => `<a href="${href}" target="_blank">${s}</a>`,
        formatPhoneNumber: (s: string) => formatPhoneNumber(s)
      },
      validClasses.value
    )
    return retString
  } catch (e) {
    if (isParseError(e)) {
      logger.error(`macroParser error: ${e.message}`)
    } else {
      logger.error('unknown parser error', e)
    }
    return 'NO DATA AVAILABLE'
    //return '<span style="coloc: red">Error parsing string<span>'
  }
}

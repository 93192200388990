
<div>
  <div v-if="customSchedulingOptions">
    <div v-html="customSchedulingOptionsHeaderText"></div>
    <v-radio-group v-model="schedule.installOption" :mandatory="true" class="mt-0">
      <v-row class="pl-2" v-for="(option, index) in customSchedulingOptions" :key="index">
        <v-radio :value="option.value"> </v-radio>
        {{ option.text }}
      </v-row>
    </v-radio-group>
    <div v-if="customConfirmationOptions">
      <div v-html="customConfirmationOptionsHeaderText"></div>
      <v-radio-group v-model="schedule.confirmationOption" :mandatory="true" class="mt-0">
        <v-row class="pl-2" v-for="(option, index) in customConfirmationOptions" :key="index">
          <v-radio :value="option.value"> </v-radio>
          {{ option.text }}
        </v-row>
      </v-radio-group>
    </div>
  </div>
  <div v-else>
    <v-radio-group v-model="schedule.installOption" :mandatory="true" class="mt-0">
      <div v-if="showSelfInstallation">
        <div v-if="selfInstallationHeaderText" v-html="selfInstallationHeaderText" class="pb-3"></div>
        <v-row class="pl-3 pb-2" style="flex-wrap: nowrap">
          <v-radio value="selfInstall"></v-radio>
          {{ selfInstallationRadioText }}
        </v-row>
        <p v-show="schedule.installOption === 'selfInstall'" v-html="selfInstallationSelectedText" class="pl-8"></p>
      </div>
      <div v-if="professionalInstallationHeaderText" v-html="professionalInstallationHeaderText" class="pb-1"></div>
      <v-row class="pl-3 pb-0 pt-2" style="flex-wrap: nowrap">
        <v-radio value="nextAvailable"></v-radio>
        <div v-html="nextAvailableInstallationText"></div>
      </v-row>
      <v-row class="pl-3 pb-0" style="flex-wrap: nowrap">
        <v-radio value="chooseMyDates"></v-radio>
        <div v-html="chooseMyDatesText"></div>
      </v-row>
      <v-row v-if="callMeToScheduleText" class="pl-3 pb-0" style="flex-wrap: nowrap">
        <v-radio value="callMeToSchedule"></v-radio>
        <div v-html="callMeToScheduleText"></div>
      </v-row>
    </v-radio-group>
  </div>
</div>

<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script lang="ts">
import CoreDefault from './components/CoreDefault.vue'
import { computed, defineComponent, onBeforeMount, onMounted, watch, watchEffect } from '@vue/composition-api'
import { GET_SHOW_SPINNER, SET_SHOW_SPINNER, SET_REFERRER } from '@/store/types'
import { UIConfig } from '@adg/catalog/src/common/UIConfig'
import useCart from './components/order/cart/useCart'
import $store from '@/store'
import $router from '@/router'
import { bus } from './main'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import ga4 from './gtm/ga4'

export default defineComponent({
  components: {
    //CoreDefault
  },
  setup(props, { root: { $vuetify } }) {
    // This will make api call to get config for current client

    const urlParams = new URLSearchParams(window.location.search)

    const urlParamsObj: any = {}

    for (const [key, value] of urlParams) {
      const val = urlParams.getAll(key)
      urlParamsObj[key] = val.length > 1 ? val : value
    }

    $store.commit('setUrlParams', urlParamsObj)
    $store.commit('addSpinner', { rank: 100, messages: [''], id: 'appLoad' })
    $store
      .dispatch('getUIConfig')
      .catch((error) => $router.push('/error'))
      .finally(() => {
        $store.commit('removeSpinner', 'appLoad')
        $store.dispatch('getReCaptchaToken')
      })

    const uiConfig = computed((): UIConfig => $store.getters.getUIConfig)

    watch(uiConfig, (newval, oldval) => {
      document.title = newval.clientTitle ?? newval.companyName ? `${newval.companyName} - Shop Now` : 'Shop Now'
      $store.commit(SET_REFERRER, window.document.referrer)
      if (newval.theme) {
        $vuetify.theme.options.customProperties = true
        $vuetify.theme.options.variations = true
        $vuetify.theme.themes.light = newval.theme
      }
    })

    const salesType = computed(() => $store.getters.getShopper.matchedAddress?.salesType)

    watch(salesType, (newval, oldval) => {
      //console.log('salesType changed', oldval, newval)
      if (newval !== oldval) {
        bus.$emit('pushSalesType')
      }
    })

    const { shoppingCart } = useCart($store)

    watch(
      shoppingCart,
      (newval, oldval) => {
        $store.commit('setShopperOrder', newval)
      },
      { deep: true }
    )

    const shopperId = computed(() => $store.getters.getShopper.id)

    watch(shopperId, (newval, oldval) => {
      // console.log('shopperId changed', oldval, newval)
      if (newval !== oldval && newval !== undefined && newval != '') {
        ga4.setDataLayerVariable({ event: 'shopper_id_changed', shopperId: newval })
      }
    })

    return {
      name: 'App',
      clientTemplate: computed(() => $store.getters.getTemplate)
    }
  }
})
</script>

<template>
  <v-card :tile="sharpCorners" class="lg tr-0 tl-0 mb-5">
    <!-- <component :is="headerComponent" :title="headerText" :image="headerIconOrImage" :componentOrder="product.displayOrder" /> -->
    <CardHeader
      :title="phoneHeaderText"
      :image="phoneHeaderImage"
      :componentOrder="product?.displayOrder"
      :color="phoneHeaderBackgroundColor"
    ></CardHeader>
    <v-container id="phone">
      <PackageUpgrades :product="product" />
      <v-form v-model="allPhoneInfoValid" v-on:submit.prevent ref="form">
        <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''" class="pb-0">
          <v-col class="py-0 title primary--text" cols="12" id="customize-group-header">
            Phone Number Option <Tooltip :payload="{ Name: 'Phone Number Option' }" />
          </v-col>
        </v-row>
        <v-radio-group class="pt-0 ma-0" v-model="phoneNumberChoice" @change="phoneChoiceChanged">
          <div class="radioRow" v-for="p in product?.Upgrades" :key="p.Name">
            <v-radio
              :class="getCatalogItemClassName(p.Name, p.itemType)"
              v-if="p.Subcategory !== 'Package Upgrade'"
              :value="p.Name"
            />
            {{ p.Description }}
            <Tooltip :payload="p" />
            <TooltipDebug :item="p" />
          </div>
        </v-radio-group>
        <div v-if="phoneNumberChoice === 'Keep'">
          <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''" class="pb-0">
            <v-col class="pt-0 title primary--text" cols="12"> Current Phone Provider Information </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="currentPhone"
                label="Current Phone Number"
                :rules="[requiredRule, phoneRule]"
                v-mask="phoneMask"
                single-line
                solo
              />
            </v-col>
          </v-row>
          <v-row v-if="phonePortingLoaText" class="pl-3 pt-2 pr-2"><span v-html="phonePortingLoaText"></span></v-row>
          <div v-if="showPhonePortConsent" class="pl-2">
            <v-list class="pt-4">
              <v-list-item :ripple="false">
                <template v-slot:default="{ active, toggle }">
                  <v-list-item-action>
                    <v-checkbox :rules="[requiredRule]" class="blackCheckbox" v-model="phonePortConsentModel" />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-html="phonePortConsentTitle" />
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list>
            <v-row>
              <v-col class="pt-0">
                <p v-html="phonePortConsentText" />
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row v-if="showCurrentPhoneBillingName" dense>
              <v-col>
                <v-text-field
                  v-model="shopper.customInfo.currentPhone.billingName"
                  label="Name as it appears on bill"
                  :rules="[requiredRule]"
                  maxlength="100"
                  single-line
                  solo
                />
              </v-col>
            </v-row>
            <v-row v-if="showCurrentPhoneCarrier" dense>
              <v-col v-if="phoneCarrierDropdown">
                <PhoneCarrierDropdown :field="phoneCarrierDropdown" />
              </v-col>
              <v-col v-else>
                <v-text-field
                  v-model="shopper.customInfo.currentPhone.carrier"
                  label="Current Provider"
                  :rules="[requiredRule]"
                  maxlength="100"
                  single-line
                  solo
                />
              </v-col>
            </v-row>

            <v-row v-if="showCurrentPhoneAccountNumber" dense>
              <v-col>
                <v-text-field
                  v-model="shopper.customInfo.currentPhone.accountNumber"
                  label="Account Number"
                  :rules="[requiredRule]"
                  maxlength="100"
                  single-line
                  solo
                />
              </v-col>
            </v-row>
          </div>
        </div>

        <div v-for="group in productGroups" :key="group.group">
          <div v-if="notAllExcluded(group.products)">
            <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
              <v-col class="py-0 title primary--text"
                >{{ getGroupTitle(group.group) }}
                <Tooltip :payload="{ Name: group.group }" />
                <TooltipDebug :item="group" :name="getGroupTitle(group.group)" />
              </v-col>
            </v-row>
            <v-row v-if="getGroupSubtitle(group.group)" :class="$vuetify.breakpoint.xs ? 'text-center' : ''">
              <v-col class="py-0" v-html="getGroupSubtitle(group.group)"></v-col>
            </v-row>

            <v-row v-if="group.products.length > 0 && radioGroup(group.products)">
              <v-container class="pl-1">
                <v-radio-group
                  :mandatory="false"
                  @change="equipmentChanged"
                  v-model="choices[group.group]"
                  class="pt-0 ma-0"
                  :key="renderMe"
                >
                  <div v-for="p in group.products" :key="p.Name">
                    <v-row v-if="shouldShowProduct(p)" dense class="py-0 px-0">
                      <v-col cols="9" class="py-0">
                        <v-row class="pl-5" style="flex-wrap: nowrap">
                          <v-radio class="py-0 black--text" :class="getCatalogItemClassName(p.Name, p.itemType)" :value="p.Name" />
                          <span v-html="uiMacroParser(shopper, itemDisplayName(p))"></span>
                          <Tooltip :payload="p" />
                          <TooltipDebug :item="p" />
                        </v-row>
                      </v-col>
                      <v-col v-if="p.included" cols="3" class="text-right pt-1"> Included </v-col>
                      <v-col v-else-if="getItemPrice(p, 'Monthly Price')" cols="3" class="text-right pt-1">
                        <span
                          v-html="uiMacroParser(shopper, p.PricePrefix) + ' ' + coreCurrency(getItemPrice(p, 'Monthly Price'))"
                        ></span>
                      </v-col>
                      <v-col v-else cols="3" class="text-right pt-1">
                        <span v-html="uiMacroParser(shopper, p?.PricePrefix) + ' ' + coreCurrency(getItemPrice(p, 'OTC'))"></span>
                      </v-col>
                    </v-row>
                    <div v-for="c in findChildren(p, equipment)" :key="c.Name">
                      <v-row v-show="productPicker(c) && c.Parent && isVisible(c)">
                        <v-col cols="9" style="padding-left: 51px" class="py-1">
                          <ProductPicker
                            :product="choices[c.Name] ? choices[c.Name] : c"
                            :min="c.Min"
                            :max="c.Max"
                            @input="equipmentChanged"
                            v-model="choices[c.Name]"
                            :render-me="renderMe"
                          />
                          <TooltipDebug :item="c" />
                        </v-col>
                        <v-col cols="3" class="text-right pt-1">
                          <span v-if="choices[c.Name]">{{ coreCurrency(choices[c.Name].calculatedPrice) }}</span>
                          <span v-if="!choices[c.Name]">{{ coreCurrency(0) }}</span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-radio-group>
              </v-container>
            </v-row>

            <v-list v-else>
              <div v-for="p in group.products" :key="p.Name">
                <ProductPickerLineItem
                  v-if="isProductPicker(p) && shouldShowProduct(p)"
                  v-model="choices[p.Name]"
                  :item="choices[p.Name] ? choices[p.Name] : p"
                  @input="equipmentChanged"
                  :render-me="renderMe"
                  :max="p.Max"
                  :min="p.Min"
                />
                <v-list-item v-else-if="shouldShowProduct(p)" :ripple="false">
                  <template v-slot:default="{ active, toggle }">
                    <v-list-item-action>
                      <v-checkbox
                        :disabled="p.included === '1' || p.required === '1'"
                        hide-details
                        class="my-1"
                        :class="getCatalogItemClassName(p.Name, p.itemType)"
                        v-model="choices[p.Name]"
                        :true-value="p.Name"
                        :false-value="null"
                        @change="equipmentChanged"
                        :key="renderMe"
                      >
                      </v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-html="uiMacroParser(shopper, itemDisplayName(p))"></span>
                        <Tooltip :payload="p" />
                        <TooltipDebug :item="p" />
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="p.required">{{ getProductSubtitle(p) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="p.included">Included</v-list-item-action>
                    <v-list-item-action v-else-if="getItemPrice(p, 'Monthly Price')"
                      ><span
                        v-html="uiMacroParser(shopper, p?.PricePrefix) + ' ' + coreCurrency(getItemPrice(p, 'Monthly Price'))"
                      ></span
                    ></v-list-item-action>
                    <v-list-item-action v-else
                      ><span v-html="uiMacroParser(shopper, p?.PricePrefix) + ' ' + coreCurrency(getItemPrice(p, 'OTC'))"></span
                    ></v-list-item-action>
                  </template>
                </v-list-item>
                <div v-for="c in findChildren(p, equipment)" :key="c.Name">
                  <v-row v-show="productPicker(c) && c.Parent && isVisible(c)">
                    <v-col cols="9" style="padding-left: 51px" class="py-1">
                      <ProductPicker
                        :product="choices[c.Name] ? choices[c.Name] : c"
                        :min="0"
                        :max="6"
                        @input="equipmentChanged"
                        v-model="choices[c.Name]"
                        :render-me="renderMe"
                      />
                      <TooltipDebug :item="c" />
                    </v-col>
                    <v-col cols="3" class="text-right pt-1">
                      <span v-if="choices[c.Name]">{{ coreCurrency(choices[c.Name].calculatedPrice) }}</span>
                      <span v-if="!choices[c.Name]">{{ coreCurrency(0) }}</span>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-list>
          </div>
        </div>
      </v-form>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onUpdated, onMounted, reactive, ComputedRef } from '@vue/composition-api'
import CardHeader from '@/components/layout/CardHeader.vue'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import useUiConfig from '../shared/useUiConfig'
import { min, max, mean, groupBy, flatten, toArray } from 'lodash'
import useComponentUtil from '../shared/useComponentUtil'
import { getConfigItem } from '../shared/getConfigItem'
import { ConfigKeys, itemDisplayName, getItemPrice, isProductPicker, addCalculatedPrice } from '@adg/catalog/src/modules/Catalog'
import { formatPhoneNumber, unFormatPhoneNumber } from '@adg/catalog/src/common/PhoneNumber'
import $store from '@/store'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import PackageUpgrades from '@/components/shared/PackageUpgrades.vue'
import { getCatalogItemClassName } from '@adg/catalog/src/common/utils'
import { coreCurrency } from '@adg/catalog/src/common/filters'
import ProductPickerLineItem from '@/components/shared/ProductPickerLineItem.vue'
import { bus } from '@/main'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import AccountFieldUI from '@/components/order/account/AccountFieldUI.vue'
import PhoneCarrierDropdown from './PhoneCarrierDropdown.vue'

export default defineComponent({
  name: 'Phone',
  props: {
    product: Object,
    sharpCorners: Boolean
  },
  components: {
    CardHeader,
    Tooltip,
    TooltipDebug,
    PackageUpgrades,
    ProductPickerLineItem,
    AccountFieldUI,
    PhoneCarrierDropdown
  },
  setup(props, { refs }) {
    const renderMe = ref(0)

    const shopper = computed(() => $store.getters.getShopper)
    const showCurrentPhoneBillingName = computed(() => getConfigItem(ConfigKeys.showCurrentPhoneBillingName) ?? false)
    const showCurrentPhoneCarrier = computed(() => getConfigItem(ConfigKeys.showCurrentPhoneCarrier) ?? false)
    const showCurrentPhoneAccountNumber = computed(() => getConfigItem(ConfigKeys.showCurrentPhoneAccountNumber) ?? false)
    const phoneCarrierDropdown = computed(() => getConfigItem(ConfigKeys.phoneCarrierDropdown))
    const phoneNumberChoice = ref('')
    const phoneMask = {
      mask: '(F##) ###-####',
      tokens: {
        '#': { pattern: /\d/ },
        F: { pattern: /[2-9]/ }
      }
    }

    const phoneHeaderImage = computed(() => getConfigItem(ConfigKeys.phoneHeaderImage) ?? 'PhoneIcon.png')
    const phoneHeaderText = computed(() => getConfigItem(ConfigKeys.phoneHeaderText) ?? 'CUSTOMIZE YOUR PHONE')
    const phoneHeaderBackgroundColor = computed(() => getConfigItem(ConfigKeys.phoneHeaderBackgroundColor) ?? undefined)
    const phonePortingLoaText = computed(() => getConfigItem(ConfigKeys.phonePortingLoaText))

    onUpdated(() => {
      if (phoneNumberChoice.value === 'Keep') {
        ;(refs as any).form.validate()
      }
      if (phoneNumberChoice.value === 'New' && currentPhone.value !== '') {
        currentPhone.value = ''
      }
      if (!phoneNumberChoice.value && props.product && props.product.Upgrades && !shopper.value.customInfo.returningPreSale) {
        phoneNumberChoice.value = 'New'

        updatePhoneUpgrades('New')
      }
    })

    const { getGroupTitle, getProductSubtitle, getGroupSubtitle } = useUiConfig()
    const { radioGroup, findChildren, productPicker } = useComponentUtil(props)

    const updatePhoneUpgrades = (val) => {
      // Update Vuex global state
      const upgradeValue = props.product && props.product.Upgrades ? props.product.Upgrades.find((p) => p.Name === val) : undefined
      if (upgradeValue) {
        $store.commit('setPhoneUpgrades', [upgradeValue])
        if (val === 'New') {
          $store.commit('setPhonePortConsent', undefined)
          usePiniaShopper().shopper.customInfo.phonePortConsent = undefined
        }
      }
    }

    const nullCheck = (v) => v !== null
    const qtyCheck = (v) => (v.qty ? v.qty && v.qty > 0 : true)

    const phoneRule = (value) => {
      const phonePattern = /^\(?[2-9]\d{2}\)?[\s.-]\d{3}[\s.-]\d{4}$/
      return phonePattern.test(value?.trimEnd() ?? '') || 'Please enter full phone number with area code'
    }
    const requiredRule = (value) => !!value || 'Required'

    const phoneChoiceChanged = (val) => {
      updatePhoneUpgrades(val)
    }

    //TODO: revisit what choices is actually supposed to be in this context, all product components do this in slightly unique ways
    let choices: Record<string | any, any> = reactive({})

    const parentExists = (v) => {
      if (v.Parent) {
        if (v.Parent in choices) {
          // Parent property exists in Model
          return nullCheck(choices[v.Parent]) && qtyCheck(choices[v.Parent])
        } else if (flatten(toArray(choices)).some((choice: any) => choice.Name === v.Parent)) {
          return true
        } else if (Object.values(choices).includes(v.Parent)) {
          return true
        } else {
          //If parent is not in current component state, check to see if it is anywhere else in cart.
          // removed because cartItems hasn't been updated
          // const t = cartItems.value.find((c) => c.Name === v.Parent)
          return false
        }
      } else {
        return true
      }
    }

    const equipmentChanged = (val) => {
      const allItems = flatten(toArray(choices).filter(nullCheck))
      const strings = allItems.filter((a) => typeof a === 'string')
      const objs = allItems.filter((a) => typeof a !== 'string')
      const stringToObjects = props.product?.Equipment.filter((p) => strings.includes(p.Name) ?? [])

      const potentialCartItems = [...objs, ...stringToObjects]

      const readyForCart = potentialCartItems.filter(nullCheck).filter(qtyCheck).filter(parentExists)

      //remove those items not ready for cart
      potentialCartItems
        .filter(nullCheck)
        .filter((f) => !readyForCart.find((ready) => ready.Name === f.Name))
        .forEach((r) => {
          if (r !== null && choices[r.Name].qty) {
            choices[r.Name].qty = 0
            choices[r.Name].calculatedPrice = 0
          }
        })
      $store.commit('setPhoneEquipment', [...objs, ...stringToObjects])
      renderMe.value = renderMe.value + 1
    }

    const initializeModel = () => {
      Object.keys(choices).forEach((k) => {
        delete choices[k]
      })
      const dd = $store.getters.getPhoneEquipment

      dd.forEach((d) => {
        //radio buttons use objects
        if (d.Min === 1 && d.Max === 1) {
          choices[d.Group] = d.Name
        }
        //checkbox use strings (name)
        if (d.Min === 0 && d.Max === 1) {
          choices[d.Name] = d.Name
        }
        //product picker uses objects
        if (d.Max > 1) {
          choices[d.Name] = { ...d, calculatedPrice: addCalculatedPrice(d) }
        }
      })
      renderMe.value = renderMe.value + 1
    }

    onMounted(() => {
      initializeModel()
    })

    bus.$on('packageChanged', (data) => {
      initializeModel()
    })

    // const equipmentChoice = computed({
    //   get: () => $store.getters.getPhoneEquipment.map((d) => d.Name),
    //   set: (val) => {
    //     const objects = props?.product?.Equipment.filter((p) => val.includes(p.Name))

    //     $store.commit('setPhoneEquipment', objects)
    //   }
    // })
    // const unFormatPhoneNumber = (phoneNumberString) => {
    //   let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    //   let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    //   if (match) {
    //     let intlCode = match[1] ? '1 ' : ''
    //     return [intlCode, match[2], match[3], match[4]].join('')
    //   }
    //   return null
    // }
    // const formatPhoneNumber = (phoneNumberString) => {
    //   let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    //   let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    //   if (match) {
    //     let intlCode = match[1] ? '+1 ' : ''
    //     return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    //   }
    //   return null
    // }

    const allPhoneInfoValid = computed({
      set: (valid) => {
        const v = valid || phoneNumberChoice.value === 'New'
        $store.commit('updatePhoneInfoValid', v)
        if (v) {
          $store.commit(
            'updatePhoneInfo',
            phoneNumberChoice.value === 'Keep' ? unFormatPhoneNumber(shopper.value.customInfo.currentPhone.number) : ''
          )
        }
      },
      get: () => $store.getters.getPhoneInfoValid
    })

    const currentPhone = computed({
      get: () =>
        shopper.value.customInfo?.currentPhone?.number ? formatPhoneNumber(shopper.value.customInfo.currentPhone.number) : '',
      set: (val) => {
        $store.commit('setCurrentPhoneNum', unFormatPhoneNumber(val))
        usePiniaShopper().shopper.customInfo.currentPhone = {
          ...usePiniaShopper().shopper.customInfo.currentPhone,
          number: unFormatPhoneNumber(val)
        }
      }
    })

    const equipment = computed(() => props.product?.Equipment)

    const productGroups = computed(() => {
      const equip = equipment.value.map((e) => {
        e.Rank ??= Number.MAX_SAFE_INTEGER // lowest rank
        return e
      })
      const dontsort = min(equip.map((x) => x.Rank)) === max(equip.map((x) => x.Rank))

      const grouped = equipment.value ? groupBy(equipment.value, 'Group') : []
      const test = Object.keys(grouped).map((k) => ({
        group: k,
        products: dontsort ? grouped[k] : grouped[k].sort((a, b) => a.Rank - b.Rank),
        meanrank: mean(grouped[k].map((x) => x.Rank)) // KWC for now just use mean value for rank of the group
      }))

      return dontsort ? test : test.sort((a, b) => a.meanrank - b.meanrank)
    })
    const notAllExcluded = (products) => products.some((product) => !product.excluded && !product.parent) //check if for a given group's products, at least one should display
    const shouldShowProduct = (p) => !p.Parent && !p.excluded

    const phonePortConsentTitle: ComputedRef<string> = computed(() => getConfigItem(ConfigKeys.phonePortConsentTitle))
    const phonePortConsentText: ComputedRef<string> = computed(() => getConfigItem(ConfigKeys.phonePortConsentText))
    const showPhonePortConsent: ComputedRef<boolean> = computed(() => !!(phonePortConsentTitle.value && phonePortConsentText.value))

    const phonePortConsentModel = computed({
      get: () => false,
      set: (consentToAgencyAgreement) => {
        const consentToAgencyAgreementTimestamp = new Date().toISOString()
        $store.commit('setPhonePortConsent', { consentToAgencyAgreement, consentToAgencyAgreementTimestamp })
        usePiniaShopper().shopper.customInfo.phonePortConsent = { consentToAgencyAgreement, consentToAgencyAgreementTimestamp }
      }
    })

    const dependenciesMet = (product) => {
      const parent = product.Parent
      const result = parent ? [parent].every(dependencyExists) : true
      return result
    }

    const dependencyExists = (dependency) => {
      const items = toArray(choices)
      const exists = items.find((i) => i !== null && i === dependency)
      return exists !== undefined
    }

    const isVisible = (product) => {
      return dependenciesMet(product)
    }

    return {
      itemDisplayName,
      phoneNumberChoice,
      phoneMask,
      phoneRule,
      requiredRule,
      allPhoneInfoValid,
      phoneChoiceChanged,
      equipment,
      getGroupTitle,
      getProductSubtitle,
      productGroups,
      radioGroup,
      notAllExcluded,
      shouldShowProduct,
      shopper,
      showCurrentPhoneBillingName,
      showCurrentPhoneCarrier,
      showCurrentPhoneAccountNumber,
      findChildren,
      productPicker,
      equipmentChanged,
      choices,
      renderMe,
      phonePortConsentTitle,
      phonePortConsentText,
      showPhonePortConsent,
      getItemPrice,
      currentPhone,
      phonePortConsentModel,
      phoneHeaderImage,
      phoneHeaderText,
      phoneHeaderBackgroundColor,
      phonePortingLoaText,
      isVisible,
      getGroupSubtitle,
      getCatalogItemClassName,
      coreCurrency,
      isProductPicker,
      uiMacroParser,
      phoneCarrierDropdown
    }
  }
})
</script>

<style scoped>
.blackCheckbox >>> .error--text {
  color: rgba(0, 0, 0, 0.54) !important;
}
.radioRow {
  display: flex;
}
#phone .v-list-item {
  margin: 0px;
  padding: 0px !important;
  min-height: 6px;
}

.v-list-group__header {
  height: 0px;
}

#phone .v-list-group__header {
  min-height: 0px;
}
#phone.v-list-group py-0 v-list-group--active v-list-group--disabled primary--text {
  padding: 0px;
}
.phone-options {
  color: #08131f;
  font-weight: 600 !important;
}
</style>

// Initialize OpenTelemetry
// if ((window as any)?.OTEL_ENABLED) {
//   const { initTracer } = require('@adg/catalog/src/web/tracer') // This is required for opentelemetry
//   initTracer('core-ui')
// }
import { initTracer } from '@adg/catalog/src/web/tracer'
if ((window as any)?.OTEL_ENABLED) {
  initTracer('core-ui') // This is required for opentelemetry
}

// Add Buffer for uuencode
import { Buffer } from 'buffer'
  ; (globalThis as any).Buffer = Buffer

// Import dependencies
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCompositionApi from '@vue/composition-api'
import VueTheMask from 'vue-the-mask'
import ecom from '@/gtm/ecom'
import { coreCurrency } from '@adg/catalog/src/common/filters'
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

// import VueCurrencyFilter from 'vue-currency-filter'

// Vue.use(VueCurrencyFilter, {
//   symbol: '$',
//   thousandsSeparator: '.',
//   fractionCount: 2,
//   fractionSeparator: '.',
//   symbolPosition: 'front',
//   symbolSpacing: false
// })

Vue.use(VueTheMask)

Vue.use(VueCompositionApi)

Vue.config.productionTip = false
// Vue.config.errorHandler = function (err, vm, info) {
//   console.log('customError:', err)
//   console.log('vm', vm)
//   console.log('info', info)
//   // handle error
//   // `info` is a Vue-specific error info, e.g. which lifecycle hook
//   // the error was found in. Only available in 2.2.0+
// }
//Vue.config.warnHandler = function (msg, vm, trace) {
//  if (!(vm as any).vueWarnIgnore) {
//    console.warn(msg, trace)
//  }
//}

Vue.filter('coreCurrency', coreCurrency)

export const bus = new Vue()
ecom.initCheckoutListeners()
window.vm = new Vue({
  data: {
    googleClientId: '',
    googleSessionId: '',
    googleSessionNumber: ''
  },
  router,
  store,
  vuetify,
  render: (h) => h(App),
  pinia
}).$mount('#app')

<template>
  <div>
    <v-divider></v-divider>
    <v-row v-if="installationOptionsHtml" class="pt-3 pb-3 installation-option">
      <div v-html="installationOptionsHtml"></div>
    </v-row>
    <div v-if="creditApprovedSchedulingText" class="pt-3 pb-3">
      <span v-html="creditApprovedSchedulingText"></span>
    </div>
    <div v-if="automatedSchedulingMessage && integrationSchedule && integrationSchedule.automated === 'yes'" class="pt-3 pb-3">
      <span v-html="automatedSchedulingMessage"></span>
    </div>
    <div v-if="allowScheduling">
      <v-row v-if="integrationSchedule && integrationSchedule.automated === 'no'">
        <v-col cols="12" class="options">
          <NonAutomatedSchedulingOptions />
        </v-col>
      </v-row>
      <div v-if="showCalendars">
        <v-row>
          <v-col cols="12" sm="7" class="my-2 pl-sm-12 px-6">
            <v-row justify="center" id="date-picker">
              <v-date-picker
                v-model="schedule.scheduleDate"
                :header-color="schedulingHeaderColor"
                :allowed-dates="dateAllowed"
                :min="getStartDate"
                :max="getEndDate"
                width="100%"
                @change="dateSelected"
              >
              </v-date-picker>
            </v-row>
          </v-col>
          <v-col cols="12" sm="5">
            <v-container fluid>
              <v-row no-gutters v-if="showSecondaryCalendar">
                <v-col class="preference" v-html="firstCalendarText"></v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-radio-group v-model="schedule.timeSlots[0]" :mandatory="true" @change="timeSlotChanged">
                    <v-radio
                      v-for="t in optionsForDay"
                      :key="t.getStartTime"
                      class="timeRadio"
                      :label="t.label"
                      :value="t"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row v-if="showSecondaryCalendar">
          <v-col cols="12" sm="7" class="my-2 pl-sm-12 px-6">
            <v-row justify="center" id="date-picker">
              <v-date-picker
                v-model="schedule.scheduleDate2"
                :header-color="schedulingHeaderColor"
                :allowed-dates="dateAllowed"
                :min="getStartDate"
                :max="getEndDate"
                width="100%"
                @change="dateSelected2"
              ></v-date-picker>
            </v-row>
          </v-col>
          <v-col cols="12" sm="5">
            <v-container fluid>
              <v-row no-gutters>
                <v-col v-html="secondCalendarText" class="preference"></v-col>
              </v-row>
              <v-row no-gutters>
                <v-radio-group v-model="schedule.timeSlots[1]" :mandatory="true" @change="timeSlotChanged">
                  <v-radio
                    v-for="t in optionsForDay2"
                    :key="t.getStartTime"
                    class="timeRadio"
                    :label="t.label"
                    :value="t"
                  ></v-radio>
                </v-radio-group>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from '@vue/composition-api'
import { Days } from '@/constants/Days'
import useOrder from '@/components/order/useOrder'
import useUiConfig from '@/components/shared/useUiConfig'
// { KWC Not complete
// import { GET_SCHEDULE_OPTIONS } from "@/store/types";
// } KWC I just commented out the GET_SCHEDULE_OPTIONS line above the "useSchedule" line was already commented out
// import useSchedule from "@/components/schedule/useSchedule";
import { flatten } from 'lodash'
//import dayjs from 'dayjs'
import dayjs from 'dayjs'
import { getConfigBoolean, getConfigItem } from '@/components/shared/getConfigItem'
import NonAutomatedSchedulingOptions from '@/components/order/scheduling/NonAutomatedSchedulingOptions.vue'
import creditCheck from '../../shared/creditCheck/useCreditCheck'
import { ConfigKeys, OrderSchedule, Product } from '@adg/catalog/src/modules/Catalog'
import { mdiWrenchOutline } from '@mdi/js'
import $store from '@/store'
import { logger } from '@/utils/RemoteLogger'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { getPunctuatedAddressFromTags } from '@adg/catalog/src/common/addressUtil'
import useAutomationState from '@/store/useAutomationState'

export default defineComponent({
  name: 'ScheduleOptions',
  components: {
    NonAutomatedSchedulingOptions
  },
  setup(props, { emit, root }) {
    const testLog = (t: string) => {
      if ($store.getters.getDebugMode) {
        console.log(t)
      }
    }
    const { $router } = root as any
    const { shouldAutomate } = useAutomationState($store)
    const { scheduleInfoHeaderColor } = useUiConfig()
    const wrenchIconColor = computed(() => getConfigItem(ConfigKeys.wrenchIconColor))

    const firstCalendarText = computed(
      () => getConfigItem(ConfigKeys.firstCalendarText) ?? 'Select your first preference for installation'
    )
    const secondCalendarText = computed(
      () => getConfigItem(ConfigKeys.secondCalendarText) ?? 'Select your second preference for installation'
    )

    const automatedSchedulingMessage = computed(() => getConfigItem(ConfigKeys.automatedSchedulingMessage))

    const address = computed(() => $store.getters.getFormattedAddress)
    const scheduling = computed(() => getConfigItem(ConfigKeys.scheduling))
    const shopper = computed(() => $store.getters.getShopper as IShopper)
    //const isAutomatedOrder = computed(() => $store.getters.getShopper?.automationState?.status === 'success')
    const isAutomatedOrder = computed(() => shouldAutomate.value)
    const canSelfInstall = computed(() => shopper.value.customInfo.canSelfInstall && isAutomatedOrder.value)
    const callMeToScheduleText = computed(() => getConfigItem(ConfigKeys.callMeToScheduleText))
    const selfInstallationHeaderText = computed(() => getConfigItem(ConfigKeys.selfInstallationHeaderText))
    const selfInstallationRadioText = computed(() => getConfigItem(ConfigKeys.selfInstallationRadioText))
    const selfInstallationSelectedText = computed(() => getConfigItem(ConfigKeys.selfInstallationSelectedText))
    const professionalInstallationHeaderText = computed(() => getConfigItem(ConfigKeys.professionalInstallationHeaderText))
    const professionalInstallationRadioText = computed(() => getConfigItem(ConfigKeys.professionalInstallationRadioText))
    const chooseMyDatesText = computed(
      () => getConfigItem(ConfigKeys.chooseMyDatesText) ?? 'I would like to request a date and time for installation'
    )
    const punctuatedAddress = computed(() => getPunctuatedAddressFromTags($store.getters.getShopper))

    const showInstallationOptionsWrenchIcon = computed(() => getConfigItem(ConfigKeys.showInstallationOptionsWrenchIcon) ?? false)
    const installationOptionsHeaderText = computed(
      () => getConfigItem(ConfigKeys.installationOptionsHeaderText) ?? 'INSTALLATION OPTIONS'
    )
    const installationOptionsClass = computed(
      () => getConfigItem(ConfigKeys.installationOptionsClass) ?? 'rounded-lg rounded-tr-0 rounded-tl-0'
    )

    const { hasGoodCredit, mustPrePay } = creditCheck()
    const creditApprovedSchedulingText = computed(() => {
      return hasGoodCredit.value && getConfigItem(ConfigKeys.creditApprovedSchedulingText)
    })
    const schedulingHeaderColor = computed(() => getConfigItem(ConfigKeys.schedulingHeaderColor) ?? 'secondaryDark lighten-3')
    const { validation, currentStep, steps } = useOrder($store, $router)

    const combinedLobs = computed(() => {
      const cart = $store.getters.getCart
      let value = ''
      cart.products.forEach((product: Product) => (value += product['Product Type']))
      return value
    })
    const showSelfInstallation = computed(() => getConfigBoolean(ConfigKeys.showSelfInstallScheduling, combinedLobs.value))

    const timeSlotChanged = () => {
      $store.commit('setCartSchedule', schedule.value)
    }

    const schedule = computed({
      get: () => $store.getters.getCartSchedule,
      set: (val) => $store.commit('setCartSchedule', val)
    })

    const allowScheduling = computed(() => getConfigItem(ConfigKeys.allowScheduling) ?? true)

    const installationOptionsHtml = computed(() => getConfigItem(ConfigKeys.installationOptionsHtml))

    const integrationSchedule = computed((): OrderSchedule | undefined => {
      const s = $store.getters.getAvailableSchedule
      return s
    })

    const dateAllowed = (d: string): boolean => integrationSchedule.value?.timeSlotsByDay?.[d]?.length > 0

    const getFormattedDate = (d) => {
      return d.toISOString().slice(0, 10)
    }

    const showCalendars = computed(() => {
      return integrationSchedule.value?.automated === 'yes' || installOption.value === 'chooseMyDates'
    })

    const showSecondaryCalendar = computed(() => {
      return installOption.value === 'chooseMyDates' && !getConfigItem(ConfigKeys.hideSecondCalendar)
    })

    const installOption = computed({
      set: (newOption) => {
        $store.commit('updateInstallOption', newOption)
      },
      get: () => {
        return $store.getters.getInstallOption
      }
    })

    const dateSelected = (e) => {
      if (integrationSchedule.value?.timeSlotsByDay) {
        $store.commit('setScheduleOptions', [
          integrationSchedule.value.timeSlotsByDay[e],
          integrationSchedule.value.timeSlotsByDay[schedule.value.scheduleDate2]
        ])
        schedule.value.timeSlots[0] = integrationSchedule.value.timeSlotsByDay[e]
      } else {
        logger.error('No schedule available')
      }
    }

    const dateSelected2 = (e) => {
      if (integrationSchedule.value?.timeSlotsByDay) {
        $store.commit('setScheduleOptions', [
          integrationSchedule.value.timeSlotsByDay[schedule.value.scheduleDate],
          integrationSchedule.value.timeSlotsByDay[e]
        ])
        schedule.value.timeSlots[1] = integrationSchedule.value.timeSlotsByDay[e]
      } else {
        logger.error('No schedule available')
      }
    }

    // KWC Commenting out because it does not seem to be complete (need to review)
    //    const scheduleOptions = computed(
    //      () => $store.getters[GET_SCHEDULE_OPTIONS]
    //    );

    const currentOptions = ref([])

    const optionsForDay = computed({
      get: () => $store.getters.getScheduleOptions[0],
      set: (val) => {}
    })
    const optionsForDay2 = computed({
      get: () => $store.getters.getScheduleOptions[1],
      set: (val) => {}
    })
    const timeSlotGroups = ref([])

    const minMax = computed(() => {
      const values = integrationSchedule.value
        ? flatten(Object.values(integrationSchedule.value.timeSlots)).reduce((acc: number[], val: any) => {
            acc[0] = acc[0] === undefined || val.startTime < acc[0] ? val.startTime : acc[0]
            acc[1] = acc[1] === undefined || val.startTime > acc[1] ? val.startTime : acc[1]
            return acc
          }, [])
        : []

      if (values.length >= 2) {
        const min = getFormattedDate(dayjs(values[0]).toDate())
        const max = getFormattedDate(dayjs(values[1]).toDate())

        return { min, max }
      } else {
        return { min: '', max: '' }
      }
    })
    const counter = ref(0)

    const getStartDate = computed(() => (minMax.value ? minMax.value.min : ''))
    const getEndDate = computed(() => (minMax.value ? minMax.value.max : ''))

    return {
      allowScheduling,
      installationOptionsHtml,
      getStartDate,
      getEndDate,
      showCalendars,
      installOption,
      dateAllowed,
      address,
      scheduling,
      emit,
      validation,
      steps,
      currentStep,
      scheduleInfoHeaderColor,
      dateSelected,
      dateSelected2,
      // KWC Not complete
      //      scheduleOptions,
      counter,
      minMax,
      currentOptions,
      optionsForDay,
      optionsForDay2,
      integrationSchedule,
      timeSlotGroups,
      schedule,
      timeSlotChanged,
      canSelfInstall,
      mustPrePay,
      schedulingHeaderColor,
      creditApprovedSchedulingText,
      showSelfInstallation,
      callMeToScheduleText,
      selfInstallationHeaderText,
      selfInstallationRadioText,
      selfInstallationSelectedText,
      professionalInstallationHeaderText,
      professionalInstallationRadioText,
      installationOptionsHeaderText,
      showInstallationOptionsWrenchIcon,
      mdiWrenchOutline,
      installationOptionsClass,
      wrenchIconColor,
      chooseMyDatesText,
      showSecondaryCalendar,
      punctuatedAddress,
      firstCalendarText,
      secondCalendarText,
      automatedSchedulingMessage
    }
  }
})
</script>

<style>
@media (min-width: 600px) {
  .options {
    padding-left: 32px;
  }
}

.options {
  padding-top: 0px;
}

.scheduleSubTitle {
  font-size: 18px;
  margin-left: 20px;
}

.timeRadio {
  margin-left: 0px;
}
</style>

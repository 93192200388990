import {
  SimpleLead,
  CatalogLead,
  SalesLeadInfo,
  CaptureLead,
  SalesLeadType,
  CrowdFiberLead,
  LeadArgs
} from '@adg/catalog/src/modules/Catalog'
import { GET_MATCHED_ADDRESS } from './../store/types'
import { GET_SALES_LEAD } from '@/store/types'
import { computed } from '@vue/composition-api'
import { getConfigItemByString } from './shared/getConfigItem'
import router from '@/router'
import store from '@/store'
import { logger } from '@/utils/RemoteLogger'
import { getElementAsString } from '@adg/catalog/src/common/utils'

export default () => {
  const salesLead = computed<SalesLeadInfo | undefined>(() => store.getters[GET_SALES_LEAD])
  const matchedAddress = computed(() => store.getters[GET_MATCHED_ADDRESS])

  const makeGetArg = ({ name, src, element }: LeadArgs): string => {
    let urlArg: string
    switch (src) {
      case 'ADDRESS':
        urlArg = matchedAddress.value[element] ?? ''
        break
      case 'SHOPPER':
        const shopper = store.getters.getShopper
        urlArg = getElementAsString(shopper, element) ?? ''
        break
      case 'TAG':
        urlArg = matchedAddress.value.tags[element] ?? ''
        break
      case 'CONFIG':
        urlArg = getConfigItemByString(element) ?? ''
        break
      default:
        //console.log(`Invalid Argument source ${s.src}`)
        urlArg = ''
    }
    return name + '=' + encodeURIComponent(urlArg)
  }

  const processSalesLead = (salesLeadInfo?: SalesLeadInfo): SalesLeadType => {
    let leadType
    if (!salesLeadInfo) {
      salesLeadInfo = salesLead.value
    }
    if (!salesLeadInfo || salesLeadInfo.type === SalesLeadType.NONE) {
      return SalesLeadType.NONE
    }
    let useType = salesLeadInfo.type
    logger.info('SalesLeadInfo', { ...salesLeadInfo })
    switch (useType) {
      case SalesLeadType.CROWD_FIBER: {
        store.commit('addSpinner', { id: 'crowdFiber', messages: [''], rank: 10 })
        crowdFiber()
        leadType = SalesLeadType.CROWD_FIBER
        break
      }
      case SalesLeadType.SIMPLE_REDIRECT: {
        store.commit('addSpinner', { id: 'simple', messages: [''], rank: 10 })
        const lead = salesLeadInfo as SimpleLead
        let href = lead.salesLeadLink
        if (lead.arguments?.length) {
          href += '?' + lead.arguments.map((a) => makeGetArg(a)).join('&')
        }
        window.location.replace(href)
        leadType = lead.type
        break
      }
      case SalesLeadType.CATALOG_REDIRECT: {
        store.commit('addSpinner', { id: 'catalogRedirect', messages: [''], rank: 10 })
        const lead = salesLeadInfo as CatalogLead
        window.location.replace(lead.salesLeadLink) // use catalog SL or fallback to UIConfig SL
        leadType = lead.type
        break
      }
      case SalesLeadType.LEAD_CAPTURE: {
        const lead = salesLeadInfo as CaptureLead
        router.push('/leadCapture')
        // store.commit('removeSpinner', 'fetchcatalog')
        leadType = lead.type
        break
      }
      default: {
        leadType = SalesLeadType.NONE
        // store.commit('removeSpinner', 'fetchcatalog')

        break
      }
    }
    return leadType
  }

  const crowdFiber = () => {
    if (salesLead.value && salesLead.value.type === SalesLeadType.CROWD_FIBER) {
      const crdsl = salesLead.value as CrowdFiberLead
      let salesLeadData = {}
      if (crdsl.salesLeadEnabled) {
        const shopper = store.getters.getShopper
        salesLeadData = {
          locate: {
            address: shopper.place.full,
            zip: shopper.place.zipcode,
            service_class: 1
          }
        }
      } else {
        salesLeadData = { locate: crdsl.salesLeadTestAddress }
      }
      createSalesLead(crdsl.salesLeadLink, salesLeadData)
        .then((data) => {
          window.location.replace(`${crdsl.salesLeadRedirectLink}/${data.uuid}`)
        })
        .catch((error) => {
          window.location.replace(`${crdsl.salesLeadFallbackLink}`)
        })
    }
  }

  async function createSalesLead(url: string, data) {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    if (response.ok) {
      return response.json() // parses JSON response into native JavaScript objects
    } else {
      throw ` Unable to obtain UUID for Crowd Fiber for address: ${data}`
    }
  }

  return {
    salesLead,
    processSalesLead
  }
}

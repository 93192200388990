<template>
  <v-dialog v-model="dialog" v-if="showDisclaimer" :max-width="$vuetify.breakpoint.xsOnly ? 400 : 500">
    <template v-slot:activator="{ on, attrs }">
      <v-row v-bind:class="rowClass">
        <v-col class="caption text--secondary">
          <span v-html="td.start" />
          <span v-html="td.link" v-bind="attrs" v-on="on" style="text-decoration: underline" />
          <span v-html="td.end" />
        </v-col>
      </v-row>
    </template>
    <v-card class="pt-2 rounded-lg" rounded>
      <v-row justify="end" class="closeButton pt-0 pb-0 px-2 ma-0">
        <v-col cols="1" class="pa-0">
          <v-icon @click="closeDialog">{{ mdiClose }}</v-icon>
        </v-col>
      </v-row>
      <v-card-text v-if="td" class="py-0">
        <v-card-title v-if="td.modalTitle" class="pb-0" style="justify-content: center">{{ td.modalTitle }}</v-card-title>
        <v-card-text v-html="td.modalText" class="py-4" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from '@vue/composition-api'
import { getConfigItem } from '@/components/shared/getConfigItem'
import creditCheck from './creditCheck/useCreditCheck'
import { mdiClose } from '@mdi/js'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'

export default defineComponent({
  name: 'Transunion',
  components: {},
  props: {
    rowClass: String
  },
  setup() {
    const dialog = ref(false)
    const td = computed(() => getConfigItem(ConfigKeys.transunionDisclaimer))
    const { hasGoodCredit } = creditCheck()
    const showDisclaimer = computed(() => hasGoodCredit.value && td.value)
    const closeDialog = () => (dialog.value = false)
    return {
      dialog,
      td,
      closeDialog,
      mdiClose,
      showDisclaimer
    }
  }
})
</script>

<style>
.scheduleSubTitle {
  font-size: 18px;
  margin-left: 20px;
}

.timeRadio {
  margin-left: 0px;
}

.installationOptionsText {
  font-size: 20px;
}

.closeButton {
  width: 100%;
  margin: 0;
}
</style>

import { computed } from '@vue/composition-api'
import { Tooltip } from '@/components/shared/tooltip/ITooltip'
import { GET_CATALOG } from '@/store/types'
import { ITooltipPayload } from '@/components/shared/tooltip/TooltipPayload'
import { MarketingText } from '@adg/catalog/src/modules/Catalog'

//TODO: don't know how to type store
export default ($store) => {
  const uiText = computed(() => $store.getters.getMarketingText)

  const getChannelGroup = (name: string) => {
    const channelGroup = $store.getters[GET_CATALOG]['ChannelGroups'][name]
    return channelGroup ? channelGroup : []
  }

  const getTooltip = (payload?: ITooltipPayload): Tooltip | undefined => {
    let tooltip: Tooltip | undefined
    if (!payload) return tooltip

    let tooltipType = payload.included ? 'included' : payload.required ? 'required' : 'default'
    let item
    // NOT WORKING
    // if (payload.itemType) {
    //   item = uiText.value ? uiText.value.find((mt) => mt.Name === payload.Name && mt.Type === payload.itemType) : []
    // } else {
    //   item = uiText.value ? uiText.value.find((mt) => mt.Name === payload.Name) : []
    // }
    item = uiText.value ? uiText.value.find((mt: MarketingText) => mt.Name === payload.Name) : []
    if (item) {
      const image = item['Tooltip Image']
      const logo = item['Tooltip Logo']
      const title = item['Title']
      const message = tooltipType === 'default' ? item['Tooltip'] : item[`Tooltip[${tooltipType}]`] ?? item['Tooltip']
      const channelGroup = message && message === '[Channel Group]' ? getChannelGroup(payload.Name) : undefined
      const composite = item['Tooltip Composite'] ? item['Tooltip Composite'].split(',') : undefined
      const component = item['Tooltip Component'] ? item['Tooltip Component'] : undefined
      const clickable = item['TooltipClickable'] ? item['TooltipClickable'] : undefined

      tooltip = new Tooltip('', [''])

      tooltip = {
        name: payload.Name,
        message,
        image,
        channelGroup,
        component,
        composite,
        type: payload.itemType,
        logo: logo,
        title: title,
        clickable: clickable
      }
    }
    return tooltip
  }

  return {
    getTooltip
  }
}

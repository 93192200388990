<template>
  <div>
    <Converge v-if="paymentControl === 'converge' && mustPrePay" :disabled="disabled" />

    <CustomPayment v-else-if="paymentControl === 'gocare' && mustPrePayGoCare" :disabled="disabled" />

    <PayIntegration v-else-if="paymentControl === 'payintegration' && mustPrePayGoCare" :disabled="disabled" />

    <PayTrace v-else-if="paymentControl === 'paytrace' && mustPrePayGoCare" :disabled="disabled" />

    <CardPointe v-else-if="paymentControl === 'cardpointe' && mustTakePayment" />

    <Stripe v-else-if="paymentControl === 'stripe' && mustTakePayment" :disabled="disabled" />

    <TranSafe v-else-if="paymentControl === 'transafe' && mustTakePayment" :disabled="disabled" />

    <StepperButtons v-else key="nonConvergeStepper" :disabled="disabled" />
  </div>
</template>
<script lang="ts">
import { computed, ComputedRef, defineComponent } from '@vue/composition-api'
import StepperButtons from '@/components/order/StepperButtons.vue'
import Converge from '@/components/order/payment/Converge.vue'
import CustomPayment from '@/components/order/payment/CustomPayment.vue'
import PayIntegration from '@/components/order/payment/PayIntegration.vue'
import PayTrace from './PayTrace.vue'
import { getConfigItem, getConfigString } from '@/components/shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import creditCheck from '@/components/shared/creditCheck/useCreditCheck'
import CardPointe from '@/components/order/payment/CardPointe.vue'
import Stripe from '@/components/order/payment/Stripe.vue'
import TranSafe from '@/components/order/payment/TranSafe.vue'
import { PaymentConfig } from '@adg/catalog/src/common/UIConfig'
import { anyConditionTrue, conditionReasons, getTrueConditions } from '@adg/catalog/src/common/utils'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import $store from '@/store'
import { logger } from '@/utils/RemoteLogger'

export default defineComponent({
  name: 'PayContainer',
  components: {
    Converge,
    CustomPayment,
    StepperButtons,
    PayIntegration,
    PayTrace,
    Stripe,
    CardPointe,
    TranSafe
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { mustPrePay, mustPrePayGoCare, mustTakePayment } = creditCheck()
    const shopper = computed((): IShopper => $store.getters.getShopper)
    const paymentConfig = computed((): PaymentConfig | undefined => getConfigItem(ConfigKeys.paymentConfig))
    const blockers = computed(() => paymentConfig.value?.customPaymentBlockers)
    const paymentControl: ComputedRef<string> = computed(() => {
      if (!getConfigItem(ConfigKeys.enablePayment)) return 'none'
      if (blockers.value && anyConditionTrue(blockers.value, shopper.value)) {
        const trueConditions = getTrueConditions(blockers.value, shopper.value)
        logger.info(`Custom payment blocked by conditions: ${conditionReasons(trueConditions)}`)
        return 'none'
      }
      return paymentConfig.value?.payType ?? 'none'
    })
    return {
      paymentControl,
      mustPrePay,
      mustPrePayGoCare,
      mustTakePayment
    }
  }
})
</script>
<style></style>

<template>
  <v-row v-if="tagMapValue && isDebugMode" class="ma-0">
    <ExpandableTagMap
      :name="name"
      :objectValue="value"
      arrayName="existingTag"
      arrayValue="newTagActual"
      :value="tagMapValue"
      :expanded="false"
      :showTypes="false"
      :showAllTags="showAllTags"
      :topLevel="true"
    />
  </v-row>
  <v-row v-else-if="!hasChildren">
    <v-col class="py-0" cols="5">
      <span class="blueText">{{ name }}</span>
    </v-col>
    <v-col class="py-0" cols="7">
      <!-- todo: value is obviously a string, but ts won't infer that. also why is the styling done in a v-bind here? -->
      <span class="blueText" v-if="typeof value === 'string'">"{{ shorten(value) }}"</span>
      <span v-else-if="value === undefined" style="color: blue; font-weight: bold">UNDEFINED</span>
      <span v-else-if="value === null" style="color: red; font-weight: bold">NULL</span>

      <span class="blueText" v-else>{{ value }}</span></v-col
    >
  </v-row>
  <v-expansion-panels v-else flat v-model="panel" :readonly="true">
    <v-expansion-panel class="ma-0 pa-0" id="nonExpLine">
      <v-expansion-panel-header hide-actions class="pa-0">
        <v-row class="ma-0">
          <v-col class="pa-0" cols="12">
            <span class="text-decoration-underline blueText">{{ name }}</span>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-if="value && typeof value === 'object'" class="ma-0 pa-0">
          <NonExpandableLine
            v-for="[key, val] in Object.entries(value)"
            :name="key"
            :value="val"
            :showAllTags="showAllTags"
            :key="'exp-line-' + key"
          />
        </div>
        <!-- <div v-else-if></div> -->
        <div v-else class="ma-0 pa-0">
          <v-row dense justify-end v-for="(val, i) in value" :key="'normal-expline' + key">
            <v-col cols="2">{{ i }}: </v-col>
            <v-col cols="7">{{ val }}</v-col>
          </v-row>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from '@vue/composition-api'
import { mdiPlusCircle, mdiMinusCircle } from '@mdi/js'
import ExpandableTagMap from './ExpandableTagMap.vue'
import $store from '@/store'
import { FoundTagMap } from '@adg/catalog/src/modules/Catalog/v4/interface/Catalog'

export default defineComponent({
  name: 'NonExpandableLine',
  props: {
    name: String,
    value: [Object, Array, String, Number, Boolean, Map, Date],
    showAllTags: {
      type: Boolean,
      default: false
    }
  },
  components: { ExpandableTagMap },
  setup(props) {
    const hasChildren = computed(() => {
      if (props.value instanceof Date) return false
      if (Array.isArray(props.value)) return props.value.length
      if (typeof props.value === 'object')
        return props.value !== undefined && props.value !== null && Object.keys(props.value).length
      return false
    })

    const isDebugMode = computed(() => $store.getters.getDebugMode)

    const panel = ref(0)
    const panel1 = ref()

    const shorten = (value: string | Date, amount: number = 500): string => {
      if (value instanceof Date) return value.toString()
      if (value.length > amount) return value.substring(0, amount) + '...'
      return value
    }

    const tagMapValue = computed(() => {
      const tagMap: FoundTagMap[] = [...$store.getters.getShopper.tagMap]
      //const foundTagMap = tagmap.reverse().find((tag) => tag.newTag === props.name)

      const foundTagMap: FoundTagMap[] = []
      let currentName = props.name
      for (let i = 0; i < tagMap.length; i++) {
        if (tagMap[i].newTag === currentName) {
          if (props.showAllTags || tagMap[i].success) foundTagMap.push(tagMap[i])
        }
      }
      if (foundTagMap.length) {
        return foundTagMap
      }
      return undefined
    })

    return {
      mdiPlusCircle,
      mdiMinusCircle,
      hasChildren,
      panel,
      panel1,
      shorten,
      tagMapValue,
      isDebugMode
    }
  }
})
</script>

<style>
#nonExpLine .v-expansion-panel-content__wrap {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 18px !important;
  border-left: 3px solid gray;
}
#nonExpLine .v-expansion-panel-header {
  min-height: 24px !important;
  font-size: 1rem;
}
.background-white {
  background-color: white;
}
.blueText {
  color: blue;
}
</style>

<template>
  <div>
    <LeadCaptureConfirmation :leadCapture="leadCapture" v-if="formComplete" />
    <LeadCaptureForm :leadCapture="leadCapture" @submit="completeForm" v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, watch, ref } from '@vue/composition-api'
import LeadCaptureForm from './LeadCaptureForm.vue'
import LeadCaptureConfirmation from './LeadCaptureConfirmation.vue'
import { LeadCapture } from '@adg/catalog/src/common/UIConfig'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'

export default defineComponent({
  name: 'LeadCapture',
  components: {
    LeadCaptureForm,
    LeadCaptureConfirmation
  },
  setup(props, { root }) {
    const formComplete = ref(false)
    const completeForm = () => (formComplete.value = true)

    const leadCapture = computed((): LeadCapture => getConfigItem(ConfigKeys.leadCapture))

    return {
      leadCapture,
      formComplete,
      completeForm
    }
  }
})
</script>
<style scoped></style>

<template v-if="labelHtml">
  <v-card class="broadband-card">
    <section class="close-row">
      <div v-if="closeButtonInLabel && closeLabelIconText" class="close-label" cols="auto" v-html="closeLabelIconText"></div>
      <v-icon v-if="closeButtonInLabel" class="close-icon" large :color="closeLabelIconColor" @click="$emit('labelClosed')">{{
        closeLabelIcon
      }}</v-icon>
    </section>
    <div class="label-content" v-html="labelHtml"></div>
    <v-btn
      v-if="selectButtonInLabel"
      :class="`button ${getCatalogItemClassName(packageData?.Name, 'Package')}`"
      rounded
      v-html="offerButtonText"
      @click="selectPackage(packageData)"
      dark
      :color="getOfferButtonColor({ type: '' }, colorIndex)"
    >
    </v-btn>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import $store from '@/store'
import { Package } from '@adg/catalog/src/modules/Catalog'
import { getCatalogItemClassName } from '@adg/catalog/src/common/utils'
import usePackage from './usePackage'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
interface PackageProps {
  pkgHeight: number
  colorIndex: number
  packageData: Package
  filterPackagesCount: number
  hasUnderPriceText: boolean
  displayNameTextColor: string
}
export default defineComponent({
  name: 'BroadbandLabelBase',
  props: {
    packageData: {
      type: Object as () => Package,
      required: true
    },
    shopper: {
      type: Object as () => IShopper,
      required: true
    },
    selectButtonInLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    closeButtonInLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    colorIndex: {
      type: Number,
      required: false
    },
    labelHtml: {
      type: String,
      required: true
    }
  },
  components: {},
  setup(props: PackageProps, { emit, refs, root: { $vuetify } }) {
    const { selectPackage, offerButtonText, getOfferButtonColor, closeLabelIcon, closeLabelIconColor, closeLabelIconText } =
      usePackage($store, $vuetify, emit)

    return {
      getCatalogItemClassName,
      selectPackage,
      offerButtonText,
      getOfferButtonColor,
      closeLabelIcon,
      closeLabelIconColor,
      closeLabelIconText
    }
  }
})
</script>

<style>
.broadband-card .close-row {
  display: grid;
  justify-content: end;
}
.broadband-card .close-label {
  padding-top: 4px;
  grid-column: 1/2;
  padding-right: 0px;
  font-weight: bold;
}
.broadband-card .close-icon {
  padding-bottom: 0px;
  padding-top: 0px;
  grid-column: 2/3;
}
.broadband-card .v-icon {
  font-size: 30px;
  color: #ff000c;
  caret-color: #ff000c;
}
.broadband-card .v-btn {
  justify-self: center;
}
</style>


<v-card :tile="sharpCorners" class="lg tr-0 tl-0 mb-5 internet-card">
  <!-- <component
    :is="headerComponent"
    :title="headerText"
    :image="headerIconOrImage"
    :componentOrder="product.displayOrder"
    :headerTextColor="headerComponent === 'CardHeaderBluepeak' ? '#08131f' : ''"
  /> -->
  <CardHeader
    :title="internetHeaderText"
    :image="internetHeaderImage"
    :componentOrder="product?.displayOrder"
    :color="internetHeaderBackgroundColor"
  ></CardHeader>
  <v-container class="" id="internet">
    <PackageUpgrades :product="product" />
    <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''" dense v-if="noItemsToShow && internetNoOptionsMessage">
      <v-col class="title primary--text" v-html="uiMacroParser(shopper, internetNoOptionsMessage)"></v-col>
    </v-row>
    <div v-for="group in productGroups" :key="group.group">
      <div v-if="notAllExcluded(group.products)">
        <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
          <v-col class="py-0 title primary--text" id="customize-group-header"
            >{{ getGroupTitle(group.group) }} <Tooltip :payload="{ Name: group.group }" /><TooltipDebug
              :item="group"
              :name="getGroupTitle(group.group)"
            />
          </v-col>
        </v-row>
        <v-row v-if="getGroupSubtitle(group.group)" :class="$vuetify.breakpoint.xs ? 'text-center' : ''">
          <v-col class="py-0" v-html="getGroupSubtitle(group.group)"> </v-col>
        </v-row>

        <v-row v-if="group.products.length > 0 && radioGroup(group.products)">
          <v-container class="pl-1">
            <v-radio-group
              :mandatory="false"
              @change="equipmentChanged"
              v-model="choices[group.group]"
              class="pt-0 ma-0"
              :key="renderMe"
            >
              <div v-for="p in group.products" :key="p.Name + renderMe">
                <v-row v-if="shouldShowProduct(p)" dense class="py-0 px-0">
                  <v-col cols="9" class="py-1">
                    <v-row class="pl-5 radio-row" style="flex-wrap: nowrap">
                      <v-radio class="py-0 black--text" :class="getCatalogItemClassName(p.Name, p.itemType)" :value="p.Name" />
                      <span v-html="uiMacroParser(shopper, itemDisplayName(p))"></span>
                      <Tooltip :payload="p" /><TooltipDebug :item="p" />
                    </v-row>
                    <v-row class="upgrade-radio-subtitle" v-if="getProductSubtitle(p)">
                      <v-col cols="12" class="py-0" v-html="uiMacroParser(shopper, getProductSubtitle(p) ?? '')"></v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="false /* old Logic */" cols="3" class="text-right pt-1"> Included </v-col>
                  <v-col v-else-if="getItemPrice(p, 'Monthly Price')" cols="3" class="text-right pt-1">
                    <span
                      v-html="uiMacroParser(shopper, p.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(p, 'Monthly Price'))"
                    ></span>
                  </v-col>
                  <v-col v-else cols="3" class="text-right pt-1">
                    <span
                      v-html="uiMacroParser(shopper, p.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(p, 'OTC'))"
                    ></span>
                  </v-col>
                </v-row>
                <div v-for="(c, i) in findChildren(p, equipment)" :key="c.Name + i + renderMe">
                  <v-row v-if="productPicker(c) && c.Parent && isVisibleRadio(c)">
                    <v-col cols="9" style="padding-left: 51px" class="py-1">
                      <ProductPicker
                        :product="choices[c.Name] ? choices[c.Name] : c"
                        :min="c.Min"
                        :max="c.Max"
                        @input="equipmentChanged"
                        v-model="choices[c.Name]"
                        :render-me="renderMe"
                      /><TooltipDebug :item="c" />
                    </v-col>
                    <v-col cols="3" class="text-right pt-1">
                      <span v-if="choices[c.Name]">{{ coreCurrency(choices[c.Name].calculatedPrice) }}</span>
                      <span v-if="!choices[c.Name]">{{ coreCurrency(0) }}</span>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-radio-group>
          </v-container>
        </v-row>

        <v-list v-else>
          <div v-for="p in group.products" :key="p.Name">
            <ProductPickerLineItem
              v-if="isProductPicker(p) && shouldShowProduct(p)"
              v-model="choices[p.Name]"
              :item="choices[p.Name] ? choices[p.Name] : p"
              @input="equipmentChanged"
              :render-me="renderMe"
              :max="p.Max"
              :min="p.Min"
            />
            <v-list-item v-else-if="shouldShowProduct(p)" :ripple="false">
              <template v-slot:default="{ active, toggle }">
                <v-list-item-action>
                  <v-checkbox
                    :disabled="p.included === '1' || p.required === '1' || isTvRequired(p.Name)"
                    hide-details
                    class="my-1"
                    :class="getCatalogItemClassName(p.Name, p.itemType)"
                    v-model="choices[p.Name]"
                    :true-value="p.Name"
                    :false-value="null"
                    @change="equipmentChanged"
                    :key="renderMe"
                  >
                  </v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-html="uiMacroParser(shopper, itemDisplayName(p))"></span>
                    <Tooltip :payload="p" /><TooltipDebug :item="p" />
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="getProductSubtitle(p)">{{ getProductSubtitle(p) }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="false /* old Logic */">Included</v-list-item-action>
                <v-list-item-action v-else-if="getItemPrice(p, 'Monthly Price')"
                  ><span
                    v-html="uiMacroParser(shopper, p.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(p, 'Monthly Price'))"
                  ></span
                ></v-list-item-action>
                <v-list-item-action v-else
                  ><span v-html="uiMacroParser(shopper, p.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(p, 'OTC'))"></span
                ></v-list-item-action>
              </template>
            </v-list-item>
            <div v-for="c in findChildren(p, equipment)" :key="c.Name + renderMe">
              <v-row v-if="productPicker(c) && c.Parent && isVisible(c)">
                <v-col cols="9" style="padding-left: 51px" class="py-1">
                  <ProductPicker
                    :product="choices[c.Name] ? choices[c.Name] : c"
                    :min="c.Min"
                    :max="c.Max"
                    @input="equipmentChanged"
                    v-model="choices[c.Name]"
                    :render-me="renderMe"
                    :key="c.Name + renderMe"
                  /><TooltipDebug :item="c" />
                </v-col>
                <v-col cols="3" class="text-right pt-1">
                  <span v-if="choices[c.Name]">{{ coreCurrency(choices[c.Name].calculatedPrice) }}</span>
                  <span v-if="!choices[c.Name]">{{ coreCurrency(0) }}</span>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-list>
      </div>
    </div>
  </v-container>
</v-card>

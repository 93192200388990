<template>
  <div @click="handleShortenClick()">
    <span v-if="expanded === 'short'">{{ shorten(shortenValue) }}</span>
    <div v-if="expanded === 'split'">
      <div v-for="(piece, i) in shortenValue.split('|')">
        <span v-if="i" style="color: red">| </span><span> {{ piece }} </span>
      </div>
    </div>
    <p v-if="expanded === 'full'">{{ shortenValue }}</p>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from '@vue/composition-api'
import $store from '@/store'

export default defineComponent({
  name: 'Shorten',
  props: {
    shortenValue: {
      type: String,
      default: ''
    },
    amount: {
      type: Number,
      default: 500
    }
  },
  setup(props, { emit }) {
    const expanded = ref('short')
    const handleShortenClick = () => {
      if (props.shortenValue.length <= props.amount) return
      if (expanded.value !== 'short') expanded.value = 'short'
      else if (props.shortenValue.includes('|')) expanded.value = 'split'
      else expanded.value = 'full'
    }
    const shorten = (value: string): string => {
      if (value?.length > props.amount) return value.substring(0, props.amount - 10) + '... (click to expand)'
      return value
    }
    return { expanded, shorten, handleShortenClick }
  }
})
</script>
<style></style>

<template>
  <div>
    <StepperButtons :forwardAction="openModal" :buttonText="paymentModalButtonText" key="PaymentStepper" :disabled="disabled" />
    <div v-if="showModal && (iFrameSrcDoc || iFrameSrc)">
      <v-overlay :opacity="0.5" v-if="showModal" z-index="6" class="customOverlay">
        <v-dialog v-model="showModal" @close="showModal = false" width="500px">
          <v-card class="dialog-card">
            <PaymentSuccess v-if="buttonState === 'success'" />
            <div v-else>
              <div class="close-button">
                <v-btn icon @click="showModal = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <div class="pay-header" v-if="paymentConfig.payHeaderHtml || paymentConfig.headerLogo">
                <v-img contain :src="paymentConfig.headerLogo" height="80" max-width="200px"></v-img>
                <div v-html="paymentConfig.payHeaderHtml" class="mb-3"></div>
              </div>
              <div class="pay-content">
                <p
                  v-if="paymentConfig.aboveIframeHtml"
                  class="mb-3 font-weight-normal"
                  v-html="insertPrices(paymentConfig.aboveIframeHtml)"
                ></p>

                <div class="iframe-wrapper">
                  <iframe v-if="iFrameSrc" id="paymentIframe" class="gocare-iframe" :src="iFrameSrc" />
                  <iframe v-else-if="iFrameSrcDoc" id="paymentIframe" class="gocare-iframe" :srcdoc="iFrameSrcDoc" />
                  <div v-else>This should never happen, please contact your administration</div>
                </div>
                <div v-if="errorsArr.length" class="error-container">
                  <span v-for="err in errorsArr" v-html="err" />
                </div>
                <div class="pay-container">
                  <v-btn class="pay-button" dark :color="submitColor" @click="submitPayment">{{ payBtnTxt }}</v-btn>
                </div>
                <div class="skip-container" v-if="showSkipPayment">
                  <v-btn class="skip-button" text @click="skipPayment">Skip Payment</v-btn>
                </div>

                <div v-if="paymentConfig.disclaimer" class="disclaimer">
                  <p v-html="paymentConfig.disclaimer" />
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </v-overlay>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import $store from '@/store'
import { headScript } from '@/utils/SourceLoader'
import StepperButtons from '../StepperButtons.vue'
import { bus } from '@/main'
import useCart from '@/components/order/cart/useCart'
import { PaymentInfo } from '@adg/catalog/src/modules/Converge/converge'
import useAutomationState from '@/store/useAutomationState'
// import { payHtml } from './payHtml'
import { getConfigItem, getConfigString } from '@/components/shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import { ApiConfig, PaymentConfig } from '@adg/catalog/src/common/UIConfig'
import AccountFieldUI from '../account/AccountFieldUI.vue'
import { GeneralProviderError, PaymentDisposition, PaymentErrorBase, ShopperDisposition } from '@adg/catalog/src/common/Payments'
import {
  insertPrices,
  iFrameSrcDoc,
  iFrameSrc,
  addDispositionListener,
  serviceAddress,
  paymentModalButtonText,
  payButtonText
} from './PaymentFunctions'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import PaymentSuccess from './PaymentSuccess.vue'
import ga4 from '@/gtm/ga4'
import { Spinner } from '@/store/State'
import { anyConditionTrue } from '@adg/catalog/src/common/utils'

export default defineComponent({
  name: 'Stripe',
  components: {
    StepperButtons,
    Tooltip,
    AccountFieldUI,
    PaymentSuccess
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { grandTotal, monthlyTotal, downPaymentTotal } = useCart($store)
    const headerLogo = computed(() => getConfigItem(ConfigKeys.headerLogo) ?? '/api/content/logo.png')
    const footerLinks = computed(() => getConfigItem(ConfigKeys.footerLinks))
    const shopper = computed((): IShopper => $store.getters.getShopper)
    const validated = ref(false)

    const paymentConfig = computed((): PaymentConfig => getConfigItem(ConfigKeys.paymentConfig))

    const showSkipPayment = computed(() => {
      //mentioning the status to force the watcher to re-evaluate when the status is changed (to allow for skipping payment in the case of certain failures)
      shopper.value.customInfo.paymentDisposition.status
      shopper.value.customInfo.paymentDisposition
      if (paymentConfig.value?.allowBypassPayment) {
        return true
      }
      if (paymentConfig.value?.allowBypassPaymentConditional) {
        return anyConditionTrue(paymentConfig.value.allowBypassPaymentConditional, shopper.value)
      }
      return false
    })

    const payAmount = computed(() => {
      if (paymentConfig.value?.payAmount) {
        return paymentConfig.value.payAmount
      } else {
        return downPaymentTotal.value
      }
    })
    const errorsArr = ref<string[]>([])
    const showModal = ref(false)
    const openModal = () => {
      buttonState.value = 'default'
      $store.dispatch('initializePayment')
      showModal.value = true
      ga4.pushPageView('/prepayment')
    }
    const closeModal = () => {
      showModal.value = false
    }
    const paymentFailed = ref(false)

    const showBillingFields = ref(false)

    const accountFieldChanged = (event: unknown, field?: string) => {
      if (field) {
        const iframe = document.getElementById('paymentIframe')
        if (iframe !== null) {
          ;(iframe as any).contentWindow.postMessage({ type: field, event }, '*')
        }
      }
    }

    type ButtonState = 'default' | 'loading' | 'success' | 'error' | 'failure'
    const buttonState: Ref<ButtonState> = ref('default')

    const submitColor = computed(() => {
      switch (buttonState.value) {
        case 'default':
        case 'failure':
          return 'secondary'
        case 'loading':
          return 'grey'
        case 'success':
          return 'green'
        case 'error':
          return 'error'
        default:
          return 'secondary'
      }
    })

    const payBtnTxt = computed(() => {
      switch (buttonState.value) {
        case 'default':
        case 'loading':
          return payButtonText.value ?? `Pay ${payAmount.value.toFixed(2)} USD`
        case 'success':
          return 'Success'
        case 'error':
          return 'Check card details'
        case 'failure':
        default:
          return payButtonText.value ?? `Pay $${payAmount.value.toFixed(2)} USD`
      }
    })

    const submitPayment = () => {
      if (['loading', 'success'].includes(buttonState.value)) {
        return
      }
      const finalizePaymentApiConfig: ApiConfig | undefined = getConfigItem(ConfigKeys.finalizePaymentApiConfig)
      const finalizeSpinner: Spinner = {
        id: 'finalizeSpinner',
        messages: finalizePaymentApiConfig?.messages ?? ['Processing Payment'],
        spinnerUpdateTime: finalizePaymentApiConfig?.messageTimeout ?? 10,
        rank: 1
      }
      $store.commit('addSpinner', finalizeSpinner)
      buttonState.value = 'loading'
      const iframe = document.getElementById('paymentIframe')
      if (iframe !== null) {
        ;(iframe as any).contentWindow.postMessage({ type: 'submit' }, '*')
      }
    }

    const skipPayment = () => {
      if (
        paymentConfig.value.skipCancelsAutomation ||
        (paymentConfig.value.skipCancelsAutomationConditional &&
          anyConditionTrue(paymentConfig.value.skipCancelsAutomationConditional, shopper.value))
      ) {
        useAutomationState($store).cancelAutomation()
      }
      const disposition: PaymentDisposition = shopper.value.customInfo.paymentDisposition
      disposition.status = 'skipped'
      disposition.paymentMethod = 'n/a'
      $store.commit('setPaymentDisposition', disposition)
      closeAndSubmitOrder()
    }

    const closeAndSubmitOrder = () => {
      closeModal()
      bus.$emit('submitOrder')
    }

    // This is not the payment success handler. This is the handler for the iframe's postMessage event
    const onSuccess = (disposition: PaymentDisposition) => {
      errorsArr.value = []
      $store.commit('setPaymentDisposition', disposition)
    }

    const onError = (disposition: PaymentDisposition) => {
      $store.commit('removeSpinner', 'finalizeSpinner')
      errorsArr.value = []
      $store.commit('setPaymentDisposition', disposition)
      disposition.errors.forEach((error: GeneralProviderError<string | number>) => {
        errorsArr.value.push(error?.message ?? '')
      })
      buttonState.value = 'failure'
    }

    const onFinished = (disposition: PaymentDisposition) => {
      buttonState.value = 'success'
      $store.commit('setPaymentDisposition', disposition)
      setTimeout(() => {
        closeAndSubmitOrder()
      }, 1500)
    }

    addDispositionListener(onFinished, onSuccess, onError, closeModal)

    return {
      monthlyTotal,
      grandTotal,
      showModal,
      openModal,
      headerLogo,
      iFrameSrcDoc,
      iFrameSrc,
      paymentConfig,
      insertPrices,
      accountFieldChanged,
      showBillingFields,
      serviceAddress,
      paymentModalButtonText,
      paymentFailed,
      submitColor,
      submitPayment,
      buttonState,
      payBtnTxt,
      errorsArr,
      footerLinks,
      validated,
      showSkipPayment,
      skipPayment
    }
  }
})
</script>
<style scoped>
.dialog-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* justify-content: ; */
  /* position: relative; */
  border-radius: 4px;
  background-color: #fff;
  /* height: 400px; */
  /* width: 400px; */
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.pay-content {
  /* padding: 16px; */
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 486px) {
  .gocare-iframe {
    min-height: 640px !important;
  }
}

@media only screen and (min-width: 487px) {
  .gocare-iframe {
    min-height: 530px;
  }
}

.gocare-iframe {
  border-width: 0;
  width: 100%;
  height: 100%;
}

.iframe-wrapper {
  height: auto;
  overflow-y: hidden;
  padding: 10px 10px 0 10px;
}

.button.close {
  display: none !important;
}
.close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

#submit {
  margin-top: 16px;
}

.pay-button {
  height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  margin-top: 16px;
  color: white;
  /* font-family: 'Roboto', sans-serif; */
  /* background-color: #f15d22 !important; */
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.skip-button {
  height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: black !important;

  /* font-family: 'Roboto', sans-serif; */
}
.pay-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.skip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.error-container span {
  color: red;
  font-size: 13px;
  margin-left: 30px;
}
.pay-error {
  color: red;
  font-size: 12px;
}
.pay-methods {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75rem !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  /* font-family: 'Roboto', sans-serif !important; */
}
.location {
  text-align: center;
  margin-top: 15px;
  font-size: 0.75rem !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  /* font-family: 'Roboto', sans-serif !important; */
}
.disclaimer {
  font-size: 0.75rem !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  /* font-family: 'Roboto', sans-serif !important; */
}
.pay-header {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  /* font-family: Roboto, sans-serif; */
  font-size: 30px !important;
  /* background-color: var(--v-secondary-base); */
  /* color: white; */
  border-bottom-color: var(--v-secondary-base) !important;
  border-bottom-style: solid !important;
  border-bottom-width: 5px !important;
}
.pay-amount {
  font-weight: bold;
}
.monthly-charge {
  font-weight: bold;
}
.radio-top {
  padding-top: 10px;
  align-items: flex-start;
  display: flex;
}
.pay-radio-group {
  margin-top: 0px;
}
.label {
  color: #9b9c9e;
  display: inline-block;
  width: 150px;
  font-family: arial;
  font-size: 14px;
}
.pay-account-fields {
  padding-left: 10px;
  padding-right: 10px;
}
.billing-content {
  padding-left: 20px;
  padding-right: 20px;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
// Can create more modules if gets big and need to refactor
import DefaultModules from './modules/store'

Vue.use(Vuex)
//Vue.config.devtools = true
export default new Vuex.Store({
  modules: {
    DefaultModules
  },
  strict: process.env.NODE_ENV !== 'production'
})


<v-toolbar class="toolbar" flat :height="$vuetify.breakpoint.smAndUp ? 80 : 100" dark :color="color" @click="headerClicked()">
  <v-container fluid class="pa-0 headerContainer">
    <v-row :class="$vuetify.breakpoint.xs ? 'text-center' : ''">
      <v-col class="title" cols="12">
        <v-row :class="$vuetify.breakpoint.smAndDown ? headerTextClass(image) + ' justify-center' : headerTextClass(image)">
          <v-btn
            class="alternate-cart-close-button"
            height="32px"
            width="32px"
            v-if="isCartHeader && $vuetify.breakpoint.smAndDown"
            icon
            @click="$emit('cartHeaderCloseButtonClicked')"
          >
            <v-icon :color="cartCloseIconColor">{{ cartCloseIcon }}</v-icon>
          </v-btn>
          <span :class="$vuetify.breakpoint.smAndDown ? 'pr-3' : 'pl-5 pr-3'" id="header-image"
            ><v-img v-if="image" :src="`/api/content/${image}`" height="32" width="32"
          /></span>
          <span id="header-text" v-html="title"></span>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</v-toolbar>

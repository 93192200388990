<template>
  <v-form>
    <v-checkbox v-show="!disableCheckbox" :label="checkboxLabel" dense class="noMargin" v-model="addressSeniorityChecked" />
    <v-autocomplete
      v-if="showAddress"
      ref="autocomplete"
      name="gogo"
      v-model="previousAddress"
      :loading="iLoading"
      :items="addressSuggestions"
      item-text="full"
      :search-input.sync="search"
      :label="addressLabel"
      @change="addressChanged"
      solo
      no-filter
      hide-no-data
      return-object
    />
  </v-form>
</template>
<script lang="ts">
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { FETCH_PREVIOUS_ADDRESS_SUGGESTIONS } from '@/store/types'
import useServiceability from '../serviceability/useServiceability'
import VInput from 'vuetify/lib/components/VInput/VInput.js'
import $store from '@/store'

export default defineComponent({
  name: 'AddressSeniority',
  extends: VInput,
  props: {
    vueWarnIgnore: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean || String,
      default: true
    },
    getAddress: {
      type: String,
      required: true
    },
    fetchAddress: {
      type: String,
      required: true
    },
    setAddress: {
      type: String,
      required: true
    },
    checkboxLabel: {
      type: String,
      default: "I've lived at the above address for 1 year or more"
    },
    addressLabel: {
      type: String,
      default: 'Enter your Previous Address'
    },
    disableCheckbox: {
      type: Boolean,
      default: false
    },
    showAddressOnCheck: {
      type: Boolean,
      default: false
      // Governs whether the address bar should show when the checkbox is checked or show when the checkbox is not checked
    },
    defaultCheckedState: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit, refs }) {
    const shopper = computed(() => $store.getters.getShopper)
    const { convertAddress } = useServiceability()

    const previousAddress: any = ref({})
    const iLoading = ref(false)
    const addressSuggestions = computed(() => {
      const suggestions = $store.getters[props.getAddress]
      return suggestions.map(convertAddress)
    })

    // watch(previousAddress, () => {
    //   props.rules?.forEach((rule) => (rule as any)(previousAddress.value.full))
    // })

    const search = ref('')
    const onFocus = () => {
      if (refs && refs.autocomplete) {
        ;(refs as any).autocomplete.isMenuActive = true
      }
    }

    const querySelections = async (searchValue: string, secondarySearchValue?: string) => {
      iLoading.value = true
      $store.dispatch(props.fetchAddress ?? FETCH_PREVIOUS_ADDRESS_SUGGESTIONS, {
        search: searchValue,
        selected: secondarySearchValue ? secondarySearchValue.trim() : null
      })
      if (secondarySearchValue) {
        search.value = secondarySearchValue.substring(0, secondarySearchValue.indexOf('(')).trim()
        onFocus()
      }
      iLoading.value = false
    }
    const addressSeniorityChecked = ref(props.defaultCheckedState)

    const showAddress = computed(() => (props.showAddressOnCheck ? addressSeniorityChecked.value : !addressSeniorityChecked.value))

    watch(previousAddress && showAddress, () => {
      //@ts-ignore
      props.value = previousAddress.value.full || !showAddress.value //set props.value for parent validation purposes
    })

    //TODO: check that string type for this value is correct
    const addressChanged = (v: string) => {
      //@ts-ignore
      props.value = previousAddress.value.full || !showAddress.value
      $store.commit(props.setAddress ?? 'updateShopperPreviousAddress', previousAddress.value)
    }

    watch(search, (search, prevSearch) => {
      if (search === '') $store.commit(props.setAddress ?? 'updateShopperPreviousAddress', null)
      if (prevSearch && prevSearch.indexOf('units)') >= 0) return
      if (
        previousAddress !== null &&
        previousAddress.value !== null &&
        previousAddress.value &&
        search &&
        search.indexOf('units)') >= 0
      ) {
        querySelections(search, previousAddress.value.secondarySearch)
        $store.commit(props.setAddress ?? 'updateShopperPreviousAddress', null)
      } else {
        search && search !== previousAddress.value && querySelections(search)
      }
    })

    return {
      shopper,
      emit,
      addressSeniorityChecked,
      previousAddress,
      addressChanged,
      iLoading,
      addressSuggestions,
      search,
      showAddress
    }
  }
})
</script>
<style>
.noMargin {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}
</style>

<template>
  <div>
    <v-menu min-width="450px" class="tagmenu" :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <div v-on="on" icon>
          <span class="header-address justify-end text-center hidden-xs-only" v-if="address != ''" v-html="address"></span>
        </div>
      </template>

      <div v-if="isDebugMode" class="tag-toggle">
        <v-switch v-model="showAllTags" class="mt-0" label="Show All Tag Maps" color="red" />
      </div>
      <v-list style="max-height: 90vh" class="overflow-y-auto pl-2">
        <NonExpandableLine
          v-for="([key, value], i) in Object.entries(tags).sort()"
          :name="key"
          :value="value"
          :key="`${key}-${i}`"
          :showAllTags="showAllTags"
        />
      </v-list>
    </v-menu>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from '@vue/composition-api'
import $store from '@/store'
import { getConfigItem } from '../getConfigItem'
import { ConfigKeys, Tags } from '@adg/catalog/src/modules/Catalog'
import NonExpandableLine from './NonExpandableLine.vue'

export default defineComponent({
  name: 'TagsDebug',
  props: {
    address: String
  },
  components: {
    NonExpandableLine
  },
  setup(props, { emit }) {
    const debugTagConfig = computed(() => getConfigItem(ConfigKeys.debugTagConfig))
    const isDebugMode = computed(() => $store.getters.getDebugMode)
    const showAllTags = ref(false)
    const tags: Tags = computed(() => {
      const unfilteredTags = $store.getters.getShopper.tags
      if (!debugTagConfig.value || isDebugMode.value) {
        return unfilteredTags
      }
      return Object.fromEntries(
        Object.entries(unfilteredTags).filter(([key]) => {
          return debugTagConfig.value.includes(key)
        })
      )
    })

    return { tags, isDebugMode, showAllTags }
  }
})
</script>
<style>
.gray-spacer {
  height: 30px;
  background-color: #a7a7a7;
}
.tagmenu {
  background-color: white;
}
.tag-toggle {
  padding: 10px 16px;
  margin-bottom: -20px;
  background-color: rgb(197, 197, 197);
}
</style>

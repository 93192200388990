
<v-overlay v-if="!!currentSpinner?.messages.length" style="z-index: 901">
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        {{ currentSpinner?.messages[spinnerIndex] }}
      </v-col>
    </v-row>
  </v-container>
</v-overlay>

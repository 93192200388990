<template>
  <v-card :tile="sharpCorners" class="lg tr-0 tl-0 mb-5">
    <!-- <component :is="headerComponent" :title="headerText" :image="headerIconOrImage" :componentOrder="product.displayOrder" />
     -->
    <CardHeader
      :title="tvHeaderText"
      :image="tvHeaderImage"
      :componentOrder="product.displayOrder"
      :color="tvHeaderBackgroundColor"
    ></CardHeader>
    <v-container fluid id="tv">
      <PackageUpgrades :product="currentProduct" />

      <div v-if="equipmentGroups.length >= 1">
        <v-row class="py-0" :class="$vuetify.breakpoint.xs ? ['pt-4', 'text-center'] : ''">
          <v-col class="py-0 title primary--text"
            >{{ getGroupTitle('tv-equipment-options') }}
            <Tooltip :payload="{ Name: 'tv-equipment-options' }" />
            <TooltipDebug :name="'tv-equipment-options'" :items="equipmentGroups" />
          </v-col>
        </v-row>
        <v-row v-if="getGroupSubtitle('tv-equipment-options')" class="py-0" :class="$vuetify.breakpoint.xs ? 'text-center' : ''">
          <v-col class="py-0" v-html="getGroupSubtitle('tv-equipment-options')"> </v-col>
        </v-row>
        <v-container class="pa-0">
          <v-radio-group :mandatory="true" class="pt-0 ma-0" v-model="currentGroup" @change="equipmentGroupChanged">
            <v-list-item v-for="group in equipmentGroups" :key="group.group" :ripple="false">
              <template v-slot:default="{ active, toggle }">
                <v-list-item-action>
                  <v-radio class="py-0" :class="getCatalogItemClassName(group.groupName, 'Equipment')" :value="group.group" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ group.group }}</v-list-item-title>
                  <v-list-item-subtitle>{{ getProductSubtitle({ Name: group.group }) }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-radio-group>
        </v-container>
      </div>

      <div v-if="equipmentProducts.length">
        <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
          <v-col class="py-0 title primary--text" id="customize-group-header" v-html="tvSetUpText"
            ><Tooltip :payload="{ Name: tvSetUpText }"
          /></v-col>
        </v-row>
        <v-row v-if="showTvSetUpSubtext" no-gutters>
          <v-col v-html="setTopBoxSubtitleText" class="pb-2">
            <Tooltip
              v-if="currentGroup"
              :payload="{
                Name: `${currentProduct['Product Type']}-${currentGroup}`
              }"
            />
            <Tooltip v-if="!currentGroup" :payload="{ Name: currentProduct['Product Type'] }" />
          </v-col>
        </v-row>

        <TvEquipment
          :products="equipmentProducts"
          v-model="tvSelections"
          :minTvChoices="equipmentMinTvs"
          @input="tvSelectionChanged"
        /><TooltipDebug :name="'TvEquipment'" :item="tvSelections" />
      </div>
      <!--Services-->
      <div class="pt-2" v-for="serviceGroup in serviceGroups.filter((sg) => sg.type === 'radio')" :key="serviceGroup.name">
        <v-row
          v-if="serviceGroup.type === 'radio' && services.length > 0"
          :class="$vuetify.breakpoint.xs ? ['pt-o', 'text-center'] : ''"
        >
          <v-col class="py-0 title primary--text" id="customize-group-header">{{ getGroupTitle(serviceGroup.name) }}</v-col>
        </v-row>
        <v-row
          v-if="serviceGroup.type === 'radio' && services.length > 0 && getGroupSubtitle(serviceGroup.name)"
          :class="$vuetify.breakpoint.xs ? 'text-center' : ''"
        >
          <v-col class="py-0" v-html="getGroupSubtitle(serviceGroup.name)"></v-col>
        </v-row>
        <v-radio-group
          v-if="serviceGroup.type === 'radio'"
          class="mt-0"
          dense
          hide-details
          @change="serviceRadioChange"
          v-model="serviceRadioBoxes[serviceGroup.name]"
          :key="renderMe"
        >
          <v-list class="pt-0">
            <div v-for="product in serviceGroup.products" :key="product.Name">
              <v-list-item
                v-if="
                  (product.Min === 1 && product.Max === 1 && product.Group === serviceGroup.name) ||
                  (product.Group === undefined && serviceGroup === '')
                "
                :ripple="false"
              >
                <template v-slot:default="{ active, toggle }">
                  <v-list-item-action>
                    <v-radio
                      v-if="serviceGroup.type === 'radio'"
                      class="py-0"
                      :class="getCatalogItemClassName(product.Name, product.itemType)"
                      :value="product.Name"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      ><span v-html="uiMacroParser(shopper, itemDisplayName(product))"></span
                      ><TooltipDebug :name="itemDisplayName(product)" :item="product" /><Tooltip :payload="product"
                    /></v-list-item-title>
                    <v-list-item-subtitle v-if="product.included">{{ getProductSubtitle(product) }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>{{ coreCurrency(getItemPrice(product, 'Monthly Price')) }}</v-list-item-action>
                </template>
              </v-list-item>
            </div>
          </v-list>
        </v-radio-group>
      </div>

      <div v-for="serviceGroup in serviceGroups.filter((sg) => sg.type === 'checkbox')" :key="serviceGroup.name">
        <v-row
          v-if="serviceGroup.name !== 'undefined' && serviceGroup.type === 'checkbox' && services.length > 0"
          :class="$vuetify.breakpoint.xs ? ['pt-o', 'text-center'] : ''"
        >
          <v-col class="py-0" v-html="getGroupTitle(serviceGroup.name)"></v-col>
        </v-row>
        <v-row
          v-if="
            serviceGroup.name !== 'undefined' &&
            serviceGroup.type === 'checkbox' &&
            services.length > 0 &&
            getGroupSubtitle(serviceGroup.name)
          "
          :class="$vuetify.breakpoint.xs ? 'text-center' : ''"
        >
          <v-col class="py-0" v-html="getGroupSubtitle(serviceGroup.name)"></v-col>
        </v-row>
        <v-list v-if="serviceGroup.type === 'checkbox'">
          <v-list-item v-for="product in serviceGroup.products" :key="product.Name" :ripple="false">
            <template v-slot:default="{ active, toggle }" v-if="!isExcluded(product.Name)">
              <v-list-item-action v-if="product.Min === 0 && product.Max === 1">
                <v-checkbox
                  :class="getCatalogItemClassName(product.Name, product.itemType)"
                  :disabled="product.included === '1'"
                  hide-details
                  v-model="upgrades"
                  :value="product.Name"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  >{{ itemDisplayName(product) }}
                  <Tooltip :payload="product" />
                  <TooltipDebug :item="product" />
                </v-list-item-title>
                <v-list-item-subtitle v-if="product.included">{{ getProductSubtitle(product) }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="getItemPrice(product, 'Monthly Price') != undefined"
                ><span
                  v-html="
                    uiMacroParser(shopper, product.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(product, 'Monthly Price'))
                  "
                ></span
              ></v-list-item-action>
              <v-list-item-action v-else
                ><span
                  v-html="uiMacroParser(shopper, product.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(product, 'OTC'))"
                ></span
              ></v-list-item-action>
            </template>
          </v-list-item>
        </v-list>
      </div>

      <!--      Channels-->
      <div v-if="channelText">
        <span v-html="channelText"></span>
      </div>
      <div v-if="streamingOptionsText">
        <span v-html="streamingOptionsText"></span>
      </div>
      <div v-for="channelGroup in channelGroups" :key="channelGroup">
        <v-row v-if="channels.length > 0" :class="$vuetify.breakpoint.xs ? ['pt-o', 'text-center'] : ''">
          <v-col class="py-0 title primary--text">{{ getChannelTitle(channelGroup) }}</v-col>
        </v-row>
        <v-row v-if="getGroupSubtitle(channelGroup)" :class="$vuetify.breakpoint.xs ? 'text-center' : ''">
          <v-col class="py-0" v-html="getGroupSubtitle(channelGroup)"></v-col>
        </v-row>
        <v-list>
          <div v-for="product in channels" :key="product.Name">
            <v-list-item
              v-if="product.Group === channelGroup || (product.Group === undefined && channelGroup === '')"
              :ripple="false"
            >
              <template v-slot:default="{ active, toggle }">
                <v-list-item-action>
                  <v-checkbox
                    :class="getCatalogItemClassName(product.Name, product.itemType)"
                    :disabled="product.included === '1'"
                    hide-details
                    v-model="upgrades"
                    :value="product.Name"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    ><span v-html="uiMacroParser(shopper, itemDisplayName(product))"></span
                    ><Tooltip :payload="product" /><TooltipDebug :item="product"
                  /></v-list-item-title>
                  <v-list-item-subtitle v-if="product.included">{{ getProductSubtitle(product) }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="getItemPrice(product, 'Monthly Price') != undefined"
                  ><span
                    v-html="
                      uiMacroParser(shopper, product.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(product, 'Monthly Price'))
                    "
                  ></span
                ></v-list-item-action>
                <v-list-item-action v-else
                  ><span
                    v-html="uiMacroParser(shopper, product.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(product, 'OTC'))"
                  ></span
                ></v-list-item-action>
              </template>
            </v-list-item>
          </div>
        </v-list>
      </div>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, reactive, Ref } from '@vue/composition-api'
import { bus } from '@/main'
import { SET_TV_EQUIPMENT, SUBCATEGORY } from '@/store/types'
import TvEquipment from './TvEquipment.vue'
import ProductPicker from '@/components/shared/ProductPicker.vue'
import CardHeader from '@/components/layout/CardHeader.vue'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import PackageUpgrades from '@/components/shared/PackageUpgrades.vue'
import { GET_TV_UPGRADES } from '@/store/modules/store'
import { mdiInformation } from '@mdi/js'
import useUiConfig from '@/components/shared/useUiConfig'
import { groupBy } from 'lodash'
import useCatalogConfig from '@/components/shared/useCatalogConfig'
import useCart from '../cart/useCart'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { Group, groupProducts } from '@/utils/GroupHelper'
import useFunctions from '@/components/order/useFunctions'
import { ConfigKeys, Product, itemDisplayName, getItemPrice, Equipment, catalogRank } from '@adg/catalog/src/modules/Catalog'
import useTv from './useTv'
import $store from '@/store'
import { usePiniaRoute } from '@/store/pinia/piniaRoute'
import { getCatalogItemClassName } from '@adg/catalog/src/common/utils'
import { coreCurrency } from '@adg/catalog/src/common/filters'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { IShopper } from '@adg/catalog/src/modules/Shopper'

interface EquipmentGroup {
  groupName: string
  children: Equipment[]
}

export default defineComponent({
  name: 'TV',
  props: {
    product: {
      type: Object as () => Product | Product[],
      required: true
    },
    sharpCorners: Boolean
  },
  components: {
    TvEquipment,
    ProductPicker,
    CardHeader,
    PackageUpgrades,
    Tooltip,
    TooltipDebug
  },
  setup(props) {
    const renderMe = ref(0)
    //todo: this component needs some serious work/simplification

    let currentProduct: Ref<Product> = ref(Array.isArray(props.product) ? props.product[0] : props.product)
    const { getMinTvs } = useCatalogConfig($store)
    let currentGroup = ref()
    const channels = ref([])
    const channelGroups = ref([])
    const serviceGroups: Ref<Group[]> = ref([])
    const serviceRadioBoxes = reactive({})
    const services = ref([])
    let servicesModel = ref([])
    let channelsModel = ref([])
    let addOnsModel = ref([])
    const equipmentProducts: Ref<Equipment[]> = ref([])
    const equipmentMinTvs = ref(getMinTvs.value) // ref(computed(() => $store.getters.getNumTv));
    const equipmentGroups: Ref<EquipmentGroup[]> = ref([])
    const tvSelections = ref([])

    const shopper = computed(() => $store.getters.getShopper as IShopper)

    const { calculatePrice } = useFunctions()
    const { cartItems } = useCart($store)
    const { mapCountedSelections } = useTv()

    const tvSetUpText = computed(() => getConfigItem(ConfigKeys.tvSetUpText) ?? 'Setup your TVs')
    const showTvSetUpSubtext = computed(() => getConfigItem(ConfigKeys.showTvSetUpSubtext) ?? true)
    const setTopBoxSubtitleText = computed(
      () => getConfigItem(ConfigKeys.setTopBoxSubtitleText) ?? 'How many TVs would you like service on?'
    )

    const tvHeaderImage = computed(() => getConfigItem(ConfigKeys.tvHeaderImage) ?? 'TVIcon.png')
    const tvHeaderText = computed(() => getConfigItem(ConfigKeys.tvHeaderText) ?? 'CUSTOMIZE YOUR TV')
    const tvHeaderBackgroundColor = computed(() => getConfigItem(ConfigKeys.tvHeaderBackgroundColor) ?? undefined)

    const channelText = computed(() => getConfigItem(ConfigKeys.channelText))
    const streamingOptionsText = computed(() => getConfigItem(ConfigKeys.streamingOptionsText))

    const findProductForGroup = (group: string) => {
      if (Array.isArray(props.product)) {
        return props.product.find((p) => p['Groups'][0]['Name'] === group)
      } else {
        return props.product
      }
    }

    const { getGroupTitle, getProductSubtitle, getGroupSubtitle } = useUiConfig()

    const upgrades = computed({
      get: () => $store.getters[GET_TV_UPGRADES].map((d) => d.Name),
      set: (val) => {
        const objects = currentProduct.value.Upgrades?.filter((p) => val.includes(p.Name))
        $store.commit('setTvUpgrades', objects)
      }
    })

    const tvSelectionChanged = () => {
      if (tvSelections.value) {
        $store.commit(SET_TV_EQUIPMENT, tvSelections.value ? mapCountedSelections(tvSelections.value) : [])
        calculateUpgradePrices()
      }
    }

    const calculateItemMonthlyPrice = (item) => {
      if (item['Price Calculation']) {
        const allSelections = cartItems.value.map((s) => s.Name)
        return calculatePrice(allSelections, item)['Monthly Price']
      } else if (item.included && item.included === '1') {
        return 'Included'
      } else if (getItemPrice(item, 'Monthly Price')) {
        return getItemPrice(item, 'Monthly Price')
      } else {
        return undefined
      }
    }

    const calculateUpgradePrices = () => {
      const newState = currentProduct.value.Upgrades!.filter((p) => upgrades.value.includes(p.Name))
      const newUpgradeState = newState.map((ci: any) => {
        return {
          ...ci,
          'Monthly Price': calculateItemMonthlyPrice(ci)
        }
      })
      $store.commit('setTvUpgrades', newUpgradeState)
    }

    const uiText = computed(() => $store.getters.getMarketingText)

    const getChannelTitle = (channelName) => {
      return channelName === '' ? 'Add Channels' : uiText.value.find((mt) => mt.Name === channelName).Title
    }

    const initialize = (tv) => {
      const currentChannels = tv.Upgrades.filter((p) => p[SUBCATEGORY] === 'Channel').sort(
        (a, b) => catalogRank(a.Rank) - catalogRank(b.Rank)
      )
      const currentServices = tv.Upgrades.filter((p) => p[SUBCATEGORY] === 'Service' && !(p.excluded && p.excluded === '1'))
      channels.value.splice(0, 100)
      services.value.splice(0, 100)
      channels.value = currentChannels
      channels.value.forEach((c: any) => {
        const group: string = c.Group ?? ''
        if (!channelGroups.value.includes(group)) channelGroups.value.push(group)
      })
      services.value = currentServices

      const includedChannels = channels.value //add included channels to cart
        .filter((channel: any) => channel.included === '1')
        .map((a: any) => a.Name)
      const includedServices = services.value.filter((service: any) => service.included === '1').map((a: any) => a.Name)
      // upgrades.value = includedChannels.concat(includedServices)

      const defaultServices = services.value

      // Group services
      serviceGroups.value = groupProducts(currentServices)
      // initialize local state

      //set first radiogroup item as selected
      serviceGroups.value.forEach((sg) => {
        if (sg.type === 'radio') {
          serviceRadioBoxes[sg.name] = sg.products[0].Name
        }
      })

      includedServices.forEach((includedService) => {
        upgrades.value.push(includedService)
        const obj: any = services.value.find((s: any) => s.Name === includedService)
        serviceRadioBoxes[obj.Group] = includedService
      })
      renderMe.value = renderMe.value + 1
    }

    //When props change
    watch(
      () => props.product,
      (curr: Object, prev) => {
        if (usePiniaRoute().currentStep < 4) {
          currentProduct.value = Array.isArray(curr) ? { ...curr[0] } : { ...curr }
          if (currentProduct.value['Groups']) {
            currentGroup.value = currentProduct.value['Groups'][0]['Name']
            equipmentMinTvs.value = currentProduct.value['Groups'][0]['Min Tvs']
            const grouped = groupBy(currentProduct.value.Equipment, 'Group')
            equipmentGroups.value = Object.keys(groupBy(currentProduct.value.Equipment, 'Group')).map((k) => ({
              groupName: k,
              children: grouped[k]
            }))
            equipmentProducts.value = equipmentGroups.value[0].children.filter(
              (p) => !p.excluded || (p.excluded && p.excluded !== '1')
            )
          } else {
            const eqp = currentProduct.value.Equipment ?? []
            equipmentProducts.value = eqp.filter((p) => !p.excluded || (p.excluded && p.excluded !== '1'))
            equipmentMinTvs.value = getMinTvs.value
          }
          initialize(currentProduct.value)
        }
      }
    )

    bus.$on('packageChanged', (data) => {
      initialize(currentProduct.value)
    })

    const isExcluded = (name) => {
      if (currentProduct.value && currentProduct.value['Groups']) {
        const productGroup = currentProduct.value['Groups'].find((g) => g.Name === currentGroup.value)
        if (productGroup && productGroup['Excluded Upgrades']) {
          return productGroup['Excluded Upgrades'].includes(name)
        }
      }
      return false
    }
    //onMounted(() => console.log('tv mounted'))
    const equipmentGroupChanged = (group: string) => {
      // If one product then do not change current product otherwise find product that is in group.
      const productToSwitchTo = findProductForGroup(group)
      if (productToSwitchTo === undefined) return
      const productGroup = productToSwitchTo['Groups'].find((g) => g.Name === group)

      const productWithCorrectGroup = {
        ...productToSwitchTo,
        Groups: productToSwitchTo['Groups'].filter((g) => g['Name'] === group)
      }
      currentProduct.value = productWithCorrectGroup
      initialize(currentProduct.value)

      equipmentProducts.value = productWithCorrectGroup.Equipment?.filter((e) => e['Group'] === group) ?? []

      equipmentMinTvs.value = productGroup['Min Tvs']

      //Look at group and add included to state
      upgrades.value = currentProduct.value.Upgrades?.filter((u) => u.included).map((i) => i['Name']) ?? []

      bus.$emit('tvProductChanged', productWithCorrectGroup)
    }

    const serviceRadioChange = (val) => {
      // find object for name of radio button product
      const object = currentProduct.value.Upgrades?.find((u) => u.Name === val)
      if (object === undefined) return
      const group = object.Group
      // Get all products from that group
      const serviceGroup: any = serviceGroups.value.find((sg: any) => sg.name === group)
      let products: string[] = []
      if (serviceGroup) {
        products = serviceGroup.products.map((p) => p.Name)
      }
      // remove all previous radio group selections from upgrades state
      const filtered = upgrades.value.filter((u) => !products.includes(u))
      // convert list of names to objects
      const newState = currentProduct.value.Upgrades?.filter((p) => filtered.includes(p.Name))
      if (newState === undefined) return
      // update tv upgrades state with new radio group selection
      $store.commit('setTvUpgrades', [...newState, object])
    }

    return {
      tvSelections,
      tvSelectionChanged,
      servicesModel,
      channelsModel,
      addOnsModel,
      upgrades,
      services,
      channels,
      channelGroups,
      getChannelTitle,
      mdiInformation,
      getProductSubtitle,
      SUBCATEGORY,
      equipmentGroups,
      equipmentGroupChanged,
      equipmentProducts,
      equipmentMinTvs,
      getGroupTitle,
      currentProduct,
      currentGroup,
      isExcluded,
      channelText,
      serviceGroups,
      serviceRadioBoxes,
      serviceRadioChange,
      itemDisplayName,
      getItemPrice,
      renderMe,
      streamingOptionsText,
      tvHeaderImage,
      tvHeaderBackgroundColor,
      tvHeaderText,
      tvSetUpText,
      showTvSetUpSubtext,
      setTopBoxSubtitleText,
      getGroupSubtitle,
      getCatalogItemClassName,
      coreCurrency,
      uiMacroParser,
      shopper
    }
  }
})
</script>

<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: -10px;
}

.v-list-item__subtitle {
  white-space: normal;
}

.v-list-item__content {
  margin: 0px;
  padding: 5px;
}
.v-list-item__action {
  margin: 0px;
  padding: 0px;
}

#tv .v-list-item {
  margin: 0px;
  padding: 0px;
  min-height: 6px;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}
</style>

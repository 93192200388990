import useShopper from '@/components/order/useShopper'
import { RemoteLoggingMessage, RemoteLogLevel } from '@adg/catalog/src/common/RemoteLoggingMessage'
import $store from '@/store'

function limitStringLength(arg: any) {
  if (typeof arg === 'string' && arg.length > 1000) {
    return arg.substring(0, 1000) + '...'
  }
  return arg
}

function log(level: RemoteLogLevel, msg: string, args: any[]): void {
  const { getShopper, isDebug } = useShopper($store)
  // if (level === 'debug' && !isDebug.value) return
  const shopper = getShopper()
  const sendargs = args.map(limitStringLength)
  $store.dispatch('remoteLogging', { shopperId: shopper.id, level, msg, args: sendargs } as RemoteLoggingMessage)
}

export const logger = {
  warn: (msg: string, ...args: any[]): void => log('warn', msg, args),
  info: (msg: string, ...args: any[]): void => log('info', msg, args),
  error: (msg: string, ...args: any[]): void => log('error', msg, args),
  debug: (msg: string, ...args: any[]): void => log('debug', msg, args)
}

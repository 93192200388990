import { getConfigBoolean, getConfigItem } from './../components/shared/getConfigItem'
import { computed } from '@vue/composition-api'
import { AutomationStatus, commonShouldAutomate } from '@adg/catalog/src/common/AutomationState'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'

//todo: how should we type the store
export default ($store) => {
  const shopper = computed<IShopper>(() => $store.getters.getShopper)
  const automationStatus = computed<AutomationStatus>(() => shopper.value.automationState?.status)

  const cancelAutomation = (msg: string = 'Client Canceled') => {
    $store.dispatch('cancelAutomation', msg)
  }

  const getshouldAutomateReasons = (failureReasons: string[]): boolean => {
    return commonShouldAutomate(
      shopper.value,
      getConfigBoolean(ConfigKeys.enableAutomation),
      getConfigItem(ConfigKeys.automationBlockers) ?? [],
      getConfigBoolean(ConfigKeys.automateActiveAccounts),
      getConfigBoolean(ConfigKeys.automateCommercialAccounts),
      getConfigBoolean(ConfigKeys.automateInitialPreSales),
      getConfigBoolean(ConfigKeys.automateReturnPreSales),
      getConfigItem(ConfigKeys.automatedMatchTypeRegex),
      failureReasons,
      getConfigItem(ConfigKeys.customAutomationBlockerConditions)
    )
  }

  const shouldAutomate = computed(() => getshouldAutomateReasons([]))

  return {
    automationStatus,
    shouldAutomate,
    getshouldAutomateReasons,
    cancelAutomation
  }
}

import { AutomationSchedule, ScheduleAutomated, Timeslot } from './../../../../common/AutomationSchedule'
import { Dictionary } from 'lodash'

export interface OrderSchedule extends AutomationSchedule {
  installOption?: InstallOption
  scheduleDate?: string
  scheduleDate2?: string
  emailScheduleInfo?: string
  confirmationOption?: string
  timeSlotsByDay?: Dictionary<Timeslot[]>
}

export const newSchedule = (): OrderSchedule => ({
  automated: 'no',
  installOption: 'N/A',
  scheduleDate: undefined,
  scheduleDate2: undefined,
  timeSlots: [],
  emailScheduleInfo: undefined,
  timeSlotsByDay: undefined
})

export interface Installation {
  installOption: InstallOption
  scheduleDate: Date
  scheduleDate2: Date
  timeOfDay: TimeOfDay
  timeOfDay2: TimeOfDay
}

export type InstallOption =
  | 'automated'
  | 'chooseMyDates'
  | 'nextAvailable'
  | 'AM'
  | 'PM'
  | 'firstAvailable'
  | 'callMeToSchedule'
  | 'selfInstall'
  | 'N/A'

export enum TimeOfDay {
  Afternoon = 'afternoon',
  Morning = 'morning'
}

// export interface TimeSlot {
//   startTime: number
//   endTime: number
//   description: string
//   label: string
//   timeSlotCode?: TimeSlotCode
//   clientDefined?: ClientDefined
// }

// export interface ClientDefined {
//   pointsAllocated: number
//   pointsAssigned: number
// }

// export enum TimeSlotCode {
//   A1 = 'A1',
//   A2 = 'A2',
//   Am = 'AM',
//   P1 = 'P1',
//   P2 = 'P2',
//   Pm = 'PM'
// }

<template>
  <div style="display: inline-block">
    <div v-if="tooltips.length > 0 && tooltips[0].component">
      <component :is="tooltips[0].component" :toolTip="tooltips[0]" />
    </div>
    <v-tooltip
      right
      :color="color ? color : tooltipColor"
      :open-on-hover="tooltips[0].clickable ? false : true"
      id="pt"
      v-if="tooltips.length > 0 && !tooltips[0].component"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          :size="$vuetify.breakpoint.xsOnly ? 18 : 18"
          class="secondaryDark--text text--lighten-2"
          dark
          id="tooltip-icon"
          v-bind="attrs"
          v-on="on"
          v-if="tooltips.length > 0 && !tooltips[0].clickable"
        >
          {{ tooltipMdiIcon ? tooltipMdiIcon : mdiInformation }}</v-icon
        >
        <v-icon
          :size="$vuetify.breakpoint.xsOnly ? 18 : 18"
          class="secondaryDark--text text--lighten-2"
          dark
          id="tooltip-icon"
          v-bind="attrs"
          v-on="on"
          @click="on.click"
          @blur="on.blur"
          v-if="tooltips.length > 0 && tooltips[0].clickable"
        >
          {{ tooltipMdiIcon ? tooltipMdiIcon : mdiInformation }}</v-icon
        >
      </template>
      <div>
        <v-list
          :width="$vuetify.breakpoint.xs ? '100%' : 400"
          :dark="isTooltipDark === 'false' ? false : true"
          :color="color ? color : tooltipColor"
        >
          <div v-for="tooltip in tooltips" :key="tooltip.name">
            <v-list-item v-if="tooltip.channelGroup && tooltip.channelGroup.length > 0">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">Included Channels</v-list-item-title>
                <v-list-item>
                  <ul>
                    <li v-for="c in tooltip.channelGroup" :key="c">{{ c }}</li>
                  </ul>
                </v-list-item>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{ tooltip.title ? tooltip.title : tooltip.name }}</v-list-item-title>
                <span v-html="tooltip.message" />
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </div>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from '@vue/composition-api'
import { mdiInformation } from '@mdi/js'
import VastHomeAutomationMarketing from '@/components/order/home-automation/client/VastHomeAutomationMarketing.vue'
import { ITooltipPayload, TooltipPayload } from './TooltipPayload'
import TooltipDialog from './TooltipDialog.vue'
import useTooltip from '@/components/shared/tooltip/useTooltip'
import { GET_ALL_PRODUCT_ITEMS } from '@/store/modules/store'
import { getConfigItem } from '../getConfigItem'
import { ConfigKeys, Equipment, Upgrade } from '@adg/catalog/src/modules/Catalog'
import { Tooltip } from './ITooltip'

export default defineComponent({
  name: 'Tooltip',
  props: {
    color: String,
    tooltipMdiIcon: String,
    isTooltipDark: String,
    payload: {
      type: Object as () => ITooltipPayload,
      required: true
    }
  },
  components: {
    VastHomeAutomationMarketing,
    TooltipDialog
  },
  setup(props, { root: { $store } }) {
    const { getTooltip } = useTooltip($store)

    const tooltipColor = computed(() => getConfigItem(ConfigKeys.tooltipColor) ?? 'secondaryDark')

    const tooltips = computed(() => {
      let tips: Tooltip[] = []
      const tooltip = getTooltip(props.payload)

      if (tooltip) {
        if (tooltip.composite || tooltip.channelGroup || tooltip.message || tooltip.component) {
          if (tooltip.component) {
            tips.push(tooltip)
          } else if (tooltip.composite) {
            tooltip.composite.forEach((t) => {
              if (t) {
                // t and tt should not be undefined if the catalog is correct, but can be if there are bad xrefs in 'TT Composite'
                let tt = getTooltip({ Name: t })
                if (tt) tips.push(tt)
              }
            })
            if (tooltip.type === 'Product') {
              const all = $store.getters[GET_ALL_PRODUCT_ITEMS].map((i: Upgrade | Equipment) => i.Name)
              tips = tips.filter((t) => all.includes(t.name))
            }
          } else {
            tips.push(tooltip)
          }
        }
      }
      return tips
    })

    return {
      mdiInformation,
      tooltips,
      tooltipColor
    }
  }
})
</script>

<style></style>

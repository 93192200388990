<template>
  <div>
    <v-row>
      <v-col>
        <div class="title field-label" v-html="field.label" />
      </v-col>
    </v-row>
    <v-row class="pb-2" v-for="(q, i) in questions" v-bind:key="`question-${i}`">
      <v-col class="col-12 ref-col">
        <v-select
          dense
          clearable
          outlined
          :rules="rules"
          :items="openQuestions"
          v-model="questions[i]"
          item-text="name"
          item-disabled="disabled"
          label="Select Question"
        />
      </v-col>
      <v-col v-if="questions[i]" class="col-12 ref-col">
        <v-text-field dense :rules="rules" outlined v-model="answers[i]" label="Enter Answer" />
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import $store from '@/store'
import { AccountField } from '@adg/catalog/src/common/UIConfig'
import { getRules } from './accountFunctions'

export default defineComponent({
  name: 'SecurityQuestions',
  props: {
    field: {
      type: Object as PropType<AccountField>,
      required: true
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const openQuestions = computed(() => {
      if (props.field?.questions)
        return props.field.questions.map((q, i) => {
          return {
            ...q,
            disabled: questions.value.includes(q.name)
          }
        })
      else return []
    })

    const questions = ref(new Array(props.field.maxLength ?? 2) as string[])
    const answers = ref(new Array(props.field.maxLength ?? 2) as string[])
    const getLocalRules = (ruleNames: string[]) => getRules($store, ruleNames)

    const updateSecurityQuestions = () => {
      const qAndAs = questions.value.map((q, i) => {
        return {
          question: q,
          answer: answers.value[i]
        }
      })
      $store.commit('setSecurityQuestions', qAndAs)
    }

    watch(questions, (pre, post) => {
      updateSecurityQuestions()
    })
    watch(answers, (pre, post) => {
      updateSecurityQuestions()
    })
    return { openQuestions, questions, answers, getLocalRules }
  }
})
</script>
<style>
.ref-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -10px;
  margin-bottom: -10px;
}
</style>

import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

const defaultTheme = {
  primary: '#A0A0A0',
  secondary: '#b0bec5',
  accent: '#8c9eff',
  error: '#b71c1c',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  footerColor: '#000000'
}

export default new Vuetify({
  options: {
    customProperties: true,
    variations: true
  },
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      light: defaultTheme
    }
  }
})

<template>
  <div>
    <div v-if="paymentInfo && paymentInfo.status === 'declined'">
      <v-row class="grey lighten-3 pb-0">
        <v-col cols="12" class="caption text--secondary">
          <span>{{ paymentDeclinedText }} </span>
        </v-col>
      </v-row>
    </div>
    <div v-if="paymentInfo && paymentInfo.status === 'error'">
      <v-row class="grey lighten-3 pb-0">
        <v-col cols="12" class="caption text--secondary">
          <span
            >An error occured while processing your payment. A customer service representative will reach out to you to finalize
            your order.
          </span>
        </v-col>
      </v-row>
    </div>
    <div v-if="showSuccess">
      <div v-if="paymentConfig && paymentConfig.paymentApprovedText" v-html="paymentConfig.paymentApprovedText" />
      <v-row v-if="amountPaid" class="grey lighten-3 pb-0">
        <v-col cols="8" class="grey--text subtitle pb-0 pt-0">Amount Paid</v-col>
        <v-col cols="4" class="grey--text text-right pb-0 pt-0">{{ amountPaid }}</v-col>
      </v-row>
      <v-row class="grey lighten-3 pb-0">
        <v-col cols="8" class="grey--text subtitle pb-0">Payment Date</v-col>
        <v-col cols="4" class="grey--text text-right pb-0">{{ payDate }}</v-col>
      </v-row>
      <v-row v-if="payType" class="grey lighten-3 pb-0">
        <v-col cols="8" class="grey--text subtitle pb-0">Payment Type</v-col>
        <v-col cols="4" class="grey--text text-right pb-0">{{ payType }}</v-col>
      </v-row>
      <v-row v-if="cardNumber" class="grey lighten-3 pb-0">
        <v-col cols="7" class="grey--text subtitle pb-0">Card Number</v-col>
        <v-col cols="5" class="grey--text text-right pb-0">{{ cardNumber }}</v-col>
      </v-row>
      <v-row v-if="referenceNumber" class="grey lighten-3 pb-0">
        <v-col cols="7" class="grey--text subtitle pb-0">Reference Number</v-col>
        <v-col cols="5" class="grey--text text-right pb-0">{{ referenceNumber }}</v-col>
      </v-row>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api'
import $store from '@/store'
import useCart from './useCart'
import { PaymentConfig } from '@adg/catalog/src/common/UIConfig'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'

export default defineComponent({
  name: 'ConfirmationPayment',
  setup(props, { emit }) {
    const { shopper, grandTotal } = useCart($store)
    const paymentConfig = computed((): PaymentConfig => getConfigItem(ConfigKeys.paymentConfig))
    const paymentInfo = computed(() => shopper.value.customInfo?.paymentInfo)
    const payDate = computed(() => {
      let payDay
      if (paymentInfo.value?.convergeResponse?.ssl_txn_time) {
        payDay = new Date(paymentInfo.value.convergeResponse.ssl_txn_time)
      } else {
        payDay = new Date()
      }
      return payDay.toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' })
    })

    const paymentDeclinedText = computed(
      () =>
        paymentConfig.value?.paymentDeclinedText ??
        'Your payment has been declined. A customer service representative will reach out to you to finalize your order.'
    )
    const amountPaid = computed(() => `$${grandTotal.value.toFixed(2)}`)
    const payType = computed(() => paymentInfo.value?.convergeResponse?.ssl_card_short_description ?? undefined)
    const cardNumber = computed(() => paymentInfo.value?.convergeResponse?.ssl_card_number ?? undefined)
    const referenceNumber = computed(() => {
      if (paymentConfig.value.payType === 'converge') return paymentInfo.value?.convergeResponse?.ssl_transaction_reference_number
      else return undefined
    })
    const showSuccess = computed(() => {
      if (paymentInfo.value?.status === 'success') return true
      if (paymentInfo.value?.status === 'approved') return true
      return false
    })
    return {
      paymentInfo,
      paymentConfig,
      payDate,
      paymentDeclinedText,
      amountPaid,
      payType,
      cardNumber,
      referenceNumber,
      showSuccess
    }
  }
})
</script>
<style></style>

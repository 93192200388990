import { UIConfig } from '@adg/catalog/src/common/UIConfig'
import { getConfigItem, getConfigBoolean } from './../shared/getConfigItem'
import { Catalog, ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { MatchType } from '@adg/catalog/src/common/MatchType'
import Classification from '@/constants/Classification'
import Serviceable from '@/constants/Serviceable'
import { FullAddress } from '@adg/catalog/src/modules/Address/address'
import { Place } from '@adg/catalog/src/modules/Shopper'
// import { SalesLeadInfo } from '@adg/catalog/src/SalesLeadInfo'
// import useUiConfig from '../shared/useUiConfig'
// import store from '@/store/modules/store'

export default () => {
  const formattedAddress = (address: Place) => {
    let filtered = Object.entries(address).filter((e) => e[0] !== 'entries' && e[1] !== '')
    if (address.entries && address.entries > 1) {
      filtered = filtered.filter((e) => e[0] !== 'secondary')
    }
    const formatted = filtered.map((e) => e[1]).join(' ')

    return address.entries && address.entries > 1 ? `${formatted} (${address.entries} units)` : formatted
  }
  const secondarySearch = (address: Place) => {
    let parts: string[] | number[] = []
    const keys = Object.keys(address).filter((k) => k !== 'entries')
    for (const k of keys) {
      // @ts-ignore
      parts.push(address[k])
      if (k === 'secondary') {
        // @ts-ignore
        parts.push(`(${address.entries})`)
      }
    }
    return parts.join(' ')
  }
  const convertAddress = (address: Place): Place => {
    return {
      ...address,
      full: formattedAddress(address),
      secondarySearch: address.entries && address.entries > 1 ? secondarySearch(address) : undefined
    }
  }

  const proceedToBuyFlow = (address: FullAddress, shopper: IShopper, catalog: Catalog) => {

    const noMatch = !address || address.serviceable === Serviceable.PENDING
    if (noMatch) return false

    const addressMatchType = address?.matchType || MatchType.NONE
    const activeRegex = /^active$/i
    const customerStatus = shopper?.tags?.customerStatus != undefined ? shopper?.tags?.customerStatus.trim() : ''
    const activeCustomer = activeRegex.test(customerStatus)

    if (isSalesLead(address, catalog)) return false

    const comm = getConfigBoolean(ConfigKeys.processCommercial)
    const commRegex = new RegExp(`${Classification.COMMERCIAL}`, 'i')

    if (
      commRegex.test(shopper.tags?.classification) &&
      !getConfigItem(ConfigKeys.processCommercial) &&
      [MatchType.EXACT, MatchType.BUILDING, MatchType.POLYGON, MatchType.VETRO_V2].includes(addressMatchType)
    ) {
      return false
    }

    if ([MatchType.ZIP_PLUS_STREET, MatchType.ZIP_ONLY, MatchType.GEOCODE, MatchType.REFERRER, MatchType.EXACT_DUP, MatchType.BUILDING].includes(addressMatchType)) {
      return true
    }

    if (
      !activeCustomer &&
      [MatchType.EXACT, MatchType.SIMILAR, MatchType.POLYGON, MatchType.VETRO, MatchType.VETRO_V2].includes(addressMatchType)
    ) {
      return true
    }

    return false
  }

  const isSalesLead = (matchedAddress, catalog): boolean => {
    const lead =
      !matchedAddress ||
      matchedAddress.matchType === MatchType.NONE ||
      !!catalog.Config.find((k) => k.Key === 'salesLead') ||
      matchedAddress.serviceable === Serviceable.PENDING
    return lead
  }

  const getServiceabilityQuestions = (matchedAddress, isResidential: boolean, uiConfig: UIConfig) => {
    if (!matchedAddress || matchedAddress.matchType === MatchType.NONE) {
      return [
        {
          question: `Sorry, we don't currently offer service in your area.`,
          link: `Click <a href="/order">here</a> to try another address`
        }
      ]
    }

    if (isResidential) {
      return (
        getConfigItem(ConfigKeys.serviceabilityQuestions) ?? [
          {
            question: 'I AM A NEW CUSTOMER MOVING TO THIS ADDRESS',
            img: 'serviceabilityNewCustomer.jpg',
            actions: [{ name: 'Select', proceedToBuyFlow: true, value: 'newMoving' }]
          },

          {
            question: `I AM AN EXISTING ${uiConfig.companyName.toUpperCase()} CUSTOMER MOVING TO THIS ADDRESS`,
            img: 'serviceabilityExistingCustomer.jpg',
            actions: [
              {
                name: 'Select',
                link: uiConfig.moverLink,
                proceedToBuyFlow: false,
                value: 'existingMoving'
              }
            ]
          },
          {
            question: `I AM AN EXISTING ${uiConfig.companyName.toUpperCase()} CUSTOMER ALREADY LIVING AT THIS ADDRESS`,
            img: 'serviceabilityContactUs.jpg',
            actions: [
              {
                name: `call ${uiConfig.customerService}`,
                link: `tel:${uiConfig.customerService}`,
                proceedToBuyFlow: false,
                value: 'existingLiving'
              }
            ]
          }
        ]
      )
    } else {
      return [
        {
          question: 'Are you looking for commercial products and services?',
          img: 'business.jpg',
          actions: [
            {
              name: 'Yes',
              link: uiConfig.businessLink,
              proceedToBuyflow: false,
              value: 'commercialYes'
            },
            { name: 'No', proceedToBuyFlow: true, value: 'commercialNo' }
          ]
        }
      ]
    }
  }

  return {
    convertAddress,
    proceedToBuyFlow,
    isSalesLead,
    getServiceabilityQuestions
  }
}

import { computed } from '@vue/composition-api'
import { CLOUD_STORAGE_UPGRADE, SUBCATEGORY } from '@/store/types'
import { CountedEquipmentGroup, Equipment } from '@adg/catalog/src/modules/Catalog'

export default (product?) => {
  const equipment = computed(() =>
    product && product.Equipment ? product.Equipment.filter((e) => !e.OTC).sort((a, b) => a.Rank - b.Rank) : []
  )
  const mapCountedSelections = (equipment: CountedEquipmentGroup[]) => {
    const filtered: Equipment[] = []
    equipment.forEach((equip, index) => {
      if (!equip.subItems) filtered.push(equip)
      else {
        const eCount = filtered.filter((f) => f.Name === equip.Name).length + 1
        const newFiltered = equip.subItems.find((sub) => (sub.Min ?? 0) <= eCount && eCount <= (sub.Max ?? Infinity)) ?? equip
        newFiltered.Name = equip.Name
        // newFiltered['Display Name'] = equip['Display Name']
        filtered.push(newFiltered)
      }
    })
    return filtered
  }
  return {
    equipment,
    mapCountedSelections
  }
}

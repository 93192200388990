import { ConsoleSpanExporter, BatchSpanProcessor, Tracer, IdGenerator, RandomIdGenerator } from '@opentelemetry/sdk-trace-base'
import { Resource } from '@opentelemetry/resources'
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load'
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction'
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { Span, context, propagation } from '@opentelemetry/api'
import { CompositePropagator } from '@opentelemetry/core'
import { B3Propagator, B3InjectEncoding } from '@opentelemetry/propagator-b3'

let GLOBAL_TRACE_ID: string | undefined = undefined
const randomIdGenerator = new RandomIdGenerator()

let AppTracer: Tracer | undefined = undefined
let currentSpan: Span | undefined = undefined

export function switchRouteSpan(spanName: string): void {
  if (!AppTracer || !GLOBAL_TRACE_ID) {
    return
  }
  if (currentSpan) {
    currentSpan.end()
  }
  currentSpan = AppTracer.startSpan(spanName)
}

export function initTracer(applicationName: string): Tracer {
  //   const propagator = new CompositePropagator({
  //     propagators: [
  //       new B3Propagator(),
  //       new B3Propagator({
  //         injectEncoding: B3InjectEncoding.MULTI_HEADER
  //       })
  //     ]
  //   })
  //   propagation.setGlobalPropagator(propagator)
  const collectorOptions = {
    // url: `http://${OTEL_AGENT_HOST}:${OTEL_AGENT_PORT}/v1/traces`,
    url: '/v1/traces',
    headers: {} //an optional object containing custom headers to be sent with each request
  }

  const idGenerator: IdGenerator = {
    generateTraceId: () => {
      GLOBAL_TRACE_ID ??= randomIdGenerator.generateTraceId()
      return GLOBAL_TRACE_ID
    },
    generateSpanId: () => {
      return randomIdGenerator.generateSpanId()
    }
  }

  const provider = new WebTracerProvider({
    idGenerator,
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: applicationName
    })
  })
  //provider.addSpanProcessor(new BatchSpanProcessor(new ConsoleSpanExporter()))
  provider.addSpanProcessor(new BatchSpanProcessor(new OTLPTraceExporter(collectorOptions)))

  provider.register({
    // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
    contextManager: new ZoneContextManager()
  })

  AppTracer = provider.getTracer(applicationName)
  currentSpan = AppTracer.startSpan('/')

  // Registering instrumentations
  registerInstrumentations({
    instrumentations: [
      new DocumentLoadInstrumentation(),
      //      new UserInteractionInstrumentation(),
      new XMLHttpRequestInstrumentation()
    ]
  })

  // initialize baggage for client_id
  // if ((window as any)?.CLIENT_ID) {
  //   const baggage = propagation.getBaggage(context.active()) || propagation.createBaggage()
  //   baggage.setEntry('client_id', (window as any).CLIENT_ID)
  //   propagation.setBaggage(context.active(), baggage)
  // }

  return AppTracer
}

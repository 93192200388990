import { Equipment } from '@adg/catalog/src/modules/Catalog'

export default (props) => {
  const checkbox = (product) => product && product.Min === 0 && product.Max === 1

  const productPicker = (product) => product && product.Min >= 0 && product.Max > 1

  const radioGroup = (products) => {
    const radios = products ? products.filter((p) => p.Min === 1 && p.Max === 1) : []
    // const totalProducts = products ? products.length : 0
    return radios.length >= 2
  }

  const findChildren = (product: Equipment, products: Equipment[]) => {
    const a = products.filter((p) => {
      return p.Parent && p.Parent.toLowerCase() === product.Name.toLowerCase()
    })
    return a
  }

  const buildProductTree = (products, allProducts) => {
    const tree = products.map((t) => {
      const children = findChildren(t, allProducts)
      if (children && children.length > 0) {
        t['children'] = children
        buildProductTree(children, allProducts)
        return t
      } else {
        t['children'] = []
        return t
      }
    })
    return tree
  }

  const associateChildrenToParents = (products) => {
    const result = buildProductTree(products, products)
    const allChildren = result.flatMap((r) => r.children).map((r) => r.Name)
    const parents = result.filter((r) => !allChildren.includes(r.Name))
    return parents
  }

  return {
    associateChildrenToParents,
    radioGroup,
    checkbox,
    productPicker,
    findChildren
  }
}

//import * as rax from 'retry-axios'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { logger } from '@/utils/RemoteLogger'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { ApiConfig } from '@adg/catalog/src/common/UIConfig'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import axiosRetry, { exponentialDelay, isNetworkOrIdempotentRequestError } from 'axios-retry'
import { v4 as uuidv4 } from 'uuid'
import { TimeSlotData } from '@adg/catalog'

const local = window.location.href.indexOf('.localhost') != -1
const retries = local ? 0 : 3
const timeout = local ? 120000 : 30000
export const httpClient = axios.create({ timeout: timeout })

export const makeAxiosConfig = (config?: ApiConfig): AxiosRequestConfig => {
  return {
    timeout: config?.callTimeout ?? getConfigItem(ConfigKeys.axiosTimeout) ?? timeout,
    'axios-retry': {
      retries: config?.retries ?? retries
    }
  }
}

export async function makeIdempotentRetryRequest<T>(
  endpoint: string,
  request: TimeSlotData,
  config?: ApiConfig
): Promise<AxiosResponse<T | any> | undefined> {
  try {
    let pending = true
    let tries = 0
    let apiResponse: AxiosResponse<T | any> | undefined
    const idempotentId = uuidv4() //maybe needs to be a function that gradually increases the generated number by 1000 or something

    request.reqId = idempotentId
    request.pendingTimeout = config?.pendingTimeout ?? 15000

    setTimeout(() => {
      pending = false
    }, config?.callTimeout ?? 30000)

    while (pending) {
      apiResponse = await httpClient.post<T>(endpoint, request, makeAxiosConfig(config))
      pending = apiResponse.status === 202
      if (pending) {
        tries++
        //try again in x seconds
        logger.info(`Checking if ${endpoint} is finished for idempotentId: ${idempotentId} (attempt: ${tries})`)
        await new Promise((resolve) => setTimeout(resolve, config?.retryDelay ?? 2000))
      }
    }
    return apiResponse
  } catch (err) {
    logger.error(`Error in idempotent retry request: ${err}`)
    return undefined
  }
}

// config axios-retry defaults
axiosRetry(httpClient, {
  retries: retries,
  retryDelay: exponentialDelay,
  shouldResetTimeout: true,
  retryCondition: (error: Error) => {
    // console.log('axios-retry error: ', { error })
    //logger.warn(`axios-retry condition:`, error)
    return true
  }
})
// axiosRetry(coreAxios, {
//   retries: 5,
//   retryDelay: exponentialDelay,
//   retryCondition: (error: Error) => {
//     console.log('axios-retry error: ', error)
//     if (axios.isAxiosError(error)) {
//       if (error.response?.status === 429) return true
//     } else {
//       // KWC: I think this should never happen, but just in case
//       logger.warn(`Axios-retry handling non-axios error: ${error.message}`)
//     }
//     return isNetworkOrIdempotentRequestError(error)
//   }
// })

// /**
//  * https://github.com/JustinBeckwith/retry-axios.git
//  */

// export const httpClient = axios.create()
// httpClient.defaults.raxConfig = {
//   // Retry 5 times on requests that return a response (500, etc) before giving up.  Defaults to 3.
//   retry: 5,

//   // Retry twice on errors that don't return a response (ENOTFOUND, ETIMEDOUT, etc).
//   noResponseRetries: 2,

//   // Milliseconds to delay at first.  Defaults to 100.
//   retryDelay: 1000,

//   // HTTP methods to automatically retry.  Defaults to:
//   // ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT']
//   httpMethodsToRetry: ['POST', 'GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT'],

//   // The response status codes to retry.  Supports a double
//   // array with a list of ranges.  Defaults to:
//   // [[100, 199], [429, 429], [500, 599]]
//   statusCodesToRetry: [
//     [100, 199],
//     [202, 202],
//     [429, 429],
//     [500, 599]
//   ],

//   // If you are using a non static instance of Axios you need
//   // to pass that instance here (const ax = axios.create())
//   instance: httpClient,

//   // You can set the backoff type.
//   // options are 'exponential' (default), 'static' or 'linear'
//   backoffType: 'static',

//   // You can detect when a retry is happening, and figure out how many
//   // retry attempts have been made
//   onRetryAttempt: (err) => {
//     console.log('axios attempting retry', err)
//     const cfg = rax.getConfig(err)
//     if (cfg) {
//       console.log(`Retry attempt #${cfg.currentRetryAttempt}`)
//     }
//   }
// }
// const interceptorId = rax.attach(httpClient)
// export default coreAxios

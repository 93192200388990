<template>
  <v-tooltip bottom :color="accountInfoTooltipColor" id="account-info-header-tooltip" v-if="message">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">{{ icon }}</v-icon>
    </template>
    {{ message }}
  </v-tooltip>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TooltipBasic',
  props: {
    message: String,
    icon: String,
    accountInfoTooltipColor: String
  },

  setup(props) {}
})
</script>

<style></style>

<template>
  <div>
    <div v-if="customSchedulingOptions">
      <div v-html="customSchedulingOptionsHeaderText"></div>
      <v-radio-group v-model="schedule.installOption" :mandatory="true" class="mt-0">
        <v-row class="pl-2" v-for="(option, index) in customSchedulingOptions" :key="index">
          <v-radio :value="option.value"> </v-radio>
          {{ option.text }}
        </v-row>
      </v-radio-group>
      <div v-if="customConfirmationOptions">
        <div v-html="customConfirmationOptionsHeaderText"></div>
        <v-radio-group v-model="schedule.confirmationOption" :mandatory="true" class="mt-0">
          <v-row class="pl-2" v-for="(option, index) in customConfirmationOptions" :key="index">
            <v-radio :value="option.value"> </v-radio>
            {{ option.text }}
          </v-row>
        </v-radio-group>
      </div>
    </div>
    <div v-else>
      <v-radio-group v-model="schedule.installOption" :mandatory="true" class="mt-0">
        <div v-if="showSelfInstallation">
          <div v-if="selfInstallationHeaderText" v-html="selfInstallationHeaderText" class="pb-3"></div>
          <v-row class="pl-3 pb-2" style="flex-wrap: nowrap">
            <v-radio value="selfInstall"></v-radio>
            {{ selfInstallationRadioText }}
          </v-row>
          <p v-show="schedule.installOption === 'selfInstall'" v-html="selfInstallationSelectedText" class="pl-8"></p>
        </div>
        <div v-if="professionalInstallationHeaderText" v-html="professionalInstallationHeaderText" class="pb-1"></div>
        <v-row class="pl-3 pb-0 pt-2" style="flex-wrap: nowrap">
          <v-radio value="nextAvailable"></v-radio>
          <div v-html="nextAvailableInstallationText"></div>
        </v-row>
        <v-row class="pl-3 pb-0" style="flex-wrap: nowrap">
          <v-radio value="chooseMyDates"></v-radio>
          <div v-html="chooseMyDatesText"></div>
        </v-row>
        <v-row v-if="callMeToScheduleText" class="pl-3 pb-0" style="flex-wrap: nowrap">
          <v-radio value="callMeToSchedule"></v-radio>
          <div v-html="callMeToScheduleText"></div>
        </v-row>
      </v-radio-group>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api'
import $store from '@/store'
import { getConfigBoolean, getConfigItem } from '@/components/shared/getConfigItem'
import { ConfigKeys, Product } from '@adg/catalog/src/modules/Catalog'
import { get } from 'http'

export default defineComponent({
  name: 'NonAutomatedSchedulingOptions',
  setup(props, { emit }) {
    const schedule = computed({
      get: () => $store.getters.getCartSchedule,
      set: (val) => $store.commit('setCartSchedule', val)
    })
    const showSelfInstallation = computed(() => getConfigBoolean(ConfigKeys.showSelfInstallScheduling, combinedLobs.value))

    const selfInstallationHeaderText = computed(() => getConfigItem(ConfigKeys.selfInstallationHeaderText))

    const combinedLobs = computed(() => {
      const cart = $store.getters.getCart
      let value = ''
      cart.products.forEach((product: Product) => (value += product['Product Type']))
      return value
    })

    const selfInstallationRadioText = computed(() => getConfigItem(ConfigKeys.selfInstallationRadioText))

    const selfInstallationSelectedText = computed(() => getConfigItem(ConfigKeys.selfInstallationSelectedText))
    const professionalInstallationHeaderText = computed(() => getConfigItem(ConfigKeys.professionalInstallationHeaderText))

    const nextAvailableInstallationText = computed(
      () => getConfigItem(ConfigKeys.nextAvailableInstallationText) ?? 'I would like the next available installation time'
    )
    const chooseMyDatesText = computed(
      () => getConfigItem(ConfigKeys.chooseMyDatesText) ?? 'I would like to request a date/time for installation'
    )

    const callMeToScheduleText = computed(() => getConfigItem(ConfigKeys.callMeToScheduleText))

    const customSchedulingOptions = computed(() => getConfigItem(ConfigKeys.customSchedulingOptions))

    const customConfirmationOptions = computed(() => getConfigItem(ConfigKeys.customConfirmationOptions))

    const customSchedulingOptionsHeaderText = computed(
      () =>
        getConfigItem(ConfigKeys.customSchedulingOptionsHeaderText) ??
        'Choose your installation preferences and a representative will call you to confirm.'
    )

    const customConfirmationOptionsHeaderText = computed(
      () =>
        getConfigItem(ConfigKeys.customConfirmationOptionsHeaderText) ??
        'Choose your preferred method to receive your confirmation.'
    )

    return {
      schedule,
      showSelfInstallation,
      selfInstallationHeaderText,
      selfInstallationRadioText,
      selfInstallationSelectedText,
      professionalInstallationHeaderText,
      callMeToScheduleText,
      customSchedulingOptions,
      customConfirmationOptions,
      customSchedulingOptionsHeaderText,
      customConfirmationOptionsHeaderText,
      chooseMyDatesText,
      nextAvailableInstallationText
    }
  }
})
</script>
<style></style>

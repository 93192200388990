<template>
  <v-tooltip right color="secondaryDark">
    <template v-slot:activator="{ on }">
      <span>
        <span>Select the type of box you want for each TV</span>
        <v-btn v-on="on" x-small icon
          ><v-icon>{{ mdiInformation }}</v-icon></v-btn
        >
      </span>
    </template>
    <div>Which TV boxes are right for me?</div>
    <v-card max-width="350" color="secondaryDark">
      <v-list subheader three-line color="secondaryDark" dark>
        <v-list-item v-for="e in equipment" :key="e.Name">
          <v-list-item-content>
            <v-list-item-title>{{ e.Name }}</v-list-item-title>
            <v-list-item-subtitle v-if="e['Marketing Text 1']">{{ e['Marketing Text 1'] }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="e['Marketing Text 2']">{{ e['Marketing Text 2'] }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="e['Marketing Text 3']">{{ e['Marketing Text 3'] }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-tooltip>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { mdiInformation } from '@mdi/js'

export default defineComponent({
  name: 'TvEquipmentMarketing',
  props: {
    equipment: Array
  },
  setup(props) {
    // KWC : Comment out mutated property for LINT ... This Component isn't used anymore and probably
    // should be deleted or archived
    //props.equipment.sort(function(a, b) {
    //  if (a.Name > b.Name) return -1;
    //  if (a.Name < b.Name) return 1;
    //  return 0;
    //});

    //remove after merge
    return {
      mdiInformation
    }
  }
})
</script>

<style scoped></style>

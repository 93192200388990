import { IShopper } from '@/modules/Shopper'
import { ShopperParserFunctions } from './Interface/Parser'
import { sanitizeString } from './macroParser'
import { BaseParser } from './BaseParser'

export class ShopperParser extends BaseParser {
  public shopper
  public pkg

  constructor(shopper: IShopper, pkg: object, validClasses?: string[]) {
    super(validClasses)
    this.shopper = shopper
    this.pkg = pkg

    this.addfunctions({
      getShopperField: this.getShopperField
    } satisfies ShopperParserFunctions)
  }
  // Helper methods
  // stub function to get shopper field with a default value
  getShopperField = (fieldname: string) => sanitizeString(this.shopper[fieldname as keyof IShopper]) ?? ''
}


<!-- <v-container dense>
  <v-row no-gutters dense v-for="(q, i) in field.questions" v-bind:key="`referral-${i}`">
    <v-col dense justify="start" align-self="start" class="ref-col col-sm-6 col-12" cols="6">
      <v-checkbox dense :label="q.name" v-model="questions[i]" />
    </v-col>
    <v-col no-gutters justify="start" dense v-if="questions[i]" class="ref-col col-sm-6 col-12" cols="6">
      <v-text-field solo dense outlined :label="q.label" v-model="answers[i]" />
    </v-col>
  </v-row>
</v-container> -->

<!-- revisit to try and fix typing issues in the HTML, unsure how to handle it now -->

<div>
  <v-row>
    <v-col>
      <div class="title field-label" v-html="field.label" />
    </v-col>
    <v-col cols="2" class="add-button">
      <v-btn icon :disabled="maxAnswersSubmitted" color="primary" @click="addReferral()"
        >Add<v-icon>{{ mdiPlusCircle }}</v-icon></v-btn
      >
    </v-col>
  </v-row>
  <v-row class="pb-2" dense v-for="(q, i) in questions" v-bind:key="`referral-${i}`">
    <v-col class="col-11">
      <v-row>
        <v-col class="ref-col col-12" :class="questions[i] && questions[i].type ? 'col-sm-6' : 'col-sm-12'">
          <v-select
            dense
            outlined
            return-object
            :items="field.questions"
            label="Select One"
            item-text="name"
            v-model="questions[i]"
            :rules="required(i) ? rules : []"
          />
        </v-col>
        <v-col v-if="questions[i] && questions[i].type && questions[i].type === 'text'" class="ref-col col-sm-6 col-12">
          <v-text-field dense outlined :rules="getLocalRules(q.rules)" :label="q.label" v-model="answers[i]"> </v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="ref-col-2 pa-0" cols="1">
      <v-btn color="red" icon @click="removeReferral(i)" v-if="!required(i)"
        ><v-icon>{{ mdiCloseCircle }}</v-icon></v-btn
      >
    </v-col>
  </v-row>
</div>

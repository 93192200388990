
<v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xsOnly ? 400 : 500">
  <template v-slot:activator="{ on, attrs }">
    <div v-on:mouseenter="dialog = !dialog">
      <v-icon :size="$vuetify.breakpoint.xsOnly ? 18 : 18">
        {{ mdiInformation }}
      </v-icon>
    </div>
  </template>
  <v-card dark color="secondaryDark" class="pt-2 rounded-lg" rounded>
    <v-card-text v-for="item in items" :key="item.name" class="py-0">
      <v-card-title v-if="item.title" class="pb-0">{{ item.title }}</v-card-title>
      <v-img v-if="item.logo" max-height="100" max-width="200" :src="item.logo" />
      <v-card-text v-html="item.message" class="py-4" />
      <!--                          <span v-html="item.message"/>-->
      <v-row no-gutters justify="space-around" @click="handleCarousel(item)">
        <v-col cols="3" class="col-sm-3 pa-2" v-for="img in item.image" :key="img">
          <video style="object-fit: contain" :src="img" autoplay muted width="100%" />
        </v-col>
      </v-row>
    </v-card-text>
    <ImageCarousel :images="carouselImages" :dialog="carouselDialog" @dialogClosed="dialogClosed" />
  </v-card>
</v-dialog>

<template>
  <v-app>
    <template v-if="useHeaderNew">
      <div class="banner-header-container">
        <v-row class="no-gutters" height="auto">
          <v-col cols="12" class="promo-banner-col">
            <Banner class="promo-banner" />
          </v-col>
          <v-col cols="12" class="header-new-col">
            <HeaderNew class="header-new-banner" />
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-else>
      <Banner class="hidden-print-only" />
      <Header class="hidden-print-only" />
    </template>
    <v-main :class="`main-app-${currentStep}`" :id="currentStep === 1 ? 'serviceability-steps' : 'buyflow-steps'">
      <router-view></router-view>
    </v-main>
    <component :class="'footer-step-' + currentStep" :is="footerComponent" />
    <Spinner />
  </v-app>
</template>

<script lang="ts">
import { computed, defineComponent, watchEffect } from '@vue/composition-api'
import Header from '@/components/layout/Header.vue'
import HeaderNew from '@/components/layout/HeaderNew.vue'
import { GET_SHOW_SPINNER } from '@/store/types'
import Spinner from '@/components/shared/Spinner.vue'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { UIConfig } from '@adg/catalog/src/common/UIConfig'
import { getConfigItem } from './shared/getConfigItem'
import $store from '@/store'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import useOrder from './order/useOrder'
import AppFooter from '@/components/AppFooter.vue'
import AppFooterAlt from '@/components/AppFooterAlt.vue'
import useFooter from './useFooter'
import Banner from '@/components/layout/Banner.vue'

export default defineComponent({
  components: {
    AppFooter,
    AppFooterAlt,
    Header,
    HeaderNew,
    Spinner,
    Banner
  },

  setup(props, context) {
    const {
      root: { $router, $route }
    } = context

    const showSpinner = computed(() => $store.getters[GET_SHOW_SPINNER])
    const uiConfig = computed(() => $store.getters.getUIConfig as UIConfig)
    const footerColor = computed(() => getConfigItem(ConfigKeys.footerColor) ?? 'black')
    const useHeaderNew = computed(() => getConfigItem(ConfigKeys.useHeaderNew) ?? false)
    const { currentStep } = useOrder($store, $router)
    const { footerComponent } = useFooter($store)

    watchEffect(() => {
      //@ts-ignore
      $store.commit('updateShopperGoogleClientId', context.root.googleClientId)
      usePiniaShopper().shopper.googleClientId = (context.root as any).googleClientId
    })

    watchEffect(() => {
      //@ts-ignore
      $store.commit('updateShopperGoogleSessionId', context.root.googleSessionId)
      // TODO:
      // usePiniaShopper().shopper.googleSessionId = (context.root as any).googleSessionId
    })

    watchEffect(() => {
      //@ts-ignore
      $store.commit('updateShopperGoogleSessionNumber', context.root.googleSessionNumber)
      // TODO:
      // usePiniaShopper().shopper.googleSessionNumber = (context.root as any).googleSessionNumber
    })

    return { showSpinner, uiConfig, footerColor, currentStep, footerComponent, useHeaderNew }
  }
})
</script>

<style>
.card-bottom-corners {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.withSystemBar {
  padding: 40px;
}

.noSystemBar {
  padding-top: 10px;
}

#footer-container {
  padding: 0rem;
}

.promo-banner {
  max-height: 20px;
  height: auto !important;
  position: relative !important;
  width: 100% !important;
  overflow: scroll;
}

.promo-banner .v-system-bar {
  width: 100% !important;
}

.banner-header-container {
  display: flex;
  width: 100%;
  max-height: 112px;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.banner-header-container .row {
  width: 100%;
  max-height: 112px;
}

.banner-header-container .promo-banner-col {
  padding: 0px !important;
  max-height: 20px;
  height: auto;
  width: 100%;
  overflow: auto;
}

.banner-header-container .header-new-col {
  padding: 0px !important;
  max-height: 92px;
  height: auto;
  width: 100%;
  overflow: auto;
}

@media only screen and (max-height: 1000px) {
  .main-app-1 {
    height: 100%;
  }
}
</style>

<template>
  <v-card :tile="sharpCorners" class="text-center" width="100%" height="100%">
    <v-container class="streaming-service-card-container">
      <v-row :class="feature.RowClass" v-for="(feature, i) in streamingServiceFeatures" :key="'service' + i">
        <v-col :id="feature.Id" :class="feature.Class">
          <div v-if="feature.FeatureType === 'Text'" v-html="feature.Description"></div>
          <v-img contain v-if="feature.FeatureType === 'Image'" :src="feature.Image"></v-img>
          <v-btn
            :class="'buttonClass' + ' ' + getCatalogItemClassName(feature.Name, 'Feature')"
            v-if="feature.FeatureType === 'Button'"
            @click="toggleIsSelected"
            >{{ buttonText(feature) }}</v-btn
          >
          <v-checkbox
            v-if="feature.FeatureType === 'Checkbox'"
            :label="feature.Description"
            v-model="checkModel"
            :true-value="streamingService"
            :false-value="null"
            :class="getCatalogItemClassName(feature.Name, 'Feature')"
          ></v-checkbox>
          <Tooltip :payload="feature" />
          <TooltipDebug :item="feature" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api'
import { Feature, Upgrade } from '@adg/catalog/src/modules/Catalog'
import { VRow, VCol, VImg, VBtn } from 'vuetify/lib'
import { getItemFeatures } from '@adg/catalog/src/modules/Catalog'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import { UpgradeWithCalculatedPrice } from '@adg/catalog/src/common/UIConfig'
import { getCatalogItemClassName } from '@adg/catalog/src/common/utils'

export default defineComponent({
  name: 'StreamingServicesCard',
  props: {
    value: Object as () => UpgradeWithCalculatedPrice,
    streamingService: {
      type: Object as () => UpgradeWithCalculatedPrice,
      required: true
    },
    sharpCorners: Boolean
  },
  components: {
    Tooltip,
    TooltipDebug
  },
  setup(props, { emit }) {
    const streamingServiceFeatures = computed(() => {
      return getItemFeatures(props.streamingService as Upgrade)
    })
    const isSelected = computed(() => props.streamingService === props.value)
    const buttonClass = computed(() => (isSelected.value ? 'selected' : 'unselected'))

    const buttonText = (feature: Feature) => {
      if (!isSelected.value) {
        return feature.Description
      } else {
        return feature.AlternativeDescription ?? feature.Description
      }
    }

    const checkModel = computed({
      get: () => props.value,
      set: (val) => {
        emit('input', val)
        emit('change')
      }
    })

    const toggleIsSelected = () => {
      emit('input', isSelected.value ? undefined : props.streamingService)
      emit('change')
    }

    return {
      streamingServiceFeatures,
      buttonClass,
      isSelected,
      toggleIsSelected,
      emit,
      checkModel,
      buttonText,
      getCatalogItemClassName
    }
  }
})
</script>
<style scoped>
.streaming-service-card-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.row {
  flex: 0 1 auto;
}
.col {
  display: flex;
  justify-content: center;
}
.flex-end {
  margin-top: auto;
}
</style>

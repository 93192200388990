<template>
  <div v-if="!hasChildren">
    <v-row v-if="isDebug">
      <v-col class="py-0" cols="5"
        ><span>{{ name }}</span>
        <span v-if="showTypes" style="color: green; font-weight: bold"> {{ getType(value) }}</span>
      </v-col>
      <v-col class="py-0" cols="7">
        <!-- todo: value is obviously a string, but ts won't infer that. also why is the styling done in a v-bind here? -->
        <Shorten v-if="typeof value === 'string'" :shortenValue="value" :amount="20" />
        <span v-else-if="value === undefined" style="color: blue; font-weight: bold">UNDEFINED</span>
        <span v-else-if="value === null" style="color: red; font-weight: bold">NULL</span>

        <span v-else>{{ value }}</span></v-col
      >
    </v-row>
    <v-row v-else>
      <v-col class="py-0" cols="4"
        ><span>{{ name }}</span>
      </v-col>
      <v-col class="py-0" cols="8">
        <span>{{ value }}</span>
      </v-col>
    </v-row>
    <!-- <v-row v-for="key in value" :key="key">
      <v-col class="py-0 text-right" cols="4">
        <span>{{ key }}</span>
      </v-col>
      <v-col class="py-0 text-right" cols="8">
        <span>{{ value[key] }}</span>
      </v-col>
    </v-row> -->
  </div>
  <v-expansion-panels v-else flat v-model="panel" class="expansionPanels-low">
    <v-expansion-panel class="ma-0 pa-0" :id="value.success === false ? 'expTagLineRed' : 'expTagLine'">
      <v-expansion-panel-header hide-actions class="pa-0">
        <v-row v-if="objectValue">
          <v-col class="py-0" cols="5">
            <span :class="value.success === false ? 'failed-tags' : topLevel ? 'blueText' : ''">
              {{ name }}
              <v-icon v-if="panel == 0" class="pb-1 mr-1" small color="primary">{{ mdiMinusCircle }}</v-icon>
              <v-icon v-else class="pb-1 mr-1" small color="primary">{{ mdiPlusCircle }}</v-icon>
            </span>
          </v-col>
          <v-col class="py-0" cols="7">
            <!-- todo: value is obviously a string, but ts won't infer that. also why is the styling done in a v-bind here? -->
            <span :class="topLevel ? 'blueText' : ''" v-if="typeof value === 'string'">"{{ shorten(value, 10) }}"</span>
            <span v-else-if="value === undefined" style="color: blue; font-weight: bold">UNDEFINED</span>
            <span v-else-if="value === null" style="color: red; font-weight: bold">NULL</span>

            <span :class="topLevel ? 'blueText' : ''" v-else>"{{ objectValue }}"</span></v-col
          >
        </v-row>
        <v-row v-else class="ma-0">
          <v-col class="pa-0" cols="12">
            <span
              ><v-icon v-if="panel == 0" class="pb-1 mr-1" small color="primary">{{ mdiMinusCircle }}</v-icon>
              <v-icon class="pb-1 mr-1" v-else small color="primary">{{ mdiPlusCircle }}</v-icon
              >{{ name }}</span
            >
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-if="value && typeof value === 'object'" class="ma-0 pa-0">
          <div v-for="key in Object.keys(value)" :key="key" class="ma-0 pa-0">
            <div v-if="!isNaN(parseFloat(key)) && arrayName && arrayValue" class="tagtooltip">
              <ExpandableTagMap
                :name="value[key][arrayName] ?? `UNDEFINED`"
                :objectValue="value[key][arrayValue]"
                :value="value[key]"
                :showTypes="showTypes"
              />
              <div class="tooltiptext">
                <span>{{ `matchExp: "${shorten(value[key].matchExpression, 15)}"` }} </span><br />
                <span>{{ `matchVal: "${shorten(value[key].existingTagValues, 15)}"` }}</span>
              </div>
            </div>
            <ExpandableTagMap v-else :name="key" :value="value[key]" :showTypes="showTypes" />
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from '@vue/composition-api'
import { mdiPlusCircle, mdiMinusCircle } from '@mdi/js'
import $store from '@/store'
import ExpandableLine from './ExpandableLine.vue'
import Shorten from './Shorten.vue'

export default defineComponent({
  name: 'ExpandableTagMap',
  props: {
    name: String,
    value: [Object, Array, String, Number, Boolean, Map, Date],
    expanded: {
      type: Boolean,
      default: false
    },
    showTypes: {
      type: Boolean,
      default: true
    },
    objectValue: [String, Number, Boolean, Date],
    arrayName: String,
    arrayValue: String,
    showAllTags: {
      type: Boolean,
      default: false
    },
    topLevel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ExpandableLine,
    Shorten
  },
  setup(props) {
    // const items = computed(() => {
    //   if (typeof props.value === 'object') {
    //     return Object.entries(props.value)
    //   }
    //   return []
    // })
    // console.log(items.value)
    const isDebug = computed(() => $store.getters.getDebugMode)
    const hasChildren = computed(() => {
      if (props.value instanceof Date) return false
      if (Array.isArray(props.value)) return props.value.length
      if (typeof props.value === 'object')
        return props.value !== undefined && props.value !== null && Object.keys(props.value).length
      return false
    })
    const panel = props.expanded ? ref(0) : ref()
    const getType = (value: any): string => {
      if (value instanceof Date) return 'Date'
      return typeof value
    }

    const sortedKeys = (value: object): string[] => {
      if (typeof value === 'object') return Object.keys(value).sort()
      return []
    }

    const shorten = (value: string | Date, amount: number = 500): string => {
      let retVal = value
      if (value instanceof Date) retVal = value.toString()
      else if (value?.length > amount) retVal = value.substring(0, amount) + '...'

      return retVal
    }
    return {
      mdiPlusCircle,
      mdiMinusCircle,
      hasChildren,
      panel,
      isDebug,
      getType,
      sortedKeys,
      shorten
    }
  }
})
</script>

<style>
.expansionPanels-low {
  z-index: auto;
}
#expTagLine .v-expansion-panel-content__wrap {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 18px !important;
  border-left: 1px solid gray;
  /* border-bottom: 1px solid gray;
  border-top: 1px solid gray; */
}

#expTagLineRed .v-expansion-panel-content__wrap {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 18px !important;
  border-left: 1px solid red;
  /* border-bottom: 1px solid gray;
  border-top: 1px solid gray; */
}

#expTagLine .v-expansion-panel-header {
  min-height: 24px !important;
  font-size: 1rem;
}

#expTagLineRed .v-expansion-panel-header {
  min-height: 24px !important;
  font-size: 1rem;
}
.failed-tags {
  color: red;
  font-style: italic;
}
.showAllButton {
  height: 24px !important;
  width: 84px !important;
}
/* Tooltip container */
.tagtooltip {
  /* border-top: 1px dotted black; */
}

/* Tooltip text */
.tagtooltip .tooltiptext {
  left: 60px;
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: start;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 100;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tagtooltip:hover .tooltiptext {
  visibility: visible;
}
.blueText {
  color: blue;
}
</style>

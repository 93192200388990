<template>
  <v-container v-if="leadCapture">
    <v-row class="align-center justify-center">
      <v-col lg="10" cols="12">
        <v-card :dark="leadCapture.isBackgroundDark" color="primary">
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-spacer></v-spacer>
            <div :class="leadCaptureConfirmationHeaderClass">
              <v-card-title
                :id="leadCapture.confirmationHeader1?.id"
                :class="$vuetify.breakpoint.mdAndUp ? 'text-h3' : 'text-h5'"
                v-html="leadCapture.confirmationHeader1?.description"
              ></v-card-title>

              <v-card-title
                :id="leadCapture.confirmationHeader2?.id"
                :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-h7'"
                v-if="leadCapture.confirmationHeader2"
                v-html="leadCapture.confirmationHeader2?.description"
              ></v-card-title>
            </div>

            <v-spacer v-if="$vuetify.breakpoint.mdAndUp && leadCapture.confirmationImage"></v-spacer>

            <div v-if="leadCapture.confirmationImage" style="display: flex; justify-content: center; align-items: center">
              <v-avatar v-if="$vuetify.breakpoint.mdAndUp" class="ma-3" size="275" tile>
                <v-img :src="`/api/content/${leadCapture.confirmationImage}`"></v-img>
              </v-avatar>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { LeadCapture } from '@adg/catalog/src/common/UIConfig'

export default defineComponent({
  name: 'LeadCaptureConfirmation',
  props: {
    leadCapture: {
      type: Object as PropType<LeadCapture>,
      required: true
    }
  },
  setup(props, { root, emit }) {
    const { $vuetify } = root as any

    const leadCaptureConfirmationHeaderClass = computed((): string => {
      let headerClass = ''
      if ($vuetify.breakpoint.mdAndDown) {
        headerClass = 'text-center'
      } else {
        headerClass = props.leadCapture.confirmationImage ? 'text-left' : 'text-center'
      }
      return headerClass
    })

    return {
      leadCaptureConfirmationHeaderClass
    }
  }
})
</script>
<style scoped>
.v-card__title {
  word-break: break-word;
  justify-content: center;
}
</style>

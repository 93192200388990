<template>
  <v-container class="pa-10">
    <v-spacer />
    <v-row justify="center">
      <v-card max-width="500">
        <v-card-title> </v-card-title>
        <v-card-text v-html="unsupportedPreSaleShopperText"></v-card-text>
        <v-card-actions>
          <v-row justify="center">
            <v-btn class="ma-3" @click="startNew" color="primary"> Start New Order </v-btn>

            <v-btn v-if="displayPresaleCustomerServiceButton" :href="customerService" class="ma-3" color="primary">
              Call Customer Service
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { bus } from '@/main'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api'
import { getConfigItem, getConfigString } from '../shared/getConfigItem'
import store from '@/store'
//import { getConfig } from 'retry-axios'

export default defineComponent({
  name: 'PreSale',
  setup() {
    const prevAddr = store.getters.getShopper.inputAddress
    const startNew = () => (window.location.href = `${window.location.origin}/${window.location.search}&ro=true`)
    // const startNew = () =>
    //   (window.location.href = prevAddr ? `${window.location.origin}/?address=${prevAddr}&debug=demo123` : window.location.origin)

    // const startNew = () => bus.$emit('goToOffers')
    const customerService = computed(() => `tel:${getConfigString(ConfigKeys.customerService)}`)
    const displayPresaleCustomerServiceButton = computed(
      () => getConfigItem(ConfigKeys.displayPresaleCustomerServiceButton) ?? true
    )
    const unsupportedPreSaleShopperText = computed(
      () =>
        getConfigString(ConfigKeys.unsupportedPreSaleShopperText) ??
        'Oops, it looks like there was a problem loading your previous order. If you would like us to honor your previous order, you can call our customer service representatives, or you can start a new order.'
    )

    return { startNew, customerService, unsupportedPreSaleShopperText, displayPresaleCustomerServiceButton }
  }
})
</script>

<style></style>


<v-card :tile="sharpCorners" class="lg tr-0 tl-0 mb-5">
  <!-- <component :is="headerComponent" :title="headerText" :image="headerIconOrImage" :componentOrder="product.displayOrder" />
   -->
  <CardHeader
    :title="tvHeaderText"
    :image="tvHeaderImage"
    :componentOrder="product.displayOrder"
    :color="tvHeaderBackgroundColor"
  ></CardHeader>
  <v-container fluid id="tv">
    <PackageUpgrades :product="currentProduct" />

    <div v-if="equipmentGroups.length >= 1">
      <v-row class="py-0" :class="$vuetify.breakpoint.xs ? ['pt-4', 'text-center'] : ''">
        <v-col class="py-0 title primary--text"
          >{{ getGroupTitle('tv-equipment-options') }}
          <Tooltip :payload="{ Name: 'tv-equipment-options' }" />
          <TooltipDebug :name="'tv-equipment-options'" :items="equipmentGroups" />
        </v-col>
      </v-row>
      <v-row v-if="getGroupSubtitle('tv-equipment-options')" class="py-0" :class="$vuetify.breakpoint.xs ? 'text-center' : ''">
        <v-col class="py-0" v-html="getGroupSubtitle('tv-equipment-options')"> </v-col>
      </v-row>
      <v-container class="pa-0">
        <v-radio-group :mandatory="true" class="pt-0 ma-0" v-model="currentGroup" @change="equipmentGroupChanged">
          <v-list-item v-for="group in equipmentGroups" :key="group.group" :ripple="false">
            <template v-slot:default="{ active, toggle }">
              <v-list-item-action>
                <v-radio class="py-0" :class="getCatalogItemClassName(group.groupName, 'Equipment')" :value="group.group" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ group.group }}</v-list-item-title>
                <v-list-item-subtitle>{{ getProductSubtitle({ Name: group.group }) }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-radio-group>
      </v-container>
    </div>

    <div v-if="equipmentProducts.length">
      <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
        <v-col class="py-0 title primary--text" id="customize-group-header" v-html="tvSetUpText"
          ><Tooltip :payload="{ Name: tvSetUpText }"
        /></v-col>
      </v-row>
      <v-row v-if="showTvSetUpSubtext" no-gutters>
        <v-col v-html="setTopBoxSubtitleText" class="pb-2">
          <Tooltip
            v-if="currentGroup"
            :payload="{
              Name: `${currentProduct['Product Type']}-${currentGroup}`
            }"
          />
          <Tooltip v-if="!currentGroup" :payload="{ Name: currentProduct['Product Type'] }" />
        </v-col>
      </v-row>

      <TvEquipment
        :products="equipmentProducts"
        v-model="tvSelections"
        :minTvChoices="equipmentMinTvs"
        @input="tvSelectionChanged"
      /><TooltipDebug :name="'TvEquipment'" :item="tvSelections" />
    </div>
    <!--Services-->
    <div class="pt-2" v-for="serviceGroup in serviceGroups.filter((sg) => sg.type === 'radio')" :key="serviceGroup.name">
      <v-row
        v-if="serviceGroup.type === 'radio' && services.length > 0"
        :class="$vuetify.breakpoint.xs ? ['pt-o', 'text-center'] : ''"
      >
        <v-col class="py-0 title primary--text" id="customize-group-header">{{ getGroupTitle(serviceGroup.name) }}</v-col>
      </v-row>
      <v-row
        v-if="serviceGroup.type === 'radio' && services.length > 0 && getGroupSubtitle(serviceGroup.name)"
        :class="$vuetify.breakpoint.xs ? 'text-center' : ''"
      >
        <v-col class="py-0" v-html="getGroupSubtitle(serviceGroup.name)"></v-col>
      </v-row>
      <v-radio-group
        v-if="serviceGroup.type === 'radio'"
        class="mt-0"
        dense
        hide-details
        @change="serviceRadioChange"
        v-model="serviceRadioBoxes[serviceGroup.name]"
        :key="renderMe"
      >
        <v-list class="pt-0">
          <div v-for="product in serviceGroup.products" :key="product.Name">
            <v-list-item
              v-if="
                (product.Min === 1 && product.Max === 1 && product.Group === serviceGroup.name) ||
                (product.Group === undefined && serviceGroup === '')
              "
              :ripple="false"
            >
              <template v-slot:default="{ active, toggle }">
                <v-list-item-action>
                  <v-radio
                    v-if="serviceGroup.type === 'radio'"
                    class="py-0"
                    :class="getCatalogItemClassName(product.Name, product.itemType)"
                    :value="product.Name"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    ><span v-html="uiMacroParser(shopper, itemDisplayName(product))"></span
                    ><TooltipDebug :name="itemDisplayName(product)" :item="product" /><Tooltip :payload="product"
                  /></v-list-item-title>
                  <v-list-item-subtitle v-if="product.included">{{ getProductSubtitle(product) }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>{{ coreCurrency(getItemPrice(product, 'Monthly Price')) }}</v-list-item-action>
              </template>
            </v-list-item>
          </div>
        </v-list>
      </v-radio-group>
    </div>

    <div v-for="serviceGroup in serviceGroups.filter((sg) => sg.type === 'checkbox')" :key="serviceGroup.name">
      <v-row
        v-if="serviceGroup.name !== 'undefined' && serviceGroup.type === 'checkbox' && services.length > 0"
        :class="$vuetify.breakpoint.xs ? ['pt-o', 'text-center'] : ''"
      >
        <v-col class="py-0" v-html="getGroupTitle(serviceGroup.name)"></v-col>
      </v-row>
      <v-row
        v-if="
          serviceGroup.name !== 'undefined' &&
          serviceGroup.type === 'checkbox' &&
          services.length > 0 &&
          getGroupSubtitle(serviceGroup.name)
        "
        :class="$vuetify.breakpoint.xs ? 'text-center' : ''"
      >
        <v-col class="py-0" v-html="getGroupSubtitle(serviceGroup.name)"></v-col>
      </v-row>
      <v-list v-if="serviceGroup.type === 'checkbox'">
        <v-list-item v-for="product in serviceGroup.products" :key="product.Name" :ripple="false">
          <template v-slot:default="{ active, toggle }" v-if="!isExcluded(product.Name)">
            <v-list-item-action v-if="product.Min === 0 && product.Max === 1">
              <v-checkbox
                :class="getCatalogItemClassName(product.Name, product.itemType)"
                :disabled="product.included === '1'"
                hide-details
                v-model="upgrades"
                :value="product.Name"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                >{{ itemDisplayName(product) }}
                <Tooltip :payload="product" />
                <TooltipDebug :item="product" />
              </v-list-item-title>
              <v-list-item-subtitle v-if="product.included">{{ getProductSubtitle(product) }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="getItemPrice(product, 'Monthly Price') != undefined"
              ><span
                v-html="
                  uiMacroParser(shopper, product.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(product, 'Monthly Price'))
                "
              ></span
            ></v-list-item-action>
            <v-list-item-action v-else
              ><span
                v-html="uiMacroParser(shopper, product.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(product, 'OTC'))"
              ></span
            ></v-list-item-action>
          </template>
        </v-list-item>
      </v-list>
    </div>

    <!--      Channels-->
    <div v-if="channelText">
      <span v-html="channelText"></span>
    </div>
    <div v-if="streamingOptionsText">
      <span v-html="streamingOptionsText"></span>
    </div>
    <div v-for="channelGroup in channelGroups" :key="channelGroup">
      <v-row v-if="channels.length > 0" :class="$vuetify.breakpoint.xs ? ['pt-o', 'text-center'] : ''">
        <v-col class="py-0 title primary--text">{{ getChannelTitle(channelGroup) }}</v-col>
      </v-row>
      <v-row v-if="getGroupSubtitle(channelGroup)" :class="$vuetify.breakpoint.xs ? 'text-center' : ''">
        <v-col class="py-0" v-html="getGroupSubtitle(channelGroup)"></v-col>
      </v-row>
      <v-list>
        <div v-for="product in channels" :key="product.Name">
          <v-list-item
            v-if="product.Group === channelGroup || (product.Group === undefined && channelGroup === '')"
            :ripple="false"
          >
            <template v-slot:default="{ active, toggle }">
              <v-list-item-action>
                <v-checkbox
                  :class="getCatalogItemClassName(product.Name, product.itemType)"
                  :disabled="product.included === '1'"
                  hide-details
                  v-model="upgrades"
                  :value="product.Name"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  ><span v-html="uiMacroParser(shopper, itemDisplayName(product))"></span
                  ><Tooltip :payload="product" /><TooltipDebug :item="product"
                /></v-list-item-title>
                <v-list-item-subtitle v-if="product.included">{{ getProductSubtitle(product) }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="getItemPrice(product, 'Monthly Price') != undefined"
                ><span
                  v-html="
                    uiMacroParser(shopper, product.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(product, 'Monthly Price'))
                  "
                ></span
              ></v-list-item-action>
              <v-list-item-action v-else
                ><span
                  v-html="uiMacroParser(shopper, product.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(product, 'OTC'))"
                ></span
              ></v-list-item-action>
            </template>
          </v-list-item>
        </div>
      </v-list>
    </div>
  </v-container>
</v-card>

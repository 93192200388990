<template>
  <div v-if="isDebug" style="display: inline-block">
    <v-icon :size="$vuetify.breakpoint.xsOnly ? 18 : 18" class="black--text text--lighten-2" dark @click="openModal">{{
      mdiBugCheck
    }}</v-icon>
    <v-overlay :opacity="0.1" v-if="showModal" z-index="6" height="100vh" class="customOverlay">
      <v-dialog v-model="showModal" @close="closeModal" max-width="70%">
        <v-card class="pt-2 rounded-lg" rounded>
          <v-card-actions v-if="showAgentIframe">
            <v-btn @click="openIframe"> Agent View </v-btn>
          </v-card-actions>
          <v-card-text class="py-0">
            <ExpandableLine :name="tooltipName" :value="item" :expanded="true" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from '@vue/composition-api'
import { mdiBugCheck } from '@mdi/js'
import ExpandableLine from './ExpandableLine.vue'
import { Item, itemDisplayName } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'
import router from '@/router'

export default defineComponent({
  name: 'TooltipDebug',
  props: {
    name: String,
    item: [Array, Object]
  },
  components: {
    ExpandableLine
  },
  setup(props) {
    const isDebug = computed(() => $store.getters.getDebugMode)
    // const itemDisplayName = (item): string => {
    //   const retVal =
    //     item.hasOwnProperty('Display Name') && item['Display Name'] !== undefined
    //       ? item['Display Name']
    //       : item.Name ?? item.name ?? 'Object'
    //   return retVal
    // }
    const tooltipName = props.name ? props.name : itemDisplayName(props.item as Item)

    const showModal = ref(false)
    const openModal = () => (showModal.value = true)
    const closeModal = () => (showModal.value = false)
    const openIframe = () => router.push('/iframe')
    const showAgentIframe = computed(() => $store.getters.getUrlParams.orderid)
    return {
      tooltipName,
      mdiBugCheck,
      showModal,
      openModal,
      closeModal,
      isDebug,
      openIframe,
      showAgentIframe
    }
  }
})
</script>

<style></style>

<template>
  <div>
    <div v-if="!hasChildren && !hasPromos">
      <v-row>
        <!-- todo: check about tooltip boolean for included/required -->
        <v-col class="py-0" cols="8"
          ><span v-html="uiMacroParser(shopper, parent.name ?? '') + quantity"></span><TooltipDebug :item="item" /><Tooltip
            :payload="{
              Name: item.Name,
              included: item.included ? true : false,
              required: item.required ? true : false,
              itemType: item.itemType
            }"
          />
        </v-col>
        <v-col class="py-0 text-right" cols="4">
          <span v-html="uiMacroParser(shopper, parent?.PricePrefix ?? '') + ' ' + coreCurrency(parent.price)"></span>
        </v-col>
      </v-row>
      <!-- <div v-if="promoProgressions.length > 1">
        <v-row v-for="(promo, i) in promoProgressions" class="pl-2" :key="`${parent.name}-promo-${i}`">
          <v-col cols="2"></v-col>
          <v-col class="py-0 promo-text" cols="8">{{ promo }} </v-col>
        </v-row>
      </div> -->
    </div>
    <v-expansion-panels v-else flat v-model="panel">
      <v-expansion-panel class="ma-0 pa-0" id="cartLine">
        <v-expansion-panel-header hide-actions class="pa-0" style="font-size: 1rem">
          <v-row class="ma-0">
            <v-col class="pa-0" cols="9"
              ><span v-html="uiMacroParser(shopper, parent.name ?? '') + quantity"></span><TooltipDebug :item="item" /><Tooltip
                :payload="{
                  Name: item.Name,
                  included: item.included ? true : false,
                  required: item.required ? true : false,
                  itemType: item.itemType
                }"
              />
            </v-col>
            <v-col :class="parentPrice" class="pa-0 text-right" cols="3">
              <span
                ><v-icon v-if="panel == 0" class="pb-1 mr-1" small color="primary">{{ mdiMinusCircle }}</v-icon>
                <v-icon class="pb-1 mr-1" v-else small color="primary">{{ mdiPlusCircle }}</v-icon
                ><span v-html="uiMacroParser(shopper, parent?.PricePrefix ?? '') + ' ' + coreCurrency(parent.price)"></span
              ></span>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-if="hasPromos">
            <v-row v-for="(promo, i) in promoProgressions" :key="`${parent.name}-promo-${i}`">
              <v-col cols="1"></v-col>
              <v-col class="py-0 pl-2 promo-text" cols="11">{{ promo }} </v-col>
            </v-row>
          </div>
          <div v-if="hasChildren" class="ma-0 pa-0">
            <div v-for="child in children" :key="child.Name" class="ma-0 pa-0">
              <CartLineItem :item="child" :priceType="priceType" :expanded="expanded" />
            </div>
          </div>
          <!-- <v-row v-for="child in children" :key="child.name">
            <v-col class="py-0 pl-2" cols="8">{{ child.name }} </v-col>

            <v-col class="py-0 text-right" cols="4">
              <span>{{ child.price | coreCurrency }}</span>
            </v-col>
          </v-row> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, ref } from '@vue/composition-api'
import {
  itemDisplayName,
  getItemPrice,
  ChargeTypeKey,
  FeedItem,
  getSubItemsAndSort,
  rescueDeepChildren,
  getItemPriceAsString,
  getRecurringItemPeriods,
  catalogRank
} from '@adg/catalog/src/modules/Catalog'
import { mdiPlusCircle, mdiMinusCircle } from '@mdi/js'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import TooltipBasic from '@/components/shared/tooltip/TooltipBasic.vue'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import $store from '@/store'
import { coreCurrency } from '@adg/catalog/src/common/filters'
import { uiMacroParser } from '@/utils/ShopperHelpers'

interface LineItem {
  name?: string
  itemType: string
  description?: string
  qty?: string | number
  price?: string | number
  PricePrefix?: string
}

export default defineComponent({
  name: 'CartLineItem',
  components: {
    Tooltip,
    TooltipBasic,
    TooltipDebug
  },
  props: {
    item: {
      type: Object as () => FeedItem,
      required: true
    },
    priceType: {
      type: String as () => ChargeTypeKey,
      required: true
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const parent: ComputedRef<LineItem> = computed(() => ({
      name: itemDisplayName(props.item),
      itemType: props.item.itemType,
      description: props.item.Description,
      qty: props.item.qty ?? '',
      price: getItemPrice(props.item, props.priceType, 1),
      PricePrefix: props.item.PricePrefix
    }))
    const shopper = computed(() => $store.getters.getShopper as IShopper)

    const isDebug = computed(() => $store.getters.getDebugMode)

    const itemPeriods = computed(() => getRecurringItemPeriods(props.item))

    const promoProgressions = computed(() =>
      itemPeriods.value.map((month) => {
        let price = getItemPriceAsString(props.item, props.priceType, month.StartMonth)
        if (month.EndMonth === Infinity) {
          // return `$${price} for months ${month.StartMonth} ...`
          // return `$${price} for months ${month.StartMonth} and therafter`
          return `${price} thereafter`
        }
        return `${price} for months ${month.StartMonth} - ${month.EndMonth}`
      })
    )

    const realChildren = computed(() => {
      return getSubItemsAndSort(props.item)
    })
    const rescuedChildren = computed(() => {
      // cleans up the children tree
      if (realChildren.value.length >= 1) return realChildren.value
      if (realChildren.value.length === 0) return []
      else {
        return rescueDeepChildren(realChildren.value)
      }
    })
    const hasChildren = computed(() => {
      if (isDebug.value) return realChildren.value.length > 0
      else return rescuedChildren.value.length > 1 && props.item.expandable

      // const childrenLength = isDebug.value ? 0 : 1
      // return children.value.length > childrenLength && (props.item.expandable || isDebug.value)
    })
    const children = computed(() => {
      if (isDebug.value) return realChildren.value
      else return rescuedChildren.value
    })

    const panel = props.expanded ? ref(0) : ref()

    const parentPrice = computed(() => (panel.value === 0 && hasChildren.value ? 'grey--text' : ''))

    const quantity = computed(() => (props.item.qty !== undefined && props.item.qty > 1 ? ` (${props.item.qty}` : ''))

    // if (parent.value.name === 'HD Set Top Box') {
    // console.log(parent.value.name)
    // if (parent.value.name == 'HD Set Top Box') {
    //   console.log(props.item)
    // }
    // }

    const hasPromos = computed(() => {
      const promoLength = isDebug.value ? 0 : 1
      return promoProgressions.value.length > promoLength
    })

    return {
      parent,
      children,
      hasChildren,
      hasPromos,
      panel,
      mdiPlusCircle,
      mdiMinusCircle,
      parentPrice,
      quantity,
      promoProgressions,
      coreCurrency,
      shopper,
      uiMacroParser
    }
  }
})
</script>

<style>
#cartLine .v-expansion-panel-content__wrap {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 18px !important;
  border-left: 1px solid gray;
}
#cartLine .v-expansion-panel-header {
  min-height: 24px !important;
  font-size: 1rem;
}
.promo-text {
  font-size: 14px;
  font-style: italic;
  color: gray;
}
</style>

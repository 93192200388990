
<v-card id="cart" :tile="sharpCorners" :ripple="false" :show="selectedPackage" class="lg tr-0 tl-0">
  <!-- <component
    :is="headerComponent"
    :title="headerText"
    :image="headerIconOrImage"
    :color="cartHeaderColor"
    @headerClicked="cartClicked"
  /> -->
  <CardHeader
    :title="cartHeaderText"
    :image="cartHeaderImage"
    :color="cartHeaderBackgroundColor"
    :isCartHeader="true"
    @headerClicked="cartClicked"
    @cartHeaderCloseButtonClicked="cartClicked"
  ></CardHeader>
  <v-container class="package-display-name" @click="cartClicked">
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-col class="headline grey--text" id="customize-group-header"
        >{{ packageDisplayNameOrDescription }}<TooltipDebug :item="shoppingCart" :name="'Cart Order to Submit'"
      /></v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.xsOnly" :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
      <v-col class="title grey--text" id="customize-group-header" v-html="packageDisplayNameOrDescription"></v-col>
    </v-row>
  </v-container>

  <v-img v-if="cartImage" @click="cartClicked" :src="cartImage" alt="" title="" />

  <MonthlyPriceDisplay
    v-if="displayMonthlyPriceTopOfCart"
    :item="cartTotal"
    priceType="Monthly Price"
    :myComponentColor="monthlyTotalColor"
    additionalTextClasses="font-weight-bold title"
    additionalPriceClasses="font-weight-bold title"
  />

  <!--      ONETIME TOTAL      -->
  <div v-if="false">
    <v-container class="py-0" @click="cartClicked">
      <v-row v-if="$vuetify.breakpoint.smAndDown" class="grey lighten-3 text-center primary--text">
        <v-col cols="12" class="headline font-weight-bold pb-0">OneTime Total </v-col>
        <v-col cols="12" class="headline font-weight-bold pt-0">
          {{ coreCurrency(oneTimeTotal) }}
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pt-0">
      <v-row v-if="$vuetify.breakpoint.mdAndUp" class="grey lighten-3 primary--text">
        <v-col cols="9" class="title font-weight-bold">OneTime Total</v-col>
        <v-col class="title text-right pt-3 font-weight-bold" cols="3">{{ coreCurrency(oneTimeTotal) }}</v-col>
      </v-row>
    </v-container>
  </div>

  <!--    CART        -->
  <v-container class="pt-0 pb-3" :key="getName(selectedPackage)">
    <v-row :class="$vuetify.breakpoint.xs ? ['text-center'] : ''">
      <v-col class="title" id="customize-group-header" v-html="monthlyChargesCartLabel"></v-col>
    </v-row>

    <!-- <v-row v-for="item in monthlyCharges" :key="item.name">
      <v-col class="py-0" v-if="item" cols="8">{{ itemDisplayName(item) }} </v-col>
      <v-col class="py-0 text-right" v-if="item" cols="4">
        <span>{{ getPrice(item, 'Monthly Price') | coreCurrency }}</span>
      </v-col>
    </v-row> -->
    <div v-for="(item, i) in monthlyCharges" :key="`${getName(item)}${i}`">
      <CartLineItem :expanded="expandCartLineItem" :item="item" priceType="Monthly Price" />
    </div>
    <v-row>
      <v-col class="py-2"></v-col>
    </v-row>
    <MonthlyPriceDisplay
      v-if="!displayMonthlyPriceTopOfCart"
      :item="cartTotal"
      priceType="Monthly Price"
      :myComponentColor="monthlyTotalColor"
      additionalTextClasses="font-weight-bold title"
      additionalPriceClasses="font-weight-bold title"
    />
    <div class="pa-0 ma-0" v-if="oneTimeCharges.length > 0">
      <v-divider></v-divider>
      <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
        <v-col class="title" id="customize-group-header">One Time Charges: </v-col>
      </v-row>
    </div>
    <div v-for="(item, i) in oneTimeCharges" :key="`${getName(item)}${i}`">
      <CartLineItem :expanded="expandCartLineItem" :item="item" priceType="OTC" />
    </div>
    <v-row v-if="oneTimeCharges && displayOtcTotalInCart" align="start" class="lighten-3 pt-2 px-0 ma-0">
      <v-col class="pa-0" cols="8">One Time Charges Total</v-col>
      <v-col class="pa-0 text-right" cols="4">
        <span>${{ oneTimeChargesTotal.toFixed(2) }}</span>
      </v-col>
    </v-row>
    <!-- <v-row v-for="item in oneTimeCharges" :key="item.Name">
      <v-col class="py-0" v-if="item && item.qty" cols="8">
        <span>{{ itemDisplayName(item) }} ({{ item.qty }})<Tooltip :payload="item" /> </span>
      </v-col>
      <v-col class="py-0" v-if="item && !item.qty" cols="8">
        <span>{{ itemDisplayName(item) }} <Tooltip :payload="item" /> </span>
      </v-col>
      <v-col class="py-0 text-right" v-if="item" cols="4">{{ getPrice(item, 'OTC') | coreCurrency }}</v-col>
    </v-row> -->

    <v-divider v-if="promo"></v-divider>

    <v-row v-if="promo" :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
      <v-col class="title">Promo Code: </v-col>
    </v-row>
    <v-row v-if="promo">
      <v-col class="py-0" cols="8"
        ><span v-html="uiMacroParser(shopper, itemDisplayName(promo['Billing Codes'][0]))"></span
      ></v-col>
      <v-col class="py-0 text-right" cols="4"><a @click="removePromo">Remove</a> </v-col>
    </v-row>
    <div v-if="promoAvailable && !promo && currentStepPromoAllowed(currentStep)">
      <v-divider></v-divider>
      <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
        <v-col cols="12" class="title">Have a promo code? </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            class="promo-text-field"
            :rules="[promoRule]"
            validate-on-blur
            outlined
            flat
            dense
            v-model="promoInput"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="py-0">
        <v-col class="py-0" align="end">
          <v-btn class="promo-apply-button" small color="primary" :class="defaultButtonColors()" @click="applyPromo">Apply</v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="showPrePay">
      <br />
      <v-divider />
      <v-row>
        <v-col class="pb-0 title">Payment Required Today</v-col>
        <v-col class="pt-4 text-right" cols="4">{{ coreCurrency(grandTotal) }}</v-col>
      </v-row>
    </div>
    <v-row class="py-0" v-for="(disclaimer, index) in getAllDisclaimers" :key="index + 'alldisclaimer'">
      <v-col cols="12" class="caption text--secondary">
        <span v-html="uiMacroParser(shopper, disclaimer)" />
      </v-col>
    </v-row>
    <Transunion />
  </v-container>
</v-card>

<template>
  <div>
    <CardHeaderCustom
      @cartHeaderCloseButtonClicked="$emit('cartHeaderCloseButtonClicked')"
      v-if="cardHeaderComponent === 'alternate'"
      :title="title"
      :image="image"
      :componentOrder="componentOrder"
      :color="lobColor"
      :isCartHeader="isCartHeader"
    ></CardHeaderCustom>
    <CardHeaderDefault
      @cartHeaderCloseButtonClicked="$emit('cartHeaderCloseButtonClicked')"
      v-else
      :title="title"
      :image="image"
      :componentOrder="componentOrder"
      :color="lobColor"
      :isCartHeader="isCartHeader"
    ></CardHeaderDefault>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api'
import useUiConfig from '@/components/shared/useUiConfig'
import CardHeaderCustom from '@/components/layout/CardHeaderCustom.vue'
import CardHeaderDefault from '@/components/layout/CardHeaderDefault.vue'
import useCardHeader from './useCardHeader'

export default defineComponent({
  name: 'CardHeader',
  props: {
    title: String,
    image: String,
    componentOrder: Number,
    color: String,
    isCartHeader: Boolean
  },
  components: {
    CardHeaderCustom,
    CardHeaderDefault
  },

  setup(props, { emit }) {
    const { lobHeaderColors } = useUiConfig()
    const { headerColor, cardHeaderComponent } = useCardHeader(emit)

    const lobColor = computed(() => {
      return headerColor(lobHeaderColors.value, props.componentOrder, props.color)
    })

    return {
      lobColor,
      cardHeaderComponent
    }
  }
})
</script>

<style scoped>
.center-button {
  left: 50%;
  transform: translateX(-50%);
}
</style>

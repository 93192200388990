
<div>
  <v-row>
    <v-col>
      <div class="title field-label" v-html="field.label" />
    </v-col>
  </v-row>
  <v-row class="pb-2" v-for="(q, i) in questions" v-bind:key="`question-${i}`">
    <v-col class="col-12 ref-col">
      <v-select
        dense
        clearable
        outlined
        :rules="rules"
        :items="openQuestions"
        v-model="questions[i]"
        item-text="name"
        item-disabled="disabled"
        label="Select Question"
      />
    </v-col>
    <v-col v-if="questions[i]" class="col-12 ref-col">
      <v-text-field dense :rules="rules" outlined v-model="answers[i]" label="Enter Answer" />
    </v-col>
  </v-row>
</div>

<template>
  <v-card
    class="rounded-lg rounded-tr-0 rounded-tl-0 mb-5"
    height="100%"
    v-if="homeAutomationPackageUpgrades && homeAutomationPackageUpgrades.length > 0"
    :tile="sharpCorners"
  >
    <CardHeader title="HOME AUTOMATION" :componentOrder="4" />
    <v-container fluid class="px-0">
      <v-list class="pa-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Which Package is best for me
              <Tooltip :payload="{ Name: 'Home Automation' }" />
            </v-list-item-title>
            <v-list-item-subtitle>
              Watch a short video
              <v-icon v-on:click="dialog = !dialog">{{ mdiYoutube }}</v-icon>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ getSubtitle('Home Automation') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-radio-group :mandatory="true" @change="upgradeChanged" v-model="choices['upgrade']" class="pt-0 ma-0" hide-details>
          <v-container>
            <v-row v-for="p in homeAutomationPackageUpgrades" :key="p.Name">
              <v-col cols="2" class="col-sm-1 col-md-1 col-lg-1 col-xl-1 py-1 pr-0">
                <v-radio :class="getCatalogItemClassName(p.Name, p.itemType)" :value="p" />
              </v-col>
              <v-col cols="7" class="py-1 pl-0 col-sm-9 col-md-8 col-lg-8 col-xl-8">
                <v-row no-gutters>
                  <v-col cols="12" class="font-weight-bold"
                    ><span v-html="uiMacroParser(shopper, p['Display Name'] ? p['Display Name'] : p.Name)"></span></v-col
                  ><TooltipDebug :item="p" />
                  <v-col cols="12" class="description">{{ p.Description }}</v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="getItemPrice(p, 'Monthly Price') !== undefined"
                cols="3"
                class="col-sm-3 col-md-3 col-lg-3 col-xl-3 text-right pt-sm-1 pt-1"
              >
                <span
                  v-html="uiMacroParser(shopper, p.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(p, 'Monthly Price'))"
                ></span>
              </v-col>
              <v-col v-else cols="3" class="col-sm-3 col-md-3 col-lg-3 col-xl-3 text-right pt-sm-1 pt-1">
                <span v-html="uiMacroParser(shopper, p.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(p, 'OTC'))"></span>
              </v-col>
            </v-row>
          </v-container>
        </v-radio-group>
      </v-list>
    </v-container>
    <v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xsOnly ? 400 : 600">
      <v-card color="secondaryDark">
        <v-card-text class="pa-2">
          <iframe
            width="100%"
            height="200"
            :src="homeAutomationVideoLink"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import CardHeader from '@/components/layout/CardHeader.vue'
import { COMPONENT_ORDER, MARKETING_UI_COMPONENT, SUBCATEGORY } from '@/store/types'
import VastHomeAutomationMarketing from '@/components/order/home-automation/client/VastHomeAutomationMarketing.vue'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import { mdiYoutube } from '@mdi/js'
import useUiConfig from '@/components/shared/useUiConfig'
import { bus } from '@/main'
import { flatten, toArray } from 'lodash'
import { getItemPrice, Upgrade } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'
import { getCatalogItemClassName } from '@adg/catalog/src/common/utils'
import { coreCurrency } from '@adg/catalog/src/common/filters'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { IShopper } from '@adg/catalog/src/modules/Shopper'

export default defineComponent({
  name: 'HomeAutomation',
  props: {
    product: {},
    sharpCorners: Boolean
  },
  components: {
    VastHomeAutomationMarketing,
    CardHeader,
    Tooltip,
    TooltipDebug
  },
  setup(props, { refs }) {
    let choices: Record<string, Upgrade | undefined> = reactive({})
    const dialog = ref(false)
    const { getSubtitle } = useUiConfig()
    const shopper = computed(() => $store.getters.getShopper as IShopper)

    const homeAutomationPackageUpgrades = computed(() => {
      const upgrades: Upgrade[] = $store.getters.getAvailablePackageUpgrades
        .filter((u: Upgrade) => u[SUBCATEGORY] === 'Home Automation')
        .sort((a: Upgrade, b: Upgrade) => (a[COMPONENT_ORDER] ?? 0) - (b[COMPONENT_ORDER] ?? 0))

      return upgrades.length === 1 && upgrades[0].Name === 'None' ? [] : upgrades
    })

    const uiConfig = computed(() => $store.getters.getUIConfig)
    const homeAutomationVideoLink = computed(() => uiConfig.value?.homeAutomationVideoLink ?? '')

    // todo: revisit these below
    const nullCheck = (v: any) => v !== null
    const noneCheck = (v: any) => v.Name !== 'None'

    const upgradeChanged = () => {
      const allItems = flatten(toArray(choices).filter(nullCheck).filter(noneCheck))
      $store.commit('setPackageUpgrades', {
        upgrades: allItems,
        subCategory: 'Home Automation'
      })
    }

    bus.$on('packageChanged', () => {
      choices['upgrade'] = homeAutomationPackageUpgrades.value[0]
    })

    return {
      homeAutomationPackageUpgrades,
      dialog,
      homeAutomationVideoLink,
      MARKETING_UI_COMPONENT,
      choices,
      upgradeChanged,
      mdiYoutube,
      getItemPrice,
      getSubtitle,
      getCatalogItemClassName,
      coreCurrency,
      uiMacroParser,
      shopper
    }
  }
})
</script>

<style scoped>
.theme--light.v-label {
  color: black;
}
.v-list-item {
  align-self: start !important;
  align-items: start !important;
}
.v-list-item__action {
  align-self: start !important;
  align-items: start !important;
}

.description {
  font-size: 0.84rem;
  color: rgba(0, 0, 0, 0.6);
}
</style>

<template>
  <v-container style="max-width: 1200px" class="px-sm-12 account" :key="renderMe">
    <div v-if="true">
      <v-row class="py-0 px-2">
        <v-col class="col-md-7 col-sm-12">
          <v-form>
            <!-- new panel version -->
            <v-expansion-panels
              :tile="sharpCorners"
              v-model="selectedPanel"
              :class="accountInfoClass"
              :readonly="accountPanels.length === 1"
              id="accountPanels"
            >
              <v-expansion-panel v-for="(p, i) of accountPanels" :key="'panel-' + i" :id="'panel-' + i">
                <v-expansion-panel-header
                  :hide-actions="accountPanels.length === 1"
                  id="account-header"
                  height="40"
                  :color="accountInfoHeaderColor"
                  disable-icon-rotate
                >
                  <template v-slot:actions>
                    <div>
                      <span>
                        <v-icon />
                        <TooltipIcon
                          v-if="p.validated"
                          :accountInfoTooltipColor="accountInfoTooltipColor"
                          message="Completed"
                          :icon="mdiCheckCircleOutline"
                        />
                        <TooltipIcon
                          v-else
                          :accountInfoTooltipColor="accountInfoTooltipColor"
                          message="Required"
                          :icon="mdiAlertCircleOutline"
                        />
                      </span>
                      <span>
                        <v-icon />
                        <v-icon v-if="selectedPanel == i" color="black">{{ mdiMinusCircleOutline }}</v-icon>
                        <v-icon v-else color="black">{{ mdiPlusCircleOutline }}</v-icon>
                      </span>
                    </div>
                  </template>
                  <div>
                    <v-icon id="account-info-icon" v-if="showAccountInfoIcon" style="padding-right: 6px" color="#08131f">
                      {{ mdiAccountCircleOutline }}
                    </v-icon>
                    {{ p.title }}
                    <!-- <v-icon v-if="testValidation[i]" color="black">{{ mdiCheckCircleOutline }}</v-icon> -->
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content id="account-panel-content">
                  <!-- only put address header on first exp panel -->
                  <v-form v-model="p.validated">
                    <v-row v-if="i == 0">
                      <v-col id="account-address-text" cols="12" class="headline primary--text">
                        {{ address }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        v-for="field of p.fields"
                        v-show="validAccountField(field, shopper)"
                        :key="field.storeProp"
                        :class="field.class ? field.class : 'col-12 pt-0'"
                      >
                        <AccountFieldUI :field="field" :maskMap="maskMap" />
                      </v-col>
                    </v-row>
                    <div v-if="accountPanels.length === 1">
                      <PayContainer v-if="preSalePay" />
                      <CreditCheck v-else />

                      <v-col v-if="image" cols="12">
                        <v-img min-width="100%" :src="image" />
                      </v-col>
                    </div>
                    <!-- don't put continue button on last exp panel -->
                    <v-row v-if="accountPanels.length > 1 && i < accountPanels.length - 1" align="center" justify="center">
                      <v-btn
                        :disabled="!p.validated"
                        @click="makeActive(i + 1)"
                        :color="accountPanelContinueButtonColor"
                        :rounded="showRoundedContinueButton"
                      >
                        Continue</v-btn
                      >
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div v-if="accountPanels.length > 1">
              <PayContainer v-if="preSalePay" />
              <CreditCheck v-else />

              <v-col v-if="image" cols="12">
                <v-img min-width="100%" :src="image" />
              </v-col>
            </div>
          </v-form>
        </v-col>
        <v-col class="hidden-sm-and-down col-md-5 col-sm-12">
          <Cart />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script lang="ts">
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import Cart from '@/components/order/cart/Cart.vue'
import {
  GET_PREVIOUS_ADDRESS_SUGGESTIONS,
  FETCH_PREVIOUS_ADDRESS_SUGGESTIONS,
  FETCH_BILLING_ADDRESS_SUGGESTIONS,
  GET_BILLING_ADDRESS_SUGGESTIONS,
  GET_PRODUCTS,
  SET_ID_IMAGE
} from '@/store/types'
import useOrder from '@/components/order/useOrder'
import StepperButtons from '@/components/order/StepperButtons.vue'
import useUiConfig from '@/components/shared/useUiConfig'
import AddressSeniority from '@/components/shared/AddressSeniority.vue'
import { getConfigBoolean, getConfigItem } from '../../shared/getConfigItem'
import CreditCheck from '../../shared/creditCheck/CreditCheck.vue'
//todo: check if this is pnpm related
import ImageUploader from 'vue-image-upload-resize'
import { ConfigKeys, Product } from '@adg/catalog/src/modules/Catalog'
import { dateDiff } from '@adg/catalog/src/common/utils'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import { mdiAccountCircleOutline } from '@mdi/js'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import $store from '@/store'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import AccountFieldUI from '@/components/order/account/AccountFieldUI.vue'
import { mdiMinusCircleOutline } from '@mdi/js'
import { mdiPlusCircleOutline } from '@mdi/js'
import { mdiCheckCircleOutline } from '@mdi/js'
import { mdiAlertCircleOutline } from '@mdi/js'
import TooltipIcon from '@/components/shared/tooltip/TooltipIcon.vue'
import ecom from '@/gtm/ecom'
import {
  AccountPanel,
  LobImage,
  MaskObject,
  defaultMaskMap,
  MaskConfigObject,
  PaymentConfig
} from '@adg/catalog/src/common/UIConfig'
import { usePiniaRoute } from '@/store/pinia/piniaRoute'
import { validAccountField } from './accountFunctions'
import { logger } from '@/utils/RemoteLogger'
import PayContainer from '@/components/order/payment/PayContainer.vue'
import creditCheck from '@/components/shared/creditCheck/useCreditCheck'

export default defineComponent({
  name: 'Account',
  components: {
    Cart,
    StepperButtons,
    AddressSeniority,
    CreditCheck,
    ImageUploader,
    Tooltip,
    AccountFieldUI,
    TooltipIcon,
    PayContainer
  },
  setup(props, { emit, refs, root /*,vuetify*/ }) {
    const renderMe = ref(0)
    const { $router } = root as any

    const { accountInfoHeaderColor } = useUiConfig()
    const selectedPanel = ref(0)

    const currentStep = computed(() => usePiniaRoute().currentStep)

    watch(currentStep, (newValue) => {
      if (newValue < 4) {
        selectedPanel.value = 0
      }
    })

    const shopper = computed(() => $store.getters.getShopper as IShopper)
    const storeAccountPanels = computed({
      get: (): AccountPanel[] => $store.getters.getAccountPanels ?? [],
      set: (val: AccountPanel[]) => $store.commit('setAccountPanels', val)
    })

    const accountPanels = ref(storeAccountPanels.value)

    watch(storeAccountPanels, (newValue) => {
      accountPanels.value = filterAccountPanels(JSON.parse(JSON.stringify(newValue)))
      renderMe.value = renderMe.value + 1
      // accountPanels.value = JSON.parse(JSON.stringify(newValue))
    })

    watch(
      shopper,
      (newValue) => {
        if (newValue && currentStep.value < 4) {
          accountPanels.value = filterAccountPanels(JSON.parse(JSON.stringify(storeAccountPanels.value)))
          renderMe.value = renderMe.value + 1
        }
      },
      { deep: true }
    )

    const filterAccountPanels = (aps: AccountPanel[]) => {
      const filtered: AccountPanel[] = []
      aps.forEach((ap) => {
        if (ap.fields.some((f) => validAccountField(f, shopper.value))) {
          filtered.push(ap)
        }
      })
      return filtered
    }

    const allAccountInfoValid = computed(() => {
      return accountPanels.value.every((a) => a.validated)
    })

    watch(allAccountInfoValid, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        $store.commit('updateAccountInfoValid', newVal)
      }
    })

    // const testValidation = computed({
    //   get: () => $store.getters.getValidationArray,
    //   set: (value) => {
    //     $store.commit('setValidationArray', value)
    //   }
    // })

    const address = computed(() => $store.getters.getFormattedAddress)
    const isStudent = computed(() => $store.getters.getStudent)
    const accountPanelContinueButtonColor = computed(() => getConfigItem(ConfigKeys.accountPanelContinueButtonColor) ?? 'primary')
    const showRoundedContinueButton = computed(() => getConfigBoolean(ConfigKeys.showRoundedContinueButton) ?? false)

    const accountInfoTooltipColor = computed(() => getConfigItem(ConfigKeys.accountInfoTooltipColor) ?? 'primary')

    // const testValidation = ref(new Array<boolean>()) //initialize to length 2 to avoid race condition (i hate this because i can't replicate locally)

    // watch(accountPanels, (newVal, oldVal) => {
    //   if (newVal.length) {
    //     testValidation.value = new Array<boolean>(newVal.length).fill(false)
    //   }
    // })

    //onMounted(() => console.log('account mounted'))

    // pretty sure the validation works without this watch
    // watch(accountPanelNum, (newVal, oldVal) => {
    //   if (newVal) {
    //     console.log(newVal)
    //     testValidation.value = new Array(newVal).fill(false)
    //   }
    // })

    const isDisabled = (i: number) => {
      if (selectedPanel.value === i) return true
      if (i === 0) return false
      return !accountPanels.value[i - 1].validated
    }

    const phone = computed({
      get: () => shopper.value?.phone ?? '',
      set: (value: string) => {
        $store.commit('setShopperPhone', value)
        usePiniaShopper().shopper.phone = value
      }
    })
    const firstName = computed({
      get: () => shopper.value?.firstName ?? '',
      set: (value: string) => {
        $store.commit('setShopperFirstName', value)
        usePiniaShopper().shopper.firstName = value
      }
    })
    const lastName = computed({
      get: () => shopper.value?.lastName ?? '',
      set: (value: string) => {
        $store.commit('setShopperLastName', value)
        usePiniaShopper().shopper.lastName = value
      }
    })
    const email = computed({
      get: () => shopper.value?.email ?? '',
      set: (value: string) => {
        $store.commit('setShopperEmail', value)
        usePiniaShopper().shopper.email = value
      }
    })
    const accountInformationText = computed(() => getConfigItem(ConfigKeys.accountInformationText) ?? 'ACCOUNT INFORMATION')
    const showAccountInfoIcon = computed(() => getConfigItem(ConfigKeys.showAccountInfoIcon))
    const accountInfoClass = computed(
      () => getConfigItem(ConfigKeys.accountInfoClass) ?? 'grey lighten-4 rounded-lg rounded-tr-0 rounded-tl-0'
    )
    const textFieldProps = computed(() => {
      const textProps: Record<string, boolean> = {}
      textProps[getConfigItem(ConfigKeys.textFieldStyle) ?? 'solo'] = true
      return textProps
    })

    const customMasks = computed((): MaskConfigObject[] => getConfigItem(ConfigKeys.customMasks))

    const maskMap = computed(() => {
      let maskMap: Map<string, MaskObject> = defaultMaskMap
      if (customMasks.value?.length && customMasks.value.length > 0) {
        customMasks.value.forEach((customMask) => {
          let tokenAndPatternRecord: Record<string, Record<string, RegExp>> = {}
          customMask.tokensAndPatterns.forEach((tokenAndPattern: Record<string, string>) => {
            try {
              const maskRegex = new RegExp(tokenAndPattern.pattern)
              tokenAndPatternRecord[tokenAndPattern.token] = { pattern: maskRegex }
              const customMaskObj: MaskObject = {
                mask: customMask.maskString,
                tokens: tokenAndPatternRecord
              }
              maskMap.set(customMask.name, customMaskObj)
            } catch (error) {
              logger.error(`Error creating custom mask: ${error}`)
            }
          })
        })
      }
      return maskMap
    })

    watch(selectedPanel, (newPanelIndex, oldPanelIndex) => {
      window.scrollTo(0, 0)
      $store.dispatch('updateShopper', `panelStateChange old: ${oldPanelIndex} new: ${newPanelIndex}`)
      if (accountPanels.value) {
        const onLeave = oldPanelIndex !== undefined ? accountPanels.value[oldPanelIndex].gtm?.onLeave : undefined
        const onEnter = newPanelIndex !== undefined ? accountPanels.value[newPanelIndex].gtm?.onEnter : undefined
        if (onLeave !== undefined) {
          //fire GA close event
          ecom.firePanelEvents(onLeave)
        }
        if (onEnter !== undefined) {
          //fire GA open event
          ecom.firePanelEvents(onEnter)
        }
      }
      // KWC try to force scroll into view ... not working
      // if (newPanelIndex !== undefined) {
      //   const element = document.getElementById(`panel-${newPanelIndex}`)
      //   if (element) {
      //     console.log('scrolling screen to element', element)
      //     element.scrollIntoView()
      //   }
      // }
      // KE this scrolling works but causes tons of ga events to fire, not sure why
      // if (newPanelIndex !== undefined) {
      //   vuetify.goTo({ selector: `#panel-${newPanelIndex}` }, { duration: 0, offeset: 0 })
      // }
    })
    const dob = computed({
      get: () => shopper.value.customInfo.dobString ?? '',
      set: (dob) => {
        if (dob && dob.match(/^\d\d\/\d\d\/\d\d\d\d$/)) {
          $store.commit('setDob', dob)
          usePiniaShopper().shopper.customInfo.dob = new Date(dob).toISOString()
          usePiniaShopper().shopper.customInfo.dobString = dob //storing this as a dob (isoString) and dobString (mm/dd/yyyy) is really annoying and we need a better way
        }
      }
    })

    // watch(dob, () => {
    //   if (dob.value.match(/^\d\d\/\d\d\/\d\d\d\d$/)) {
    //     $store.commit('setDob', new Date(dob.value).toISOString())
    //     piniaShopper.customInfo.dob = new Date(dob.value).toISOString()
    //   }
    // })

    const dobMenu = ref(false)
    const uiConfig = computed(() => $store.getters.getUIConfig)
    const { validation } = useOrder($store, $router)
    const ageLimit = computed(() => getConfigItem(ConfigKeys.ageLimit) ?? 0)

    const emailRule = (value: string) => {
      const emailPattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return emailPattern.test(value) || 'Invalid email'
    }

    const phoneRule = (value: string) => {
      const phonePattern = /^\(?[2-9]\d{2}\)?[\s.-]\d{3}[\s.-]\d{4}$/
      return phonePattern.test(value?.trimEnd() ?? '') || 'Please enter full phone number with area code'
    }

    const requiredRule = (value: string) => !!value || 'Required'

    // todo: figure out how to type this correctly; string | Date doesn't seem to work
    const dobRule = (value) => {
      if (value && !value.match(/^\d\d\/\d\d\/\d\d\d\d$/)) {
        return 'MM/DD/YYYY format required'
      }
      if (value) {
        return dateDiff(value).years >= Number(ageLimit.value) ? true : `Must be ${ageLimit.value} years or older`
      } else {
        return true
      }
    }

    const ssnRule = (value: string) => {
      return value?.length === 4
    }

    const pinRule = (value: string) => {
      return value?.length === 4
    }

    const ruleMap = new Map<string, Function>()
    ruleMap.set('required', requiredRule)
    ruleMap.set('email', emailRule)
    ruleMap.set('phone', phoneRule)
    ruleMap.set('ssn', ssnRule)
    ruleMap.set('dob', dobRule)
    ruleMap.set('pin', pinRule)

    // const allAccountInfoValid = computed({
    //   set: (valid) => {
    //     $store.commit('updateAccountInfoValid', valid)
    //   },
    //   get: () => $store.getters.getAccountInfoValid
    // })

    // const testValIncrement = ref(0)

    // watch(testValidation, (newVal, oldVal) => {
    //   if (newVal.length > 0) {
    //     testValIncrement.value++
    //     usePiniaShopper().shopper.customInfo.accountFields.testValidation = `${testValIncrement.value} ${JSON.stringify(newVal)}`
    //     allAccountInfoValid.value = newVal.every((v) => v)
    //     if (newVal.every((v) => v)) {
    //       let shopperSnap: any[] = []
    //       accountPanels.value.forEach((p: AccountPanel) => {
    //         p.fields.forEach((f: AccountField) => {
    //           if (f.storeProp) {
    //             shopperSnap.push({
    //               prop: f.storeProp,
    //               value: eval(`shopper.value.${f.storeProp}`)
    //             })
    //           }
    //         })
    //       })
    //       usePiniaShopper().shopper.customInfo.accountFields[`allAccountInfoValidReason-${testValIncrement.value}`] =
    //         JSON.stringify(shopperSnap)
    //     }
    //   }
    // })

    // watch(selectedPanel, (newVal, oldVal) => {
    //   $store.dispatch('updateShopper', `account panel: ${newVal ?? 'closed'}`)
    // })

    const disclaimer = computed(() => getConfigItem(ConfigKeys.disclaimer)?.join('') ?? '')

    const productTypes = computed(() => $store.getters[GET_PRODUCTS].map((p: Product) => p['Product Type']))
    const lobImages = computed((): LobImage[] => (uiConfig.value && uiConfig.value.lobImages ? uiConfig.value.lobImages : []))
    const image = computed(() => {
      const lob: LobImage | undefined =
        lobImages.value && productTypes.value
          ? lobImages.value.find(
              (i) => productTypes.value.length === i.lob.length && productTypes.value.every((p: any) => i.lob.includes(p))
            )
          : undefined
      return lob?.image ? `/api/content/${lob.image}` : ''
    })

    const showAddressSeniority = computed(() => getConfigItem(ConfigKeys.addressSeniority) ?? false)
    const showDifferentBillingAddress = computed(() => getConfigItem(ConfigKeys.differentBillingAddress) ?? false)
    const showDriversLicense = computed(() => getConfigItem(ConfigKeys.driversLicenseNum) ?? false)
    const showDob = computed(() => getConfigItem(ConfigKeys.showDob) ?? false)
    const showPin = computed(() => getConfigItem(ConfigKeys.showPinField) ?? false)
    const showStudentId = computed(() => getConfigItem(ConfigKeys.showStudentId) ?? false)

    const textAlerts = computed(() => getConfigItem(ConfigKeys.textAlerts))

    const acceptTextAlerts = computed({
      get: () => $store.getters.getShopper.customInfo.acceptTextAlerts,
      set: (val) => {
        $store.commit('setAcceptTextAlerts', val)
        usePiniaShopper().shopper.customInfo.acceptTextAlerts = val
      }
    })

    const idImage = computed({
      get: () => $store.getters.getIdImage,
      set: (val) => $store.commit(SET_ID_IMAGE, val)
    })

    // todo: figure out how to type this correctly
    const onFileSelected = (event) => {
      idImage.value = event
    }

    const showBusinessName = computed(() => getConfigItem(ConfigKeys.showBusinessName) ?? false)

    const showTermsCheckbox = computed(() => getConfigItem(ConfigKeys.showTermsCheckbox))

    const termsLabel = computed(() => {
      const terms = getConfigItem(ConfigKeys.termsCheckboxLabel)
      if (Array.isArray(terms)) {
        return terms.join('')
      } else return terms
    })

    const termsModel = computed({
      get: () => shopper.value.customInfo?.termsConsent?.termsAndConditionsConsent ?? false,
      set: (termsAndConditionsConsent) => {
        const termsAndConditionsConsentTimestamp = new Date().toISOString()
        $store.commit('setTermsCheckbox', { termsAndConditionsConsent, termsAndConditionsConsentTimestamp })
        usePiniaShopper().shopper.customInfo.termsConsent = { termsAndConditionsConsent, termsAndConditionsConsentTimestamp }
      }
    })

    const textAreaLabel = computed(() => getConfigItem(ConfigKeys.textAreaLabel))

    const textArea = computed({
      get: () => shopper.value.customInfo?.textArea,
      set: (text) => {
        $store.commit('setTextArea', text)
        usePiniaShopper().shopper.customInfo.textArea = text
      }
    })

    const showSsnField = computed(() => getConfigItem(ConfigKeys.showSsnField) ?? false)

    const pinFieldLabel = computed(() => getConfigItem(ConfigKeys.pinFieldLabel) ?? '4 Digit PIN')

    const ssnFieldLabel = computed(() => getConfigItem(ConfigKeys.ssnFieldLabel) ?? 'Last 4 of SSN')

    const makeActive = (panelNum: number) => {
      selectedPanel.value = panelNum
    }

    const sharpCorners = computed(() => getConfigItem(ConfigKeys.cardSharpCorners) ?? false)
    const { downPayment, recurringPayment, isInitialPreSale } = creditCheck()

    const preSalePay = computed(() => {
      if (!isInitialPreSale.value) return false
      if (getConfigItem(ConfigKeys.preSaleDownPayment) && downPayment.value) return true
      if (getConfigItem(ConfigKeys.preSaleRecurringPayment) && recurringPayment.value) return true
      return false
    })

    return {
      firstName,
      lastName,
      email,
      phone,
      // emailRule,
      // phoneRule,
      // requiredRule,
      // dobRule,
      // ssnRule,
      address,
      shopper,
      disclaimer,
      productTypes,
      image,
      lobImages,
      // validation,
      emit,
      accountInfoHeaderColor,
      showAddressSeniority,
      textAlerts,
      acceptTextAlerts,
      showDob,
      dobMenu,
      isStudent,
      onFileSelected,
      idImage,
      showDriversLicense,
      showDifferentBillingAddress,
      GET_PREVIOUS_ADDRESS_SUGGESTIONS,
      FETCH_PREVIOUS_ADDRESS_SUGGESTIONS,
      GET_BILLING_ADDRESS_SUGGESTIONS,
      FETCH_BILLING_ADDRESS_SUGGESTIONS,
      showBusinessName,
      dob,
      showPin,
      termsLabel,
      showTermsCheckbox,
      termsModel,
      textAreaLabel,
      textArea,
      mdiAccountCircleOutline,
      mdiMinusCircleOutline,
      mdiPlusCircleOutline,
      mdiCheckCircleOutline,
      mdiAlertCircleOutline,
      accountInformationText,
      showAccountInfoIcon,
      accountInfoClass,
      textFieldProps,
      showSsnField,
      pinFieldLabel,
      ssnFieldLabel,
      selectedPanel,
      accountPanels,
      ruleMap,
      makeActive,
      isDisabled,
      showStudentId,
      accountInfoTooltipColor,
      // pinRule,
      accountPanelContinueButtonColor,
      showRoundedContinueButton,
      renderMe,
      customMasks,
      maskMap,
      sharpCorners,
      validAccountField,
      preSalePay
    }
  }
})
</script>
<style scoped>
.blackCheckbox >>> .error--text {
  color: rgba(0, 0, 0, 0.54) !important;
}
.noMargin {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

#accountPanels {
  padding-top: 0px;
}
#accountPanels .v-expansion-panel-header {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-radius: 0px;
}
#accountPanels .v-expansion-panel-header {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-radius: 0px;
}
</style>

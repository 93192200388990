import useCatalogConfig from '@/components/shared/useCatalogConfig'
import store from '@/store'
import { ConfigItems, ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { AxiosRequestConfig } from 'axios'

const _getConfigItemByString = (element: string, type?: string): any => {
  const { getCatalogItemValue } = useCatalogConfig(store)
  const uiconfig = store.getters.getUIConfig
  let value: any = type
    ? getCatalogItemValue(`${element}.${type}`) ?? uiconfig[`${element}.${type}`] ?? undefined
    : getCatalogItemValue(element) ?? uiconfig[element] ?? undefined
  if (typeof value === 'string') {
    const mtch = value.match(/^\s*@([a-zA-Z0-9_\-]+)\s*$/)
    if (mtch) {
      value = _getConfigItemByString(mtch[1])
    }
  }
  return value
}

export const getConfigItemByString = (element: string, type?: string): any => {
  return _getConfigItemByString(element, type)
}

export const getConfigItem = (element: ConfigKeys, type?: string): any => {
  return getConfigItemByString(ConfigKeys[element], type)
}

export const allConfigItems = (): ConfigItems => {
  const cfgItems: ConfigItems = {}
  for (const configKey in ConfigKeys) {
    if (isNaN(Number(configKey))) {
      const item = getConfigItemByString(configKey)
      if (item !== undefined) {
        cfgItems[configKey as keyof ConfigItems] = item
      }
    }
  }
  return cfgItems
}

export const getConfigBoolean = (element: ConfigKeys, type?: string): boolean => {
  const returnVal = getConfigItem(element, type)
  switch (typeof returnVal) {
    case 'string':
      return returnVal.match(/(1|true|t)/i) !== null
    case 'number':
      return returnVal !== 0
    case 'boolean':
      return returnVal
    default:
      return false
  }
}

export const getConfigString = (element: ConfigKeys, type?: string): string | undefined => {
  const returnVal = getConfigItem(element, type)
  switch (typeof returnVal) {
    case 'string':
      return returnVal
    case 'number':
      return returnVal.toString()
    case 'boolean':
      return returnVal ? 'true' : 'false'
    case 'undefined':
      return returnVal
    default:
      return JSON.stringify(returnVal)
  }
}

// export const axiosConfig = (): AxiosRequestConfig | undefined => {
//   const axiosTimeout = getConfigItem(ConfigKeys.axiosTimeout)
//   if (!axiosTimeout) return undefined
//   return {
//     timeout: axiosTimeout
//   }
// }


<v-container id="confirmation-background">
  <v-row v-show="printImageTop" id="printConfirmationImage" justify="center"></v-row>
  <v-row
    v-if="showConfirmationHeaderText"
    no-gutters
    id="confirmation-header-text"
    class="text-center grey--text no-print"
    :hide-print="hidePrintConfirmationTitle"
  >
    <v-col class="display-2" v-html="uiMacroParser(shopper, confirmationText)"></v-col>
  </v-row>
  <v-row
    no-gutters
    id="confirmation-subtitle-text"
    class="text-center grey--text no-print"
    :hide-print="hidePrintConfirmationTitle"
  >
    <v-col id="confirmation-subtitle" class="headline" v-html="uiMacroParser(shopper, confirmationSubtitle)"></v-col>
  </v-row>
  <v-row>
    <v-col cols="12" sm="7">
      <v-card :tile="sharpCorners" class="confirmation-card my-auto mx-auto">
        <v-toolbar flat id="confirmation-header" height="40" dark color="secondary">
          <v-toolbar-title :class="$vuetify.breakpoint.xsOnly ? 'text-center' : ''" id="confirmation-card-header-text"
            ><v-icon v-if="showConfirmationIcon" style="padding-right: 6px" :color="infoIconColor">
              {{ mdiInformationOutline }}
            </v-icon>
            <span
              v-html="
                concatenateConfirmationCardHeaderText
                  ? uiMacroParser(shopper, confirmationHeaderText + confirmationCardHeaderText)
                  : uiMacroParser(shopper, confirmationCardHeaderText)
              "
            ></span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-subtitle class="pb-0 pt-1">
          <v-container class="pa-0">
            <v-row>
              <v-col cols="12">
                <span id="payment-row-style" v-html="message + additionalConfirmationMessages" />
              </v-col>
            </v-row>
            <v-row v-if="showSelfInstallInfo">
              <v-col cols="12">
                <CustomSchedulingText />
              </v-col>
            </v-row>
          </v-container>
        </v-card-subtitle>
        <!-- dont worry about this, it preloads the image so it displays on print-->
        <v-img src="/api/content/printLogo.png?url" style="opacity: 0; position: absolute"></v-img>
        <v-row v-show="!printImageTop" id="printConfirmationImage" justify="center"></v-row>
        <a v-bind="imageAttrs" class="confirmation-image-container">
          <v-img :src="image" class="hidden-print-only confirmation-image"> </v-img
        ></a>
      </v-card>
    </v-col>
    <v-col cols="12" sm="5">
      <ConfirmationCart :sharpCorners="sharpCorners" @print="print" />
    </v-col>
  </v-row>
</v-container>


<v-container fluid class="pv-2">
  <v-row v-if="message" class="pt-4 text-center grey--text">
    <v-col class="headline"><span v-html="message" /> </v-col>
  </v-row>
  <v-row v-else class="text-center grey--text">
    <v-col class="headline">Sorry ... we currently do not offer service that address. Please try again!</v-col>
  </v-row>
  <v-row justify="center">
    <v-img :src="image" max-width="300px"> </v-img>
  </v-row>
  <v-row justify="center">
    <v-btn class="mr-3" :class="defaultButtonColors()" color="primary" @click="stepOne">Back</v-btn>
  </v-row>
</v-container>


<v-container fluid class="error-container">
  <v-row justify="center">
    <v-card flat class="ma-2">
      <v-container>
        <v-row>
          <v-col v-if="errorPageHeader" cols="12" class="display-2 text-center" v-html="errorPageHeader"></v-col>
          <v-col v-else cols="12" class="display-2 text-center"> Looks like something went wrong. </v-col>
        </v-row>
        <v-row>
          <v-col v-if="errorPageText" cols="12" class="headline text-center" v-html="errorPageText"> </v-col>
          <v-col v-else cols="12" class="headline text-center">
            Rest assured we’re on the case and will be back up and running soon.
          </v-col>
        </v-row>
        <v-row>
          <v-col id="error-page-button" cols="12" class="text-center">
            <v-btn @click="startNew()">Try Again</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-row>
</v-container>

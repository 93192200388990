
<div>
  <v-select
    :items="field.dropdownOptions"
    v-model="accountFieldModel"
    :label="field.label"
    :background-color="field.backgroundColor ? field.backgroundColor : 'white'"
    :rules="rules"
    v-bind="textFieldProps"
  >
    <template v-if="field.labelMacro" v-slot:label>
      <span @click.stop class="dropdown-label" v-html="uiMacroParser(shopper, field.labelMacro, true)"></span>
    </template>
  </v-select>
  <v-text-field
    v-if="secondaryFieldOpen"
    v-model="secondaryFieldModel"
    :label="field.secondaryLabel ?? field.label"
    :background-color="field.backgroundColor ? field.backgroundColor : 'white'"
    :rules="rules"
    v-bind="textFieldProps"
  />
</div>

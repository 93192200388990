export const API_CONTENT_ROOT: string = '/api/content'
export const SET_CATALOG: string = 'SET_CATALOG'
export const SET_PACKAGE: string = 'SET_PACKAGE'
export const UPGRADE_PACKAGE: string = 'UPGRADE_PACKAGE'
export const PRODUCT_TYPE: string = 'Product Type'
export const TV: string = 'TV'
export const CLOUD_STORAGE_UPGRADE = '1000 Hours Cloud Storage'
export const SUBCATEGORY = 'Subcategory'
export const PACKAGE_UPGRADE = 'Package Upgrade'
export const GET_TV_EQUIPMENT = 'GET_TV_EQUIPMENT'
export const SET_TV_EQUIPMENT = 'SET_TV_EQUIPMENT'
export const SET_MATCHED_ADDRESS = 'SET_MATCHED_ADDRESS'
export const GET_MATCHED_ADDRESS = 'GET_MATCHED_ADDRESS'
export const GET_ADDRESS_SUGGESTIONS = 'GET_ADDRESS_SUGGESTIONS'
export const GET_PREVIOUS_ADDRESS_SUGGESTIONS = 'GET_PREVIOUS_ADDRESS_SUGGESTIONS'
export const GET_BILLING_ADDRESS_SUGGESTIONS = 'GET_BILLING_ADDRESS_SUGGESTIONS'
export const SET_ADDRESS_SUGGESTIONS = 'SET_ADDRESS_SUGGESTIONS'
export const SET_PREVIOUS_ADDRESS_SUGGESTIONS = 'SET_PREVIOUS_ADDRESS_SUGGESTIONS'
export const SET_BILLING_ADDRESS_SUGGESTIONS = 'SET_BILLING_ADDRESS_SUGGESTIONS'
export const FETCH_ADDRESS_SUGGESTIONS = 'FETCH_ADDRESS_SUGGESTIONS'
export const FETCH_PREVIOUS_ADDRESS_SUGGESTIONS = 'FETCH_PREVIOUS_ADDRESS_SUGGESTIONS'
export const FETCH_BILLING_ADDRESS_SUGGESTIONS = 'FETCH_BILLING_ADDRESS_SUGGESTIONS'
export const RESET_ADDRESS_SUGGESTIONS = 'RESET_ADDRESS_SUGGESTIONS'
export const RESET_PREVIOUS_ADDRESS_SUGGESTIONS = 'RESET_PREVIOUS_ADDRESS_SUGGESTIONS'
export const FETCH_CATALOG = 'FETCH_CATALOG'
export const GET_CATALOG = 'GET_CATALOG'
export const RESET_SERVICEABILITY = 'RESET_SERVICEABILITY'
export const SET_ADDRESS_VERIFIED = 'SET_ADDRESS_VERIFIED'
export const GET_ADDRESS_VERIFIED = 'GET_ADDRESS_VERIFIED'
export const UPDATE_SHOPPER_INFO = 'UPDATE_SHOPPER_INFO'
export const SET_SHOPPER = 'SET_SHOPPER'
export const SET_ORDER = 'SET_ORDER'
export const GET_SALES_LEAD = 'GET_SALES_LEAD'
export const SET_SALES_LEAD = 'SET_SALES_LEAD'
export const SET_SHOW_SPINNER = 'SET_SHOW_SPINNER'
export const GET_SHOW_SPINNER = 'GET_SHOW_SPINNER'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const GET_CURRENT_STEP = 'GET_CURRENT_STEP'
export const SET_UNIQUE_PLAN_ID = 'SET_UNIQUE_PLAN_ID'

export const UI_COMPONENT = 'UI Component'
export const COMPONENT_ORDER = 'Component Order'
export const MARKETING_UI_COMPONENT = 'Marketing UI Component'

export const GET_PRODUCTS = 'GET_PRODUCTS'

export const CONFIG_DEFAULT_LOBS = 'Default LOBS'
export const CONFIG_OFFER_CHECKBOX_LABEL = 'Offer Checkbox Label'
export const CONFIG_MIN_TV = 'MinTvs'
export const CONFIG_MAX_TV = 'MaxTvs'
export const GET_REFERRER = 'GET_REFERRER'
export const SET_REFERRER = 'SET_REFERRER'
export const SET_ID_IMAGE = 'SET_ID_IMAGE'

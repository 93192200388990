import { Equipment, newSchedule, Package, Product, Promo, OrderSchedule, Upgrade, newPackage } from '../Catalog'
import { PhoneNumberDetails } from '../../common/PhoneNumber'

export interface CartTv {
  numTv: number
  equipment: Equipment[]
  upgrades: Upgrade[]
}

export const newCartTv = (): CartTv => ({
  numTv: 0,
  equipment: [],
  upgrades: []
})

export interface CartInternet {
  equipment: Equipment[]
  upgrades: Upgrade[]
}

export const newCartInternet = (): CartInternet => ({
  equipment: [],
  upgrades: []
})

export interface CartPhone {
  Name: 'New' | 'Keep' | null
  equipment: Equipment[]
  upgrades: Upgrade[]
  phoneNumber?: string
  phoneNumberDetails?: PhoneNumberDetails
}

export const newCartPhone = (): CartPhone => ({
  Name: null,
  equipment: [],
  upgrades: [],
  phoneNumber: undefined,
  phoneNumberDetails: undefined
})

export interface CartHomeAutomation {
  equipment: Equipment[]
  upgrades: Upgrade[]
}

export const newCartHomeAutomation = (): CartHomeAutomation => ({
  equipment: [],
  upgrades: []
})

export interface Cart {
  promo: Promo | undefined
  schedule: OrderSchedule
  package: Package
  products: Product[]
  packageUpgrades: Upgrade[]
  tv: CartTv
  internet: CartInternet
  phone: CartPhone
  homeAutomation: CartHomeAutomation
}

export const newCart = (): Cart => ({
  promo: undefined,
  schedule: newSchedule(),
  package: newPackage(),
  products: [],
  packageUpgrades: [],
  tv: newCartTv(),
  internet: newCartInternet(),
  phone: newCartPhone(),
  homeAutomation: newCartHomeAutomation()
})

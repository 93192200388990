import { bus } from '@/main'
import { SET_PACKAGE, SET_UNIQUE_PLAN_ID } from '@/store/types'
import {
  ConfigKeys,
  Feature,
  FeaturePackagePiece,
  FeaturePackagePieceItem,
  getItemPriceAsNumber,
  itemDisplayName
} from '@adg/catalog/src/modules/Catalog'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { Package } from '@adg/catalog/src/modules/Catalog'
import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api'
import useUiConfig from '@/components/shared/useUiConfig'
import { PackagePiece } from '@adg/catalog/src/common/UIConfig'

export default ($store, $vuetify, emit) => {
  const { offerHeaderColors, offerButtonColors } = useUiConfig($store)

  const selectPackage = (pkg: Package) => {
    $store.commit(SET_PACKAGE, pkg)
    $store.commit(SET_UNIQUE_PLAN_ID, pkg.uniquePlanId)
    emit('packageSelected')
    bus.$emit('packageChanged')
  }

  const underPriceId = (filterPackagesCount: number): string => {
    return filterPackagesCount === 5 && $vuetify.breakpoint.md ? 'under-price-column' : ''
  }

  const image = (packageData: Package): string => {
    return 'api/content/' + (packageData?.Image ?? 'package-header.png')
  }

  const imageToUse = (packageData: Package): string => {
    if (getConfigItem(ConfigKeys.singlePackageHeader)) {
      return 'api/content/' + getConfigItem(ConfigKeys.singlePackageHeader)
    }
    if (packageData?.lobs) {
      const lobs = JSON.parse(JSON.stringify(packageData.lobs))
      lobs.sort((a, b) => (a > b ? 1 : -1))
      const retVal = lobs?.join('').replace(/\s/g, '') + '.png'
      return 'api/content/' + retVal
    }
    return ''
  }

  const offerCardId = (packageData: Package, colorIndex: number): string => {
    const lobString = packageData?.lobs?.join('')
    if (lobString) {
      return lobString + (colorIndex + 1)
    }
    return ''
  }

  const numberOfProducts = (packageData: Package): number => {
    return new Set(packageData?.Products.map((p) => p['Product Type'])).size
  }

  const packageNameHeight = (packageData: Package): number => {
    return numberOfProducts(packageData) < 3 ? 80 : 110
  }

  const packageMaxWidthOld = (filterPackagesCount: number): string => {
    return filterPackagesCount === 5 && $vuetify.breakpoint.md ? '230' : '340'
  }

  const packageMaxWidth = (filterPackagesCount: number = 1) => {
    const maxWidth = ($vuetify.breakpoint.width * 0.9) / filterPackagesCount

    if ($vuetify.breakpoint.mdAndUp) {
      return maxWidth > 340 ? 340 : maxWidth
    } else return maxWidth < 225 ? 225 : maxWidth
  }

  const priceParts = (packageData: Package): string[] => {
    const price = getItemPriceAsNumber(packageData, 'Monthly Price').toFixed(2).split('.') ?? ['0']
    if (price.length === 1) {
      price.push('00')
    }
    return price
  }

  const speedUnits = (packageData: Package): string => {
    return packageData?.Products?.find((p) => p['Product Type'] === 'Internet')?.['Speed Unit'] ?? ''
  }

  const speed = (packageData: Package): number | string => {
    return packageData?.Products?.find((p) => p['Product Type'] === 'Internet')?.Speed ?? ''
  }

  const displayName = (packageData: Package): string => {
    if (getConfigItem(ConfigKeys.packageSquareUsePackageDescription) && packageData.Description) {
      return packageData.Description
    }
    const product = packageData?.Products?.find((p) => p['Product Type'] === 'Internet')
    return product !== undefined ? itemDisplayName(product) : itemDisplayName(packageData)
  }

  const internetProductDisplayName = (packageData: Package): string => {
    const product = packageData?.Products?.find((p) => p['Product Type'] === 'Internet')
    return product !== undefined ? itemDisplayName(product) : itemDisplayName(packageData)
  }

  const duration = (packageData: Package): string => {
    if (packageData['Offer Duration']) {
      return packageData['Offer Duration']
    } else {
      return getConfigItem(ConfigKeys.costDuration) ?? 'mo./1yr'
    }
  }

  const mktTextLines = (packageData: Package): number => {
    return Object.keys(packageData).filter((k) => k.startsWith('Marketing Text')).length ?? []
  }

  const backgroundImage = (packageData: Package): string | undefined => {
    // return 'api/content/noimg.png' //this is not working correctly and needs to be fixed

    return packageData?.backgroundImage ? `api/content/${packageData?.backgroundImage}` : undefined
  }

  const backgroundColor = (
    colorIndex: number | undefined,
    offerHeaderColors: string[],
    offerHeaderSecondaryColors: string[]
  ): string => {
    const primary = colorIndex !== undefined ? offerHeaderColors[colorIndex % offerHeaderColors.length] : 'white'
    const secondary =
      colorIndex !== undefined && offerHeaderSecondaryColors.length
        ? offerHeaderSecondaryColors[colorIndex % offerHeaderSecondaryColors.length]
        : undefined
    return secondary ? `-webkit-linear-gradient(${secondary}, ${primary})` : primary
  }

  const showUnderPriceText = computed(() => getConfigItem(ConfigKeys.showUnderPriceText) ?? false)

  const marketingTextArray = (packageData: Package): Array<any> => {
    return [
      packageData?.['Marketing Text 1'],
      packageData?.['Marketing Text 2'],
      packageData?.['Marketing Text 3'],
      packageData?.['Marketing Text 4'],
      packageData?.['Marketing Text 5'],
      packageData?.['Marketing Text 6'],
      packageData?.['Marketing Text 7'],
      packageData?.['Marketing Text 8']
    ]
  }

  const hrWidth = (filterPackagesCount: number): string => {
    if (packageMaxWidth(filterPackagesCount) === '340') {
      return '270'
    } else return '170'
  }

  const underPriceTextHeight = (filterPackagesCount: number): string => {
    if (packageMaxWidth(filterPackagesCount) === '230') {
      return '81px'
    } else return '65px'
  }

  const packageCardSquareCorners = computed(() => getConfigItem(ConfigKeys.packageCardSquareCorners) ?? false)

  const packageCardClass = computed(() => {
    let packageSquareCorners = packageCardSquareCorners
    return packageSquareCorners.value === true ? 'mx-auto lg tr-0 tl-0' : 'mx-auto rounded-lg rounded-tr-0 rounded-tl-0'
  })

  const headerText = (headerTextType: string, packageData: Package): string => {
    let headerText: string = ''

    if (headerTextType === 'speed') {
      headerText = speed(packageData).toString() + ' ' + speedUnits(packageData)
    }
    if (headerTextType === 'description') {
      headerText = packageDescription(packageData)
    }
    if (headerTextType === 'displayName') {
      headerText = packageDisplayName(packageData)
    }
    if (headerTextType === 'priceWithCents') {
      headerText = '$' + priceParts(packageData)[0] + '.' + priceParts(packageData)[1] + duration(packageData)
    }
    if (headerTextType === 'priceWithoutCents') {
      headerText = '$' + priceParts(packageData)[0] + duration(packageData)
    }

    return headerText
  }

  const approvedBroadbandLabelClasses = computed(() => getConfigItem(ConfigKeys.approvedBroadbandLabelClasses) ?? [])
  const broadbandConfigString = computed(() => getConfigItem(ConfigKeys.broadbandConfigString) ?? '')
  const broadbandLabelPlacement = computed(() => {
    const placement = getConfigItem(ConfigKeys.broadbandLabelPlacement)
    if (placement === 'expandable-footer' || placement === 'flippable-footer') {
      return 'footer'
    }
    return placement
  })

  const displayNameTextColor = (i) => {
    if (i % 4 === 0) {
      return getConfigItem(ConfigKeys.firstDisplayNameColor) ?? ''
    }
    if (i % 4 === 1) {
      return getConfigItem(ConfigKeys.secondDisplayNameColor) ?? ''
    }
    if (i % 4 === 2) {
      return getConfigItem(ConfigKeys.thirdDisplayNameColor) ?? ''
    }
    if (i % 4 === 3) {
      return getConfigItem(ConfigKeys.fourthDisplayNameColor) ?? ''
    }
  }

  const abovePriceText = computed(() => getConfigItem(ConfigKeys.abovePriceText))

  const noBullets = computed(() => getConfigItem(ConfigKeys.noBullets) ?? false)

  const hidePackagePriceZeroes = computed(() => getConfigItem(ConfigKeys.hidePackagePriceZeroes) ?? false)

  const offerButtonColor = computed(() => getConfigItem(ConfigKeys.offerButtonColor))

  const offerButtonText = computed(() => getConfigItem(ConfigKeys.offerButtonText) ?? 'Select')

  const showSpeedUnits = computed(() => getConfigItem(ConfigKeys.showSpeedUnits) ?? true)

  const marketingTextIconColor = computed(() => getConfigItem(ConfigKeys.marketingTextIconColor) ?? 'black')

  const showDisplayName = computed(() => getConfigItem(ConfigKeys.showDisplayName) ?? false)

  const showDescription = computed(() => getConfigItem(ConfigKeys.showDescription) ?? false)

  const showUpperOfferButton = computed(() => getConfigItem(ConfigKeys.showUpperOfferButton) ?? false)

  const showRoundedOfferButton = computed(() => getConfigItem(ConfigKeys.showRoundedOfferButton) ?? false)

  const showTextAbovePrice = computed(() => getConfigItem(ConfigKeys.showTextAbovePrice) ?? false)

  const isOfferTileClickable = computed(() => getConfigItem(ConfigKeys.isOfferTileClickable) ?? false)

  const packagePieces = computed(() => getConfigItem(ConfigKeys.packagePieces))

  const closeLabelIconText = computed(() => getConfigItem(ConfigKeys.closeLabelIconText) ?? undefined)

  const closeLabelIcon = computed(() => {
    return getConfigItem(ConfigKeys.closeLabelIcon) ?? 'mdi-arrow-u-left-top-bold'
  })

  const closeLabelIconColor = computed(() => getConfigItem(ConfigKeys.closeLabelIconColor) ?? 'red')

  const broadbandLabelToggleOption = computed(() => getConfigItem(ConfigKeys.broadbandLabelToggleOption) ?? 'expand')

  const packageDescription = (packageData: Package) => {
    if (getConfigItem(ConfigKeys.useDisplayNameInPackageHeader)) {
      return itemDisplayName(packageData)
    } else return packageData.Description
  }

  const displayFeatures = (packageData: Package, packageFeaturePiece: FeaturePackagePiece) => {
    // checks if there is any feature associated to the package with the same name as the parent feature name of any of the packageFeaturePiece items
    const parentFeatureNames = packageFeaturePiece.packageFeatureItems.map((featurePieceItem: FeaturePackagePieceItem) => {
      return featurePieceItem.parentName
    })
    if (packageData.Features?.find((feature: Feature) => parentFeatureNames.includes(feature.Name))) {
      return true
    } else {
      return false
    }
  }

  const getPackageFeatureText = (
    packageData: Package,
    parentTextFeatureName: string,
    childTextFeatureName: string
  ): string | undefined => {
    const parentPackageTextFeature = packageData.Features?.find((feature: Feature) => feature.Name === parentTextFeatureName)
    const chileTextFeature = parentPackageTextFeature?.Features?.find((feature: Feature) => feature.Name === childTextFeatureName)

    return chileTextFeature?.Description ?? undefined
  }

  const featureImage = (
    packageData: Package,
    parentImageFeatureName: string,
    childImageFeatureName: string
  ): string | undefined => {
    const parentPackageImageFeature = packageData.Features?.find((feature: Feature) => feature.Name === parentImageFeatureName)
    const childImageFeature = parentPackageImageFeature?.Features?.find(
      (feature: Feature) => feature.Name === childImageFeatureName
    )
    return 'api/content/' + childImageFeature?.Image ?? undefined
  }

  const packageDisplayName = (packageData: Package) => {
    if (getConfigItem(ConfigKeys.useDisplayNameInPackageHeader)) {
      return packageData.Description
    } else return itemDisplayName(packageData)
  }

  const getColorHeaderClass = (packageData: Package) => {
    let lobString = ''
    if (packageData.lobs) {
      packageData.lobs
        .slice()
        .sort()
        .forEach((lob) => {
          if (lob) {
            lobString += '-' + lob.toLowerCase()
          }
        })
    }
    return 'color-header' + lobString ?? ''
  }

  const itemClass = (item: PackagePiece, numLines: number) => {
    if (item.largeClass && numLines >= 3) {
      return item.largeClass
    }
    if (item.medClass && numLines === 2) {
      return item.medClass
    } else return item.class
  }

  const getOfferButtonColor = (item: PackagePiece, colorIndex: number) => {
    if (item.singleButtonColor) return item.singleButtonColor
    else {
      return item.buttonSameColorAsCard
        ? offerHeaderColors.value[colorIndex % offerHeaderColors.value.length]
        : offerButtonColors.value[colorIndex % offerButtonColors.value.length]
    }
  }

  //todo: revisit
  const getPackageMarketingTextAtIndex = (packageData: Package, index: number): string => {
    const marketingText = packageData[('Marketing Text ' + index.toString()) as keyof Package]
    return typeof marketingText === 'string' ? marketingText : ''
  }

  return {
    hidePackagePriceZeroes,
    packageCardSquareCorners,
    packageCardClass,
    offerButtonText,
    abovePriceText,
    noBullets,
    offerButtonColor,
    showSpeedUnits,
    marketingTextIconColor,
    showDisplayName,
    showDescription,
    showUnderPriceText,
    showUpperOfferButton,
    showRoundedOfferButton,
    showTextAbovePrice,
    isOfferTileClickable,
    packagePieces,
    offerHeaderColors,
    offerButtonColors,
    broadbandConfigString,
    approvedBroadbandLabelClasses,
    broadbandLabelPlacement,
    closeLabelIcon,
    closeLabelIconColor,
    closeLabelIconText,
    broadbandLabelToggleOption,
    offerCardId,
    numberOfProducts,
    packageNameHeight,
    image,
    imageToUse,
    priceParts,
    speedUnits,
    displayName,
    packageMaxWidth,
    duration,
    underPriceId,
    selectPackage,
    mktTextLines,
    backgroundImage,
    backgroundColor,
    marketingTextArray,
    hrWidth,
    underPriceTextHeight,
    packageDescription,
    packageDisplayName,
    headerText,
    speed,
    internetProductDisplayName,
    getColorHeaderClass,
    itemClass,
    getOfferButtonColor,
    getPackageMarketingTextAtIndex,
    displayFeatures,
    getPackageFeatureText,
    featureImage,
    displayNameTextColor
  }
}

import { getConfigItem } from '@/components/shared/getConfigItem'
import { ConfigKeys, SalesLeadInfo, SalesLeadType, Catalog, catalogHasSalesLead } from '@adg/catalog/src/modules/Catalog'
import { logger } from './RemoteLogger'

export const isSalesLead = (inputValue: object): inputValue is SalesLeadInfo => {
  const value: SalesLeadInfo = inputValue as SalesLeadInfo
  if (value.type === undefined) return false
  if (value.type === SalesLeadType.LEAD_CAPTURE) return true
  return (
    typeof value === 'object' &&
    typeof value.salesLeadLink === 'string' &&
    (value.type === SalesLeadType.NONE ||
      value.type === SalesLeadType.CROWD_FIBER ||
      value.type === SalesLeadType.SIMPLE_REDIRECT ||
      value.type === SalesLeadType.CATALOG_REDIRECT)
  )
}

export const isString = (value: any): value is string => {
  return typeof value === 'string' || value instanceof String
}

export const getSalesLeadInfo = (catalog: Catalog): SalesLeadInfo | undefined => {
  if (!catalogHasSalesLead(catalog)) return undefined
  let cfgItm = getConfigItem(ConfigKeys.salesLead)
  if (typeof cfgItm === 'string') {
    let cfgstr = cfgItm.toString().trim()
    if (cfgstr.startsWith('{')) {
      cfgItm = JSON.parse(cfgstr) || undefined
    } else {
      if (!cfgstr.match(/^https?:\/\//)) {
        cfgstr = `https://${cfgstr}`
      }
      return {
        type: SalesLeadType.CATALOG_REDIRECT,
        salesLeadLink: cfgstr
      }
    }
  }
  if (isSalesLead(cfgItm)) {
    return cfgItm
  }
  logger.error('Invalid SalesLead config item', cfgItm)
  return undefined
}

<template>
  <div v-if="notAllExcluded">
    <v-card class="rounded" tile>
      <CardHeader
        :render-me="renderMe"
        :title="streamingServicesHeaderText"
        :image="streamingServicesHeaderImage"
        :componentOrder="componentOrder"
      ></CardHeader>
      <v-container>
        <v-row>
          <v-col
            v-if="!streamingService.excluded"
            id="streaming-services-card"
            :cols="streamingServiceCols"
            v-for="(streamingService, i) in streamingServices"
            :key="'service' + i"
          >
            <StreamingServicesCard
              :sharpCorners="sharpCorners"
              @change="upgradeChanged"
              v-model="streamingServiceSelections[streamingService.Name]"
              :streamingService="streamingService"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  watch,
  onMounted,
  watchEffect,
  onBeforeUpdate,
  onActivated,
  reactive,
  onBeforeUnmount
} from '@vue/composition-api'
import CardHeader from '@/components/layout/CardHeader.vue'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { SUBCATEGORY, COMPONENT_ORDER } from '@/store/types'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import $store from '@/store'
import ProductPicker from '@/components/shared/ProductPicker.vue'
import ProductPickerLineItem from '@/components/shared/ProductPickerLineItem.vue'
import StreamingServicesCard from './StreamingServicesCard.vue'
import { flatten, toArray } from 'lodash'
import { bus } from '@/main'
import {
  ConfigKeys,
  catalogRank,
  nullCheck,
  addCalculatedPrice,
  getItemPrice,
  itemDisplayName,
  isProductPicker,
  Upgrade,
  Package,
  undefinedCheck
} from '@adg/catalog/src/modules/Catalog'
import { VCard } from 'vuetify/lib'
export default defineComponent({
  name: 'StreamingServices',
  props: {
    sharpCorners: Boolean
  },
  components: {
    CardHeader,
    Tooltip,
    TooltipDebug,
    ProductPicker,
    ProductPickerLineItem,
    StreamingServicesCard
  },
  setup(props, { emit }) {
    const renderMe = ref(0)

    const streamingServicesHeaderImage = computed(() => getConfigItem(ConfigKeys.streamingServicesHeaderImage) ?? '')
    const streamingServicesHeaderText = computed(
      () => getConfigItem(ConfigKeys.streamingServicesHeaderText) ?? 'STREAMING SERVICES'
    )
    const streamingServiceCols = computed(() => {
      const cols = 12 / (streamingServices.value.length ?? 1)
      return cols >= 3 ? cols : 3
    })

    const selectedPackage = computed(() => $store.getters.getPackage as Package)
    const componentOrder = computed(() => {
      const numProducts = selectedPackage.value.Products ? selectedPackage.value.Products.length : 0
      return numProducts
    })

    const streamingServices = computed((): Upgrade[] => {
      const streamingUpgrades = $store.getters.getAvailablePackageUpgrades
        .filter((u: Upgrade) => u[SUBCATEGORY] === 'Streaming Service')
        .sort((a: Upgrade, b: Upgrade) => catalogRank(a.Rank) - catalogRank(b.Rank))
      return streamingUpgrades
    })

    const notAllExcluded = computed(() => streamingServices.value.some((streamingService) => !streamingService.excluded))

    let streamingServiceSelections: Record<string, Upgrade | undefined> = reactive({})

    const upgradeChanged = () => {
      const allItems = flatten(toArray(streamingServiceSelections).filter(undefinedCheck))
      $store.commit('setPackageUpgrades', {
        upgrades: allItems,
        subCategory: 'Streaming Service'
      })
      renderMe.value = renderMe.value + 1
    }

    const initializeModel = () => {
      Object.keys(streamingServiceSelections).forEach((k) => {
        delete streamingServiceSelections[k]
      })
      const streamingServiceUpgrades: Upgrade[] = $store.getters.getPackageUpgrades.filter(
        (packageUpgrade: Upgrade) => packageUpgrade[SUBCATEGORY] === 'Streaming Service'
      )

      streamingServiceUpgrades.forEach((streamingUpgrade: Upgrade) => {
        //radio buttons use groups THIS COMPONENT DOESN'T SUPPORT GROUPS YET
        // if (streamingUpgrade.Min === 1 && streamingUpgrade.Max === 1) {
        //   streamingServiceSelections[streamingUpgrade.Name] = streamingUpgrade
        // }
        //checkbox dont use groups
        if (streamingUpgrade.Min === 0 && streamingUpgrade.Max === 1) {
          streamingServiceSelections[streamingUpgrade.Name] = streamingUpgrade
        }
        //product picker uses objects - this component doesn't use product pickers
        // if (streamingUpgrade.Max && streamingUpgrade.Max > 1) {
        //   streamingServiceSelections[streamingUpgrade.Name] = {
        //     ...streamingUpgrade,
        //     calculatedPrice: addCalculatedPrice(streamingUpgrade)
        //   }
        // }
      })
      renderMe.value = renderMe.value + 1
    }

    bus.$on('packageChanged', () => {
      initializeModel()
    })

    return {
      getItemPrice,
      itemDisplayName,
      streamingServicesHeaderImage,
      streamingServicesHeaderText,
      isProductPicker,
      streamingServiceCols,
      streamingServices,
      streamingServiceSelections,
      upgradeChanged,
      notAllExcluded,
      initializeModel,
      renderMe,
      componentOrder,
      selectedPackage
    }
  }
})
</script>
<style scoped></style>

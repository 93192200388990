import { UrlParamValue } from '../../common/utils'
import { CreditCheckResultType, CreditCheckType } from '../../common/CreditChecks'
import { SalesType } from '../Address'

export type EventCategories = 'Ecommerce'

export type EventAction =
  | 'Cart Add'
  | 'Cart Remove'
  | 'Checkout'
  | 'Detail'
  | 'Package Add'
  | 'Package Downgrade'
  | 'Package Remove'
  | 'Package Upgrade'
  | 'Promo Add'
  | 'Purchase'

export type EventType = 'ecomPush' | 'packageImpression' | 'salesType' | 'shopperType' | 'virtualPageView' | ''

export type VirtualPageURL =
  // | undefined
  | '/activeAccount'
  | '/addressToolbar'
  | '/checkAddress'
  | '/chooseServices'
  | '/creditCheck'
  | '/customizeOptions'
  | '/error'
  | '/noservice'
  | '/orderConfirmation'
  | '/presaleConfirmation'
  | '/prepayment'
  | '/scheduleInstallation'
  | '/setupAccount'
  | '/presales'
  | '/presaleError'
  | '/additionalInfo'
  | '/comingSoon'
  | '/leadCapture'
  | '/leadCaptureSubmitted'
  | '/commercialQuestion'
  | '/agent'

export type VirtualPageUrlMap = Record<VirtualPageURL, VirtualPageURL>

export const VirtualPageTypes: VirtualPageUrlMap = {
  '/activeAccount': '/activeAccount',
  '/addressToolbar': '/addressToolbar',
  '/checkAddress': '/checkAddress',
  '/chooseServices': '/chooseServices',
  '/creditCheck': '/creditCheck',
  '/customizeOptions': '/customizeOptions',
  '/error': '/error',
  '/noservice': '/noservice',
  '/orderConfirmation': '/orderConfirmation',
  '/presaleConfirmation': '/presaleConfirmation',
  '/prepayment': '/prepayment',
  '/scheduleInstallation': '/scheduleInstallation',
  '/setupAccount': '/setupAccount',
  '/presales': '/presales',
  '/presaleError': '/presaleError',
  '/additionalInfo': '/additionalInfo',
  '/comingSoon': '/comingSoon',
  '/leadCapture': '/leadCapture',
  '/commercialQuestion': '/commercialQuestion',
  '/leadCaptureSubmitted': '/leadCaptureSubmitted',
  '/agent': '/agent'
}

export type CheckoutStep = 1 | 2 | 3 | 4

export type CheckoutStepMap = Record<CheckoutStep, CheckoutStep>

export const CheckoutStepTypes: CheckoutStepMap = {
  1: 1,
  2: 2,
  3: 3,
  4: 4
}

export interface GtmEvent {
  event: EventType
  virtualPageUrl?: VirtualPageURL
  virtualPageTitle?: string
  'gtm.uniqueEventId'?: number
  page?: string
  ts?: number
  ecommerce?: Ecommerce
  eventCategory?: EventCategories
  eventAction?: EventAction
  shopperType?: string
  salesType?: SalesType
  dimension5?: string // this ends up being salesType as a custom dimension
}

export interface Ecommerce {
  impressions?: EcomProduct[]
  detail?: Detail
  add?: Action
  remove?: Action
  promoClick?: PromoClick
  checkout?: Checkout
  currencyCode?: string
  purchase?: Purchase
}

export interface Action {
  actionField: ActionField
  products: EcomProduct[]
}

export interface ActionField {
  list?: string
  action?: string
}

export interface EcomProduct {
  id?: string
  name?: string
  category?: string
  brand?: string
  price?: Price
  variant?: string
  quantity: number
  dimension2?: string | number
  dimension3?: string
  dimension4?: string
  dimension5?: Price
  list?: string
  position?: number
  metric2?: string
  sku?: string
}

export type Price = number | string

export interface Checkout {
  actionField: CheckoutActionField
  products?: EcomProduct[]
}

export interface CheckoutActionField {
  step: number
  action?: string
  option?: string
}

export interface Detail {
  actionField: ActionField
  products: EcomProduct[]
}

export interface PromoClick {
  promotions: Promotion[]
}

export interface Promotion {
  name: string
  id: string
}

export interface Purchase {
  actionField: PurchaseActionField
  products: EcomProduct[]
}

export interface PurchaseActionField {
  id?: number
  list?: string
  revenue: string
  action?: string
}

// ----------------------------
// GA4
// ----------------------------
// NOTE TO SELF: all event names and property/parameter names need to be in snake_case for GA4 to be happy
// ----------------------------

export type GA4EventWrapper =
  | GA4CoreBuyflowStartEvent
  | GA4PageViewEvent
  | GA4ViewItemListEvent
  | GA4ViewItemEvent
  | GA4AddToCartEvent
  | GA4RemoveFromCartEvent
  | GA4BeginCheckoutEvent
  | GA4ConversionEvent
  | GA4PromoEvent
  | GA4IncrementShopperEvent
  | GA4AddressEnteredEvent
  | CreditCheckEvent
  | GA4LeadCaptureSubmittedEvent
  | GA4CaptchaEvent
  | GA4ViewBroadbandLabelEvent

export type NonGA4EventWrapper = AllTicketUpdateEvents | AllAgentManagementEvents | AllMiddlewareEvents

export type AllAgentEvents = AllAgentManagementEvents | AllTicketUpdateEvents

export type AllMiddlewareEvents = UnscheduledLeadTransactionEvent

export type AllTicketUpdateEvents =
  | TicketStatusUpdateEvent
  | OrderStatusUpdateEvent
  | AgentAssignedUpdateEvent
  | NoteAddedEvent
  | ReasonUpdateEvent
  | FieldUpdateEvent
export type AllAgentManagementEvents = AgentCreateEvent | AgentUpdateEvent | AutoAssignEvent | AutoAssignAgentEvent

export type GA4ConversionEvent = GA4PurchaseEvent | GA4RefundEvent
export type GA4EventName = 'core_buyflow_start' | 'page_view' | GA4EcomEventName | GA4CustomEventName
export type GA4EcomEventName =
  | 'view_item_list'
  | 'view_item'
  | 'add_to_cart'
  | 'remove_from_cart'
  | GA4CheckoutEventName
  | GA4ConversionEventName
  | GA4PromoEventName
  | GA4CaptchaEventName
export type NonGA4EventName = TicketUpdateEventName | AgentEventName | GA4MiddlewareEventName
export type EventName = GA4EventName | NonGA4EventName
export type GA4CheckoutEventName = 'begin_checkout'
export type GA4ConversionEventName = 'purchase' | 'refund'
export type GA4PromoEventName = 'view_promotion' | 'select_promotion'
export type GA4CustomEventName =
  | 'increment_shopper_count'
  | 'address_entered'
  | 'credit_check'
  | 'lead_capture_submitted'
  | 'view_broadband_label'
export type TicketUpdateEventName =
  | 'ticket_status_update'
  | 'order_status_update'
  | 'agent_assigned_update'
  | 'reason_update'
  | 'note_added'
  | 'field_update'

export type AgentManagementEventName = 'agent_create' | 'agent_update' | 'auto_assign' | 'auto_assign_agent'

export type AgentEventName = AgentManagementEventName | TicketUpdateEventName

export type GA4EventNameMap = Record<GA4EventName, GA4EventName>

export type GA4MiddlewareEventName = 'unscheduled_lead_transaction'

export type GA4CaptchaEventName = 'captcha'

export const GA4EventNames: GA4EventNameMap = {
  page_view: 'page_view',
  view_item_list: 'view_item_list',
  view_item: 'view_item',
  add_to_cart: 'add_to_cart',
  remove_from_cart: 'remove_from_cart',
  begin_checkout: 'begin_checkout',
  purchase: 'purchase',
  refund: 'refund',
  view_promotion: 'view_promotion',
  select_promotion: 'select_promotion',
  increment_shopper_count: 'increment_shopper_count',
  address_entered: 'address_entered',
  credit_check: 'credit_check',
  lead_capture_submitted: 'lead_capture_submitted',
  captcha: 'captcha',
  core_buyflow_start: 'core_buyflow_start',
  view_broadband_label: 'view_broadband_label'
}

export interface GA4MeasurementIdAndApiSecret {
  type: 'benchmark' | 'client'
  measurementId: string
  apiSecret: string
}

// based on official spec, do not change
export interface GA4MeasurementProtocolBody {
  client_id: string
  user_id?: string
  timestamp_micros?: number // only for events in the past, backdated up to 3 days
  user_properties?: GA4CustomUserParameters
  non_personalized_ads?: boolean
  events: GA4MeasurementProtocolEvent[]
}

// based on official spec, do not change
export interface GA4MeasurementProtocolEvent {
  name: GA4EventName
  params?: {
    page_location?: VirtualPageURL
    page_title?: string
    promotion_id?: string
    promotion_name?: string
    creative_name?: string
    creative_slot?: string
    currency?: string
    value?: number
    items?: GA4MeasurementProtocolItem[]
    captcha_score?: number
    time_elapsed?: number
  } & GA4CustomEventParameters
}
export interface BaseEventWrapper<A extends ApplicationName> {
  event: EventName // must be called "event" because that's what GTM triggers look at in the datalayer
  page?: string
  ts?: number
  host?: string
  page_location?: string
  page_title?: string
  session_id?: string
  session_number?: string
  orderTotal?: number
  orderMonthlyTotal?: number
  app_load_id?: string
  buyflow_start_id?: string
  app_name?: A
}

export interface GA4CoreEventWrapper extends BaseEventWrapper<'ui'> {
  mp_event: GA4MeasurementProtocolEvent
  user_properties?: GA4CustomUserParameters
}

export type ApplicationName = 'agent' | 'ui' | 'middleware'
export type GA4MPEnforcer<T extends GA4MeasurementProtocolEvent> = T

export function hasItems(ew: GA4EventWrapper): boolean {
  return ew.mp_event?.params !== undefined && Object.keys(ew.mp_event?.params as any).includes('items')
}

export type UTMParameter = 'utm_campaign' | 'utm_source' | 'utm_medium' | 'utm_content' | 'utm_term'

export interface GA4CoreBuyflowStartEvent extends GA4CoreEventWrapper {
  event: 'core_buyflow_start'
  mp_event: GA4MPEnforcer<{
    name: 'core_buyflow_start'
    params: Record<UTMParameter, string | null>
  }>
}

export interface GA4PageViewEvent extends GA4CoreEventWrapper {
  event: 'page_view'
  mp_event: GA4MPEnforcer<{
    name: 'page_view'
    params: {
      page_location: VirtualPageURL
      page_title: string
    }
  }>
}

export interface GA4ViewBroadbandLabelEvent extends GA4CoreEventWrapper {
  event: 'view_broadband_label'
  mp_event: GA4MPEnforcer<{
    name: 'view_broadband_label'
    params: {
      currency: 'USD'
      unique_plan_id: string
      items?: GA4MeasurementProtocolItem[]
    }
  }>
}

export interface GA4IncrementShopperEvent extends GA4CoreEventWrapper {
  event: 'increment_shopper_count'
  mp_event: GA4MPEnforcer<{
    name: 'increment_shopper_count'
    params: GA4CustomEventParameters
  }>
}

export interface GA4AddressEnteredEvent extends GA4CoreEventWrapper {
  event: 'address_entered'
  mp_event: GA4MPEnforcer<{
    name: 'address_entered'
    params: {
      input_address: string
    }
  }>
}
export interface GA4EcommerceEventRequiresItems extends GA4CoreEventWrapper {
  event: GA4EcomEventName
  mp_event: GA4MPEnforcer<{
    name: GA4EcomEventName
    params: {
      currency: 'USD'
      value?: number // total of items price - any discounts
      items: GA4MeasurementProtocolItem[]
    }
  }>
}

export interface GA4EcommerceEventOptionalItems extends GA4CoreEventWrapper {
  event: GA4EcomEventName
  mp_event: GA4MPEnforcer<{
    name: GA4EcomEventName
    params: {
      currency: 'USD'
      value?: number // total of items price - any discounts
      items?: GA4MeasurementProtocolItem[]
    }
  }>
}

export interface GA4PromoEvent extends GA4CoreEventWrapper {
  event: GA4PromoEventName
  mp_event: GA4MPEnforcer<{
    name: GA4PromoEventName
    params: {
      promotion_id: string
      promotion_name?: string
      creative_name?: string
      creative_slot?: string
    }
  }>
}

export interface GA4CaptchaEvent extends GA4CoreEventWrapper {
  event: GA4CaptchaEventName
  mp_event: GA4MPEnforcer<{
    name: GA4CaptchaEventName
    params: {
      captcha_score: number
      time_elapsed: number
    }
  }>
}

export function isGA4CoreBuyflowStartEvent(ew: GA4EventWrapper): ew is GA4CoreBuyflowStartEvent {
  return ew.event === 'core_buyflow_start'
}

export function isPromoEvent(ew: GA4EventWrapper): ew is GA4PromoEvent {
  return ew.event === 'select_promotion' || ew.event === 'view_promotion'
}

export interface CreditCheckEvent extends GA4CoreEventWrapper {
  event: 'credit_check'
  mp_event: GA4MPEnforcer<{
    name: 'credit_check'
    params: {
      credit_bureau?: string
      credit_check_type: CreditCheckType
      credit_check_result: CreditCheckResultType
    }
  }>
}
export interface GA4ViewItemListEvent extends GA4EcommerceEventRequiresItems {
  event: 'view_item_list'
  user_properties: GA4CustomUserParameters
}

export interface GA4ViewItemEvent extends GA4EcommerceEventRequiresItems {
  event: 'view_item'
}
export interface GA4AddToCartEvent extends GA4EcommerceEventRequiresItems {
  event: 'add_to_cart'
}
export interface GA4RemoveFromCartEvent extends GA4EcommerceEventRequiresItems {
  event: 'remove_from_cart'
}

export interface GA4BeginCheckoutEvent extends GA4EcommerceEventRequiresItems {
  event: 'begin_checkout'
}
export interface GA4PurchaseEvent extends GA4EcommerceEventRequiresItems {
  event: 'purchase'
  mp_event: GA4MPEnforcer<{
    name: 'purchase'
    params: {
      currency: 'USD'
      transaction_id: string
      value: number // total of items price - any discounts
      items: GA4MeasurementProtocolItem[]
    }
  }>
}

export interface GA4RefundEvent extends GA4EcommerceEventOptionalItems {
  event: 'refund'
  mp_event: GA4MPEnforcer<{
    name: 'refund'
    params: {
      currency: 'USD'
      transaction_id: string
      value: number // total of items price - any discounts
      items?: GA4MeasurementProtocolItem[]
    }
  }>
}

export interface GA4LeadCaptureSubmittedEvent extends GA4CoreEventWrapper {
  event: 'lead_capture_submitted'
}

// based on official spec, do not change
export type GA4MeasurementProtocolItem = {
  item_id: string
  item_name?: string
  item_list_name?: string
  index?: number
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_brand?: string
  price?: number
  discount?: number
  item_variant?: string
  quantity?: number
} & GA4CustomItemParameters

// max 25
export type GA4CustomUserParameters = {
  shopper_type?: { value: string }
  offer_set?: { value: string }
  market?: { value: string }
  gcid?: { value: string } // this is google client id, but param names cannot begin with google or g_
}
export interface GA4CustomEventParameters {
  package_change_type?: 'initial add' | 'upgrade' | 'downgrade'
  shopper_count?: 1
  credit_bureau?: string
  credit_check_type?: CreditCheckType
  credit_check_result?: CreditCheckResultType
  input_address?: string
  lead_capture_form_name?: string
  utm_campaign?: UrlParamValue | null
  utm_source?: UrlParamValue | null
  utm_medium?: UrlParamValue | null
  utm_content?: UrlParamValue | null
  utm_term?: UrlParamValue | null
  unique_plan_id?: string
}
export interface GA4CustomItemParameters {
  internet_speed?: string // custom dimension
  internet_tier?: string // custom dimension
  tv_tier?: string // custom dimension
  package_name?: string // custom dimension
  package_price?: number // custom dimension
}

// based on official spec, do not change
export interface GA4ValidationMessage {
  fieldPath: string
  description: string
  validationCode: string
}
// based on official spec, do not change
export interface GA4ValidationResponse {
  validationMessages: GA4ValidationMessage[]
}

export type GA4AgentEvent<E extends AgentEventName> = BaseEventWrapper<'agent'> & {
  event: E
  fieldChanged?: string
  newVal?: string
  oldVal?: string
  agent: string
}

export type GA4AgentManagementEvent<E extends AgentManagementEventName> = GA4AgentEvent<E> & {
  editedAgent: string
}

export type GA4TicketUpdateEvent<E extends TicketUpdateEventName> = GA4AgentEvent<E> & {
  ticketId: number
}

export type TicketStatusUpdateEvent = GA4TicketUpdateEvent<'ticket_status_update'>
export type OrderStatusUpdateEvent = GA4TicketUpdateEvent<'order_status_update'>
export type AgentAssignedUpdateEvent = GA4TicketUpdateEvent<'agent_assigned_update'>
export type NoteAddedEvent = GA4TicketUpdateEvent<'note_added'>
export type ReasonUpdateEvent = GA4TicketUpdateEvent<'reason_update'>
export type FieldUpdateEvent = GA4TicketUpdateEvent<'field_update'>

export type AgentCreateEvent = GA4AgentManagementEvent<'agent_create'>
export type AgentUpdateEvent = GA4AgentManagementEvent<'agent_update'>
export type AutoAssignEvent = GA4AgentManagementEvent<'auto_assign'>
export type AutoAssignAgentEvent = GA4AgentManagementEvent<'auto_assign_agent'>

export interface UnscheduledLeadTransactionEvent extends BaseEventWrapper<'middleware'> {
  event: 'unscheduled_lead_transaction'
  ticketId: number
}

export function isTicketUpdateEvent(ew: NonGA4EventWrapper): ew is AllTicketUpdateEvents {
  return (
    ew.event === 'ticket_status_update' ||
    ew.event === 'order_status_update' ||
    ew.event === 'agent_assigned_update' ||
    ew.event === 'reason_update' ||
    ew.event === 'note_added' ||
    ew.event === 'field_update'
  )
}

export function isAgentUpdateEvent(ew: NonGA4EventWrapper): ew is AllAgentManagementEvents {
  return (
    ew.event === 'agent_create' || ew.event === 'agent_update' || ew.event === 'auto_assign' || ew.event === 'auto_assign_agent'
  )
}

<template>
  <v-card flat class="mx-auto number-picker pa-0 ma-0">
    <v-list-item class="pt-3">
      <v-slider
        v-model="model"
        color="secondary"
        track-color="grey"
        always-dirty
        :value="value"
        :min="min"
        :max="max"
        @change="changed"
        thumb-size="20"
        thumb-label="always"
        style="max-width: 275px"
      >
        <template v-slot:prepend>
          <v-icon color="secondary-dark" @click="decrement">
            {{ minus }}
          </v-icon>
        </template>

        <template v-slot:append>
          <v-icon color="secondary-dark" @click="increment">
            {{ plus }}
          </v-icon>
        </template>
      </v-slider>
    </v-list-item>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, computed, watch } from '@vue/composition-api'
import { mdiMinus, mdiPlus, mdiInformation } from '@mdi/js'
import { Tooltip } from '@/components/shared/tooltip/ITooltip'

//TODO: in template, need to fix value on v-slider

export default defineComponent({
  name: 'NumberPicker',
  props: {
    description: String,
    min: Number,
    max: Number,
    value: Number,
    tooltip: Tooltip
  },
  setup(props, { emit }) {
    const items = (props.max && Array.from(Array(props.max + 1).keys())) ?? []
    const model = computed({
      get: () => {
        return props.value
      },
      set: (val) => {
        emit('input', val)
      }
    })

    const changed = (val: number) => {
      model.value = val
    }
    const increment = () => {
      if (model.value !== undefined) model.value += 1
    }
    const decrement = () => {
      if (model.value !== undefined && props.min !== undefined && model.value > props.min) {
        model.value -= 1
      }
    }

    watch(
      () => props.min,
      (a, b) => {
        model.value = props.min
      }
    )

    return {
      model,
      changed,
      increment,
      decrement,
      items,
      minus: mdiMinus,
      plus: mdiPlus,
      mdiInformation
    }
  }
})
</script>

<style>
.number-picker .v-list-item {
  padding: 0px;
}

.number-picker div.v-messages.theme--light {
  min-height: 0px;
}
</style>

<template>
  <div :id="`header-new-${currentStep}`">
    <v-system-bar app class="white--text" color="primary" height="40" v-if="determineIfBannerDisplayed">
      <v-spacer></v-spacer>
      <div class="pr-10" v-html="bannerText"></div>

      <v-btn
        color="secondary"
        outlined
        class="promo-button font-weight-bold hidden-md-and-up"
        @click="applyPromo"
        :small="$vuetify.breakpoint.smAndUp"
        :x-small="$vuetify.breakpoint.xsOnly"
      >
        <span class="white--text">CLICK TO APPLY TO CART</span>
      </v-btn>
      <v-spacer></v-spacer>
    </v-system-bar>
    <v-app-bar class="brand-header">
      <v-spacer v-if="allowDebug"></v-spacer>
      <div v-if="allowDebug" class="pt-4 pb-0 pl-3 pr-3" style="background-color: black">
        <v-switch v-model="debugMode" label="Debug Mode" color="red" dark />
      </div>
      <TooltipDebug :item="appState" :name="'State'" />
      <template v-for="headerItem in headerPieces">
        <v-row v-if="shouldHeaderItemDisplay(headerItem.steps)" :class="headerItem.rowClass">
          <v-col cols="auto" :class="headerItem.class">
            <div v-if="headerItem.type === 'clickableImage'">
              <a :href="headerItem.link" target="_blank">
                <img :src="headerItem.img" />
              </a>
            </div>
            <div v-if="headerItem.type === 'image'">
              <img :src="headerItem.img" />
            </div>
            <div v-if="headerItem.type === 'clickableText'">
              <a :href="headerItem.link" target="_blank">
                <div v-html="headerItem.text"></div>
              </a>
            </div>
            <div v-if="headerItem.type === 'text'">
              <div v-html="headerItem.text"></div>
            </div>
            <div v-if="headerItem.type === 'address' && punctuatedAddress != ''">
              <div v-html="punctuatedAddress"></div>
            </div>
            <div v-if="headerItem.type === 'icon'">
                <v-icon>{{ headerItem.icon }}</v-icon>
            </div>
            <div v-if="headerItem.type === 'spacer'">
              <v-spacer></v-spacer>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-app-bar>
    <v-app-bar id="mobile-cart-header" @click="dialog = true" color="black" class="mobile-cart-header" v-if="mobileCart">
      <v-row class="header-row" align="center">
          <v-col class="icon-col" cols="auto">
            <a @click="dialog = true">
              <v-icon color="white">mdi-cart-check</v-icon>
            </a>
        </v-col>
          <v-col class="total-col" cols="auto">
          <v-toolbar-title
            ><v-spacer></v-spacer>Monthly Total ${{ monthlyTotal.toFixed(2) }}</v-toolbar-title
          >
        </v-col>
        <v-row class="img-row">
          <v-col class="img-col" cols="auto" >
            <img :src="headerLogo"></img>
          </v-col>
      </v-row>
      </v-row>
    </v-app-bar>

    <!--    DIALOG CART-->
    <v-row justify="center">
      <v-dialog overlay-color="black" overlay-opacity=".8" v-model="dialog" scrollable max-width="450px">
        <v-card-text style="height: 800px" class="pa-0">
          <Cart @cartClicked="dialog = !dialog" />
        </v-card-text>
        <v-divider></v-divider>
      </v-dialog>
    </v-row>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUpdated, ref } from '@vue/composition-api'
import Cart from '@/components/order/cart/Cart.vue'
import useCart from '@/components/order/cart/useCart'
import useOrder from '@/components/order/useOrder'
import { ConfigKeys, Promo } from '@adg/catalog/src/modules/Catalog'
import { getConfigItem } from '../shared/getConfigItem'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import $store from '@/store'
import { usePiniaUIConfig } from '@/store/pinia/piniaUIConfig'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import ga4 from '@/gtm/ga4'
import { getPunctuatedAddressFromTags, getInputAddressFromTags } from '@adg/catalog/src/common/addressUtil'

export default defineComponent({
  name: 'HeaderNew',
  components: {
    Cart,
    TooltipDebug
  },
  setup(props, { refs, root }) {
    const { $vuetify, $router } = root as any
    const piniaState = computed(() => ({ uiConfig: usePiniaUIConfig().$state, shopper: usePiniaShopper().shopper }))

    const appBarColor = computed(() => (bannerText.value ? 'primary' : 'white'))
    const { monthlyTotal } = useCart($store)
    const selectedPackage = computed(() => $store.getters.getPackage)
    const logoLink = computed(() => getConfigItem(ConfigKeys.logoLink))
    const headerPieces = computed(() => getConfigItem(ConfigKeys.headerPieces)) as HeaderPieces
    const headerLogo = computed(() => getConfigItem(ConfigKeys.headerLogo) ?? '/api/content/logo.png')

    const monthlyTotalHeaderColor = computed(() => getConfigItem(ConfigKeys.monthlyTotalHeaderColor) ?? 'secondary')

    const allowDebug = computed(() => $store.getters.allowDebug)
    const debugMode = computed({
      get: () => $store.getters.getDebugMode,
      set: (val) => $store.commit('setDebugMode', val)
    })

    const appState = computed(() => $store.getters.getState)
    const mobileCart = computed(() => $vuetify.breakpoint.smAndDown && $store.getters.getMobileCart)

    const { currentStep } = useOrder($store, $router)

    const shouldHeaderItemDisplay = (steps?: string[]) => {
      if (!steps) return true
      return steps.includes(currentStep.value.toString())
    }

    onUpdated(() => {
      if ($vuetify.breakpoint.mdAndUp) {
        dialog.value = false
      }
    })

    onMounted(() => {
      if (determineIfBannerDisplayed.value) {
        ga4.pushPromo('view_promotion', availablePromo.value, 'banner')
      }
    })

    const determineIfBannerDisplayed = computed(
      () =>
        showBanner.value &&
        bannerText.value &&
        (getConfigItem(ConfigKeys.promoStepsAllowed) ?? [1, 2, 3, 4]).includes(currentStep.value)
    )

    const availablePromo = computed(() => {
      if (selectedPackage && selectedPackage.value && selectedPackage.value.Promos && selectedPackage.value.Promos.length > 0) {
        let bestPromo = selectedPackage.value.Promos.filter((pro: Promo) => pro['Promo Type'] == 'Automatic') //look for promos with type as automatic
        if (bestPromo.length == 0) bestPromo = selectedPackage.value.Promos // if no automatic promos, use first promo in the array
        return bestPromo[0]
      }

      return undefined
    })

    const promo = computed(() => $store.getters.getPromo)

    const applyPromo = () => {
      $store.commit('setPromo', availablePromo.value)
      if (promo.value) {
        ga4.pushPromo('select_promotion', promo.value, 'banner')
      }
    }

    const bannerText = computed(() => {
      if (!availablePromo.value || promo.value) return undefined
      return $vuetify.breakpoint.smAndDown ? availablePromo.value['Mobile Description'] : availablePromo.value.Description
    })

    const inputAddress = computed(() => getInputAddressFromTags($store.getters.getShopper))
    const punctuatedAddress = computed(() => inputAddress.value ?? getPunctuatedAddressFromTags($store.getters.getShopper))

    const showBanner = computed({
      get: () => $store.getters.getShowBanner,
      set: (val) => $store.commit('setShowBanner', val)
    })

    const dialog = ref(false)

    const bannerColor = computed(() => getConfigItem(ConfigKeys.bannerColor) ?? '#FFFFFF')

    const addressTextColor = computed(() => (bannerColor.value === '#FFFFFF' ? '#000000' : '#FFFFFF'))

    const showTagsDebug = computed(() => debugMode.value || $store.getters.getUrlParams.showAddressTags === 'true')

    //console.log(bannerText.value)

    return {
      logoLink,
      monthlyTotal,
      selectedPackage,
      promo,
      applyPromo,
      punctuatedAddress,
      bannerText,
      determineIfBannerDisplayed,
      mobileCart,
      dialog,
      appBarColor,
      addressTextColor,
      bannerColor,
      headerLogo,
      allowDebug,
      debugMode,
      appState,
      monthlyTotalHeaderColor,
      piniaState,
      showTagsDebug,
      headerPieces,
      currentStep,
      shouldHeaderItemDisplay
    }
  }
})
</script>

<style>
.mobile-cart-header .col img {
  display: block;
  height: 100%;
  width: auto;
  max-height: 44px;
}
.img-col {
  justify-content: flex-end;
  display: flex;
}
.cart-total {
  display: flex;
}
.brand-header {
  position: relative !important;
  height: 100% !important;
}
.brand-header .v-toolbar__content {
  height: 100% !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.header-new-banner {
  position: relative;
  width: 100%;
  height: 92px;
  overflow: auto;
}
</style>

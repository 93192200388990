
<v-container id="monthly-total-row" class="py-0 grey lighten-3">
  <v-row v-if="!hasPromos" align="start" id="monthly-total-row" class="grey lighten-3 pt-2 px-0 ma-0">
    <v-col :class="textClasses" id="monthly-total-row" class="pa-0" cols="8"
      ><span v-html="uiMacroParser(shopper, itemName) + parent.qty"></span><TooltipDebug :item="item" /><Tooltip
        :payload="item"
      />
    </v-col>
    <v-col :class="priceClasses" id="monthly-total-row" class="pa-0 text-right" cols="4">
      <span v-html="uiMacroParser(shopper, parent.PricePrefix ?? '') + ' ' + coreCurrency(parent.price)"></span>
    </v-col>
  </v-row>

  <v-row v-else class="pa-0 ma-0" align="start" justify="space-between">
    <v-expansion-panels flat v-model="panel" id="mpdPanel">
      <v-expansion-panel class="grey lighten-3 pt-2 px-0 ma-0">
        <v-expansion-panel-header hide-actions class="pa-0 ma-0" style="font-size: 1rem" align="start">
          <v-row class="pa-0 ma-0" align="start">
            <v-col :class="textClasses" class="pa-0" cols="8"
              ><span v-html="uiMacroParser(shopper, itemName) + parent.qty"></span><TooltipDebug :item="item" /><Tooltip
                :payload="item"
              />
            </v-col>
            <v-col :class="priceClasses" class="pa-0 text-right" cols="4">
              <v-icon :color="componentColor" class="pb-1" small>{{ circleIcon }}</v-icon>
              <span v-html="uiMacroParser(shopper, parent.PricePrefix ?? '') + ' ' + coreCurrency(parent.price)"></span>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-if="hasPromos">
            <v-row v-for="(promo, i) in promoProgressions" :key="`${parent.name}-promo-${i}`">
              <v-col class="py-0 promo-text" cols="11">{{ promo }} </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</v-container>


<v-container class="mt-12" v-if="comingSoon" :class="comingSoon.class">
  <v-row>
    <v-col id="coming-soon-title" class="headline" cols="12" v-html="comingSoon.title"></v-col>
    <v-col id="coming-soon-subtitle" class="title" cols="12" v-html="comingSoon.subtitle"></v-col>
  </v-row>
  <v-row>
    <v-col v-for="(action, i) in comingSoon.actions" :class="action.class" v-bind:key="`coming-soon-action-${i}`">
      <div v-if="action.type === 'button'">
        <v-btn
          v-bind="action.bind"
          :color="action.color"
          :href="action.link"
          @click="answerQuestion(action.onClick)"
          target="_blank"
        >
          <span>{{ action.text }}</span>
        </v-btn>
      </div>
      <div v-else-if="action.type === 'text'">
        <p v-html="action.text"></p>
      </div>
      <div v-else-if="action.type === 'forcastedConstructionStartDate'">
        <p v-html="action.text + forcastedConstructionStartDate"></p>
      </div>
      <div v-else-if="action.type === 'estimatedConstructionCompletionDate'">
        <p v-html="action.text + estimatedConstructionCompletionDate"></p>
      </div>
    </v-col>
  </v-row>
</v-container>

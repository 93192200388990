import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { computed } from '@vue/composition-api'

export default ($store) => {
  const getShopper = (): IShopper => {
    return $store.getters.getShopper
  }
  const isDebug = computed((): boolean => {
    return $store.getters.getDebugMode
  })

  return {
    getShopper,
    isDebug
  }
}

import {
  BillingCode,
  CartTotal,
  Catalog,
  ChargeTypeKey,
  Equipment,
  Fee,
  FeedItem,
  Item,
  Package,
  PricedItem,
  Product,
  Promo,
  CatalogStatus,
  Upgrade,
  Feature,
  FeeGroup
} from './interface/Catalog'
import { SalesLeadInfo } from './SalesLeadInfo'
import { SalesLeadType } from './interface/SalesLeadType'
import { Config, ShoppingOrder } from '.'
import { ConfigKeys } from './interface/ConfigKeys'
import { uniq, flatten } from 'lodash'

import { IShopper } from '../../Shopper'
import { FullAddress } from '../../Address'
import { AddressMatchResults } from '../../Client/client'


export interface ConvertedProductType {
  name: string
  label: string
  lobs: string[]
}

export interface CatalogLookup {
  statusCode: CatalogStatus
  statusMessage?: string
  catalog?: Catalog
  address?: FullAddress
  matchResults?: AddressMatchResults
}

export const catalogRank = (rank: string | number | undefined): number => {
  const avgRank = Number.MAX_SAFE_INTEGER / 2
  if (typeof rank === 'undefined') {
    rank = avgRank
  } else if (typeof rank === 'number') {
    //do nothing, its already a number
  } else if (typeof rank === 'string') {
    rank = rank.match(/s*/) ? avgRank : Number(rank)
    rank = isNaN(rank) ? avgRank : rank
  }
  if (rank < 0) rank = Number.MAX_SAFE_INTEGER + rank
  return rank
}

export const catalogItemCompare = (a: Item, b: Item): number => {
  return catalogRank(a.Rank) - catalogRank(b.Rank)
}

function isConfigItem(x: any, key: string): boolean {
  return x.Key === key // || x.Name === key
}

const findCatalogConfigItem = (config: Config[], key: string, type?: string): Config | undefined => {
  return config.find((i) => isConfigItem(i, key) && (i.Type && type ? i.Type === type : true))
}

const getCatalogConfigItem = (config: Config[], key: string): Config | undefined => {
  return config.find((i) => i?.Name === key)
}

export const getCatalogConfigItemValue = (config: Config[], key: string): string | undefined => {
  return getCatalogConfigItem(config, key)?.['Config Value']?.toString()
}

export const catalogHasSalesLead = (catalog: Catalog): boolean => {
  return getCatalogConfigItemValue(catalog.Config, ConfigKeys[ConfigKeys.salesLead]) !== undefined
}

// export const salesLeadInfo = (catalog: Catalog): SalesLeadInfo | undefined => {
//   const catalogLeadLink = getCatalogConfigItemValue(catalog.Config, ConfigKeys[ConfigKeys.salesLead])
//   if (catalogLeadLink) {
//     let cfg = catalogLeadLink.toString().trim()
//     console.log(`salesLeadInfo: cfg = ${cfg}`)
//     if (cfg.startsWith('{')) {
//       return JSON.parse(cfg) || undefined
//     } else {
//       if (!cfg.startsWith('@') && !cfg.match(/^https?:\/\//)) {
//         cfg = `https://${cfg}`
//       }
//       return {
//         type: SalesLeadType.CATALOG_REDIRECT,
//         salesLeadLink: cfg
//       }
//     }
//   } else {
//     return undefined
//   }
// }

export function isPackage(item: Item): item is Package {
  return item.itemType === 'Package'
}

export const newPackage = (): Package => ({
  itemType: 'Package',
  Name: '',
  'Display Name': '',
  Products: []
})

export function isProduct(item: Item): item is Product {
  return item.itemType === 'Product'
}

export function isEquipment(item: Item): item is Equipment {
  return item.itemType === 'Equipment'
}

export function isFee(item: Item): item is Fee {
  return item.itemType === 'Fee'
}

export function isFeeGroup(item: Item): item is FeeGroup {
  return item.itemType === 'FeeGroup' && (item as FeeGroup).Fees?.length > 0
}

export function hasFees(item: Item): item is FeedItem {
  return (item as FeedItem).Fees !== undefined
}

export function isUpgrade(item: Item): item is Upgrade {
  return item.itemType === 'Upgrade'
}

export function isBillingCode(item: Item): item is BillingCode {
  return item.itemType === 'BillingCode'
}

export function isPromo(item: Item): item is Promo {
  return item.itemType === 'Promo'
}

export function isCartTotal(item: Item): item is CartTotal {
  return item.itemType === 'CartTotal'
}

// export function hasPrice(item: Item): item is PricedItem {
//   const possiblePricedItem = item as PricedItem
//   return (
//     possiblePricedItem['Monthly Price'] !== undefined ||
//     possiblePricedItem.OTC !== undefined ||
//     possiblePricedItem.CalculatedPrice !== undefined
//   ) // }

// export const getItemPrice = (item: PricedItem, priceType: ChargeTypeKey, month?: number): string | number | undefined => {
//   let checkMonth = 1
//   if (priceType === 'OTC') {
//     checkMonth = 0
//   } else if (month === undefined) {
//     checkMonth = 1
//   } else {
//     checkMonth = month
//   }
//   let price
//   if (item.priceIncluded) {
//     price = 'Included'
//   } else if (item.CalculatedPrice) {
//     price = item.CalculatedPrice.filter((f) => (f?.StartMonth ?? 0) <= checkMonth)
//       .filter((f) => checkMonth <= (f?.EndMonth ?? Infinity))
//       .map((f) => f[priceType])
//       .reduce((a, b) => smartSum(a, b), 0)
//   } else {
//     price = item[priceType]
//   }
//   // If Package get prices recursively from Products
//   if (!price && isPackage(item)) {
//     if (Array.isArray(item.Products)) {
//       price = item.Products.map((p) => getItemPrice(p, priceType)).reduce((a, b) => smartSum(a, b), 0)
//     }
//   }
//   return price
// }

// export const getMultipleItemPrice = (item: PricedItem, priceType: ChargeTypeKey, month?: number, qty: number): number => {
//   if (item.included) {

//   })
// }

export const getItemPriceAsNumber = (item: PricedItem, priceType: ChargeTypeKey, month?: number): number => {
  const checkMonth = priceType === 'OTC' ? 0 : month ?? 1
  return getItemPriceAsNumberPrivate(item, priceType, checkMonth)
}

const getItemPriceAsNumberPrivate = (item: PricedItem, priceType: ChargeTypeKey, month: number): number => {
  const n = getItemPricePrivate(item, priceType, month)
  return n === undefined || typeof n === 'string' ? 0 : n
}

export const getItemPrice = (item: PricedItem, priceType: ChargeTypeKey, month?: number): string | number | undefined => {
  const checkMonth = priceType === 'OTC' ? 0 : month ?? 1
  return getItemPricePrivate(item, priceType, checkMonth)
}

export const getItemPriceWithCents = (price: string | number): string => {
  if (typeof price === 'number') {
    price = price.toString()
  }
  if (price.includes('.')) {
    return price
  } else {
    return price + '.00'
  }
}

export const addCalculatedPrice = (item: Upgrade): number => {
  let price = 0
  if (getItemPrice(item, 'Monthly Price')) {
    price = getItemPriceAsNumber(item, 'Monthly Price') * (item?.qty ?? 0)
  }
  if (item.OTC) {
    price = item.qty === 0 ? 0 : getItemPriceAsNumber(item, 'OTC') // This look WRONG   KWC
  }
  return price
}

export function getAllFeaturedSubItems(item: FeedItem): FeedItem[] | undefined {
  let directSubItems: FeedItem[] = [...(item.Fees ?? []), ...(item['Billing Codes'] ?? []), ...(item?.Features ?? [])]
  // if Package and no Calculated prices and no in record charges get Product prices
  if (isPackage(item)) {
    directSubItems = [...directSubItems, ...(item.Products ?? [])]
  }
  if (isProduct(item)) {
    const includedEquiment = (item.Equipment ?? []).filter((e) => e.included)
    const IncludedUpgrades = (item.Upgrades ?? []).filter((u) => u.included)
    directSubItems = [...directSubItems, ...includedEquiment, ...IncludedUpgrades]
  }
  // const allSubItems: FeedItem[] = directSubItems
  // directSubItems.forEach((f) => {
  //   const subsubitems = getAllFeaturedSubItmes(f)
  //   allSubItems.push(...(subsubitems ?? []))
  // })
  return directSubItems
}

export const getItemFeatures = (item: FeedItem, num?: number): Feature[] => {
  const subitems = getAllFeaturedSubItems(item) ?? []
  const subFeatures = subitems.map((f) => getItemFeatures(f, num)).flat()
  const allFeatures = [...(item?.Features ?? []), ...subFeatures]
  const sorted = allFeatures.sort(catalogItemCompare)
  return num !== undefined ? sorted.slice(0, num) : sorted
}

export const getItemPriceAsString = (item: PricedItem, priceType: ChargeTypeKey, month: number): string => {
  let price = getItemPrice(item, priceType, month)
  if (typeof price === 'number') {
    price = `$${price.toFixed(2)}`
  } else if (typeof price === 'undefined') {
    price = '$0.00'
  }
  return price
}

const getItemPricePrivate = (item: PricedItem, priceType: ChargeTypeKey, month: number): string | number | undefined => {
  let price
  if (((item?.StartMonth ?? 0) <= month && month <= (item?.EndMonth ?? Infinity)) || month === Infinity) {
    const subprcs = getItemSubItems(item, month, 'pricedIntoParent')
    // KWC don't include based on priceIncluded, this is only a flag used in TvEquipment
    if (item.priceIncluded !== false && item.included) {
      // i think this is breaking includeds in later months
      price = 'Included'
    } else if (subprcs && subprcs.length > 0) {
      price = subprcs.map((f) => getItemPricePrivate(f, priceType, month)).reduce((a, b) => smartSum(a, b), 0)
      if (item.Name === 'Installation Fee' && priceType === 'OTC') {
        //console.log(`month = ${month} ... price = ${price}`)
      }
    } else {
      price = item[priceType]
    }
  } else {
    price = undefined
  }
  return price
}

export interface PeriodSpan {
  StartMonth: number
  EndMonth: number
}

// get list of ALL PeriodSpans for an Item and its sub-items in order of StartMonth
export function getRecurringItemPeriods(item: FeedItem): PeriodSpan[] {
  const starts = getRecurringItemStartPeriods(item)
  const nextStarts = starts.slice(1) // get slice for end periods (can be empty array)
  let lastStart = starts[0] ?? 1 // get first start period (should always have one, but add default of 1 just to be safe)

  // loop through possible nextStarts and build the spans we can
  const res: PeriodSpan[] = nextStarts.map((n) => {
    const span = {
      StartMonth: lastStart,
      EndMonth: n - 1 // end is the new period minus 1
    }
    lastStart = n // set lastStart for next iteration of map
    return span
  })

  // Add last span
  res.push({
    StartMonth: lastStart,
    EndMonth: Infinity
  })

  return res
}

// get list of ALL starting Months (Periods) for an Item and its sub-items in order
export function getRecurringItemStartPeriods(item: FeedItem): number[] {
  let starts = [item.StartMonth ?? 1]
  const s = getItemSubItems(item, Infinity)
  s?.forEach((i) => (starts = starts.concat(getRecurringItemStartPeriods(i))))
  starts = uniq(starts)
  starts.sort((a, b) => a - b)
  return starts
}

type ItemSubItems = 'all' | 'pricedIntoParent'

export function getItemSubItems(item: FeedItem, month: number, type: ItemSubItems = 'pricedIntoParent'): FeedItem[] | undefined {
  let subitems: FeedItem[] = [...(item.Fees ?? []), ...(item['Billing Codes'] ?? [])]
  // if Package and no Calculated prices and no in record charges get Product prices
  if (isPackage(item) && item['Monthly Price'] === undefined && item.OTC === undefined && item.Products) {
    subitems = subitems.concat(item.Products)
  } else if (isCartTotal(item)) {
    subitems = item.subItems
  }
  if (type === 'pricedIntoParent' && !isFeeGroup(item)) {
    subitems = subitems.filter((i) => shouldPriceIntoParent(i))
  }
  if (month !== Infinity) {
    // filter out months
    subitems = subitems.filter((i) => (i.StartMonth ?? 0) <= month && month <= (i.EndMonth ?? Infinity))
  }
  return subitems
}

export function getItemSubItemsRecursive(
  item: FeedItem,
  month: number,
  type: ItemSubItems = 'pricedIntoParent'
): FeedItem[] | undefined {
  let subitems = getItemSubItems(item, month, type) ?? []
  subitems?.forEach((i) => (subitems = subitems.concat(getItemSubItemsRecursive(i, month, type) ?? [])))
  return subitems
}

export function rescueDeepChildren(items: FeedItem[]): FeedItem[] {
  if (items.length === 0) return []
  if (items.length > 1) return items
  else return rescueDeepChildren(getSubItemsAndSort(items[0]))
}

export function getSubItemsAndSort(item: FeedItem): FeedItem[] {
  const subItems = getItemSubItems(item, 1) ?? []
  return subItems.sort((a, b) => catalogRank(a.Rank) - catalogRank(b.Rank))
}

export function computeFeeAmounts(fee: Fee, item: FeedItem): Fee {
  const f = { ...fee }
  if (f.OTC !== undefined) f.OTC = typeof f.OTC !== 'number' ? f.OTC : (f.Rate ?? 1) * (item.qty ?? 1) * f.OTC
  if (f['Monthly Price'] !== undefined)
    f['Monthly Price'] =
      typeof f['Monthly Price'] !== 'number' ? f['Monthly Price'] : (f.Rate ?? 1) * (item.qty ?? 1) * f['Monthly Price']
  return f

  // let chrg = item[chargeType]
  // if (typeof chrg === 'number') {
  //   if (f.Rate) {
  //     chrg = f.Rate * chrg * (item.qty ?? 1)
  //   } else {
  //     chrg *= item.qty ?? 1
  //   }
  // }
  // f[chargeType] = chrg
}

export const getItemFees = (item: FeedItem, month: number, includePricedIntoParentFees = false): Fee[] => {
  const tempItem = { ...item }
  tempItem['Monthly Price'] = getItemPricePrivate(item, 'Monthly Price', month)
  tempItem['OTC'] = getItemPricePrivate(item, 'OTC', month)
  let allFees
  if (includePricedIntoParentFees === true) {
    allFees = (item.Fees ?? []).map((f) => computeFeeAmounts(f, tempItem))
  } else {
    allFees = (item.Fees ?? []).filter((i) => !shouldPriceIntoParent(i)).map((f) => computeFeeAmounts(f, tempItem))
  }
  if (month !== Infinity) {
    // filter out months
    allFees = allFees.filter((i) => (i.StartMonth ?? 0) <= month && month <= (i.EndMonth ?? Infinity))
  }
  return allFees
}

export function getItemFeesRecursive(item: FeedItem, month: number, includePricedIntoParentFees = false): Fee[] {
  const subItems = getItemSubItems(item, month, 'all')
  const subItemFees = flatten(subItems?.map((i) => getItemFeesRecursive(i, month)))
  const itemFees = getItemFees(item, month, includePricedIntoParentFees)
  const allFees = [...itemFees, ...subItemFees]
  return allFees
}

export function getPromoProgressions(item: FeedItem): string[] {
  return getRecurringItemPeriods(item).map((month) => {
    const price = getItemPriceAsNumber(item, 'Monthly Price', month.StartMonth).toFixed(2)
    return month.EndMonth !== Infinity ? `$${price} for months ${month.StartMonth} - ${month.EndMonth}` : `$${price} thereafter`
  })
}

export function shouldPriceIntoParent(item: FeedItem): boolean {
  return item.PriceIntoParent === true || item.PriceIntoParent === 'true'
}

const getNum = (s: string): number => {
  const num = Number(s)
  return isNaN(num) ? 0 : num
}

export const smartSum = (
  accumulator: number | string | undefined,
  increment: number | string | undefined
): number | string | undefined => {
  if (increment === undefined) {
    return accumulator
  } else if (accumulator === undefined) {
    accumulator = increment
    return accumulator
  } else {
    return typeof accumulator === 'number' && typeof increment === 'number'
      ? accumulator + increment
      : typeof accumulator === 'number' && typeof increment === 'string'
        ? accumulator + getNum(increment)
        : typeof accumulator === 'string' && typeof increment === 'string'
          ? `${accumulator},${increment}`
          : typeof accumulator === 'string' && typeof increment === 'number'
            ? increment
            : accumulator // should NEVER get here
  }
}

const getBillingCodeNames = (codes: BillingCode[], hideBillingCodes?: boolean) => {
  const bc: string[] = []
  if (codes) {
    for (let code of codes) {
      if (code['Display Name']) {
        bc.push(code['Display Name'])
      } else if (!hideBillingCodes && code['Billing Code']) {
        bc.push(code['Billing Code'])
      }
    }
  }

  return bc
}

const formatBillingCodes = (bc: string[]) => JSON.stringify(bc).replace(/"/g, '')

export const packageAndProductBillingCodes = (cart: ShoppingOrder, hideBillingCodes?: boolean): string => {
  const bc: string[][] = []
  // if (cart.package['Billing Codes']) {
  //   for (let code of cart.package['Billing Codes']) {
  //     bc.push(code)
  //   }
  // }
  bc.push(_getBillingCodes(cart.package, hideBillingCodes))
  // if (cart.products) {
  //   for (let prod of cart.products) {
  //     if (prod['Billing Codes']) {
  //       for (let code of prod['Billing Codes']) {
  //         bc.push(code)
  //       }
  //     }
  //   }
  // }
  //
  cart.products?.forEach((prod) => bc.push(_getBillingCodes(prod, hideBillingCodes)))

  return formatBillingCodes(flatten(bc))
}

const _getBillingCodes = (item: FeedItem, hideBillingCodes?: boolean) => {
  let itemBillingCodes: string[] = []
  if (item['Billing Codes']) {
    itemBillingCodes = getBillingCodeNames(item['Billing Codes'], hideBillingCodes)
  }
  let feeBillingCodes: string[] = []
  if (item.Fees) {
    feeBillingCodes = flatten(item.Fees?.map((fee) => _getBillingCodes(fee, hideBillingCodes)))
  }

  const combined = [...itemBillingCodes, ...feeBillingCodes]
  return combined
}

export const getBillingCodes = (item: FeedItem, hideBillingCodes?: boolean): string => {
  return formatBillingCodes(_getBillingCodes(item, hideBillingCodes))
}

// catalog.Packages.forEach(
//   (p) => (p['Monthly Price'] = p.Products.map((prd) => prd['Monthly Price']).reduce((a, b) => smartSum(a, b), 0))
// )
// catalog.Packages.forEach((p) => (p['OTC'] = p.Products.map((prd) => prd['OTC']).reduce((a, b) => smartSum(a, b), 0)))
